import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Company} from "../../../../../../model";

@Component({
    selector: 'app-company-list-item',
    standalone: true,
    imports: [],
    templateUrl: './company-list-item.component.html',
    styleUrl: './company-list-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyListItemComponent {
    
    @Input() company!: Company;
    @Input() arrowVisible = true;

}
