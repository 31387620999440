import {Component, OnInit, signal} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {FooterComponent} from "../../components/footer/footer.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {PureTryAiGuideComponent} from "./components/pure-try-ai-guide/pure-try-ai-guide.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {TryAiGuideFormData} from "./components/try-ai-guide-form/try-ai-guide-form.component";
import {TutorService} from "../../services/tutor.service";
import {OfferingType, TutorCoachingLevels} from "../../model";

@Component({
    selector: 'app-try-ai-guide',
    standalone: true,
    imports: [
        FooterCompactComponent,
        FooterComponent,
        MainHeaderComponent,
        PageHeaderComponent,
        FooterSmartComponent,
        PureTryAiGuideComponent,
        MainTitleComponent,
        RouterLink
    ],
    templateUrl: './try-ai-guide.component.html',
    styleUrl: './try-ai-guide.component.scss'
})
export class TryAiGuideComponent implements OnInit {

    userInitials = this.authService.userInitials;
    authenticated = this.authService.authenticated;

    offering = signal('');
    job = signal('');
    
    constructor(private authService: AuthService, 
                private route: ActivatedRoute,
                private router: Router,
                private tutorService: TutorService) {
    }
    
    ngOnInit() {
        const params = this.route.snapshot.params;
        this.offering.set(params['offering']);
        this.job.set(params['job']);
        this.authService.reloadProfile();
    }

    onSubmit(data: TryAiGuideFormData) {
        console.log('[TryAiGuideComponent] onSubmit', data);

        this.tutorService.roles.set([]);
        this.tutorService.start(TutorCoachingLevels.Intermediate,
            data.offering,
            OfferingType.EquipmentOrToolOrConsumable, 
            data.jobToBeDone,
            data.question);
        
        this.router.navigate(['/ai-guide', 'try']);
    }
}
