<div class="container">    
    <div class="level-title" [class.no-padding]="!showStep" qa="cl-title">@if (showStep) { 3. }Select how Claire should treat you for this interview:</div>
    
    <app-select-coaching-level [userInitials]="userInitials" [coachingLevel]="coachingLevel" (onSelect)="select($event)"/>    
</div>

<hr>

<div class="ready-to-start">
    @if (coachingLevel == TutorCoachingLevels.Beginner) {
        <div class="bold" qa="st-1">Ready to start?</div>
        <div class="bold" qa="st-2"><br>Click Begin to see the first interaction.</div>
    } @else {
        <div class="bold" qa="st-1">Ready to start?</div>
        <div class="text" qa="st-2">
            Ask the customer if they’re having any problems or challenges with {{ offeringName }} @if (job) {{{ offeringType | offeringAction:false }} {{ job }}}.
        </div>
    }

    @if(coachingLevel == TutorCoachingLevels.Beginner) {
        <div class="sp-form">
            <button class="primary start" (click)="onStart.emit('')" [disabled]="busy" qa="begin">
                @if (busy) {
                    <span class="material-symbols-outlined sp-spin">progress_activity</span> Starting...
                } @else {
                    Begin    
                }            
            </button>
        </div>
    } @else {
        <app-message-form [message]="'Before I tell you about our products, can you tell me what challenges you\'re facing today?'" (send)="onStart.emit($event)" />
    }

</div>