import {Component, EventEmitter, Output} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-mobile-nav',
    standalone: true,
    imports: [
        RouterLink,
        RouterLinkActive
    ],
    templateUrl: './mobile-nav.component.html',
    styleUrl: './mobile-nav.component.scss'
})
export class MobileNavComponent {
    @Output() onLogout = new EventEmitter();
}
