    
@if (selectedCategory || selectedOffering) {
    <app-edit-offering
            (onSave)="onSave.emit($event)"
            (onRemove)="onRemoveOffering.emit($event)"
            (onRemoveJob)="onRemoveJob.emit($event)"
            [category]="selectedCategory!"
            [offering]="selectedOffering"/>
} @else if (type) {
    <app-select-offering-category
            (categorySelected)="onCategorySelected.emit($event)" [type]="+type"
            [categories]="categories"/>
} @else {
    <app-select-offering-type (onSelect)="onSelectOfferingType.emit($event)"/>
}

