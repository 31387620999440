<app-main-header class="desktop-layout" />
<app-main-title class="mobile-layout" [routerLink]="['/']" [text]="'Create Account'"></app-main-title>

<app-main-panel class="sp-wrapped">
    @if (loadingProfile()) {
        <app-spinner/>
    }
    <app-pure-signup
            [signUpMode]="mode || 0"                
            (onSignup)="signup($event)"
            (signInWithGoogle)="signInWithGoogle()"
            [errorMessages]="errorMessages()"/>
</app-main-panel>

<app-footer-compact class="desktop-layout" />