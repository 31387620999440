import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
    ELearningModule,
    ELearningModuleStatus,
    ELearningModuleStatusEnum
} from "../../../../services/elearning.service";
import {CountLabelComponent} from "../count-label/count-label.component";

@Component({
    selector: 'app-elearning-stats',
    standalone: true,
    imports: [
        CountLabelComponent
    ],
    templateUrl: './elearning-stats.component.html',
    styleUrl: './elearning-stats.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElearningStatsComponent {
    @Input() moduleStatuses: ELearningModuleStatus[] = [];
    @Input() modules: ELearningModule[] = [];
    @Input() certified: Date | null | undefined;

    get completedModules() {
        return this.moduleStatuses
            .filter(x => x.status == ELearningModuleStatusEnum.Completed)
            .length;
    }

    getModule(number: number) {
        return this.modules.find(x => x.number == number);
    }

    getStatus(number: number) {
        return this.moduleStatuses.find(x => x.id == number)?.status;
    }

    isCompleted(number: number) {
        return this.getStatus(number) == ELearningModuleStatusEnum.Completed;
    }

    getTitle(number: number) {
        const m = this.getModule(number);
        const s = this.getStatus(number);

        return `Module ${number}: ${m?.title} - ${this.getStatusLabel(s)}`;
    }

    private getStatusLabel(m: ELearningModuleStatusEnum | undefined) {
        switch (m) {
            case ELearningModuleStatusEnum.Completed:
                return 'Completed';
            case ELearningModuleStatusEnum.InProgress:
                return 'In Progress';
            default:
                return 'Not completed';
        }

    }
}
