<h3>COMPANY</h3>

<div class="content">
    <div class="left">
        <div class="logo"></div>

        <h2>
            <b>A</b>dvanced <b>I</b>nnovation and <b>M</b>arketing for B2B
        </h2>
    </div>

    <div>
        <p>
            No, we’re not full-spectrum sales trainers. Many fine firms will teach you how to handle objections,
            negotiate, close, and other important sales skills. SalesPrep training is in <em>addition</em> to these skills. It’s
            the “fuel additive” that helps you get the most horsepower and mileage out of your trained sales force.
            <br><BR>
            <a href="https://theaiminstitute.com/services/new-product-blueprinting/"
               title="New Product Blueprinting for B2B Customer Innovation">New Product Blueprinting</a> is the world’s
            leading B2B voice-of-customer method for <em>new product development</em>.
            Now with SalesPrep tools, you can use these same B2B-optimized probing methods every day. To train your
            entire
            customer-facing team, check out our <a
                href="https://theaiminstitute.com/aim-product-development-training/everyday-voc-workshops/" title="Everyday VOC training workshops">Everyday VOC
            training</a> workshops. They’re a valuable 4-hour addition for
            your next annual sales or business meeting!
            <br><BR>
            <b>Intrigued, but not yet convinced?</b>
            &nbsp;<a routerLink="/contact-us">Contact us</a> to ask for a free
            demo and/or consultation.
        </p>
    </div>
</div>