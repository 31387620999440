<app-main-header class="sp-desktop"/>

<app-page-header 
        [title]="'Develop new skills with e-Learning'" 
        [text]="'Your SalesPrep reports help you prepare for customer meetings in seconds. This e-learning will help you impress them for years!'">
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="53.18" viewBox="0 0 66 53.18">
        <path id="E-Learning_Icon" data-name="E-Learning Icon" d="M-2846.262-966.819a.5.5,0,0,1-.494-.574l2.963-19.68a3.7,3.7,0,0,1-1.815-3.184,3.7,3.7,0,0,1,1.541-3.006v-6.718l-3.659-1.642a2.158,2.158,0,0,1-1.275-1.959,2.157,2.157,0,0,1,1.257-1.971l30.988-14.25a2.162,2.162,0,0,1,1.785-.007l30.7,13.775a2.071,2.071,0,0,1,.641.442,2.145,2.145,0,0,1,.634,1.516,2.154,2.154,0,0,1-1.257,1.973l-13.251,6.094a31.086,31.086,0,0,1,3.405,14.388c0,.544-.013,1.094-.04,1.635l-.069,1.412-21.082,8.014-.869.417h0l-.839-.417-20.749-7.643-.085-1.41c-.041-.713-.059-1.388-.059-2.008a31.175,31.175,0,0,1,3.27-14.122l-5.119-2.3v4.779a3.7,3.7,0,0,1,1.542,3.006,3.7,3.7,0,0,1-1.816,3.185l2.963,19.679a.5.5,0,0,1-.494.574Zm12.681-14.8c0,.118,0,.236,0,.354l17.418,6.417,17.735-6.744v-.027a26.661,26.661,0,0,0-3.013-12.582l-13.807,6.349a2.147,2.147,0,0,1-.9.2,2.186,2.186,0,0,1-.886-.189l-13.656-6.128A26.752,26.752,0,0,0-2833.581-981.623Zm20.092-25.328a2.158,2.158,0,0,1-1.614,2.592l-18.582,4.307,17.527,7.865,25.779-11.855-25.466-11.426-25.779,11.853,1.426.64,24.12-5.59a2.162,2.162,0,0,1,.488-.057A2.159,2.159,0,0,1-2813.489-1006.951Z" transform="translate(2849.002 1019.999)" fill="#fff"/>
    </svg>
</app-page-header>

<app-modules-list class="sp-wrapped sp-padding" [modules]="modules" [modulesStatuses]="modulesStatuses()" />

<app-footer-compact/>