<app-main-header [sticky]="false" />

<app-page-header [title]="'Privacy Policy'"/>

<div class="sp-wrapped content">
    
    <h1 class="first">Our Privacy Policy</h1>
    
    <p>We know that you care about your personal data and how it is used, and we want you to&nbsp;<b>trust</b>&nbsp;that the The AIM Institute uses your personal data carefully. This Privacy Policy will help you understand what personal data we collect, why we collect it and what we do with it.</p>
    <p>As you read our Policy, please keep in mind that it applies to the AIM Institute as defined below.</p>
    <p>Please take a moment to familiarise yourself with our privacy practices and let us know if you have any&nbsp;<b>questions</b>&nbsp;by sending us an&nbsp;<b>email</b>&nbsp;to&nbsp;<a href="mailto:Privacy@theaiminstitute.com">Privacy{{ '@' }}theaiminstitute.com</a></p>
    <p>We have tried to keep this Policy as simple as possible, but if you’re not familiar with terms, such as cookies, IP addresses, and browsers, then please read about these&nbsp;<a href="privacy#key-terms"><b>key terms</b></a>&nbsp;first.</p>
    <p>You have the&nbsp;<b>right to object</b>&nbsp;to certain uses of your personal data including the use of your personal data for direct marketing. See what your rights are and how you can exercise them&nbsp;<a href="privacy#8">here</a>.</p>
    
    <h1>Contents</h1>
    <ol>
        <li><a href="privacy#1">Who is collecting it?</a></li>
        <li><a href="privacy#2">What personal data is being collected?</a>
            <ol class="a">
                <li><a href="privacy#2a">Ways in which we collect your personal data</a></li>
                <li><a href="privacy#2b">When and why we collect “special categories of personal data”</a></li>
                <li><a href="privacy#2c">How do we protect children’s privacy?</a></li>
            </ol>
        </li>
        <li><a href="privacy#3">What purpose do we use your data for?</a>
            <ol class="a">
                <li><a href="privacy#3a">Profiling</a></li>
                <li><a href="privacy#3b">Automated decision-making</a></li>
            </ol>
        </li>
        <li><a href="privacy#4">Who will it be shared with?</a></li>
        <li><a href="privacy#5">International data transfers</a></li>
        <li><a href="privacy#6">How do we protect your personal data?</a></li>
        <li><a href="privacy#7">How long do we keep your personal data for?</a></li>
        <li><a href="privacy#8">What are your rights?</a></li>
        <li><a href="privacy#9">How do you contact The AIM Institute?</a></li>
        <li><a href="privacy#10">How do we keep this Policy up to date?</a></li>
        <li><a href="privacy#key-terms">Key terms</a></li>
    </ol>
    
    <h1 id="1" name="1">1. Who is collecting it?</h1>
    <p>Any personal data provided to or collected by the AIM Institute is controlled by the AIM Institute and one or more of the AIM Institute’s service providers that you are interacting with or have a business relationship with. This Privacy Policy applies to personal data collected by the AIM Institute (the data controllers) in connection with the services and products we offer.</p>
    <p>This Privacy Policy also applies to the AIM Institute’s marketing content, including offers and advertisements for the AIM Institute’s products and services, which we (or a service provider acting on our behalf) send to you on&nbsp;<b>third-party</b>&nbsp;websites, platforms and applications based on your site usage information. These third-party websites generally have their own Privacy Policy and Terms and Conditions. We encourage you to read them before using those websites.</p>
    
    <h1 id="2" name="2">2. What personal data is being collected?</h1>
    <p><b>Personal data</b>&nbsp;means any information that can be used to identify directly or indirectly a specific individual.</p>
    <p>You are&nbsp;<b>not required</b>&nbsp;to provide the AIM Institute the personal data that we request, but if you choose not to do so, we may not be able to provide you with our products or services, or with a high quality of service or respond to any queries you may have.</p>
    <p>We may collect personal data from a variety of&nbsp;<b>sources</b>. This includes:</p>
    
    <blockquote>
        Personal data you give us directly,<br>
        Personal data we collect automatically, and<br>
        Personal data we collect from other sources.<br>
    </blockquote>
    <p><b>Personal data</b>&nbsp;means any information that can be used to identify directly or indirectly a specific individual. This definition includes personal data collected offline (including when you speak to us by phone), direct marketing campaigns, sweepstakes and competitions and online through our websites, applications and branded pages on third-party platforms and applications accessed or used through third-party platforms.</p>
    <p>You may be asked to provide your personal data when you are in contact with us. The above-mentioned joint data controllers&nbsp;<b>may share</b>&nbsp;this personal data with each other and use it in a manner consistent with this Privacy Policy. We&nbsp;<b>may also combine</b>&nbsp;it with other information to improve our products, services, content, and advertising.</p>
    <p>You are&nbsp;<b>not required</b>&nbsp;to provide the AIM Institute the personal data that we request, but if you choose not to do so, we may not be able to provide you with our products or services, or with a high quality of service or respond to any queries you may have.</p>
    
    <h1 id="2a" name="2a">a. Ways in which we collect your personal data</h1>
    <p>We may collect personal data from a variety of&nbsp;<b>sources</b>. This includes:</p>
    
    <blockquote class="alternative">
        <b>Personal data you give us directly.</b>
        We collect data about how you use our services and products, such as the types of content you view or engage with, or the frequency and duration of your activities. We also collect personal data you provide us when you sign up for a marketing newsletter, complete a survey or register for an account to buy our products. In so doing, we may ask for personal data, such as your name, gender, date of birth, address, email address, telephone number or credit card details. The AIM Institute (and its service providers) may collect “special categories of personal data” about you with your explicit consent. For more information on the special categories of data we collect and how we use it, please refer to the relevant section below. 
    </blockquote>
    <blockquote class="alternative">
        <b>Personal data we collect automatically.</b>
        We also receive and store certain types of personal data whenever you interact with us online. For example, we use cookies and tracking technologies (to find out more, see our privacy key terms to obtain personal data when your web browser accesses our websites or advertisements and other content served by or on behalf of the AIM Institute on other websites. Your personal data is also collected when you search, buy, post, participate in a contest or questionnaire or communicate with our customer service teams. Examples of the types of personal data we collect include IP address device ID, location data, computer and connection information such as browser type and version, time zone. To find out more see our privacy key terms[link]. The AIM Institute sometimes aggregates with similar information from other consumers. During some of your internet browsing on the AIM Institute’s websites we may also use software tools to measure and collect session information, including page response times, download errors, length of visits to certain pages, page interaction information, and methods used to browse away from the page. We may also collect technical information to help us identify your device for fraud prevention and diagnostic purposes.
    </blockquote>
    <blockquote class="alternative">
        <b>Personal data we collect from other sources.</b>
        We collect personal data from other sources including our trusted partnerships with third-parties service providers and where we operate the AIM Institute accounts on third-party platforms: For example, when you use the “like” functionality on Facebook or the +1 functionality on Google+. Additionally, we receive information about you and other visitors’ interactions with our advertising to measure whether our advertising is relevant and successful. We also collect information about you and your activities from a third-party when we jointly offer services or products, or from third-party data enrichment providers (to find out more see our privacy key terms who may deliver insights to the AIM Institute about the personal data we hold).
    </blockquote>
    
    <h1 id="2b" name="2b">b.&nbsp;Special categories of personal data</h1>
    <p>Certain categories of personal data, such as race, ethnicity, religion, health, sexuality genetic or biometric data are classified as “<b>special categories of data</b>” and benefit from&nbsp;<b>additional protection</b>&nbsp;under the European data protection legislation.</p>
    <p>We&nbsp;<b>limit</b>&nbsp;the circumstances where we collect and process these special categories of data.<span class="Apple-converted-space">&nbsp;&nbsp;</span>The AIM Institute&nbsp;<b>does not collect</b>&nbsp;special category information through its public website. Workshop attendees may be asked to complete a survey that includes special category information. The AIM Institute only collects and uses this personal data where you have provided us with your consent for us to do so.</p>
    
    <h1 id="2c">c.&nbsp;How do we protect children’s privacy?</h1>
    <p>We understand the importance of taking&nbsp;<b>extra precautions</b>&nbsp;to protect the privacy and safety of children using the AIM Institute’s products and services.</p>
    <p>The AIM Institute’s websites are designed and&nbsp;<b>intended for use by adults</b>. If you are a child under the age where parental consent is required in your Country, you should review the terms of this Privacy Policy with your&nbsp;<b>parent</b>&nbsp;or&nbsp;<b>guardian</b>&nbsp;to make sure you understand and accept them. If we discover that we have collected personal data from a child without consent from a parent or guardian where such consent should have been obtained, we will&nbsp;<b>delete</b>&nbsp;that personal data as soon as practical. Access to certain parts of The AIM Institute’s websites and/or eligibility to receive prizes, samples or other rewards are generally limited to users over a certain age.</p>
    <p>We sometimes use your personal data to carry out&nbsp;<b>age verification</b>&nbsp;checks and enforce any such age restrictions.</p>
    
    <h1 id="3" name="3">3.&nbsp;What purpose do we use your data for?</h1>
    <p>We&nbsp;<b>collect</b>,&nbsp;<b>process</b>&nbsp;and&nbsp;<b>disclose</b>&nbsp;your personal data only for specific and limited&nbsp;<b>purposes</b>. For example, to process your<b>&nbsp;payments</b>, to assess and handle any&nbsp;<b>complaints</b>, to&nbsp;<b>develop and improve</b>&nbsp;our products, services, communication methods and the functionality of our websites, to provide&nbsp;<b>personalised</b>&nbsp;products, communications and targeted advertising as well as product recommendations to you.</p>
    <p>We also create profiles by analysing the information about your online surfing, searching and buying behavior and your interactions with our brand communications by building segments (creating groups that have certain common characteristics) and by placing your personal data in one or more segments.</p>
    <p>Additionally, the AIM Institute processes your personal data also using&nbsp;<b>automated means</b>. An automated decision is a decision which is made solely by automatic means, where&nbsp;<b>no humans</b>&nbsp;are involved in the decision-making process related to your personal data.</p>
    <p>We&nbsp;<b>collect</b>,&nbsp;<b>process</b>&nbsp;and&nbsp;<b>disclose</b>&nbsp;your personal data for the following&nbsp;<b>purposes</b>:</p>
    <blockquote class="alternative">
        To process your<b>&nbsp;payments</b>, if you purchase our products, to provide you with your&nbsp;<b>order status</b>, deal with your enquiries and requests, and assess and handle any&nbsp;<b>complaints</b>;
    </blockquote>
    <blockquote class="alternative">
        To process and answer your<b>&nbsp;inquiries</b>&nbsp;or to contact you to answer your questions and/or requests;
    </blockquote>
    <blockquote class="alternative">
        To&nbsp;<b>develop and improve</b>&nbsp;our products, services, communication methods and the functionality of our websites;
    </blockquote>
    <blockquote class="alternative">
        For the purposes of&nbsp;<b>competitions</b>&nbsp;or&nbsp;<b>promotions</b>&nbsp;that you have entered;
    </blockquote>
    <blockquote class="alternative">
        To communicate<b>&nbsp;information&nbsp;</b>to you and to manage your&nbsp;<b>registration</b>&nbsp;and/or&nbsp;<b>subscription</b>&nbsp;to our newsletter or other communications;
    </blockquote>
    <blockquote class="alternative">
        To manage our&nbsp;<b>everyday business needs</b>&nbsp;regarding your participation in our promotional activities or request;
    </blockquote>
    <blockquote class="alternative">
        To&nbsp;<b>authenticate the identity</b>&nbsp;of individuals contacting us by telephone, electronic means or otherwise;
    </blockquote>
    <blockquote class="alternative">
        For internal&nbsp;<b>training</b>&nbsp;and&nbsp;<b>quality assurance</b>&nbsp;purposes;
    </blockquote>
    <blockquote class="alternative">
        To understand and assess the&nbsp;<b>interests</b>,&nbsp;<b>wants</b>, and&nbsp;<b>changing needs</b>&nbsp;of consumers, to improve our website, our current products and services, and/or developing new products and services; and
    </blockquote>
    <blockquote class="alternative">
        To provide&nbsp;<b>personalised</b>&nbsp;products, communications and targeted advertising as well as product recommendations to you.
    </blockquote>
    <p>When we collect and use your personal data for purposes mentioned above or for other purposes, we will&nbsp;<b>inform</b>&nbsp;you before or at the time of collection.</p>
    <p>Where appropriate, we will ask for your&nbsp;<b>consent</b>&nbsp;to process the personal data. Where you have given consent for processing activities, you have the&nbsp;<b>right to withdraw your consent</b>&nbsp;at any time.</p>
    <p>In some cases, we rely on&nbsp;<b>legitimate interest</b>&nbsp;for processing your personal data. A legitimate interest could exist for example, when you sign up for a loyalty scheme and we use the personal data collected to conduct data analytics to improve our products or services. This ground will only be used where it is necessary to achieve a legitimate interest, for example to assist in the performance of a contract, or to optimise a service, and does not outweigh your rights as an individual. This legal basis will only be relied upon where there is no less intrusive way to process your personal data. We can assure you that if legitimate interest is used as a ground for processing your personal data, we will keep a record of this and you have the right to ask for this information.</p>
    
    <h1 id="3a">a.&nbsp;Profiling</h1>
    <p>The AIM Institute uses your personal data to build&nbsp;<b>profiles</b>. We create profiles by analysing the information about your online surfing, searching and buying behaviour and your interactions with our brand communications by building segments (creating groups that have certain common characteristics) and by placing your personal data in one or more segments. These segments are used by the AIM Institute to&nbsp;<b>personalise</b>&nbsp;the website and our communications to you (such as showing relevant content to you when you visit our site or in a newsletter to you), and to display relevant offers and advertisements from the AIM Institute brand on the AIM Institute site, and via third-party websites. The segments can also be used for third-party campaigns on the AIM Institute site. The AIM Institute profiles your data where you have provided&nbsp;<b>consent</b>&nbsp;for us to do so; for example, accepting the setting of cookies on your browser online or signing up for email newsletters from one of our brands.</p>
    <p>You can&nbsp;<b>withdraw your consent</b>&nbsp;to prevent your personal data being used this way at any time by changing your browser settings, see section of our Cookie Policy or&nbsp;<b>unsubscribing</b>&nbsp;to the use of your email address if you have logged into one of our websites or signed up to any marketing newsletters.</p>
    <p>By way of example –</p>
    <blockquote class="alternative">
        The AIM Institute collects data, with your consent, from:
        <ul>
            <li>Our websites about what you view and the<b>&nbsp;way you interact</b>&nbsp;with our content;</li>
            <li>Our&nbsp;<b>digital display advertising</b>&nbsp;that we serve to you on social platforms and other publisher’s websites; and</li>
            <li><b>Forms you fill</b>&nbsp;in online and send to us about what your interests are.</li>
        </ul>
    </blockquote><blockquote class="alternative">
        If you have asked to receive&nbsp;<b>emails</b>&nbsp;or&nbsp;<b>SMS communications</b>&nbsp;from us, we track whether you open, read or click on the content to see what you are interested in so that we can give you more content that we think you are more likely to enjoy.
    </blockquote><blockquote class="alternative">
        We use this data to profile your&nbsp;<b>likes</b>&nbsp;and&nbsp;<b>dislikes</b>.&nbsp; For instance, we may tailor our web content when you visit towards things we think you’ll be most interested in.
    </blockquote><blockquote class="alternative">
        Based on this profile information, we may also give you advertising that we think you will&nbsp;<b>like</b>&nbsp;and&nbsp;<b>want to see</b>&nbsp;as you view content from us or from our network of publishers that we advertise with. &nbsp;Sometimes, with your consent, we may use your current&nbsp;<b>location</b>&nbsp;to serve advertising to you that is to do with promotions or events that are happening nearby that we think you might be interested in.
    </blockquote><blockquote class="alternative">
        We may also use information you have provided to&nbsp;<b>selected third-parties</b>&nbsp;and&nbsp;<b>consented to be shared</b>, like your age, gender, life stage, lifestyle and wider interests to identify people who we think will have similar interests to you and who we believe will be interested in similar advertising.
    </blockquote>
    
    <h1 id="3b">b. Automated decision-making</h1>
    <p>In some instances, the AIM Institute processes your personal data using&nbsp;<b>automated means</b>. An automated decision is a decision which is made solely by automatic means, where&nbsp;<b>no humans</b>&nbsp;are involved in the decision-making process related to your personal data.</p>
    <p>We will not make decisions based solely on automated decision making that have significant impact on you. If we do so we notify you and provide you with&nbsp;<b>clear information</b>&nbsp;about our decision to rely on automated processing to make our decision and our lawful basis for doing so. For example, the AIM Institute processes your personal data using automated means only if it is necessary for the entering into or the performance of a&nbsp;<b>contract</b>&nbsp;with you, or when you have given your&nbsp;<b>explicit consent</b>.</p>
    <p>You have the right&nbsp;<b>not to be subject</b>&nbsp;to a decision which is based solely on automated processing and which produces legal or other significant effects on you. In particular, you have the right:</p>
    <blockquote class="alternative">to obtain&nbsp;<b>human intervention</b>;</blockquote>
    <blockquote class="alternative">to express your&nbsp;<b>point of view</b>;</blockquote>
    <blockquote class="alternative">to obtain an&nbsp;<b>explanation of the decision</b>&nbsp;reached after an assessment; and</blockquote>
    <blockquote class="alternative">to&nbsp;<b>challenge</b>&nbsp;such a decision.</blockquote>
    
    <h1 id="4">4.&nbsp;Who will it be shared with?</h1>
    <p>As a global business, the AIM Institute shares your personal data&nbsp;<b>internally</b>&nbsp;and with&nbsp;<b>selected third-parties</b>. For example, we share your personal data with&nbsp;<b>third-party service providers</b>,<b>&nbsp;other third-parties</b>, as well as in case of&nbsp;<b>business transfers</b>&nbsp;or&nbsp;<b>legal disclosure</b>.</p>
    <p>As a global business, the AIM Institute shares your personal data&nbsp;<b>internally</b>&nbsp;and with&nbsp;<b>selected third-parties</b>&nbsp;in the following circumstances:</p>
    <blockquote class="alternative"><b>Third-party service providers</b>. In order to carry out your requests, respond to your inquiries, fulfil your orders, or make various other features, services and materials available to you through our websites we share your personal data with&nbsp;<b>third-party service providers that perform</b>&nbsp;<b>functions on our behalf</b>, such as companies that: host or operate the AIM Institute websites, process payments, analyse data, provide customer service, postal or delivery services, and sponsors or other third-parties that participate in or administer our promotions. They have access to personal data needed to perform their functions but may not use it for other purposes. Further, they must process this personal data in accordance with this Privacy Policy and as permitted by applicable data protection laws and regulations.</blockquote>
    <blockquote class="alternative"><b>Other third-parties</b>. Your personal data will also be used by us or shared with our sponsors, advertisers, advertising networks, advertising servers, social media networks, and analytics companies or other third-parties in connection with&nbsp;<b>marketing</b>,&nbsp;<b>promotional</b>,&nbsp;<b>data enrichment</b>&nbsp;(to find out more about our privacy key terms follow the link and other offers, as well as product information.</blockquote>
    <blockquote class="alternative"><b>Business transfers</b>. Your personal data will be used by us or shared with joint data controllers for internal reasons, primarily for business and operational purposes.<span class="Apple-converted-space">&nbsp;&nbsp;</span>As we continue to develop our business, we may sell or purchase assets, subsidiaries or business units.<span class="Apple-converted-space">&nbsp;&nbsp;</span>In such transactions, your personal data generally is one of the&nbsp;<b>transferred business assets</b>&nbsp;but remains&nbsp;<b>subject to the promises made</b>&nbsp;in any pre-existing Privacy Policy (unless, of course, you consent otherwise). If another entity acquires us, our businesses or substantially all or part of our assets, or assets related to the AIM Institute’s website, your personal data will be disclosed to such entity as part of the&nbsp;<b>due diligence process</b>&nbsp;and will be transferred to such entity as one of the transferred assets.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Also, if any bankruptcy or reorganization proceeding is brought by or against us, all such personal data will be considered an asset of ours and as such it is possible they will be sold or transferred to third-parties.</blockquote>
    <blockquote class="alternative"><b>Legal disclosure</b>. We may transfer and disclose your personal data to third-parties:
        <ul>
            <li>To comply with a&nbsp;<b>legal obligation</b>;</li>
            <li>When we believe in&nbsp;<b>good faith</b>&nbsp;that an applicable law requires it;</li>
            <li>At the request of governmental authorities conducting an&nbsp;<b>investigation</b>;</li>
            <li>To verify or enforce our “<b>Terms of Use</b>” or other applicable policies;</li>
            <li>To detect and protect against&nbsp;<b>fraud</b>, or any&nbsp;<b>technical</b>&nbsp;or&nbsp;<b>security vulnerabilities</b>;</li>
            <li>To respond to an&nbsp;<b>emergency</b>; or otherwise</li>
            <li>To protect the&nbsp;<b>rights</b>,&nbsp;<b>property</b>,&nbsp;<b>safety</b>, or&nbsp;<b>security</b>&nbsp;of third-parties, visitors to the AIM Institute’s website, the AIM Institute or the public.</li>
        </ul>
    </blockquote>
    
    <h1 id="5">5.&nbsp;International data transfers</h1>
    <p>The AIM Institute shares personal data internally or with third-parties for purposes described in this Privacy Policy.</p>
    <p>The AIM Institute will only send personal data collected within the&nbsp;<b>European Economic Area</b>&nbsp;<b>(EEA)</b>&nbsp;to foreign countries in circumstances such as:</p>
    <blockquote class="alternative">To follow your&nbsp;<b>instructions</b>;</blockquote>
    <blockquote class="alternative">To transfer it within the AIM Institute;</blockquote>
    <blockquote class="alternative">To comply with a&nbsp;<b>legal duty</b>; or</blockquote>
    <blockquote class="alternative">To work with our agents and advisers who we use to help&nbsp;<b>run our business</b>&nbsp;and&nbsp;<b>services</b>.</blockquote>

    <p>If we do transfer personal data to outside of the EEA, the AIM Institute will make sure that it is&nbsp;<b>protected in the same way</b>&nbsp;as if it was being used in the EEA. We’ll use one of the following safeguards:</p>
    <blockquote class="alternative">Transfer to a non-EEA Country whose privacy legislation ensures an&nbsp;<b>adequate level of protection</b>&nbsp;of personal data to the EEA one;</blockquote>
    <blockquote class="alternative">Put in place a&nbsp;<b>contract</b>&nbsp;with the foreign third-party that means they must protect personal data to the same standards as the EEA; or</blockquote>
    <blockquote class="alternative">Transfer personal data to organisations that are part of specific agreements on cross-border data transfers with the European Union (e.g.,&nbsp;<b>Privacy Shield</b>, a framework that sets privacy standards for data sent between the United States and the European countries).</blockquote>
    
    <h1 id="6">6.&nbsp;How do we protect your personal data?</h1>
    <p>The AIM Institute takes the&nbsp;<b>security</b>&nbsp;of your personal data very seriously. We take every effort to&nbsp;<b>protect</b>&nbsp;your personal data from misuse, interference, loss, unauthorised access, modification or disclosure.</p>
    <p>Our measures include implementing appropriate&nbsp;<b>access controls</b>, investing in the latest&nbsp;<b>Information Security Capabilities</b>&nbsp;to protect the IT environments we leverage, and ensuring we&nbsp;<b>encrypt</b>, (and may in the future&nbsp;<b>pseudonymise</b>&nbsp;and&nbsp;<b>anonymise</b>) personal data wherever possible.</p>
    <p>Access to your personal data is only permitted among our employees and agents on a&nbsp;<b>need-to-know basis</b>&nbsp;and subject to strict&nbsp;<b>contractual confidentiality obligations</b>&nbsp;when processed by third-parties.</p>
    
    <h1 id="7">7.&nbsp;How long do we keep your personal data for?</h1>
    <p>We will keep your personal data for&nbsp;<b>as long as we need</b>&nbsp;it for the&nbsp;<b>purpose</b>&nbsp;it is being processed for. For example, where you make a purchase online with us we will keep the data related to your purchase, so we can perform the specific contract you have entered and after that, we will keep the personal data for a period which enables us to handle or respond to any complaints, queries or concerns relating to the purchase.</p>
    <p>Your data may also be retained so that we can continue to&nbsp;<b>improve your experience</b>&nbsp;with us and to ensure that you receive any loyalty rewards which are due to you.</p>
    <p>We retain the identifiable data we collect directly for targeting purposes for&nbsp;<b>as little time as possible</b>, after which we employ measures to&nbsp;<b>permanently delete</b>&nbsp;it.</p>
    <p>We will actively review the personal data we hold and&nbsp;<b>delete it securely</b>, or in some cases&nbsp;<b>anonymise</b>&nbsp;it, when there is no longer a legal, business or consumer need for it to be retained.</p>
    
    <h1 id="8">8.&nbsp;What are your rights?</h1>
    <p>You have rights in relation to how your personal data it is processed. You can exercise these rights at any point. We have provided an overview of these rights below together with what this entails for you. You can exercise your rights by sending an email to&nbsp;<a href="mailto:Privacy@theaiminstitute.com">Privacy{{ '@' }}theaiminstitute.com</a>.</p>
    <p>Where we process your personal data, you have a number of rights over how the data is processed and can exercise these rights at any point. We have provided an overview of these rights below together with what this entails for you. You can exercise your rights by sending an email to&nbsp;<a href="mailto:Privacy@theaiminstitute.com"><span class="s1">Privacy{{ '@' }}theaiminstitute.com</span></a>.</p>
    <blockquote class="alternative"><b>The right to be informed</b>. You have the right to be provided with clear, transparent and easily understandable information about how we use your personal data and your rights. Therefore, we’re providing you with the information in this Policy.</blockquote>
    <blockquote class="alternative"><b>The right to access and rectification</b>. You have the right to access, correct or update your personal data at any time. We understand the importance of this and should you want to exercise your rights, please contact us.</blockquote>
    <blockquote class="alternative"><b>The right to data portability</b>. The personal data you have provided us with is portable. This means it can be moved, copied or transmitted electronically under certain circumstances.</blockquote>
    <blockquote class="alternative"><b>The right to be forgotten</b>. Under certain circumstances, you have right to request that we delete your data. If you wish to delete the personal data we hold about you, please let us know and we will take reasonable steps to respond to your request in accordance with legal requirements. If the personal data we collect is no longer needed for any purposes and we are not required by law to retain it, we will do what we can to delete, destroy or permanently de-identify it.</blockquote>
    <blockquote class="alternative"><b>The right to restrict processing</b>. Under certain circumstances, you have the right to restrict the processing of your personal data.</blockquote>
    <blockquote class="alternative"><b>The right to object</b>. Under certain circumstances, you have the right to object to certain types of processing, including processing for direct marketing (i.e., receiving emails from us notifying you or being contacted with varying potential opportunities).</blockquote>
    <blockquote class="alternative"><b>The right to lodge a complaint with a Supervisory Authority</b>. You have the right to lodge a complaint directly with any local Supervisory Authority about how we process your personal data.</blockquote>
    <blockquote class="alternative"><b>The right to withdraw consent</b>. If you have given your consent to anything we do with your personal data (i.e., we rely on consent as a legal basis for processing your personal data), you have the right to withdraw your consent at any time (although if you do so, it does not mean that anything we have done with your personal data with your consent up to that point is unlawful). You can withdraw your consent to the processing of your personal data at any time by contacting us with the details provided below.</blockquote>
    <blockquote class="alternative"><b>Rights related to automated decision-making</b>. You have the right not to be subject to a decision which is based solely on automated processing and which produces legal or other significant effects on you. In particular, you have the right:
        <ul class="ul1">
            <li>to obtain&nbsp;<b>human intervention</b>;</li>
            <li>to express your&nbsp;<b>point of view</b>;</li>
            <li>to obtain an&nbsp;<b>explanation of the decision</b>&nbsp;reached after an assessment; and</li>
            <li>to&nbsp;<b>challenge</b>&nbsp;such a decision.</li>
        </ul>
    </blockquote>
    <p>Further information and advice about your rights can be obtained from the data protection Regulator in your Country.</p>
    
    <h1 id="9">9.&nbsp;How do you contact The AIM Institute?</h1>
    <p>The general point of contact for you for any data privacy queries you may have is&nbsp;<a href="mailto:Privacy@theaiminstitute.com">Privacy{{ '@' }}theaiminstitute.com</a>.</p>
    <p>If you have any&nbsp;<b>questions</b>&nbsp;or&nbsp;<b>concerns</b>&nbsp;about the AIM Institute’s Privacy Policy or data processing or if you would like to make a&nbsp;<b>complaint</b>&nbsp;about a possible breach of local privacy laws, please do so by sending an email mailto:&nbsp;<a href="mailto:Privacy@theaiminstitute.com"><span class="s1">Privacy{{ '@' }}theaiminstitute.com</span></a>.</p>
    <p>When a privacy question or access request is received we have a&nbsp;<b>dedicated staff&nbsp;</b>which triages the contacts and seeks to address the specific concern or query which you are seeking to raise. Where your issue may be more substantive in nature, more information may be sought from you. All such substantive contacts receive a response. If you are unsatisfied with the reply received, you may refer your complaint to the relevant Supervisory Authority in your Country. If you ask us, we will endeavour to provide you with information about relevant complaint avenues which may be applicable to your circumstances.</p>
    
    <h1 id="10">10.&nbsp;How do we keep this Policy up to date?</h1>
    <p>We will&nbsp;<b>update</b>&nbsp;this Privacy Policy when necessary to reflect customer feedback and changes in our products and services. When we post changes to this statement, we will revise the “<b>last updated</b>” date at the top of this Policy. If the changes are significant, we will provide a more prominent&nbsp;<b>Policy</b>&nbsp;(including, for certain services, email notification of Privacy Policy changes). We will also keep prior versions of this Privacy Policy in an archive for your review.</p>
    <p>We&nbsp;<b>will not reduce your rights</b>&nbsp;under this Privacy Policy without your consent.</p>
    
    <h1>Additional Privacy Terms or Policies</h1>
    <p>In addition to this Privacy Policy, there may be specific campaigns or promotions which will be governed by&nbsp;<b>additional privacy terms</b>or&nbsp;<b>Policies</b>. We encourage you to read these additional terms or Policies before participating in any such campaigns or promotions as you will be required to comply with them if you participate. Any additional privacy terms or Policies will be made prominently available to you.</p>
    
    <h1 id="key-terms">11. Key terms</h1>
    <p>This is a list of recurring terms in the AIM Institute’s Privacy Policy.</p>
    <blockquote class="alternative"><b>NOTE:</b>&nbsp;The scope of this document is global.</blockquote>
    <blockquote class="alternative"><b>NOTE:</b>&nbsp;The terms and the relevant definitions may change from time to time.</blockquote>
    
    <h1>Glossary</h1>
    <dl>
        <dt><strong>Anonymisation</strong></dt>
        <dd>The process of either encrypting or removing personal data from a database, so that the individuals whom the data describe remain anonymous. This is done for the purpose of protecting individuals’ private activities while maintaining the integrity of the data gathered and shared.</dd>
        <dt><strong>Behavioural Advertising</strong></dt>
        <dd>The act of tracking users’ online activities and then delivering ads or recommendations based upon the tracked activities.</dd>
        <dt><strong>Binding Corporate Rules (BCRs)</strong></dt>
        <dd>Personal data protection policies which are adhered to by a controller or processor established on the territory of a Member State for transfers or a set of transfers of personal data to a controller or processor in one or more third countries within a group of undertakings, or group of enterprises engaged in a joint economic activity,</dd>
        <dt><strong>Biometric Data</strong></dt>
        <dd>Personal data resulting from specific technical processing relating to the physical, physiological or behavioural characteristics of a natural person, which allow or confirm the unique identification of that natural person, such as facial images or dactyloscopy data.</dd>
        <dt><strong>Consent</strong></dt>
        <dd>Any freely given, specific, informed and unambiguous indication of the data subject’s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.</dd>
        <dt><strong>Cookies</strong></dt>
        <dd>A small text file stored on a user machine that may later be retrieved by a web server from the machine. Cookies allow web servers to keep track of the end user’s browser activities and connect individual web requests into a session.</dd>
        <dt><strong>Data Controller</strong></dt>
        <dd>An entity that has the authority over the processing of personal data. It controls the use of personal data by determining the purposes for its use and the way personal data will be processed.</dd>
        <dt><strong>Data Enrichment</strong></dt>
        <dd>A process used to enhance, refine or otherwise improve existing data.</dd>
        <dt><strong>Data Processing</strong></dt>
        <dd>Any operation or set of operations which is performed on personal data, such as collecting, recording, organizing, storing, adapting or altering, retrieving, consulting, using, disclosing by transmission, dissemination or otherwise making the data available, aligning or combining data, or blocking, erasing or destroying data. Not limited to automatic means.</dd>
        <dt><strong>Data Protection Officer</strong></dt>
        <dd>The individual appointed where required by law in a certain country to carry out certain responsibilities and functions in respect of privacy and data protection.</dd>
        <dt><strong>Data Retention</strong></dt>
        <dd>The policies and processes used within AIM Institute for determining the time period for archiving and storing of personal data.</dd>
        <dt><strong>Data Subject</strong></dt>
        <dd>The natural person that the personal data refers to.</dd>
        <dt><strong>Direct Marketing</strong></dt>
        <dd>A form of advertising in which companies provide physical marketing materials to consumers to communicate information about a product or service.</dd>
        <dt><strong>EEA</strong></dt>
        <dd>European Economic Area.</dd>
        <dt><strong>Encryption</strong></dt>
        <dd>The method by which plaintext or any other type of data is converted from a readable form to an encoded version that can only be decoded by another entity if they have access to a decryption key.</dd>
        <dt><strong>GDPR</strong></dt>
        <dd>General Data Protection Regulation.</dd>
        <dt><strong>Genetic Data</strong></dt>
        <dd>Personal data relating to the inherited or acquired genetic characteristics of a natural person which give unique information about the physiology or the health of that natural person and which result, in particular, from an analysis of a biological sample from the natural person in question.</dd>
        <dt><strong>Health Data</strong></dt>
        <dd>Personal data related to the physical or mental health of a natural person, including the provision of health care services, which reveal information about his or her health status</dd>
        <dt><strong>International Organisation</strong></dt>
        <dd>An organisation and its subordinate bodies governed by public international law, or any other body which is set up by, or on the basis of, an agreement between two or more countries.</dd>
        <dt><strong>IP Address</strong></dt>
        <dd>A unique address that identifies a device on the Internet or a local network and which allows a system to be recognized by other systems connected via the Internet protocol.</dd>
        <dt><strong>Online Behavioural Advertising</strong></dt>
        <dd>Websites or online advertising services that engage in the tracking or analysis of, e.g., search terms, browser or user profiles, preferences, demographics, online activity, offline activity, location data, and offer advertising based on that tracking.</dd>
        <dt><strong>Personal Data</strong></dt>
        <dd>Any information relating to an identified or identifiable individual. An identifiable individual is one who can be identified, directly or indirectly, in particular, by reference to an identification number or to one or more factors specific to his physical, physiological, mental, economic, cultural or social identity.</dd>
        <dt><strong>Personal Data Breach</strong></dt>
        <dd>A breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored or otherwise processed.</dd>
        <dt><strong>Privacy and Data Protection</strong></dt>
        <dd>The collection of laws and regulation that applies to the collection, usage, storage, protection and other processing of personal data.<span class="Apple-converted-space">&nbsp;&nbsp;</span>This includes data protection, privacy, banking secrecy, electronic communications and confidentiality laws and regulations, and any other applicable laws or regulations to the extent they relate to privacy of personal data.</dd>
        <dt><strong>Privacy Champion</strong></dt>
        <dd>An internal employee who serves as the privacy practice sponsor and acts as an advocate to further foster privacy as a core organization concept.</dd>
        <dt><strong>Processor</strong></dt>
        <dd>A natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.</dd>
        <dt><strong>Profiling</strong></dt>
        <dd>Any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person’s performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements.</dd>
        <dt><strong>Pseudonymization</strong></dt>
        <dd>The processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organisational measures to ensure that the personal data are not attributed to an identified or identifiable natural person.</dd>
        <dt><strong>Recipient</strong></dt>
        <dd>A natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third-party or not. However, public authorities which may receive personal data in the framework of a inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing of those data by those public authorities shall be in compliance with the applicable data protection rules according to the purposes of the processing;</dd>
        <dt><strong>Representative</strong></dt>
        <dd>Natural or legal person established in the Union who, designated by the controller or processor in writing pursuant to Article 27, represents the controller or processor regarding their respective obligations under this Regulation</dd>
        <dt><strong>Restriction of Processing</strong></dt>
        <dd>The marking of stored personal data with the aim of limiting their processing in the future.</dd>
        <dt><strong>Special Categories of Personal Data</strong></dt>
        <dd>Special categories of personal data include: racial or ethnic origin, political opinions, religious or philosophical beliefs, trade-union membership, genetic data, biometric data (for uniquely identifying an individual) and of data concerning health, sex life or sexual orientation.</dd>
        <dt><strong>Supervisory Authority</strong></dt>
        <dd>Independent Authority or division associated with an Authority in any relevant jurisdiction, whose primary purpose and function is to regulate matters related to personal data.</dd>
        <dt><strong>Third-Party</strong></dt>
        <dd>A natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.</dd>
    </dl>
    <p><em>Version: 25 May 2018</em></p>
</div>

<app-footer-smart/>