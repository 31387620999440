import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges, Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {DiscussionTopic, ReportSectionRating} from "../../../../model";
import {DiscussionTopicItemComponent} from "../discussion-topic-item/discussion-topic-item.component";
import {RateSectionComponent} from "../../../../components/rate-section/rate-section.component";
import {RateSectionFooterComponent} from "../../../../components/rate-section-footer/rate-section-footer.component";

@Component({
    selector: 'app-discussion-topics-list',
    standalone: true,
    imports: [
        DiscussionTopicItemComponent,
        RateSectionComponent,
        RateSectionFooterComponent
    ],
    templateUrl: './discussion-topics-list.component.html',
    styleUrl: './discussion-topics-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscussionTopicsListComponent implements OnChanges {
    @Input() rating!: ReportSectionRating;
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();
    
    @Input() title = '';
    @Input() topics!: DiscussionTopic[] | undefined;    
    @Input() icon = 1;

    @ViewChildren(DiscussionTopicItemComponent) items!: QueryList<DiscussionTopicItemComponent>;
    
    @HostBinding('class.flat') @Input() flat = false;

    selectedTopics!: DiscussionTopic[] | undefined;
    
    ngOnChanges() {
        this.selectedTopics = this.topics?.filter(x => x.selected) ?? [];
    }

    expandAll() {
        console.log('[DiscussionTopicsListComponent] expandAll');
        this._set(true);
    }

    collapseAll() {
        console.log('[DiscussionTopicsListComponent] collapseAll');
        this._set(false);
    }
    
    _set(expanded: boolean) {
        this.items.forEach(item => item.expanded = expanded);
    }
}
