import {MyProblemBriefReportGenerator} from "./problem-brief-report-generator";
import {IReportPart} from "../pdf-export/ireport-part";
import {PdfGeneratorBase} from "../pdf-export";

export class BriefDisclaimerSection implements IReportPart {
    private marginTop = 20;
    private sectionHeight = 30;
    
    constructor(private pdf: PdfGeneratorBase) {
    }
    
    draw(y: number) {
        const pdf = this.pdf;
        const styles = pdf.styles;
        const x = pdf.pageWidth / 2;
        pdf.text('THIS WORK PRODUCT HAS BEEN GENERATED BY AI AND SHOULD BE REVIEWED CAREFULLY.', x, y + this.marginTop, 'Raleway-ExtraBold', 8, 0, {
            align: 'center'
        });
        return y + this.sectionHeight + this.marginTop;
    }
}