<app-main-panel>
    
    <div class="left">

        @if (error) {
            
            @if (error == 'Email already confirmed') {
                <h1>Email already confirmed</h1>
                <div class="note">
                    You can <a class="sp-link" routerLink="/login">login</a> now.
                </div>
                
            } @else {
                
                <h1>Email Verification Failed</h1>
                <div class="note bold">
                    Something Went Wrong
                </div>
                <div class="note">
                    We were unable to verify your email address. This could be due to an invalid or expired verification code.
                </div>
    
                <div class="details">
                    <h2>
                        Possible Reasons:
                    </h2>
                    <ul>
                        <li><strong>Expired Code:</strong> The verification code has expired.</li>
                        <li><strong>Invalid Code:</strong> The verification code you provided is incorrect.</li>
                    </ul>
    
                    <h2>What Can You Do?</h2>
                    
                    <ul>
                        <li>
                            <strong>
                                Request a New Verification Email:
                            </strong>
                            <ul>
                                <li>Click the link below to request a new verification email:</li>
                                <li><a class="sp-link" (click)="onResendVerificationEmail.emit()">Resend Verification Email</a>.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Contact Support:</strong>
                            <ul>
                                <li>If you continue to experience issues, please <a class="sp-link" [routerLink]="['/contact-us']">contact our support team</a> for assistance.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            }
        } @else {
            <h1>Verifying Your Email Address</h1>
            <div class="note bold">
                Please Wait...
            </div>                
            <div class="note">
                We are currently verifying your email address. This process may take a few moments.
            </div>
            <span class="material-symbols-outlined sp-spin">progress_activity</span>
        }
    </div>
    
</app-main-panel>
