import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {DatePipe, LowerCasePipe} from "@angular/common";
import {MainPanelComponent} from "../../../../../../components/main-panel/main-panel.component";
import {
    ReportBuilderHeaderComponent
} from "../../../../components/report-builder-header/report-builder-header.component";
import {Company } from "../../../../../../model";
import {Offering} from "../../../../../../model/offering";
import {TopProgressBarComponent} from "../../../../components/top-progress-bar/top-progress-bar.component";
import {InfoPanelComponent} from "../../../../../../components/info-panel/info-panel.component";
import {FormsModule} from "@angular/forms";
import {AutofocusDirective} from "../../../../../../directives/autofocus.directive";
import {SideProgressBarComponent} from "../../../../components/side-progress-bar/side-progress-bar.component";
import {Router, RouterLink} from "@angular/router";
import {JobSelectedEvent, Role, SelectJobFormComponent} from "../select-job-form/select-job-form.component";
import {OfferingActionPipe} from "../../../../../../pipes/offering-action.pipe";
import {OfferingActionTipPipe} from "../../../../../../pipes/offering-action-tip.pipe";
import {
    PureAddOfferingComponent
} from "../../../../../profile/components/pure-add-offering/pure-add-offering.component";

@Component({
  selector: 'app-pure-select-job',
  standalone: true,
    imports: [
        LowerCasePipe,
        MainPanelComponent,
        ReportBuilderHeaderComponent,
        TopProgressBarComponent,
        InfoPanelComponent,
        PureAddOfferingComponent,
        FormsModule,
        AutofocusDirective,
        SideProgressBarComponent,
        RouterLink,
        DatePipe,
        SelectJobFormComponent,
        OfferingActionPipe,
        OfferingActionTipPipe
    ],
  templateUrl: './pure-select-job.component.html',
  styleUrl: './pure-select-job.component.scss'
})
export class PureSelectJobComponent {
    @Input() company!: Company;
    @Input() offering!: Offering;
    
    @Output() onSelect = new EventEmitter<JobSelectedEvent>();
    @Output() onAdd = new EventEmitter();
    @Output() onSkip = new EventEmitter();

    @HostBinding('class.sp-screen') s = true;

    @Input() addNew = false;
    @Input() job = '';
    @Input() roles: Role[] = [];


    @Input() set rolesList(roles: string[]) {
        this.roles = roles.map(x => <Role> {name: x})
    }

    constructor(private router: Router) {
    }

    goBack() {
        this.router.navigate(['/report-builder', 'offering']);
    }
}
