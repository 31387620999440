import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output
} from '@angular/core';
import {AutofocusDirective} from "../../../../../../directives/autofocus.directive";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@Component({
    selector: 'app-company-not-found',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule
    ],
    templateUrl: './company-not-found.component.html',
    styleUrl: './company-not-found.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyNotFoundComponent {
    @Input() companyNotFound = false;
    @HostBinding('class.sp-typography') t = true;

    @Output() onNext = new EventEmitter();

}
