<app-main-header class="sp-desktop"/>

<app-pure-profile class="sp-wrapped"
                  (onLogout)="authService.logout()"
                  (onAddOffering)="addOfferingMode()"
                  (onEditOffering)="selectedOffering.set($event)"
                  [signUpMode]="setupOfferings()"
                  [userProfile]="userProfile()!"
                  [offerings]="offerings()!"/>

<app-footer-compact/>

@if (addOffering() || selectedOffering()) {
    <app-info-panel [title]="'Add Offering'" (onClose)="cancelDialog()">
        <app-pure-add-offering
                class="sp-form-alternative"

                (onSelectOfferingType)="type.set($event)"
                (onCategorySelected)="category.set($event)"
                (onSave)="saveOffering($event)"
                (onRemoveOffering)="removeOffering($event)"
                (onRemoveJob)="removeJob($event)"

                [inline]="true"
                [type]="type()"
                [selectedCategory]="category()"
                [selectedOffering]="selectedOffering()"
                [categories]="categories()"
        />

    </app-info-panel>
}