import {Injectable, signal} from '@angular/core';
import {catchError, retry} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {UserProfile} from "../model/userProfile";
import {SignUpMode} from "../model";

@Injectable({
    providedIn: 'root'
})
export class SignupService {

    signedUpEmail = signal('');

    constructor(private http: HttpClient) {
    }

    signup(data: SignUpDetails) {
        const trimmedData: SignUpDetails = {
            firstName: data.firstName.trim(),
            lastName: data.lastName.trim(),
            email: data.email.trim(),
            password: data.password.trim(),
            code: ('' + data.code),
            mode: data.mode
        };
        
        return this.http.post('/api/auth/create-account', trimmedData).pipe(
            retry(1)
        );
    }

    getPaymentLink(token: string) {
        return this.http.get<PaymentLink>(`/api/payment-link/${token}`).pipe(
            retry(1)
        );
    }

    updateProfile(data: UserProfile) {
        return this.http.post('/api/user/profile', {
            firstName: data.firstName,
            lastName: data.lastName,
            company: data.company,
            linkedIn: data.linkedIn,
        }).pipe(
            retry(1)
        );
    }
}

export interface SignUpDetails {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    code?: string;
    mode: number;
}

export interface PaymentLink {
    trialPeriodDays: number;
}