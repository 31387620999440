import {Component, ElementRef, ViewChild} from '@angular/core';
import {PricingComponent} from "../splash/components/pricing/pricing.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {PureSplashComponent} from "../splash/components/pure-splash/pure-splash.component";
import {Router} from "@angular/router";
import {SignUpMode} from "../../model";
import {AuthService} from "../../services/auth.service";
import {
    TwelveWayComparitionGridComponent
} from "../twelve-way-slider-screen/components/twelve-way-comparition-grid/twelve-way-comparition-grid.component";

@Component({
    selector: 'app-pricing-screen',
    standalone: true,
    imports: [
        PricingComponent,
        MainPanelComponent,
        FooterSmartComponent,
        MainHeaderComponent,
        PureSplashComponent,
        TwelveWayComparitionGridComponent
    ],
    templateUrl: './pricing-screen.component.html',
    styleUrl: './pricing-screen.component.scss'
})
export class PricingScreenComponent {
    authenticated = this.authService.authenticated;
    @ViewChild('grid', { static: true }) grid: ElementRef | undefined;
    SignUpMode = SignUpMode;
    
    constructor(private router: Router, private authService: AuthService) {
    }

    signUp(mode: SignUpMode = SignUpMode.Trial) {
        console.log('[PureSplashComponent] startTrial');
        if (this.authenticated()) {
            this.router.navigate(['/profile']);
        } else {
            this.router.navigate(['/signup', mode]);
        }
    }

    contactUs() {
        this.router.navigate(['/contact-us']);
    }
    
    scrollToGrid() {
        this.grid?.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
}
