import {AfterViewInit, Component, ElementRef, HostBinding, Renderer2, ViewChild} from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {FooterComponent} from "../../components/footer/footer.component";
import {JsscriptService} from "../../services/jsscript.service";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";

@Component({
    selector: 'app-cookie-policy',
    standalone: true,
    imports: [
        MainHeaderComponent,
        PageHeaderComponent,
        FooterComponent,
        FooterSmartComponent
    ],
    templateUrl: './cookie-policy.component.html',
    styleUrl: './cookie-policy.component.scss'
})
export class CookiePolicyComponent implements AfterViewInit {
    @HostBinding('class.sp-typography') t = true;

    @ViewChild('wrapper', {read: ElementRef}) wrapper!: ElementRef;

    constructor(private renderer: Renderer2, private jsScriptService: JsscriptService) {
    }

    ngAfterViewInit() {
        if (this.wrapper) {
            this.jsScriptService.loadScript(this.renderer, this.wrapper,
                'https://consent.cookiebot.com/fe14b9f3-05ab-4553-977d-4b84d7171f47/cd.js',
                true,
                'CookieDeclaration'
            );
        }
    }
}