<div class="overflow" qa="rep-duv">
    <div class="label" qa="comp-lbl">Company</div>
    <app-multiselect (valueChange)="updateFilter()" [(value)]="filter.selectedCompany" [items]="companiesItems" qa="comp"/>
    
    <div class="label" qa="date-lbl">Meeting Date</div>
    <app-date-filter (valueChange)="updateFilter()" [(value)]="filter.meetingDateFilter" qa="date"/>
    
    <div class="label" qa="with-lbl">Meeting With</div>
    <app-multiselect (valueChange)="updateFilter()" [items]="rolesItems" [(value)]="filter.roles" qa="with"/>
    
    <div class="label" qa="off-lbl">Offering</div>
    <app-multiselect (valueChange)="updateFilter()" [items]="offeringsItems" [(value)]="filter.offerings" qa="off"/>
    
    <div class="label" qa="view-lbl">Last Viewed</div>
    <app-date-filter (valueChange)="updateFilter()" [position]="'top'" [(value)]="filter.lastViewedFilter" qa="view"/>
    
    <div class="label" qa="mod-lbl">Last Modified</div>
    <app-date-filter (valueChange)="updateFilter()" [position]="'top'" [(value)]="filter.lastModifiedFilter" qa="mod"/>
</div>

<div class="sp-form actions">
    <button class="clear" type="button" (click)="clearFilters()" qa="clear">Clear Filters</button>
    <button class="apply primary" type="button" (click)="applyFilters()" qa="apply">Apply Filters</button>
</div>