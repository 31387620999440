<form #tutorForm="ngForm" (ngSubmit)="submit(tutorForm)"> 
    <textarea appAutosize appAutofocus [autofocus]="1" required rows="1" name="message" id="message" [(ngModel)]="message" (keydown)="keydown($event, tutorForm)" placeholder="Ask the customer..." qa="ask-ai"></textarea>

    <button type="submit" [disabled]="tutorForm.invalid" qa="sub">
        <svg xmlns="http://www.w3.org/2000/svg" width="27.578" height="23.635" viewBox="0 0 27.578 23.635">
            <g id="Icon_material-send" data-name="Icon material-send" transform="translate(-0.997 -1.468)" fill="none">
                <path d="M3.01,22.071,23.5,13.286,3.01,4.5,3,11.333l14.643,1.952L3,15.238Z" stroke="none"/>
                <path d="M 3.009758949279785 22.07142448425293 L 23.5 13.2857141494751 L 3.009758949279785 4.500004291534424 L 2.999999046325684 11.33333396911621 L 17.64285850524902 13.2857141494751 L 2.999999046325684 15.23809432983398 L 3.009758949279785 22.07142448425293 M 1.014088988304138 25.10321426391602 L 0.9974990487098694 13.48739433288574 L 2.510072946548462 13.2857141494751 L 0.9974990487098694 13.08403396606445 L 1.014088988304138 1.468214154243469 L 28.57513999938965 13.2857141494751 L 1.014088988304138 25.10321426391602 Z" stroke="none" fill="#fff"/>
            </g>
        </svg>
    </button>

</form>