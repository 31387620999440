import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {EditOfferingComponent} from "../edit-offering/edit-offering.component";
import {MainHeaderComponent} from "../../../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../../../components/main-panel/main-panel.component";
import {MainTitleComponent} from "../../../../components/main-title/main-title.component";
import {SelectOfferingCategoryComponent} from "../select-offering-category/select-offering-category.component";
import {SelectOfferingTypeComponent} from "../select-offering-type/select-offering-type.component";
import {OfferingCategory} from "../../../../model";
import {OfferingType} from "../../../../model/offeringType";
import {Offering} from "../../../../model/offering";

@Component({
    selector: 'app-pure-add-offering',
    standalone: true,
    imports: [
        EditOfferingComponent,
        MainHeaderComponent,
        MainPanelComponent,
        MainTitleComponent,
        SelectOfferingCategoryComponent,
        SelectOfferingTypeComponent
    ],
    templateUrl: './pure-add-offering.component.html',
    styleUrl: './pure-add-offering.component.scss'
})
export class PureAddOfferingComponent {
    @Input() type: OfferingType | undefined;
    @Input() category: number | undefined;
    
    @Input() selectedCategory: OfferingCategory | undefined;
    @Input() selectedOffering: Offering | undefined;
    @Input() categories: OfferingCategory[] = [];

    @Output() onShowHome = new EventEmitter();
    @Output() onCategorySelected = new EventEmitter<OfferingCategory>();
    @Output() onSelectOfferingType = new EventEmitter<OfferingType>();
    @Output() onSave = new EventEmitter<Offering>();
    @Output() onRemoveOffering = new EventEmitter<Offering>();
    @Output() onRemoveJob = new EventEmitter<RemoveJobArgs>();

    @HostBinding('class.sp-screen') s = true;
    @Input() inline = false;
    
    showHome() {
        this.onShowHome.emit();
    }
}

export interface RemoveJobArgs {
    offering: Offering;
    jobIndex: number;
}
