<span class="tablet-layout text" [class.rated]="rating.rating !== undefined" qa="rate-lbl">Rate this section</span>

@if (rating.rating == true) {    
    <svg xmlns="http://www.w3.org/2000/svg" width="16.766" height="17" viewBox="0 0 16.766 17" qa="th-up-sel">
        <path id="Thumb_up_selected" data-name="Thumb up selected" d="M15.227,18.75H4.5A2.253,2.253,0,0,1,2.25,16.5V11.25A2.253,2.253,0,0,1,4.5,9H6.263l2.8-6.3A.75.75,0,0,1,9.75,2.25a3,3,0,0,1,3,3V7.5h3.491a2.25,2.25,0,0,1,2.245,2.588l-1.035,6.751A2.26,2.26,0,0,1,15.227,18.75ZM5.043,10.8a.746.746,0,0,0-.75.745l-.076,4.623a.885.885,0,0,0,.826.824H6V10.8Z" transform="translate(-2 -2)" fill="#00376f" stroke="#fff" stroke-linecap="round" stroke-width="0.5"/>
    </svg>
} @else {
    <a (click)="set(true)">    
        <svg xmlns="http://www.w3.org/2000/svg" width="16.265" height="16.5" viewBox="0 0 16.265 16.5" qa="th-up">
            <path id="Thumb_up_outline" data-name="Thumb up outline" d="M12,8.25v-3A2.25,2.25,0,0,0,9.75,3l-3,6.75V18h8.46a1.5,1.5,0,0,0,1.5-1.275l1.035-6.75a1.5,1.5,0,0,0-1.5-1.725ZM6.75,18H4.5A1.5,1.5,0,0,1,3,16.5V11.25a1.5,1.5,0,0,1,1.5-1.5H6.75" transform="translate(-2.25 -2.25)" fill="none" stroke="#b5b5b5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </svg>
    </a>
}

@if (rating.rating === false) {
    <svg xmlns="http://www.w3.org/2000/svg" width="16.766" height="17" viewBox="0 0 16.766 17" qa="th-dow-sel">
        <path id="Thumb_down_selected" data-name="Thumb down selected" d="M15.227,18.75H4.5A2.253,2.253,0,0,1,2.25,16.5V11.25A2.253,2.253,0,0,1,4.5,9H6.263l2.8-6.3A.75.75,0,0,1,9.75,2.25a3,3,0,0,1,3,3V7.5h3.491a2.25,2.25,0,0,1,2.245,2.588l-1.035,6.751A2.26,2.26,0,0,1,15.227,18.75ZM5.043,10.8a.746.746,0,0,0-.75.745l-.076,4.623a.885.885,0,0,0,.826.824H6V10.8Z" transform="translate(18.766 19) rotate(180)" fill="#00376f" stroke="#fff" stroke-linecap="round" stroke-width="0.5"/>
    </svg>
} @else {
    <a (click)="set(false)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.265" height="16.5" viewBox="0 0 16.265 16.5" qa="th-dow">
            <path id="Thumb_down_outline" data-name="Thumb down outline" d="M9.238,12.75v3A2.25,2.25,0,0,0,11.488,18l3-6.75V3H6.028a1.5,1.5,0,0,0-1.5,1.275l-1.035,6.75a1.5,1.5,0,0,0,1.5,1.725ZM14.488,3h2a1.732,1.732,0,0,1,1.747,1.5V9.75a1.732,1.732,0,0,1-1.747,1.5h-2" transform="translate(-2.723 -2.249)" fill="none" stroke="#b5b5b5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </svg>
    </a>
}
