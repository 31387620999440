@switch (icon) {
    @case (1) {
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10.8" viewBox="0 0 18 10.8">
            <path id="Trend_Icon" data-name="Trend Icon" d="M15.6,9l2.061,2.061-4.392,4.392-3.6-3.6L3,18.531,4.269,19.8l5.4-5.4,3.6,3.6,5.67-5.661L21,14.4V9Z" transform="translate(-3 -9)" fill="#b5b5b5"/>
        </svg>
    }
    @case (2) {
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Common_Problem_Icon" data-name="Common Problem Icon" transform="translate(-29 -498)">
                <g id="Ellipse_39" data-name="Ellipse 39" transform="translate(29 498)" fill="none" stroke="#b5b5b5" stroke-width="2">
                    <circle cx="9" cy="9" r="9" stroke="none"/>
                    <circle cx="9" cy="9" r="8" fill="none"/>
                </g>
                <path id="Icon_metro-warning" data-name="Icon metro-warning" d="M18.843,23.214a1.388,1.388,0,1,1-1.388-1.388A1.388,1.388,0,0,1,18.843,23.214Zm-1.388-2.776a1.388,1.388,0,0,1-1.388-1.388V14.884a1.388,1.388,0,0,1,2.776,0v4.165A1.388,1.388,0,0,1,17.455,20.437Z" transform="translate(20.839 488.531)" fill="#b5b5b5"/>
            </g>
        </svg>
    }
    @case (3) {
        <svg xmlns="http://www.w3.org/2000/svg" width="16.777" height="16.268" viewBox="0 0 16.777 16.268">
            <g id="Process_Step_Icon" data-name="Process Step Icon" transform="translate(-198.326 -590.505)">
                <circle id="Ellipse_90" data-name="Ellipse 90" cx="7.743" cy="7.743" r="7.743" transform="translate(198.327 590.505)" fill="#fff"/>
                <path id="Union_3" data-name="Union 3" d="M-770.816-3141.939l5.2.023-.744,1.29a5.671,5.671,0,0,0,2.1.4,5.721,5.721,0,0,0,4.476-2.148,2.594,2.594,0,0,0,1.813.916,7.77,7.77,0,0,1-6.289,3.232,7.654,7.654,0,0,1-3.109-.652l-.829,1.435Zm3.9,2.287c0-.007.009-.014.013-.022Zm.018-.031a.264.264,0,0,0,.015-.026Zm6.518-7.579h1.722a5.736,5.736,0,0,0-4.446-4.337,2.6,2.6,0,0,0,.266-1.986,7.658,7.658,0,0,1,4.229,2.308,7.694,7.694,0,0,1,1.992,4.015h1.4l-2.581,4.517Zm2.645,0h0Zm-13.736,4.093a7.687,7.687,0,0,1-.525-2.8,7.724,7.724,0,0,1,3.289-6.334l-.707-1.224,5.2-.023-2.621,4.494-.867-1.5a5.76,5.76,0,0,0-2.3,4.59,5.711,5.711,0,0,0,.4,2.1c-.032,0-.065,0-.093,0a2.573,2.573,0,0,0-1.777.708v0Z" transform="translate(970.326 3744.219)" fill="#b5b5b5"/>
            </g>
        </svg>
    }
    @case (4) {
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16.103" viewBox="0 0 17 16.103">
            <path id="Reminder_Icon" data-name="Reminder Icon" d="M6.525,14.22h3.763a1.881,1.881,0,1,1-3.763,0ZM2.763,13.28a.942.942,0,0,1-.944-.941.917.917,0,0,1,.253-.638C2.641,11.089,3.7,10.171,3.7,7.163A4.644,4.644,0,0,1,7.466,2.6V1.987a.941.941,0,1,1,1.881,0V2.6A4.643,4.643,0,0,1,13.11,7.163c0,3.008,1.063,3.927,1.631,4.537a.918.918,0,0,1,.253.638.942.942,0,0,1-.944.941ZM15.117,6.714a6.229,6.229,0,0,0-1.833-4.227,6.332,6.332,0,0,0-1.154-.913L13.161,0A8.151,8.151,0,0,1,17,6.714ZM0,6.714A8.15,8.15,0,0,1,3.839,0L4.871,1.573a6.313,6.313,0,0,0-1.154.913A6.23,6.23,0,0,0,1.883,6.714Z" fill="#b5b5b5"/>
        </svg>
    }
}
<div class="container" qa="cont">
    <a class="title" (click)="expanded = !expanded" qa="tit-a">
        <div qa="topic">{{ topic.title }}</div>
        <i class="material-icons remove" qa="rem">@if (expanded) { remove } @else { add }</i>
    </a>
    
    @if (expanded) {
        <p qa="descr">{{ topic.description }}</p>
        
        @if (icon != 4) {
            <p>
                @if (topic.reportGenerating) {
                    <app-report-generation-indicator [title]="'Generating a Brief Report...'" />                    
                } @else {
                    <a class="brief" (click)="topic.reportGenerating = true; onBriefReport.emit(topic)" title="Download a 2-page in-depth report" qa="brief">
                        @switch (icon) {
                            @case (1) {
                                TREND
                            }
                            @case (2) {
                                PROBLEM
                            }
                            @case (3) {
                                PROCESS
                            }
                        }
                        BRIEF
                    </a>
                }
            </p>
        }
    }
</div>