<div class="download">
    <a target="_blank" href="/api/resources/download?type=EverydayVoc" class="img evoc"></a>
    @if (detailed) {
        <div class="details">
            <div class="title">Everyday VOC Guide</div> 
            <div>
                This is your most important downloadable resource. Use it to remind you to first ask “What” questions… then “Why” questions… and finally, a “Clarifying” outcome statement.
            </div>
            <a class="sp-button primary" target="_blank" href="/api/resources/download?type=EverydayVoc">
                Download PDF
            </a>
        </div>
    }
</div>

<div class="download">
    <a target="_blank" href="/api/resources/download?type=SaltProbing" class="img salt"></a>
    @if (detailed) {
        <div class="details">
            <div class="title">SALT Probing Method</div> 
            <div>
                “What” questions in What-Why-Clarify probing help you truly understand the customer’s job to be done. This resource gives you 16 types of questions to ask… 
                around <b>S</b>enses, <b>A</b>ctors, <b>L</b>ocation & <b>T</b>iming.
            </div>
            <a class="sp-button primary" target="_blank" href="/api/resources/download?type=SaltProbing">
                Download PDF
            </a>
        </div>
    }
</div>

<div class="download">
    <a target="_blank" href="/api/resources/download?type=PearSignals" class="img pear"></a>
    @if (detailed) {
        <div class="details">
            <div class="title">PEAR Listening Signals</div> 
            <div>
                In addition to truly listening, you need to demonstrate you’re listening. Otherwise, the customer will stop giving you good information. 
                You do this through proper <b>P</b>osture, <b>E</b>xpression, <b>A</b>ctivity & <b>R</b>esponse.
            </div>
            <a class="sp-button primary" target="_blank" href="/api/resources/download?type=PearSignals">
                Download PDF
            </a>
        </div>
    }
</div>

<div class="download">
    <a target="_blank" href="/api/resources/download?type=AmuseChecklist" class="img amuse"></a>
    @if (detailed) {
        <div class="details">
            <div class="title">AMUSE Observation Checklist</div>
            <div>
                Do you take tours of your customers’ facilities? For each step in their process, look for ways to <b>A</b>ccelerate the activity, <b>M</b>inimize input, <b>U</b>pgrade output, <b>S</b>implify the transition, or <b>E</b>liminate the activity.
            </div>
            <a class="sp-button primary" target="_blank" href="/api/resources/download?type=AmuseChecklist">
                Download PDF
            </a>
        </div>
    }
</div>

@if (detailed) {
    <div class="download">
        <a target="_blank" href="/api/resources/download?type=TwelveWays" class="img twelve-ways"></a>
        
        <div class="details">
            <div class="title">12 ways to use SalesPrep</div>
            <div>
                SalesPrep will do a lot more for you than just increase sales. Make sure you are getting the maximum benefit from it.
            </div>
            <div class="flex actions">
                <a class="sp-button" [routerLink]="['/12-ways']">Open</a>
                <a class="sp-button primary" target="_blank" href="/api/resources/download?type=TwelveWays">
                    Download Comparison Grid PDF
                </a>
            </div>
        </div>
    </div>
}

@if (detailed) {
    <hr>
    
    <h2>BONUS RESOURCES</h2>

    <div class="download">
        <a target="_blank" href="https://theaiminstitute.com/aim-product-development-training/everyday-voc-workshops/" class="img voc-practice"></a>
        @if (detailed) {
            <div class="details">
                <div class="title">
                    VOC Practice Sticky Notes
                    <span class="badge">WEBSITE</span>
                </div>
                <div>
                    You’ll use this if you attend an <a class="sp-link blue" target="_blank" href="https://theaiminstitute.com/aim-product-development-training/everyday-voc-workshops/">Everyday VOC workshop</a>.
                    During your role-play, a note-taker records the customer’s comments on sticky notes. This helps you visualize the flow of What-Why-Clarify responses as you hear them.
                </div>
                <a class="sp-button" target="_blank" href="https://vocpractice.com">Open</a>
            </div>
        }
    </div>

    <div class="download">
        <a target="_blank" href="https://theaiminstitute.com/aim-product-development-training/everyday-voc-workshops/" class="img voc-workshop"></a>
        @if (detailed) {
            <div class="details">
                <div class="title">
                    Everyday VOC Workshop Resources
                </div>
                <div>
                    As a SalesPrep subscriber, you have access to the following resources from The AIM Institute’s 
                    <a class="sp-link blue" target="_blank" href="https://theaiminstitute.com/aim-product-development-training/everyday-voc-workshops/">Everyday VOC® workshop</a>.
                    The Everyday VOC workshop is a 4-hour session including a small-group breakout where learners role-play our <b>What-Why-Clarify</b> probing.
                    <a class="sp-link blue" target="_blank" href="https://www.blueprintingcenter.com/public_downloads/0eaa5bd2-d00b-431f-b761-e1cb78f910a8">Watch a recording</a> 
                    from a recent workshop recording and 
                    <a class="sp-link blue" target="_blank" href="https://www.blueprintingcenter.com/public_downloads/801e54d6-a57d-4c52-b2fe-5ab5b303baac">download the workshop log</a> 
                    with 95 pages of presentation slides from the workshop.
                </div>
                <div class="flex actions">
                    <a class="sp-button primary" target="_blank" href="https://www.blueprintingcenter.com/public_downloads/0eaa5bd2-d00b-431f-b761-e1cb78f910a8">Workshop Recording</a>
                    <a class="sp-button primary" target="_blank" href="https://www.blueprintingcenter.com/public_downloads/801e54d6-a57d-4c52-b2fe-5ab5b303baac">Workshop LOG</a>
                    <a class="sp-button" target="_blank" href="https://theaiminstitute.com/aim-product-development-training/everyday-voc-workshops/">Learn more</a>
                </div>
            </div>
        }
    </div>

    
}
