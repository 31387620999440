import {Component, HostBinding, Input} from '@angular/core';
import {OfferingType} from "../../model/offeringType";

@Component({
    selector: 'app-offering-type-icon',
    standalone: true,
    imports: [],
    templateUrl: './offering-type-icon.component.html',
    styleUrl: './offering-type-icon.component.scss'
})
export class OfferingTypeIconComponent {
    @Input() type = OfferingType.MaterialOrIngredientOrComponent;
}
