import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IStrengthsGroup} from "../../../../services/tutor.service";

@Component({
    selector: 'app-strengths',
    standalone: true,
    imports: [],
    templateUrl: './strengths.component.html',
    styleUrl: './strengths.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrengthsComponent {
    @Input() strengths!: IStrengthsGroup;
}
