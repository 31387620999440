import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

const ANIMATION_DURATION = 200;

@Component({
    selector: 'app-info-panel',
    standalone: true,
    imports: [],
    templateUrl: './info-panel.component.html',
    styleUrl: './info-panel.component.scss',
    animations: [
        trigger('flyInOut', [
            state('in', style({ transform: 'translateX(0)' })),
            state('out', style({ transform: 'translateX(100%)' })),
            transition('void => *', [
                style({ transform: 'translateX(100%)' }),
                animate(ANIMATION_DURATION)
            ])
        ]),
        trigger('fadeInOut', [
            state('in', style({ opacity: '1' })),
            state('out', style({ opacity: '0' })),
            transition('void => *', [
                style({ opacity: '0' }),
                animate(ANIMATION_DURATION, style({ opacity: '0' })),
                animate(ANIMATION_DURATION)
            ])
        ]),
    ],
})
export class InfoPanelComponent {
    @Input() title = '';
    @Output() onClose = new EventEmitter();

    @HostListener('document:keydown.escape', ['$event'])
    onEscapeKey(event: KeyboardEvent) {
        console.log('[InfoPanelComponent] onEscapeKey', event);
        this.onClose.emit();
    }
}
