import {AfterContentInit, Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: 'textarea[appAutosize]',
    standalone: true
})
export class AutosizeDirective implements AfterContentInit {
    constructor(public element: ElementRef) {
    }

    @HostListener('change') onChange(){ this.adjust(); }
    @HostListener('input') onInput(){ this.adjust(); }

    ngAfterContentInit(): void {
        window.setTimeout(() => {
            this.adjust();
        }, 10);
    }

    adjust(): void {
        const e = this.element.nativeElement;
        e.style.overflow = 'hidden';
        e.style.height = 'auto';
        if (e.value) {            
            e.style.height = e.scrollHeight + 'px';
        } else {
            
        }
    }


}
