<app-main-panel padding="0">

    <div class="flex-column left">
        <h1>Create new report</h1>
        <app-new-report [widgetMode]="true" [autofocus]="false" (click)="onSearch.emit('')" (onSearch)="onSearch.emit($event)"></app-new-report>
        
        <h1>Use SalesPrep to…</h1>
        <app-twelve-ways-widget/>
        
        <div class="tools" qa="dashboard-div">
            @if (certified) {
                <h1 qa="title">Your SalesPrep activity</h1>
            } @else {
                <h1 qa="title">Your progress towards SalesPrep Practitioner Certification</h1>
                <p class="sub-title" qa="sub-title">Learn more about certification</p>
            }
            <div class="cells">
                <div class="cell elearning" qa="elearn-div">
                    @if (certified) {
                        <h2 qa="title">Certification</h2>
                        
                        <app-certification-badge/>
                        <a target="_blank" href="/assets/images/certification/SalesPrep Certified Badge.png" class="link" qa="badge" download="SalesPrep Certified Badge.png">Download your badge</a>
                        
                        <p style="font-size: 16px;">
                            <br>
                            <span qa="ach-cert">You achieved certification on {{ certified | date }}.</span>                            
                        </p>
                    } @else {
                        <a routerLink="/e-learning" title="Open E-Learning">
                            <h2 qa="title-e">E-Learning</h2>
                        </a>
    
                        <app-elearning-stats [certified]="eLearningCertified" [modules]="modules" [moduleStatuses]="moduleStatuses" qa="e-stats" />
                        
                        <hr>
                        
                        @if (nextModule) {
                            <h3 qa="title-h3">NEXT UP</h3>                        
    
                            <a class="next-module" href="/api/user/elearning-session/{{nextModule.number}}" title="Learn this module" qa="next-mod">
                                <app-module [thumbOnly]="true" [module]="nextModule" qa="mod-div" />
                                <u qa="mod-num">Module {{  nextModule.number }}:</u> <span qa="mod-title"> {{ nextModule.title }}</span> <em><span qa="mod-dur"> ({{ nextModule.duration }}</span> min)</em>
                            </a>                        
                        } @else if (eLearningCertified) {
                            <h3 qa="title-done">ALL DONE!</h3>                            
                            <p qa="comp-date">Completed on {{ eLearningCertified | date }}</p>
                            
                            <div class="done"></div>                        
                        } @else {
                            <app-spinner />
                        }
                    }
                </div>
                <div class="cell ai-guide" qa="ai-div">
                    
                        <a routerLink="/ai-guide" title="Open AI Guide">
                            <h2 qa="title">AI Guide</h2>
                        </a>
                        
                        @if (certified) {
                            <app-count-label 
                                    [count]="totalPracticeSessionsCompleted > 99 ? 99 : totalPracticeSessionsCompleted"
                                    [plus]="totalPracticeSessionsCompleted > 99" qa="ai-ses">
                                Total<br>
                                sessions<br>
                                completed
                            </app-count-label>
                        } @else if (aiGuideCertified) {
                            <app-count-label [count]="10" [plus]="totalPracticeSessionsCompleted > 10" qa="ai-ses">
                                Qualifying<br>
                                sessions<br>
                                completed
                            </app-count-label>
                        } @else {
                            <app-count-label [count]="qualifyingPracticeSessionsCompleted" qa="ai-ses">
                                Of <span class="number">10</span> qualifying<br>
                                sessions<br>
                                completed
                            </app-count-label>
                        }
                        
                        @if (!certified) {
                            <app-aiguide-stats [practiceSessions]="eLearningData?.practiceSessionsCompleted || []" qa="ai-stats"/>
                            <hr>
                        }                                
                        
                        @if (aiGuideCertified) {
                            <div class="completed">

                                @if (!certified) {
                                    <h3 qa="title-h3">ALL DONE!</h3>
                                    <p class="completed-on" qa="compl">Completed on {{ aiGuideCertified | date }}</p>                                    
                                }
                                
                                <h3 qa="title-h3">
                                    @if (certified) { SUMMARY } @else { TOTALS }
                                </h3>
                                
                                <div class="aiguide-totals" qa="ai-tot">
                                    <div style="background: #FE5B61" qa="nov">
                                        <span class="number" qa="stat">{{ novicePracticeSessionsCompleted }}</span> <span qa="desc">&nbsp;Novice</span>
                                    </div>
                                    <div style="background: #42D84E" qa="int">
                                        <span class="number" qa="stat">{{ intermediatePracticeSessionsCompleted }}</span> <span qa="desc">&nbsp;Intermediate</span>
                                    </div>
                                    <div style="background: #9384FF" qa="exp">
                                        <span class="number" qa="stat">{{ advancedPracticeSessionsCompleted }}</span> <span qa="desc">&nbsp;Expert</span>
                                    </div>
                                    <app-tutor-avatar/>
                                </div>
                            </div>
                        } @else {
                            <div class="flex title">
                                <h3 qa="pr-title">PRACTICE NOW</h3>
                                <app-tutor-avatar qa="pr-ava"/>
                            </div>
                        }
                    <a class="link bottom" [routerLink]="['/ai-guide']" qa="pr-link">                        
                        @if (!aiGuideCertified) {Start a practice session} @else {Practice again now}
                        <span class="material-symbols-outlined">chevron_right</span>
                    </a>
                </div>
            
                <div class="cell reports" qa="rep-div">
                    <div>
                        <a routerLink="/reports" title="Open Reports">
                            <h2 qa="title">Reports</h2>
                        </a>
                        @if (certified) {                        
                            <app-count-label 
                                    [count]="reports.length > 99 ? 99 : reports.length"
                                    [plus]="reports.length > 99" qa="rep-all">
                                Reports<br>
                                generated
                            </app-count-label>
                        } @else if (reportsCertified) {
                            <app-count-label [count]="10" [plus]="reports.length > 0" qa="rep-all">
                                Reports<br>
                                generated
                            </app-count-label>                            
                        } @else {
                            <app-count-label [count]="reports.length" qa="rep-all">
                                Of <span class="number">10</span> reports<br>
                                generated
                            </app-count-label>
                        }
                        
                        @if (!certified) {
                            <app-reports-stats [reports]="reports" qa="report-stats"/>
                        }
                    </div>

                    @if (certified) {
                        <h3 qa="title-h3">SUMMARY</h3>
                    } @else {
                        <hr>
                        
                        @if (reportsCertified) {
                            <h3 qa="title-h3-d">ALL DONE!</h3>
                            <p class="completed-on" qa="compl">Completed on {{ reportsCertified | date }}</p>
                            <h3>TOTALS</h3>
                        } @else {
                            <h3 style="margin-bottom: 7px;" qa="title-h3-sum">SUMMARY</h3>
                        }
                    }
                    
                    <div [class.completed]="reportsCertified">
                        <app-count-label [small]="true" [count]="upcoming.length" qa="int-up">Upcoming meetings</app-count-label>
                        <app-count-label [small]="true" [count]="notScheduled.length" qa="int-not">Not dated</app-count-label>
                        <app-count-label [small]="true" [count]="historical.length" qa="int-com">Completed Interviews</app-count-label>
                    </div>

                    @if (certified || !reportsCertified) {
                        <a class="link bottom" [routerLink]="['/report-builder', 'new']" title="Create Report" qa="create">
                            @if (certified) {Start a new report} @else {Create a report}
                            <span class="material-symbols-outlined">chevron_right</span>
                            @if (!certified) {<br><br>}
                        </a>
                    }
                </div>                    
            </div>
        </div>
    </div>

    <div class="separator"></div>
    
    <div class="flex-column reports right">
        @if (reportsLoading) {
            <app-spinner></app-spinner>
        } @else {
            <h1 qa="next-m">Your next meeting@if (upcoming.length > 1){s}</h1>        
            @for (report of upcoming; track report.id; let i = $index) {
                <app-home-report-tile (onRemove)="onRemove.emit($event)" [routerLink]="['/report', report.id]" [report]="report" [attr.qa]="'report-' + (i + 1)"></app-home-report-tile>
            } @empty {
                <div style="margin-left: 10px" qa="no-reports">No reports created for upcoming meetings.</div>
            }

            @if (recent.length) {
                <div class="recent-reports" qa="recent-m">
                    <h1>Recent reports</h1>
                    @if (this.reports.length > 0) {
                        <a [routerLink]="['/reports']">View all <span class="number">{{ this.reports.length }}</span></a>
                    }
                </div>
                @for (report of recent; track report.id; let i = $index) {
                    <app-home-report-tile (onRemove)="onRemove.emit($event)" [routerLink]="['/report', report.id]" [report]="report" [attr.qa]="'report-' + (i + 1)"></app-home-report-tile>
                }
            } @else {
                @if (!upcoming.length) {
                    <app-no-reports/>
                }                
            }
        }
    </div>

</app-main-panel>