import {PdfGenerator} from "../pdf-generator";
import {OfferingActionPipe} from "../../../pipes/offering-action.pipe";
import {CompanySizePipe} from "../../../pipes/company-size.pipe";
import {DecimalPipe} from "@angular/common";
import {IReportPart} from "../ireport-part";

export class CompanyInformationSection implements IReportPart {
    
    constructor(private pdf: PdfGenerator) {
    }
    
    draw(y: number) {
        const pdf = this.pdf;
        const r = pdf.report;
        const doc = pdf.doc;
        
        const titleMargin = 15

        if (!r.company.isCompanyFound) {
            return y;            
        }
        
        const titleHeight = pdf.getLineHeight('Raleway-ExtraBold', 12) + titleMargin;
        const descriptionSection = pdf.createSection(r.company.description, 345, 'Raleway-Regular', 9)

        const descHeight = descriptionSection.getHeight(pdf);
        if (y + titleHeight + descHeight > pdf.pageHeight) {
            y = pdf.addPage();            
        }
        
        doc.setTextColor('#000');
        pdf.partTitle(y, 'COMPANY DESCRIPTION');

        const descriptionOffset = y + titleHeight;
        descriptionSection.draw(pdf, pdf.styles.paddingX, descriptionOffset);

        const marginLeft = 435.610;

        const companySize = r.company.companySize;
        if (companySize) {
            doc.addImage(`assets/pdf/company-size-icon.png`, 'png', 410.741, descriptionOffset + 18.02, 12, 21.218);
            pdf.text(r.company.companySizeCurrency + new CompanySizePipe().transform(companySize), marginLeft, descriptionOffset + 21.352, 'Raleway-Bold', 10);
        }

        const employeesCount = r.company.employeesCount;
        if (employeesCount) {
            doc.addImage(`assets/pdf/employees-icon.png`, 'png', 405.000, descriptionOffset - 4.033, 24, 15.597);
            pdf.text(new DecimalPipe('en-US').transform(employeesCount) + ' employees', marginLeft, descriptionOffset, 'Raleway-Bold', 10);
        }

        const minHeight = 80;
        return y + Math.max(descHeight, minHeight);
    }
}