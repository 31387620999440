import {Injectable} from '@angular/core';
import {TwelveWayGrid} from "../components/twelve-way-comparition-grid/twelve-way-comparition-grid.component";

@Injectable({
    providedIn: 'root'
})
export class TwelveWaysServiceService {

    reportTitle = ['Company News', 'Market Trends', 'Common Problems', 'Process Steps', 'Company Overview', 'Problem Brief'];
    rolePlayTitle = ['Any Problem', 'This Problem'];
    
    steps: TwelveWayGrid[] = [
        {
            title: 'Start conversations',
            description: 'Use Company News & Market Trends for ice-breakers: <em>“How does this new acquisition impact you?” “What are your thoughts on this trend?”</em>',
            note: 'This is better than:<br>“I was in town and thought<br> I’d stop by.”',
            report: [1, 1, 0, 0, 0, 0], ai: [0, 0]
        }, {
            title: 'Deepen conversations',
            description: 'Create a full report & role-play with Claire. Now you’re prepared to discuss any topic… and challenge the customer with fresh insights.',
            note: 'Customers have more time<br>for sales pros who<br>understand their world.',
            report: [1, 1, 1, 1, 1, 0], ai: [1, 0]
        }, {
            title: 'Stay current',
            description: 'For recurring visits, check Company News for late-breaking news. Refresh in the customer’s lobby by reviewing their full report on your phone.',
            note: 'Use your phone to refresh<br>your memory anytime,<br> anywhere.',
            report: [1, 1, 1, 1, 1, 0], ai: [0, 0]
        }, {
            title: 'Scout new accounts',
            description: 'Use the Company Overview section to quickly learn their size, leadership, products, org structure, competitors & published strategy.',
            note: 'In <60 seconds,<br>you’ll have all this<br>company info at<br> your fingertips.',
            report: [0, 0, 0, 0, 1, 0], ai: [0, 0]
        }, {
            title: 'Explore new applications',
            description: 'Run a report to learn about Market Trends, Common Problems, & Process Steps. Learn new terminology & role-play with Claire.',
            note: 'You can enter new markets<br>like a seasoned pro… when<br> you do this homework first.',
            report: [0, 1, 1, 1, 0, 0], ai: [1, 0]
        }, {
            title: 'Engage each contact',
            description: 'Run a report that specifies the job function(s) you’ll meet with. Role-play with this job function to explore the problems important to them.',
            note: 'Each job function at your<br>account has unique<br>interests. Now you can<br> prepare for each.',
            report: [1, 1, 1, 1, 1, 0], ai: [1, 0]
        }, {
            title: 'Research problems',
            description: 'If you think the account has a specific problem you could solve, create a 2-page Problem Brief.1 Role-play this problem with Claire.2',
            note: 'Learn this problem’s causes, conditions, metrics, KPIs, and impact of solving the problem.',
            report: [0, 0, 1, 0, 0, 1], ai: [0, 1]
        }, {
            title: 'Find prospects',
            description: 'Use the Competitors section of the Company Overview to find prospect companies with offerings and a business model like this account’s.',
            note: 'This quickly gives you a prospect list …<i>and</i> lets you recognize competitors when their names are mentioned.',
            report: [0, 0, 0, 0, 1, 0], ai: [0, 0]
        }, {
            title: 'Make CRM entries',
            description: 'Record faster, better notes by copy-pasting from any report sections. Paste an entire Problem Brief 1 and delete the unneeded portions.',
            note: 'Enter impressive CRM notes by pasting & editing sections of your SalesPrep report.',
            report: [1, 1, 1, 1, 1, 1], ai: [0, 0]
        }, {
            title: 'Leapfrog purchasing',
            description: 'Hand them 1 of 3 Customer Handouts3 (Trends, Problems, Process Steps). Ask which are most critical. Ask who you should talk to about them.',
            note: 'Move past “gatekeepers” by asking them questions only their colleagues can answer.',
            report: [0, 1, 1, 1, 0, 0], ai: [0, 0]
        }, {
            title: 'Build skills',
            description: 'Skills need refreshing to stay sharp. On a regular basis, conduct Expert role-plays with Claire… especially prior to important key account meetings.',
            note: 'A great time to practice is prior to an important key account meeting.',
            report: [0, 0, 0, 0, 0, 0], ai: [1, 0]
        }, {
            title: 'Investigate ideas',
            description: 'Not just for sales: If you have a new product idea, run a Problem Brief to make sure you’re not “creating a cure for no known disease.”',
            note: 'Great for product developers: Make sure your “solution” solves a real customer problem!',
            report: [0, 0, 1, 0, 0, 1], ai: [0, 1]
        }

    ]
  
}
