<div class="title">YOUR STRENGTHS</div>
{{ strengths.summary }}

<ol>
@for (item of strengths.strengths; track item.title) {
    <li>
        <strong>{{ item.title}}</strong><br>
        <em>{{item.text}}</em>
    </li>
}
</ol>