import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'app-count-label',
    standalone: true,
    imports: [],
    templateUrl: './count-label.component.html',
    styleUrl: './count-label.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountLabelComponent {
    @HostBinding('class.small')
    @Input() small = false;
    @Input() count = 0;
    @Input() plus = false;
}
