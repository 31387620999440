import {MyProblemBriefReportGenerator} from "./problem-brief-report-generator";
import {BriefReportItem} from "../../model";
import {BriefCheckboxSection} from "./brief-checkbox-section";


export interface BriefCheckboxGroupSectionData {
    title: string;
    description: string;
    items: string[];
}
export class BriefCheckboxGroupSection {    
    private fontSize = 9;
    private maxWidth = 446;
    private marginBottom = 9.7;

    private _titleSection: BriefCheckboxSection;

    constructor(private pdf: MyProblemBriefReportGenerator, private item: BriefCheckboxGroupSectionData) {
        this._titleSection = new BriefCheckboxSection(pdf, item);
    }

    draw(y: number) {
        const pdf = this.pdf;
        const doc = this.pdf.doc;
        
        y = this._titleSection.draw(y);

        if (this.item.items.length > 0) {
            y += this.marginBottom;
            
            doc.setFont("Raleway-Medium");
            this.item.items.forEach(x => {
                pdf.doc.circle(90 + 4.4, y - 1 + 4.4, 4.4);
                y = pdf.text(x, 105, y, '', 0, this.maxWidth) + this.marginBottom;
            });
        }
        
        return y;
    }

    getHeight() {
        const doc = this.pdf.doc;
        doc.setFontSize(this.fontSize);
        doc.setFont("Raleway-Medium");

        let h = this._titleSection.getHeight() + this.marginBottom;
        const lineHeight = doc.getLineHeight() * 1.2;        
        this.item.items.forEach(x => {
            h += lineHeight * doc.splitTextToSize(x, this.maxWidth).length + this.marginBottom;
        });
        return h;
    }
}