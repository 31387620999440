import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {PracticeSessionStatus} from "../../../../services/elearning.service";
import {DatePipe} from "@angular/common";
import {TutorCoachingLevels} from "../../../../model";

@Component({
    selector: 'app-aiguide-stats',
    standalone: true,
    imports: [],
    providers: [
        DatePipe,
    ],
    templateUrl: './aiguide-stats.component.html',
    styleUrl: './aiguide-stats.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiguideStatsComponent implements OnChanges {
    @Input() practiceSessions: PracticeSessionStatus[] = [];
    filteredSessions: PracticeSessionStatus[] = [];
    
    constructor(private datePipe: DatePipe) {
    }
    ngOnChanges(changes: SimpleChanges) {
        this.filteredSessions = this.practiceSessions.filter(x => x.level > 1);
    }

    getTitle(status: PracticeSessionStatus) {
        if (!status) return '';
        return `${this._getLevel(status.level)} session completed on ${this.datePipe.transform(status.completed)}`;
    }

    private _getLevel(level: TutorCoachingLevels) {
        switch (level) {
            case TutorCoachingLevels.Expert:
                return 'Advanced';
            case TutorCoachingLevels.Intermediate:
                return 'Intermediate';
            default: 
                return 'Novice';
        }        
    }
}
