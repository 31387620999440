import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {ErrorMessageComponent} from "../../../../components/error-message/error-message.component";
import {RouterLink} from "@angular/router";
import {SignUpMode} from "../../../../model";
import {SignupService} from "../../../../services/signup.service";

@Component({
    selector: 'app-pure-signup',
    standalone: true,
    imports: [
        AutofocusDirective,
        FormsModule,
        ReactiveFormsModule,
        ErrorMessageComponent,
        RouterLink
    ],
    templateUrl: './pure-signup.component.html',
    styleUrl: './pure-signup.component.scss'
})
export class PureSignupComponent {

    agreed = false;
    user: SignUpData = <any>{
        code: ''
    };

    @Input() signUpMode = SignUpMode.Trial;
    @Input() signInWithGoogleActive = false;
    @Input() trialPeriod = 0;
    

    @Output() onSignup = new EventEmitter<SignUpData>();
    @Output() onValidatePromoCode = new EventEmitter<string>();
    @Output() signInWithGoogle = new EventEmitter();
    @Input() errorMessages: string[] = [];

    SignUpMode = SignUpMode;
    showPassword = false;
    
    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    signup(f: NgForm) {
        if (f.valid) {
            this.onSignup.emit(this.user);
        } else {
            this.errorMessages = ['Please enter a valid email and password'];
        }
    }

    onSignInWithGoogle() {
        if (this.signInWithGoogleActive) return;

        this.signInWithGoogleActive = true;
        this.signInWithGoogle.emit();
    }
}

export interface SignUpData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    company: string;
    linkedInProfileUrl: string;
    code: string;
    mode: SignUpMode
}
