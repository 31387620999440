
<app-discussion-topics-list
        [index]="4"
        [rating]="stakeholdersRating" (onRatingChange)="onRatingChange.emit($event)"
        title="Company Stakeholders" [loading]="stakeholdersLoading" [loadingFailed]="stakeholdersLoadingFailed" 
        [topics]="stakeholders" (onRetry)="onStakeholdersRetry.emit()" qa="trends"/>
<hr>


@for (topics of discussionTopics; track topics; let index = $index) {
    @if (topics.role) {
        <h3 class="role" [attr.qa]="'roles-' + (index + 1)" qa2="h3-role">Role: {{topics.role}}</h3>
    }
    <app-discussion-topics-list
            [index]="1" (onSelect)="title.set('Market Trends');selectedTopic.set($event)"
            [rating]="marketTrendsRating" (onRatingChange)="onRatingChange.emit($event)"
            title="Market Trends" [loading]="topics.marketTrendsLoading()" [loadingFailed]="topics.marketTrendsLoadingFailed()" [topics]="topics.marketTrends()" (onRetry)="onMarketTrendsRetry.emit()" qa="trends"/>
    <hr>
    <app-discussion-topics-list
            [index]="2" (onSelect)="title.set('Common Problems');selectedTopic.set($event)"
            [rating]="commonProblemsRating" (onRatingChange)="onRatingChange.emit($event)"
            title="Common Problems" [loading]="topics.commonProblemsLoading()" [loadingFailed]="topics.commonProblemsLoadingFailed()" [topics]="topics.commonProblems()" (onRetry)="onCommonProblemsRetry.emit()" qa="problems"/>
    <hr>
    <app-discussion-topics-list
            [index]="3" (onSelect)="title.set('Process Steps');selectedTopic.set($event)"
            [rating]="processStepsRating" (onRatingChange)="onRatingChange.emit($event)"
            title="Process Steps" [loading]="topics.processStepsLoading()" [loadingFailed]="topics.processStepsLoadingFailed()" [topics]="topics.processSteps()" (onRetry)="onProcessStepsRetry.emit()" qa="steps"/>
    <hr>
}

<app-discussion-topics-list
        [inline]="true"
        [index]="0" (onSelect)="onSelectNewsArticle($event)"
        [rating]="companyNewsRating" (onRatingChange)="onRatingChange.emit($event)"
        title="Company News" [loading]="companyNewsLoading" [loadingFailed]="companyNewsLoadingFailed" [topics]="newsTopics" (onRetry)="onCompanyDataRetry.emit()" qa="news"></app-discussion-topics-list>

<hr>

@if (companyNewsLoading) {
    <div class="sp-form actions" qa="actions">
        @if (sessionExpired) {
            <p class="warn" style="font-weight: bold">
                You session has been expired please go back and create a new report.        
                <a class="sp-link warn" routerLink="/practice" style="text-decoration: underline;">Go back</a>
                <br><br>
            </p>
        }
        <button class="primary" type="submit" (click)="onNext.emit()" [disabled]="companyNewsLoading" qa="submit">
            Create report
        </button>
    </div>
} @else {
    <app-pure-exploratory-questions [inlineMode]="true" [practiceMode]="true"
                                    [exploratoryQuestionsLoading]="exploratoryQuestionsLoading"
                                    [exploratoryQuestionsLoadingFailed]="exploratoryQuestionsLoadingFailed"
                                    [questions]="exploratoryQuestions"
                                    (onGenerate)="onGenerateExploratoryQuestions.emit($event)"
                                    (onCreateReport)="onNext.emit()"
    />
}