import {Component, Input} from '@angular/core';
import {LowerCasePipe} from "@angular/common";
import {OfferingActionPipe} from "../../pipes/offering-action.pipe";
import {OfferingType} from "../../model/offeringType";

@Component({
    selector: 'app-report-subject',
    standalone: true,
    imports: [
        LowerCasePipe,
        OfferingActionPipe
    ],
    templateUrl: './report-subject.component.html',
    styleUrl: './report-subject.component.scss'
})
export class ReportSubjectComponent {
    @Input() offeringName = '';
    @Input() offeringType = OfferingType.MaterialOrIngredientOrComponent;
    @Input() job = '';
    
    @Input() showUse = true;
}
