import {Pipe, PipeTransform} from '@angular/core';
import {UserProfile} from "../model/userProfile";

@Pipe({
    name: 'initials',
    standalone: true
})
export class InitialsPipe implements PipeTransform {

    transform(value: UserProfile | undefined, ...args: unknown[]): string {
        if (!value || !value.firstName || !value.lastName) return 'JD';
        
        return (value.firstName[0] + value.lastName[0]);
    }
}
