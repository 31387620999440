import {CustomerHandoutsGenerator} from "../customer-handouts-generator";

export class FooterSection {
    draw(pdf: CustomerHandoutsGenerator) {
        const y = 772.304 - 5;
        const x = 142.802;

        const now = new Date();
        const date = pdf.datePipe.transform(now);
        const time = pdf.timePipe.transform(now.toTimeString());

        const text = ` Report generated ${date} ${time} | Learn more at `;

        pdf.forEachPage((doc, index, numbersOfPages) => {

            pdf.line(pdf.styles.paddingX, 761.461, 522, "#7abfeb", 1);

            doc.setTextColor(0, 0, 0);
            let w = pdf.text('SalesPrep', x, y, 'Raleway-Regular', 8);
            w += pdf.text('TM', x + w, 770.147 - 3,'Raleway-Regular', 4);
            w += pdf.text(text, x + w, y, 'Raleway-Regular', 8);
            
            doc.setTextColor(pdf.styles.linkColor);
            pdf.text("www.salesprep.com", x + w, y, 'Raleway-SemiBold');

        });
    }
}