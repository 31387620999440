<div class="image m{{ module.number }}" qa="im">
    
</div>
@if (thumbOnly) {
} @else {
    <div class="flex-column">
        <div class="title">
            <span class="module-name" qa="name">Module {{ module.number }}:</span>&nbsp; <span qa="tit">{{ module.title }}</span>&nbsp;

            <span class="duration" qa="dur">({{ module.duration }} min)</span>

            <span class="status" [class.completed]="completed" qa="st">
                @if (inProgress) {
                    In progress            
                } @else if (completed) {
                    Complete
                } @else {
                    &nbsp;
                }
            </span>
        </div>
        <div class="description" [innerHTML]="module.description" qa="desc">
        </div>
    </div>
    
    <div class="button">
        <a href="/api/user/elearning-session/{{module.number}}" class="sp-button sp-link" qa="action"
           [class.secondary]="completed"
           [class.primary]="inProgress">
            @if (completed) {
                View Again
            } @else if (inProgress) {
                Continue
            } @else {
                View
            }
        </a>
    </div>
}