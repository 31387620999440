import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Company, ReportSectionRating} from "../../../../model";
import {CompanySizePipe} from "../../../../pipes/company-size.pipe";
import {RateSectionComponent} from "../../../../components/rate-section/rate-section.component";
import {RateSectionFooterComponent} from "../../../../components/rate-section-footer/rate-section-footer.component";
import {CompanyLinksComponent} from "../company-links/company-links.component";
import {DecimalPipe} from "@angular/common";

@Component({
    selector: 'app-company-overview',
    standalone: true,
    imports: [CompanySizePipe, RateSectionComponent, RateSectionFooterComponent, CompanyLinksComponent, DecimalPipe],
    templateUrl: './company-overview.component.html',
    styleUrl: './company-overview.component.scss'
})
export class CompanyOverviewComponent {
    @Input() company!: Company | undefined;

    @Input() companyDescriptionRating!: ReportSectionRating;
    @Input() companySizeRating!: ReportSectionRating;
    @Input() leadershipRating!: ReportSectionRating;
    @Input() productsRating!: ReportSectionRating;
    @Input() competitorsRating!: ReportSectionRating;
    @Input() strategyRating!: ReportSectionRating;
    
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();
}
