import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-main-title',
    standalone: true,
    imports: [],
    templateUrl: './main-title.component.html',
    styleUrl: './main-title.component.scss'
})
export class MainTitleComponent {
    @Input() icon = 'keyboard_backspace';
    @Input() text = 'SalesPrep';
    @Input() color = '';

    @Input() backNavigationEnabled = true;
    @Input() customAction = true;
    
    @Output() onAction = new EventEmitter();

    back() {
        if (!this.backNavigationEnabled) return;
        
        if (this.customAction) {
            this.onAction.emit();
        } else {
            window.history.back();
        }
    }
}
