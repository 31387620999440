import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {FormsModule, NgForm} from "@angular/forms";
import {ErrorMessageComponent} from "../../../../components/error-message/error-message.component";

@Component({
    selector: 'app-pure-login',
    standalone: true,
    imports: [
        FormsModule,
        AutofocusDirective,
        ErrorMessageComponent,
    ],
    templateUrl: './pure-login.component.html',
    styleUrl: './pure-login.component.scss'
})
export class PureLoginComponent {
    user = {
        email: '',
        password: ''
    };
    
    @Input() errorMessage = '';
    @Input() signInWithGoogleActive = false;
    
    @Output() onLogin = new EventEmitter<LoginCredentials>();
    @Output() signInWithGoogle = new EventEmitter();
    @Output() onCreateAccount = new EventEmitter();
    @Output() onForgotPassword = new EventEmitter();

    showPassword = false;

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }
    
    login(f: NgForm) {
        if (f.valid) {
            this.onLogin.emit({email: this.user.email, password: this.user.password});
        } else {
            this.errorMessage = 'Please enter a valid email and password';
        }
    }

    onSignInWithGoogle() {
        if (this.signInWithGoogleActive) return;
        
        this.signInWithGoogleActive = true;
        this.signInWithGoogle.emit();
    }
}


export interface LoginCredentials {
    email: string;
    password: string;
}