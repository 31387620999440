<div class="flex-column overflow" style="flex: 1">
    <div class="flex">
        <div class="icon" [style.background-image]="'url(' + company.logoUrl + ')'"></div>
        
        <div class="title">
            <div class="name" qa="c-name">{{company.name}}</div>
            <div class="website text-overflow" qa="c-site">{{company.website || '&nbsp;'}}</div>
        </div>
    </div>
    
    <div class="desc" qa="c-desc">{{company.description}}</div>
</div>

@if (arrowVisible) {
    <i class="material-icons arrow mobile-layout" qa="c-arr">
        arrow_forward_ios
    </i>
}