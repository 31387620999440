import {Component, Input} from '@angular/core';
import {TutorCoachingLevels} from "../../../../model";
import {ReportSubjectComponent} from "../../../../components/report-subject/report-subject.component";
import {OfferingType} from "../../../../model/offeringType";

@Component({
    selector: 'app-chat-header',
    standalone: true,
    imports: [
        ReportSubjectComponent
    ],
    templateUrl: './chat-header.component.html',
    styleUrl: './chat-header.component.scss'
})
export class ChatHeaderComponent {
    
    @Input() userInitials = '';
    
    @Input() level = TutorCoachingLevels.Beginner;

    @Input() offeringName = '';
    @Input() offeringType = OfferingType.Unknown;
    @Input() job = '';
    
    TutorCoachingLevels = TutorCoachingLevels;    
}
