import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatOfferingCategoryDesc',
    standalone: true
})
export class FormatOfferingCategoryDescPipe implements PipeTransform {

    transform(value: string | undefined, ...args: unknown[]): string {
        if (!value) return 'NA';
        const i = value.indexOf('(');
        if (i > 0 && value[value.length - 1] == ')') {
            return value.substring(i);
        }
        return '';
    }

}
