<h1>Would you like any steps to be...</h1>
<div class="image">
    <div class="cheaper">
        <svg width="35" height="55" viewBox="8 0 10 52">
            <g id="Faster_Better_Cheaper" data-name="Faster Better Cheaper" transform="translate(5.294 -210)">
                <g id="Cheaper_1" data-name="Cheaper 1" transform="translate(-1610 110) rotate(3)">
                    <path id="Cheaper" d="M-1273.685-3463.111l2.075.009v-3.9a2,2,0,0,1-.023-.3v-2.76h-8.122a2,2,0,0,1-2-2,2,2,0,0,1,2-2h8.122v-9.329h-2.288a7.429,7.429,0,0,1-5.63-2.635,9.125,9.125,0,0,1-2.2-6.03,9.123,9.123,0,0,1,2.2-6.029,7.429,7.429,0,0,1,5.63-2.635h2.288v-2.759a2,2,0,0,1,2-2,2,2,0,0,1,2,2v2.759h6.212a2,2,0,0,1,2,2,2,2,0,0,1-2,2h-6.212v9.328h2.045a7.429,7.429,0,0,1,5.63,2.634,9.127,9.127,0,0,1,2.2,6.03,9.125,9.125,0,0,1-2.2,6.03,7.426,7.426,0,0,1-5.63,2.634h-2.022v6.977l1.863.008-3.938,6.893Zm6.052-10.949h2.045c2.113,0,3.833-2.093,3.833-4.665s-1.72-4.664-3.833-4.664h-2.045Zm-10.122-17.994c0,2.572,1.72,4.665,3.834,4.665h2.288v-9.328h-2.288C-1276.034-3496.718-1277.754-3494.625-1277.754-3492.054Z" transform="translate(2891.632 3523.478)"/>
                </g>
            </g>
        </svg>
        <span class="title">Cheaper?</span>
        <span class="text">
            More efficient<br>
            Less resources<br>
            Less waste
        </span>
    </div>
    <div class="better">
        <svg width="60" height="55" viewBox="252 12 10 53">
            <path id="Better" d="M-913.688-3169.948l5-5v3.257a18.3,18.3,0,0,0,9.184-5.215,19.472,19.472,0,0,0,4.033-6.187,20.011,20.011,0,0,0,1.483-7.606,20.045,20.045,0,0,0-1.217-6.917l2.884-1.7a23.289,23.289,0,0,1,1.634,8.617,23.144,23.144,0,0,1-5.661,15.233,21.787,21.787,0,0,1-12.339,7.141v3.376Zm-13.474-1.708a23.147,23.147,0,0,1-9.919-12.874,21.791,21.791,0,0,1,.482-14.249l-2.863-1.788,6.89-1.59,1.591,6.889-2.762-1.726a18.3,18.3,0,0,0-.445,10.552,19.485,19.485,0,0,0,3.109,6.7,20.014,20.014,0,0,0,5.665,5.288,20.047,20.047,0,0,0,6.511,2.634l-.086,3.346A23.3,23.3,0,0,1-927.162-3171.657Zm8.474-7.792v-12h-9l14-15,14,15h-9v12Zm15.8-24.962,2.821-1.629a18.311,18.311,0,0,0-9.108-5.346,19.455,19.455,0,0,0-7.374-.4,20.011,20.011,0,0,0-7.329,2.518,20.023,20.023,0,0,0-5.382,4.512l-2.914-1.648a23.3,23.3,0,0,1,6.646-5.723,23.141,23.141,0,0,1,16.024-2.714,21.784,21.784,0,0,1,12.353,7.115l2.923-1.688-1.83,6.83Z" transform="translate(1171.688 3230.449)"/>
        </svg>
        <span class="title">Better?</span>
        <span class="text">
            Higher output<br>
            More uniform<br>
            Greater value
        </span>
    </div>
    <div class="faster">
        <svg width="90" height="55" viewBox="30 0 10 50">
            <path id="Faster" d="M28.325,46.849a20.907,20.907,0,0,1-6.674-4.5A21.118,21.118,0,0,1,18.607,38.5h4.931c.3.35.614.693.941,1.021a17,17,0,1,0,0-24.042c-.327.327-.644.671-.942,1.021h-4.93a21.09,21.09,0,0,1,9.718-8.348A20.8,20.8,0,0,1,34.5,6.593V5h-2a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V4a1,1,0,0,1-1,1h-2V6.593a20.94,20.94,0,0,1,11.663,4.958l.016-.018,1.338-1.487a1,1,0,0,1,1.412-.074l1.486,1.338a1,1,0,0,1,.075,1.412L53.152,14.21a1,1,0,0,1-.235.192A21.007,21.007,0,0,1,28.325,46.849Zm-6.8-10.9L5.087,34,21.53,32.055a2,2,0,1,1,0,3.889Zm-5.088-6.5L0,27.5l16.443-1.944a2,2,0,1,1,0,3.889Zm20.058-1.7V15a12.462,12.462,0,0,1,8.963,3.788L36.5,27.75l0,0ZM21.53,22.944,5.087,21,21.53,19.056A2,2,0,1,1,22,23,1.969,1.969,0,0,1,21.53,22.944Z"/>
        </svg>
        <span class="title">Faster?</span>
        <span class="text">
            Streamlined<br>
            Less time<br>
            Less complex
        </span>
    </div>
</div>