<div class="title">
    INTERVIEW SUMMARY
    
    @if (level == TutorCoachingLevels.Expert) {
        <span class="expert">(EXPERT)</span>
    } @else {
        <span>(INTERMEDIATE)</span>
    }
</div>
<div class="subject">
    <app-report-subject
            [offeringName]="offeringName"
            [offeringType]="offeringType"
            [job]="job" />
</div>


<div class="stats">
    <div class="label">
        Questions asked
    </div>
    <div class="value">
        {{ numWhat + numWhy + numClarify }}
    </div>
    <div class="label">
        Problems explored
    </div>
    <div class="value">
        {{ numProblem }}
    </div>
    <div class="label">
        "What" Questions asked
    </div>
    <div class="value">
        {{ numWhat }}
    </div>
    <div class="label">
        "Why" Questions asked
    </div>
    <div class="value">
        {{ numWhy }}
    </div>
    <div class="label">
        "Clarify" Questions asked
    </div>
    <div class="value">
        {{ numClarify }}
    </div>
</div>