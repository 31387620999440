import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {SalesPrepReport} from "../../../../model";
import {DatePipe} from "@angular/common";
import {TimePipe} from "../../../../pipes/time.pipe";
import {RolesPipe} from "../../../../pipes/roles.pipe";
import {ReportSubjectComponent} from "../../../../components/report-subject/report-subject.component";
import {CompanyLogoComponent} from "../../../../components/company-logo/company-logo.component";
import {FormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-select-report',
    standalone: true,
    imports: [
        DatePipe,
        TimePipe,
        RolesPipe,
        ReportSubjectComponent,
        CompanyLogoComponent,
        FormsModule,
        RouterLink
    ],
    templateUrl: './select-report.component.html',
    styleUrl: './select-report.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectReportComponent implements OnChanges {
    @Input() reports: SalesPrepReport[] = [];
    
    @Input() selectedIndex = -1;
    
    @Output() onSelectOffering = new EventEmitter();
    @Output() onSelectReport = new EventEmitter<SalesPrepReport>();

    sortedReports: SalesPrepReport[] = [];
    ngOnChanges() {
        this.sortedReports = [...this.reports];
        this.sortedReports.sort(this._sortByLastModified)
    }

    private _sortByLastModified(x: SalesPrepReport, y: SalesPrepReport) {
        const a = x.lastUpdated;
        const b = y.lastUpdated;

        return a.getTime() - b.getTime();
    }
}
