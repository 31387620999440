import {Component, HostBinding, Input} from '@angular/core';
import {OfferingType, OfferingTypeDescription} from "../../../../model/offeringType";
import {FormatOfferingCategoryPipe} from "../../../../pipes/format-offering-category.pipe";
import {OfferingTypeIconComponent} from "../../../../components/offering-type-icon/offering-type-icon.component";
import {FormatOfferingCategoryTitlePipe} from "../../../../pipes/format-offering-category-title.pipe";
import {FormatOfferingCategoryDescPipe} from "../../../../pipes/format-offering-category-desc.pipe";

@Component({
    selector: 'app-offering-type',
    standalone: true,
    imports: [
        FormatOfferingCategoryPipe,
        OfferingTypeIconComponent,
        FormatOfferingCategoryTitlePipe,
        FormatOfferingCategoryDescPipe
    ],
    templateUrl: './offering-type.component.html',
    styleUrl: './offering-type.component.scss'
})
export class OfferingTypeComponent {
  
    @Input() text = '';
    @Input() type!: OfferingType;
    
    @HostBinding('class.header-mode') @Input() headerMode = false;
    
    OfferingType = OfferingType;    
}
