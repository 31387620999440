import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {NewsArticle, ReportSectionRating} from "../../../../model";
import {RateSectionComponent} from "../../../../components/rate-section/rate-section.component";
import {RateSectionFooterComponent} from "../../../../components/rate-section-footer/rate-section-footer.component";
import {NewsSectionItemComponent} from "../news-section-item/news-section-item.component";

@Component({
    selector: 'app-news-section',
    standalone: true,
    imports: [
        RateSectionComponent,
        RateSectionFooterComponent,
        NewsSectionItemComponent
    ],
    templateUrl: './news-section.component.html',
    styleUrl: './news-section.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsSectionComponent {
    @Input() news: NewsArticle[] | undefined;

    @Input() rating!: ReportSectionRating;
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();
}
