<div class="dropdown" appClickOutside (clickOutside)="collapse()">
    <span class="text">{{ selectedItem?.text || selectedItem?.value }}</span>
    <span class="material-symbols-outlined icon">keyboard_arrow_down</span>
    
    @if (expanded) {
        <div class="menu {{position}}">
            @for (item of items; track item.value; let i = $index) {
                <a (click)="select(item)" [class.selected]="selectedItem?.value == item.value" [attr.dp]="'dp-' + (i + 1)">{{ item.text || item.value }}</a>
            }
        </div>
    }
</div>