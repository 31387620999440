<app-main-header class="desktop-layout"/>
<app-main-title class="mobile-layout" [text]="'My Account'" [routerLink]="['/']"></app-main-title>

<app-main-panel class="sp-wrapped">
    @if (loadingProfile()) {
        <app-spinner/>
    } @else {
        <app-profile-setup
                (onUpdate)="update($event)"
                [errorMessage]="errorMessage()"
                [setupMode]="profileSetupMode()" [profileInfo]="userProfile()!"/>
    }
</app-main-panel>

<app-footer-compact/>