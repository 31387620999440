<app-pure-select-company
        (onSearch)="searchQuery.set($event)"
        (onSelectCompany)="selectCompany($event)"
        (onCompanyNotFound)="companyNotFound($event)"
        
        [searchQuery]="searchQuery()"
        [searchQueryChanged]="searchQueryChanged()"
        [searchResultsLoading]="searchResultsLoading()"
        [companies]="companies()"
/>
