<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      
     viewBox="0 30 400 143">
    <defs>
        <filter id="Ellipse_54" x="289" y="108" width="111" height="111" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Ellipse_53" x="236" y="11" width="158" height="158" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur-2"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Intersection_1" x="107" y="66" width="179" height="159.999" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur-3"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur-3"/>
            <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Ellipse_49" x="0" y="0" width="178" height="178" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur-4"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur-4"/>
            <feComposite in="SourceGraphic"/>
        </filter>
        <clipPath id="clip-path">
            <rect id="Rectangle_472" data-name="Rectangle 472" width="21.756" height="34" fill="none"/>
        </clipPath>
        <clipPath id="clip-path-2">
            <rect id="Rectangle_473" data-name="Rectangle 473" width="34" height="34" fill="none"/>
        </clipPath>
        <clipPath id="clip-path-3">
            <rect id="Rectangle_481" data-name="Rectangle 481" width="33.94" height="34" fill="none"/>
        </clipPath>
    </defs>
    <g id="Listen_Ask_Suggest" data-name="Listen Ask Suggest" transform="translate(-15 -196)">
        <g transform="matrix(1, 0, 0, 1, 15, 196)" filter="url(#Ellipse_54)">
            <circle id="Ellipse_54-2" data-name="Ellipse 54" cx="46.5" cy="46.5" r="46.5" transform="translate(298 114)" fill="#5bbffe"/>
        </g>
        <text id="Process_Steps" data-name="Process Steps" transform="translate(358 353)" fill="#00376f" font-size="16" font-family="Raleway-ExtraBold, Raleway" font-weight="800"><tspan x="-30" y="15">Process </tspan><tspan x="-21" y="34">Steps</tspan></text>
        <g transform="matrix(1, 0, 0, 1, 15, 196)" filter="url(#Ellipse_53)">
            <circle id="Ellipse_53-2" data-name="Ellipse 53" cx="70" cy="70" r="70" transform="translate(245 17)" fill="#5bbffe"/>
        </g>
        <text id="_3._Suggest" data-name="3. Suggest" transform="translate(330 282)" font-size="20" font-family="Raleway-ExtraBold, Raleway" font-weight="800"><tspan x="-57" y="0">3. SUGGEST</tspan></text>
        <text id="Using_Common_Problems_" data-name="Using Common Problems " transform="translate(330 285)" fill="#00376f" font-size="16" font-family="Raleway-Medium, Raleway" font-weight="500"><tspan x="-21" y="15">Using </tspan><tspan x="-35" y="34" font-family="Raleway-ExtraBold, Raleway" font-weight="800">Common </tspan><tspan x="-37" y="53" font-family="Raleway-ExtraBold, Raleway" font-weight="800">Problems </tspan></text>
        <text id="_" data-name="&amp;" transform="translate(352 338)" fill="#00376f" font-size="16" font-family="Raleway-Medium, Raleway" font-weight="500"><tspan x="-5" y="15">&amp;</tspan></text>
        <g transform="matrix(1, 0, 0, 1, 15, 196)" filter="url(#Intersection_1)">
            <path id="Intersection_1-2" data-name="Intersection 1" d="M-5982.446,239A80.33,80.33,0,0,1-6011,177.5,80.5,80.5,0,0,1-5930.5,97a80.5,80.5,0,0,1,80.5,80.5,80.33,80.33,0,0,1-28.554,61.5Z" transform="translate(6127 -25)" fill="#b0ffbd"/>
        </g>
        <text id="_2._Ask" data-name="2. Ask" transform="translate(212 345)" font-size="22" font-family="Raleway-ExtraBold, Raleway" font-weight="800"><tspan x="-32" y="0">2. ASK</tspan></text>
        <text id="If_this_topic_is_giving_them_problems" data-name="If this topic is giving them problems" transform="translate(210 348)" fill="#00376f" font-size="16" font-family="Raleway-Medium, Raleway" font-weight="500"><tspan x="-48" y="15">If this topic is </tspan><tspan x="-44" y="34">giving them </tspan><tspan x="-35" y="53">problems</tspan></text>
        <g transform="matrix(1, 0, 0, 1, 15, 196)" filter="url(#Ellipse_49)">
            <circle id="Ellipse_49-2" data-name="Ellipse 49" cx="80" cy="80" r="80" transform="translate(9 6)" fill="#ffa5a5"/>
        </g>
        <text id="_1._Listen" data-name="1. Listen" transform="translate(107 281)" font-size="24" font-family="Raleway-ExtraBold, Raleway" font-weight="800"><tspan x="-53" y="0">1. LISTEN</tspan></text>
        <text id="For_any_problems_they_bring_up" data-name="For any problems they bring up" transform="translate(103 285)" fill="#00376f" font-size="16" font-family="Raleway-Medium, Raleway" font-weight="500">
            <tspan x="-27" y="15">For any </tspan><tspan x="-54" y="34">problems they </tspan><tspan x="-31" y="53">bring up</tspan>
        </text>
        <g id="Group_434" data-name="Group 434" transform="translate(96 215.651)">
            <g id="Group_433" data-name="Group 433" clip-path="url(#clip-path)">
                <path id="Path_90" data-name="Path 90" d="M21.6,9.453A10.894,10.894,0,0,0,.416,7.605,9.788,9.788,0,0,0,0,9.754,1.041,1.041,0,0,0,1,10.893a1.11,1.11,0,0,0,1.289-.814,4.145,4.145,0,0,0,.141-.667,8.521,8.521,0,0,1,13.5-5.277c2.978,2.263,4.1,6.056,2.991,10.037A11.591,11.591,0,0,1,14.872,19.8a43.563,43.563,0,0,0-3.231,2.979A7.321,7.321,0,0,0,9.6,27.792a3.655,3.655,0,0,1-7.171,1.137,1.223,1.223,0,0,0-1.564-.918,1.23,1.23,0,0,0-.707,1.671,6.063,6.063,0,0,0,6.4,4.281,5.952,5.952,0,0,0,5.407-5.623,6.469,6.469,0,0,1,2.34-4.891c1-.894,2.029-1.752,3.037-2.636a12.488,12.488,0,0,0,4.418-9.7C21.7,10.558,21.672,10,21.6,9.453" transform="translate(0 0.001)"/>
                <path id="Path_91" data-name="Path 91" d="M32.517,46.532c-.912.094-1.419.567-1.37,1.281.051.75.679,1.188,1.6,1.114a4.882,4.882,0,0,0,4.382-4.075,4.72,4.72,0,0,0-2.737-5.088c-.717-.306-1-.663-.842-1.282,0-.263,0-.415,0-.566a3.657,3.657,0,0,1,7.3-.133c.07.958.55,1.493,1.289,1.437s1.166-.663,1.109-1.6a6.1,6.1,0,0,0-5.659-5.642,5.977,5.977,0,0,0-6.3,4.877A21.4,21.4,0,0,0,31.15,40.1c-.017,1.08.313,1.424,1.4,1.561a2.492,2.492,0,0,1,2.234,2.458,2.452,2.452,0,0,1-2.266,2.414" transform="translate(-26.399 -27.093)"/>
            </g>
        </g>
        <g id="Group_436" data-name="Group 436" transform="translate(195.326 282.651)">
            <g id="Group_435" data-name="Group 435" clip-path="url(#clip-path-2)">
                <path id="Path_92" data-name="Path 92" d="M71.959,41.861a5.557,5.557,0,0,0-2.814,1.066,6.7,6.7,0,0,0-2.777,5.409c-.014.483,0,.968,0,1.454h3.412c.035-.644.041-1.265.108-1.879a2.6,2.6,0,0,1,1.844-2.4,3.687,3.687,0,0,1,1.874-.156,2.818,2.818,0,0,1,2.008,1.114,2.342,2.342,0,0,1-.7,3.113c-.644.433-1.3.852-1.948,1.275a3.189,3.189,0,0,0-1.589,2.934c.033,1.182.007,2.366.008,3.549,0,.126.012.253.018.391h3.47v-.411c0-.693.013-1.386,0-2.079a2.254,2.254,0,0,1,.869-1.88,13.124,13.124,0,0,1,1.36-.924,5.386,5.386,0,0,0,1.18-8.352,6.919,6.919,0,0,0-6.317-2.221" transform="translate(-56.031 -35.296)"/>
                <path id="Path_93" data-name="Path 93" d="M100.454,153.127a1.852,1.852,0,0,0-1.849,1.809,1.827,1.827,0,0,0,3.653.047,1.855,1.855,0,0,0-1.8-1.857" transform="translate(-83.257 -129.292)"/>
                <path id="Path_94" data-name="Path 94" d="M34,17a16.994,16.994,0,1,0-8.8,14.9l6.566,1.434L30.6,27.2A16.924,16.924,0,0,0,34,17m-6.129,9.848c.16.952.346,1.9.536,2.92-1.019-.253-1.985-.468-2.934-.741a1.142,1.142,0,0,0-1.029.163,13.636,13.636,0,0,1-7.794,2.149A14.347,14.347,0,1,1,31.1,14.313a13.95,13.95,0,0,1-2.25,10.693c-.3.445-.606.889-.883,1.349a.784.784,0,0,0-.1.493"/>
            </g>
        </g>
        <g id="Group_438" data-name="Group 438" transform="translate(314.248 222.651)">
            <g id="Group_437" data-name="Group 437" clip-path="url(#clip-path-3)">
                <path id="Path_95" data-name="Path 95" d="M84.051,203.928a1.324,1.324,0,0,0,1.32,1.32h5.28a1.324,1.324,0,0,0,1.32-1.32v-1.32h-7.92Z" transform="translate(-71.041 -171.248)"/>
                <path id="Path_96" data-name="Path 96" d="M66.768,53.07A9.238,9.238,0,1,0,53.906,65.926V68.91a1.324,1.324,0,0,0,1.32,1.32h7.92a1.323,1.323,0,0,0,1.32-1.32V65.926a9.235,9.235,0,0,0,2.3-12.856M64.612,62.1a6.6,6.6,0,0,1-1.661,1.661h0l-1.12.792V67.59h-5.28V64.554l-1.12-.792A6.6,6.6,0,1,1,64.612,62.1" transform="translate(-42.216 -41.51)"/>
                <rect id="Rectangle_474" data-name="Rectangle 474" width="5.187" height="2.455" transform="translate(0 15.706)"/>
                <rect id="Rectangle_475" data-name="Rectangle 475" width="2.455" height="5.187" transform="translate(15.742)"/>
                <rect id="Rectangle_476" data-name="Rectangle 476" width="5.187" height="2.455" transform="translate(28.752 15.706)"/>
                <rect id="Rectangle_477" data-name="Rectangle 477" width="2.455" height="5.187" transform="translate(4.103 5.802) rotate(-45)"/>
                <rect id="Rectangle_478" data-name="Rectangle 478" width="2.455" height="5.187" transform="translate(24.434 26.133) rotate(-45)"/>
                <rect id="Rectangle_479" data-name="Rectangle 479" width="5.187" height="2.455" transform="translate(4.103 28.065) rotate(-45)"/>
                <rect id="Rectangle_480" data-name="Rectangle 480" width="5.187" height="2.455" transform="translate(24.434 7.734) rotate(-45)"/>
            </g>
        </g>
    </g>
</svg>
