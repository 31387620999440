import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    HostBinding,
    Input,
    OnChanges, Output,
    SimpleChanges
} from '@angular/core';
import {TutorChatMessage, TutorCoachingLevels} from "../../../../model";
import {TutorAvatarComponent} from "../../../../components/tutor-avatar/tutor-avatar.component";
import {UserAvatarComponent} from "../../../../components/user-avatar/user-avatar.component";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";

@Component({
    selector: 'app-chat-messages',
    standalone: true,
    imports: [
        TutorAvatarComponent,
        UserAvatarComponent,
        SpinnerComponent
    ],
    templateUrl: './chat-messages.component.html',
    styleUrl: './chat-messages.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessagesComponent implements OnChanges {
    @Input() userInitials = '';
    @Input() loading = false;
    @Input() completed = false;
    @Input() messages: TutorChatMessage[] = [];
    @Input() level = TutorCoachingLevels.Beginner;

    @HostBinding('class.novice') novice = false;
    @HostBinding('class.intermediate') intermediate = false;
    @HostBinding('class.expert') expert = false;
    
    @Output() messageAdded = new EventEmitter(); 
    @Output() onMessageSelected = new EventEmitter<string>(); 
    TutorCoachingLevels = TutorCoachingLevels;
    
    _messages: TutorChatMessage[] = [];
    
    constructor(private cdref: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.novice = this.level == TutorCoachingLevels.Beginner;
        this.intermediate = this.level == TutorCoachingLevels.Intermediate;
        this.expert = this.level == TutorCoachingLevels.Expert;
        
        if (changes['messages']) {
            if (this.completed) {
                this._messages = this.messages;
                this.messageAdded.emit();
            } else {
                this._addMessage();
            }
        }
        
        if (changes['loading']) {
            this.messageAdded.emit();
        }
    }

    private _addMessage() {
        const target = this._messages;
        const source = this.messages;
        
        if (target.length < source.length) {
            target.push(source[target.length]);
            
            this.cdref.detectChanges();
            this.messageAdded.emit();
            setTimeout(() => this._addMessage(), 1000);
        }
    }

    onClick(event: MouseEvent, text: string) {
        if (event.ctrlKey) {
        // if (event.ctrlKey && text.indexOf('"') !== -1) {
            this.onMessageSelected.emit(text);
        }
    }
}
