import {Component} from '@angular/core';
import {
    PureSetMeetingDateComponent,
    SetMeetingDateArgs
} from "./components/pure-set-meeting-date/pure-set-meeting-date.component";
import {
    PureSelectDiscussionTopicsComponent
} from "../select-discussion-topics/components/pure-select-discussion-topics/pure-select-discussion-topics.component";
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {Router} from "@angular/router";
import {RatingService} from "../../../../services/rating.service";
import {SalesPrepReport} from "../../../../model";
import {ReportService} from "../../../../services/report.service";

@Component({
    selector: 'app-set-meeting-date',
    standalone: true,
    imports: [
        PureSetMeetingDateComponent,
    ],
    templateUrl: './set-meeting-date.component.html',
    styleUrl: './set-meeting-date.component.scss'
})
export class SetMeetingDateComponent {
    saving = false;
    company = this.report.company;
    product = this.report.offering;
    job = this.report.job;

    constructor(private report: ReportBuilderService,
                private ratingService: RatingService,
                private reportsService: ReportService,
                private router: Router) {

        if (!report.job()) {
            const o = report.offering();
            if (o?.jobs?.length) {
                this.router.navigate(['/report-builder', 'job']);
            } else {
                report.job.set('_');
            }
        }
    }

    save(args: SetMeetingDateArgs) {
        console.log('[SetMeetingDateComponent] save', args);

        this.saving = true;
        this.report.saveReport(args.date, args.time).subscribe({
            next: (data: SalesPrepReport) => {
                this.reportsService.reloadList(data);
                this.ratingService.saveBuilderRatings(data.id).subscribe({
                    next: () => console.log('[SetMeetingDateComponent] assigned ratings to report'),
                    error: (e: any) => console.error(e),
                    complete: () => this._openReport(data.id)
                });
            },
            complete: () => this.saving = false
        });
    }

    private _openReport(reportId: string) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        this.router.navigate(['/report', reportId]);
    }
}
