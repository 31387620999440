<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="218.149" height="240" viewBox="0 0 218.149 240">
    <defs>
        <linearGradient id="linear-gradient" x1="-0.014" y1="0.006" x2="0.99" y2="-0.009" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#d1d1d1"/>
            <stop offset="1" stop-color="#d1d1d1" stop-opacity="0"/>
        </linearGradient>
    </defs>
    <g id="Group_342" data-name="Group 342" transform="translate(2760 -2482.713)" style="mix-blend-mode: multiply;isolation: isolate">
        <path id="Rectangle_397" data-name="Rectangle 397" d="M15,0H127.263a0,0,0,0,1,0,0V203.522a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V15A15,15,0,0,1,15,0Z" transform="matrix(0.839, 0.545, -0.545, 0.839, -2648.583, 2482.713)" opacity="0.5" fill="url(#linear-gradient)" style="mix-blend-mode: multiply;isolation: isolate"/>
        <path id="Rectangle_396" data-name="Rectangle 396" d="M0,0H119.866a4,4,0,0,1,4,4V161.933a4,4,0,0,1-4,4H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(-2760 2487.958)" fill="#d1d1d1"/>
        <path id="Path_61" data-name="Path 61" d="M0,0H4.587V7.454L0,12.04Z" transform="translate(-2748.531 2653.113)" fill="#ff851b"/>
        <path id="Path_62" data-name="Path 62" d="M4.587,0H0V7.454L4.587,12.04Z" transform="translate(-2743.945 2653.113)" fill="#db6600"/>
        <rect id="Rectangle_398" data-name="Rectangle 398" width="4.588" height="165.154" transform="translate(-2755.412 2487.958)" fill="#b5b5b5"/>
        <rect id="Rectangle_399" data-name="Rectangle 399" width="77.99" height="64.227" rx="2" transform="translate(-2732.474 2513.191)" fill="#fff"/>
        <rect id="Rectangle_400" data-name="Rectangle 400" width="50.464" height="4.588" rx="1" transform="translate(-2718.712 2600.355)" fill="#fff"/>
        <rect id="Rectangle_401" data-name="Rectangle 401" width="50.464" height="4.588" rx="1" transform="translate(-2718.712 2609.531)" fill="#fff"/>
        <rect id="Rectangle_402" data-name="Rectangle 402" width="50.464" height="4.588" rx="1" transform="translate(-2718.712 2618.706)" fill="#fff"/>
    </g>
</svg>

<div class="text">
    No reports have been created. <a [routerLink]="['/report-builder', 'new']">Create one now</a>
</div>
