import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-page-header',
    standalone: true,
    imports: [],
    templateUrl: './page-header.component.html',
    styleUrl: './page-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {
    @Input() title = 'No Title';
    @Input() text = '';
}
