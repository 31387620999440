import {Component, OnInit, signal} from '@angular/core';
import {FormsModule, NgForm} from "@angular/forms";
import {} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {AutofocusDirective} from "../../directives/autofocus.directive";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {Router} from "@angular/router";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {LoginCredentials, PureLoginComponent} from "./components/pure-login/pure-login.component";
import {MessageService} from "../../services/message.service";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [
        FormsModule,
        AutofocusDirective,
        MainHeaderComponent,
        MainPanelComponent,
        SpinnerComponent,
        PureLoginComponent,
        FooterCompactComponent,
    ],
    providers: [
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
    errorMessage = signal('');
    loadingProfile = this.authService.loadingProfile;
    
    constructor(public authService: AuthService, private messageService: MessageService, private router: Router) {
    }

    login(user: LoginCredentials) {
        this.authService.login(user.email.trim(), user.password.trim()).subscribe({
            next: () => {
                // this.authService.loadProfile();
                location.href = '/dashboard?' + +(new Date());
                // this.router.navigate(['/dashboard']);
            },
            error: err => this.errorMessage.set('Login failed')
        });
    }

    signInWithGoogle() {
        this.authService.signInWithGoogle();
    }

    forgotPassword() {
        this.router.navigate(['/forgot-password']);
    }

    createAccount() {
        this.router.navigate(['/signup']);
    }
}
