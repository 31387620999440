import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Offering, UserProfile} from "../../../../model";
import {InitialsPipe} from "../../../../pipes/initials.pipe";
import {RouterLink} from "@angular/router";
import {DatePipe} from "@angular/common";
import {MainPanelComponent} from "../../../../components/main-panel/main-panel.component";
import {ProfileInfoComponent} from "../profile-info/profile-info.component";
import {SignupNoteComponent} from "../signup-note/signup-note.component";
import {PureMyOfferingsComponent} from "../pure-my-offerings/pure-my-offerings.component";
import {of} from "rxjs";

@Component({
    selector: 'app-pure-profile',
    standalone: true,
    imports: [
        InitialsPipe,
        RouterLink,
        DatePipe,
        MainPanelComponent,
        ProfileInfoComponent,
        PureMyOfferingsComponent,
        SignupNoteComponent
    ],
    templateUrl: './pure-profile.component.html',
    styleUrl: './pure-profile.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureProfileComponent {
    @Input() signUpMode = false;
    @Input() userProfile!: UserProfile;
    @Input() offerings!: Offering[];

    @Output() onAddOffering = new EventEmitter();
    @Output() onEditOffering = new EventEmitter<Offering>();
    @Output() onDeleteOffering = new EventEmitter<Offering>();
    @Output() onLogout = new EventEmitter();
}
