import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
    TwelveWayStep
} from "../../../twelve-way-slider-screen/components/twelve-way-slider/twelve-way-slider.component";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-twelve-ways-widget',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './twelve-ways-widget.component.html',
    styleUrl: './twelve-ways-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwelveWaysWidgetComponent implements OnInit {
    @Input() step = 1;
    selected!: TwelveWayStep;
    
    ngOnInit() {
        this.select(Math.floor(Math.random() * 11) + 1);
    }
    
    select(i: number) {
        this.step = i;
        this.selected = this.steps[this.step - 1];
    }

    steps: TwelveWayStep[] = [
        {
            title: 'Start conversations',
            description: 'Use Company News and Market Trends for ice-breakers',
        }, {
            title: 'Deepen conversations',
            description: 'Prep with pre-call customer reports & role-play with AI',
        }, {
            title: 'Stay current',
            description: 'Check for late-breaking customer news before visits',
        }, {
            title: 'Scout new accounts',
            description: 'Study their leadership, products, competitors & strategy',
        }, {
            title: 'Explore new applications',
            description: 'Learn application-specific problems, trends & processes',
        }, {
            title: 'Engage each contact',
            description: 'Study needs of each job function in an application area',
        }, {
            title: 'Research problems',
            description: 'Create an in-depth, 2-page Problem Brief in 60 seconds',
        }, {
            title: 'Find prospects',
            description: 'Identify prospects by viewing your account’s competitors',
        }, {
            title: 'Make CRM entries',
            description: 'Record faster, better notes by copy-pasting from a report',
        }, {
            title: 'Leapfrog purchasing',
            description: 'Use a Customer Handout to access their internal experts',
        }, {
            title: 'Build skills',
            description: 'Conduct role-play conversations with Claire, AI Guide',
        }, {
            title: 'Investigate ideas',
            description: 'Ensure you understand the problem you hope to solve'
        }
    ];

}
