import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DatePipe} from "@angular/common";
import {NewsArticle} from "../../../../model";

@Component({
    selector: 'app-news-section-item',
    standalone: true,
    imports: [
        DatePipe
    ],
    templateUrl: './news-section-item.component.html',
    styleUrl: './news-section-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsSectionItemComponent {    
    @Input() article!: NewsArticle;
}
