<div class="selection">
    <div class="time">
        <a (click)="hoursActive = true" [class.selected]="hoursActive">{{ hour }}</a> :
        <a (click)="hoursActive = false" [class.selected]="!hoursActive">{{ minute }}</a>
    </div>
    
    <div class="mode">
        <a [class.selected]="mode == 'AM'" (click)="setMode('AM')">AM</a>
        <a [class.selected]="mode == 'PM'" (click)="setMode('PM')">PM</a>
    </div>
</div>

<div class="analog-watch">
    <div class="center"></div>
    @if (hoursActive) {
        <div class="hour-hand" [style.transform]="hourHandTransform"></div>
        
        <div class="hour-labels">
            @for (h of hourLabels; track h; let i = $index) {
                <div class="hour-label" (click)="setHour(+h)"
                     [class.selected]="+h == +hour"
                     [style.left]="getLabelPosition(i).left"
                     [style.top]="getLabelPosition(i).top">
                    {{ h }}
                </div>
            }
        </div>
    } @else {
        <div class="hour-hand" [style.transform]="minuteHandTransform"></div>
        <div class="hour-labels">
            @for (h of minuteLabels; track h; let i = $index) {
                <div class="hour-label" (click)="setMinute(+h)"
                     [class.selected]="h == minute || (h == '60' && minute == '00')"
                     [style.left]="getLabelPosition(i).left"
                     [style.top]="getLabelPosition(i).top">
                    {{ h }}
                </div>
            }
        </div>
    }

    <div class="arrows">
        <a [class.disabled]="hoursActive" (click)="hoursActive = true">
            <span class="material-symbols-outlined">chevron_left</span>
        </a>
        <a [class.disabled]="!hoursActive" (click)="hoursActive = false">
            <span class="material-symbols-outlined">chevron_right</span>
        </a>
    </div>
</div>
