<div class="flex info-section">
    <div class="info">
        <h3 qa="name">USE-CASE #{{ step }}</h3>
        <h1 qa="title">{{ selected.title }}</h1>
        <div class="desc">{{ selected.description }}</div>
    </div>
    <div class="img step-{{ step }}" qa="img"></div>
</div>

<div class="actions">
    <a class="view" [routerLink]="['/12-ways', step]" qa="view">View how to apply</a>
    
    <div class="steps">
        @for (x of steps; track x; let i = $index) {
            <a [class.selected]="i + 1 == step" (click)="select(i + 1)" [attr.qa]="'case-' + (i + 1)">{{ i + 1 }}</a>        
        }
    </div>
</div>