<div class="content" qa="conf-dialog">
    <div class="title">
        <h1>{{title}}</h1>
    </div>
    <div class="message">
        {{message}}
    </div>
    
    <div class="sp-form">
        <button (click)="onCancel.emit()" qa="can">Cancel</button>
        <button (click)="onConfirm.emit()" class="primary" qa="conf">Confirm</button>
    </div>
</div>
