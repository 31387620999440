import {computed, Injectable, signal} from '@angular/core';
import {BehaviorSubject, catchError, exhaustMap, finalize, map, of, tap} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {toSignal} from "@angular/core/rxjs-interop";
import {TutorCoachingLevels} from "../model";

@Injectable({
    providedIn: 'root'
})
export class ElearningService {

    loading = signal<boolean>(false);
    eLearningData = signal<ELearningData | undefined>(undefined);
    modulesStatuses = computed<ELearningModuleStatus[]>(() => this.eLearningData()?.modules || []);

    private loadData$ = new BehaviorSubject<void>(undefined);

    constructor(private http: HttpClient) {
    }

    eLearningData$ = this.loadData$.pipe(
        tap(() => this.loading.set(true)),
        tap(x => console.log('[AuthService] userProfile$', x)),
        exhaustMap(() => this.http.get<ELearningData>('/api/user/elearning')),        
        catchError(error => of(undefined)),
        tap(x => this.eLearningData.set(x)),
        tap(x => console.log('[ElearningService] eLearningData$', x)),

        finalize(() => this.loading.set(false))
    );

    eLearningDataReadonly = toSignal<ELearningData | undefined, undefined>(this.eLearningData$, {
        initialValue: undefined
    });

    reloadELearningData() {
        console.log('[ElearningService] reloadELearningData');
        this.loadData$.next();
    }
    
    static modules: ELearningModule[] = [
        {
            number: 1,
            title: 'Become customers’ trusted advisor',
            duration: 8,
            description: 'Consultative selling was given a major boost in 1988 with <span class="italic">SPIN Selling</span> and again in 2013 with <span class="italic">The Challenger Sale</span>. This research showed top salespeople focus less on their world. They enter the customer’s world with great questions and challenging ideas. This module explains how to use “jobs-to-be-done” and desired customer “outcomes” to become a trusted advisor.'
        },
        {
            number: 2,
            title: 'Prepare with SalesPrep software',
            duration: 8,
            description: 'This module shows you how to complete your one-time software setup: Just enter the products you normally sell and the common applications (customer jobs to be done) for each. After this, it will just take seconds to enter the customer you intend to visit and create your AI-generated report... viewable on your phone, desktop, or in PDF format.'
        },
        {
            number: 3,
            title: 'Probe with “What-Why-Clarify”',
            duration: 10,
            description: 'You’ll learn to use our powerful What-Why-Clarify method to understand customers’ desired outcome in their job-to-be-done. Then you’ll role-play as a salesperson, with multiple questions you could ask as the conversation proceeds. You’ll get feedback on which questions are Great, OK, or Weak. After this, consider practicing with Claire, your AI Guide.'
        },
        {
            number: 4,
            title: 'Listen like you mean it',
            duration: 8,
            description: 'For great listening you need to 1) actively listen... and 2) demonstrate you’re listening. You’ll learn tips to promote your active listening, and roadblocks to be aware of. The PEAR method ensures you demonstrate your interest, with Posture, Expression, Activity, and Responses. You’ll see videos demonstrating both weak and good PEAR practices.'            
        },
        {
            number: 5,
            title: 'Uncover customer outcomes',
            duration: 12,
            description: 'When your customer speaks, you’ll hear either an Outcome, a Solution, or Background information. This module provides video role-playing to help you identify each. After probing with “What” and “Why” questions, your job is to “Clarify” with an Outcome Statement. This ensures you “get it” and encourages the customer to think clearly about their needs.'
        },
        {
            number: 6,
            title: 'Use advanced SALT probing',
            duration: 8,
            description: 'The “What” questions in What-Why-Clarify are critical for deeply understanding the customer’s job to be done. Our SALT method helps with questions you can ask... what your Senses would experience, the Actors involved (people and things), Timing, and Location. Your role-playing in this module will prepare you to then practice with Claire, your AI Guide.'
        },
        {
            number: 7,
            title: 'Use SalesPrep ‘News & Trends’',
            duration: 8,
            description: 'The first part of your SalesPrep report includes news about your target customer and market trends. This prep-work ensures a) you’re not blind-sided by leadership changes or an acquisition, b) gives you “ice-breaker” comments to start your customer meeting, and c) may generate important problems for you to probe in depth.'
        },
        {
            number: 8,
            title: 'Use SalesPrep ‘Common Problems’',
            duration: 8,
            description: 'This part of your SalesPrep report describes 15-20 problems customers typically see using your type of product in the target customer’s job to be done. This lets you a) understand their challenges, b) learn their terminology, c) know what you should research further, b) prepare your What & Why questions, and e) plan specific sales opportunities to pursue.'
        },
        {
            number: 9,
            title: 'Use SalesPrep ‘Process Steps’',
            duration: 11,
            description: 'Your SalesPrep report also describes 15-20 process steps customers normally conduct when using your type of product. Whether you’re taking a tour, observing an operation, or just discussing it, this pre-knowledge improves your questions. This module teaches two methods: 1) Faster-Better-Cheaper, and 2) AMUSE observation methodology.'
        },
        {
            number: 10,
            title: 'Apply your probing skills in many ways.',
            duration: 11,
            description: 'You can use your probing skills beyond typical sales visits. Use SalesPrep’s Company Overview with New Prospects. Apply these skills with great impact during Virtual Meetings. Marketing and sales can join forces for early detection of market needs with Market Scouting. And if your business uses New Product Blueprinting, you can directly apply these probing skills during team-based customer interviews.'
        }
    ]    
}


export interface ELearningModule {
    number: number;
    title: string;
    description: string;
    duration: number;
}

export interface ELearningData {
    
    eLearningCertified: Date | null;
    aiGuideCertified: Date | null;
    reportsCertified: Date | null;
    certified: Date | null;

    modules: ELearningModuleStatus[];
    practiceSessionsCompleted: PracticeSessionStatus[];
    reportsGenerated: number;
}

export enum ELearningModuleStatusEnum {
    NotStarted = 0,
    InProgress = 1,
    Completed = 2
}

export interface ELearningModuleStatus {
    id: number;
    status: ELearningModuleStatusEnum;
}

export interface PracticeSessionStatus {
    level: TutorCoachingLevels;
    completed: Date;
}