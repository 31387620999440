import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular";
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

const host = location.host;
let env = 'QA';
if (host.indexOf('salesprep.com') !== -1) {
    env = 'Production';
} else {
    switch (host) {
        case 'stage.sp.aim.vayner.systems':
            env = 'Stage';
            break;
        case 'uat.sp.aim.vayner.systems':
            env = 'UAT';
            break;
    }
}

Sentry.init({   
    dsn: "https://7a0b8564190d8e9a83985c39c14a7f11@o530098.ingest.us.sentry.io/4507452003450880",
    release: (<any> window).version || '1.0.0',
    environment: env,
    integrations: [
    ],
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
