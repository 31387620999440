<a title="Home page" [routerLink]="['/dashboard']" routerLinkActive="active">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
        <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(-3.5 -2)">
            <path id="Path_3" data-name="Path 3" d="M4.5,10l9-7,9,7V21a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Path_4" data-name="Path 4" d="M13.5,28V18h6V28" transform="translate(-3 -5)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </g>
    </svg>
    
    Dashboard
</a>

<a title="My Reports" [routerLink]="['/reports']" routerLinkActive="active">
    <svg xmlns="http://www.w3.org/2000/svg" width="15.307" height="22" viewBox="0 0 15.307 22" style="stroke: none">
        <g id="Reports_Icon" data-name="Reports Icon" transform="translate(2760 -2485)">
            <path id="Union_85" data-name="Union 85" d="M-878.075,1637.092v-.925h.85v1.495Zm-.85-.925h.85v.925l-.85.57Zm-1.076,0v-20.5h12.307a3,3,0,0,1,3,3v14.505a3,3,0,0,1-3,3Zm1.6-1.6h10.706a1.4,1.4,0,0,0,1.4-1.4v-14.505a1.4,1.4,0,0,0-1.4-1.4H-878.4Z" transform="translate(-1880 869.338)"/>
            <rect id="Rectangle_400" data-name="Rectangle 400" width="7.175" height="1.394" rx="0.697" transform="translate(-2755.934 2496.167)"/>
            <rect id="Rectangle_401" data-name="Rectangle 401" width="7.175" height="1.394" rx="0.697" transform="translate(-2755.934 2498.7)"/>
            <rect id="Rectangle_402" data-name="Rectangle 402" width="7.175" height="1.394" rx="0.697" transform="translate(-2755.934 2501.233)"/>
            <path id="Rectangle_710" data-name="Rectangle 710" d="M1.6,1.6V4.324H8.157V1.6H1.6M1.5,0H8.257a1.5,1.5,0,0,1,1.5,1.5V4.424a1.5,1.5,0,0,1-1.5,1.5H1.5A1.5,1.5,0,0,1,0,4.424V1.5A1.5,1.5,0,0,1,1.5,0Z" transform="translate(-2757.225 2488.146)"/>
        </g>
    </svg>

    My Reports
</a>

<a title="AI Guide" [routerLink]="['/ai-guide']" routerLinkActive="active">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 22 22" style="stroke: none">
        <g id="Group_409" data-name="Group 409" transform="translate(6 6)">
            <g id="Group_410" data-name="Group 410" transform="translate(-5.001 -4.999)">
                <path id="Union_8" data-name="Union 8" d="M10.189,13.211l-.2-.04c-.136-.027-.272-.053-.405-.088a3.854,3.854,0,0,1-1.341-.624c-.042-.031-.059-.031-.067-.031a.22.22,0,0,0-.066.015,8.671,8.671,0,0,1-2.876.521A7.813,7.813,0,0,1,.323,11.146a.768.768,0,0,1-.285-.867.734.734,0,0,1,.649-.532.846.846,0,0,1,.649.228,6.11,6.11,0,0,0,2.858,1.334,6.589,6.589,0,0,0,1.173.108,5.953,5.953,0,0,0,2.455-.53,1.159,1.159,0,0,1,.477-.115.987.987,0,0,1,.664.281,2.353,2.353,0,0,0,1.58.6,2.915,2.915,0,0,0,.562-.057l-.055-.168c-.085-.258-.172-.524-.234-.794a5.2,5.2,0,0,1,.21-3.025A6.2,6.2,0,0,0,10.1,1.308.789.789,0,0,1,10.179.174.757.757,0,0,1,10.655,0,.839.839,0,0,1,11.3.338a7.713,7.713,0,0,1,1.7,4.206.91.91,0,0,0,.018.1l.013.063,0,1.051-.017.073a.389.389,0,0,0-.014.067,7.931,7.931,0,0,1-.542,2.275,3.556,3.556,0,0,0-.207,1.7,4.207,4.207,0,0,0,.583,1.7.774.774,0,0,1-.321,1.139,3.347,3.347,0,0,1-.591.261c-.221.067-.447.119-.672.17l-.3.071Z" transform="translate(6.957 6.79)"/>
                <path id="Union_7" data-name="Union 7" d="M.537,15.19a.834.834,0,0,1-.441-.5.775.775,0,0,1,.094-.616,4.109,4.109,0,0,0,.39-3.507A15.456,15.456,0,0,1,.1,8.652a6.721,6.721,0,0,1,.561-4A7.592,7.592,0,0,1,6.325.141,7.2,7.2,0,0,1,7.742,0,7.821,7.821,0,0,1,14.4,3.874a7.663,7.663,0,0,1,1.052,3.87,7.838,7.838,0,0,1-7.792,7.7,7.528,7.528,0,0,1-2.739-.527.13.13,0,0,0-.044-.011.184.184,0,0,0-.072.036,4.169,4.169,0,0,1-2.32.754A4.275,4.275,0,0,1,.537,15.19ZM7.13,1.585A6.049,6.049,0,0,0,2.341,4.732a5.467,5.467,0,0,0-.718,3.686,12.51,12.51,0,0,0,.372,1.488c.042.144.084.287.125.431a5.3,5.3,0,0,1,.176,2.413c-.063.351-.158.686-.257,1.041q-.04.143-.08.288a2.893,2.893,0,0,0,.538.053,2.421,2.421,0,0,0,1.636-.64.91.91,0,0,1,.611-.259.98.98,0,0,1,.416.1,5.9,5.9,0,0,0,2.494.558A6.277,6.277,0,0,0,8.685,13.8a6.343,6.343,0,0,0,4.1-2.58,6.065,6.065,0,0,0,1.021-4.584A6.209,6.209,0,0,0,7.767,1.55,5.8,5.8,0,0,0,7.13,1.585Z" transform="translate(0)"/>
                <path id="Union_6" data-name="Union 6" d="M1.107,2.221A1.1,1.1,0,0,1,0,1.1a1.11,1.11,0,0,1,2.221.006A1.1,1.1,0,0,1,1.11,2.221Z" transform="translate(3.223 6.64)"/>
                <path id="Union_5" data-name="Union 5" d="M1.107,2.222A1.1,1.1,0,0,1,0,1.105,1.117,1.117,0,0,1,1.1,0,1.119,1.119,0,0,1,2.222,1.109a1.1,1.1,0,0,1-.322.783,1.11,1.11,0,0,1-.793.33Z" transform="translate(6.621 6.64)"/>
                <path id="Union_4" data-name="Union 4" d="M1.108,2.221A1.117,1.117,0,0,1,0,1.125,1.1,1.1,0,0,1,.32.338a1.106,1.106,0,0,1,1.9.776A1.1,1.1,0,0,1,1.114,2.221Z" transform="translate(10.011 6.64)"/>
            </g>
        </g>
    </svg>
    
    AI Guide
</a>

<a title="View Account" [routerLink]="['/mobile-menu']" routerLinkActive="active">
    <span style="font-weight: 800" class="material-symbols-outlined mobile-layout"> more_vert </span>
    
    More
</a>

