import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReportSectionRating} from "../../model";

@Component({
    selector: 'app-rate-section',
    standalone: true,
    imports: [],
    templateUrl: './rate-section.component.html',
    styleUrl: './rate-section.component.scss'
})
export class RateSectionComponent {
    @Input() rating!: ReportSectionRating;    
    @Output() ratingChange = new EventEmitter<ReportSectionRating>();

    set(rating: boolean) {
        this.rating.rating = rating;
        this.ratingChange.emit(this.rating);        
    }
}
