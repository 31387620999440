@if (certified) {
    <app-count-label [count]="completedModules" qa="comp-modules">
        Modules<br>
        completed
    </app-count-label>
} @else {
    <app-count-label [count]="completedModules" qa="all-modules">
        Of <span class="number">10</span> Modules<br>
        completed
    </app-count-label>
}

<div>
    <div class="chart" qa="mod-chart">
        @for (number of [1,2,3,4,5,6,7,8,9,10]; track number; let i = $index) {
            <a href="/api/user/elearning-session/{{number}}" [attr.qa]="'ses-' + (i + 1)">
                <span class="material-symbols-outlined bar" [class.done]="isCompleted(number)"
                      [title]="getTitle(number)" qa="bar">done</span>
            </a>
        }
    </div>
</div>