import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {MainPanelComponent} from "../../../../components/main-panel/main-panel.component";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-top-section',
    standalone: true,
    imports: [
        MainPanelComponent,
        RouterLink
    ],
    templateUrl: './top-section.component.html',
    styleUrl: './top-section.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopSectionComponent {
    @Input() authenticated = false;
    @Input() trialDays = 30;
}
