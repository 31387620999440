import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'today',
    standalone: true
})
export class TodayPipe implements PipeTransform {

    transform(date: string | undefined, ...args: unknown[]): boolean {
        if (!date) {
            return false;
        }
        const d = new Date(date);
        const today = new Date();

        return d.getFullYear() === today.getFullYear() &&
            d.getMonth() === today.getMonth() &&
            d.getDate() === today.getDate();
    }
}
