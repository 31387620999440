@if (company() && offerings()) {
    <app-pure-select-offering
            (onSelectOfferingType)="setCategories($event)"
            
            (onSelect)="select($event)"
            (onAdd)="addOffering($event)"
            (onRemoveJob)="removeJob($event)"
            [company]="company()!"
            [offerings]="offerings()!"
            [categories]="categories()"
    ></app-pure-select-offering>
}