<app-select-offering-form
        [aiGuideMode]="true"
        [step]="'1'"
        [title]="'this practice session'"
        [offerings]="offerings"
        [backVisible]="false"
        
        (onSelect)="onSelect.emit($event)"
        (onAddNew)="addNew = true"
        (onSelectReport)="onSelectReport.emit()"
    />

@if (addNew) {

    <app-info-panel [title]="'Add Offering'" (onClose)="cancelDialog()">
        <app-pure-add-offering
                class="sp-form-alternative"

                (onSelectOfferingType)="type = $event; onSelectOfferingType.emit($event)"
                (onCategorySelected)="category = $event"
                (onSave)="saveOffering($event)"
                (onRemoveJob)="onRemoveJob.emit($event)"

                [inline]="true"
                [type]="type"
                [selectedCategory]="category"
                [categories]="categories"
        />

    </app-info-panel>
}