import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {ErrorMessageComponent} from "../../../../components/error-message/error-message.component";
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {MainPanelComponent} from "../../../../components/main-panel/main-panel.component";

@Component({
    selector: 'app-pure-reset-password',
    standalone: true,
    imports: [
        AutofocusDirective,
        ErrorMessageComponent,
        FormsModule,
        ReactiveFormsModule,
        MainPanelComponent
    ],
    templateUrl: './pure-reset-password.component.html',
    styleUrl: './pure-reset-password.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureResetPasswordComponent {
    @Input() password = '';
    @Input() password2 = '';
    @Input() errorMessage = '';
    @Output() onResetPassword = new EventEmitter<string>();

    @Input() showPassword = false;
    @Input() showPassword2 = false;
    
    resetPassword(f: NgForm) {
        if (!f.valid) {
            this.errorMessage = 'Please enter a valid email and password';
            return;
        }
        
        if (this.password != this.password2) {
            this.errorMessage = 'Passwords aren\'t the same';
        } else {
            this.errorMessage = '';
            this.onResetPassword.emit(this.password);
        }
    }

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    togglePasswordVisibility2() {
        this.showPassword2 = !this.showPassword2;
    }
}
