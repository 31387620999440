import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-resources-list',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './resources-list.component.html',
    styleUrl: './resources-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesListComponent {
    @HostBinding('class.detailed') @Input() detailed = false;
}
