<app-page-header [title]="'More'"/>

<div class="links">
    <a title="E-Learning" [routerLink]="['/e-learning']" routerLinkActive="active">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="25" viewBox="0 0 26.75 20.757">
            <path id="Icon_awesome-graduation-cap" data-name="Icon awesome-graduation-cap" d="M24.31,8.913l-10.9-4.24a2.5,2.5,0,0,0-1.828,0L.69,8.913a1.267,1.267,0,0,0,0,2.256l1.9.739a4.548,4.548,0,0,0-.7,2.321,1.659,1.659,0,0,0-.641,1.354,1.693,1.693,0,0,0,.541,1.269l-1,5.684c-.087.494.21.963.61.963H3.6c.4,0,.7-.469.61-.963l-1-5.684a1.693,1.693,0,0,0,.541-1.269,1.668,1.668,0,0,0-.612-1.329,2.526,2.526,0,0,1,.808-1.817l7.639,2.973a2.5,2.5,0,0,0,1.828,0l10.9-4.24a1.267,1.267,0,0,0,0-2.256Zm-10.529,8.01a3.5,3.5,0,0,1-2.562,0l-5.665-2.2L5,20.333C5,22.082,8.358,23.5,12.5,23.5S20,22.082,20,20.333l-.554-5.614-5.665,2.2Z" transform="translate(0.875 -3.618)" fill="none" stroke-width="1.75"/>
        </svg>
    
        E-Learning
    </a>
    
    <a title="Resources" [routerLink]="['/resources']" routerLinkActive="active">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
            <g id="Icon_feather-file-text" data-name="Icon feather-file-text" transform="translate(-5 -2)">
                <path id="Path_10" data-name="Path 10" d="M16,3H8A2,2,0,0,0,6,5V21a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V9Z" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_11" data-name="Path 11" d="M21,3V9h6" transform="translate(-5 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_12" data-name="Path 12" d="M20,19.5H12" transform="translate(-2 -5.5)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_13" data-name="Path 13" d="M20,25.5H12" transform="translate(-2 -7.5)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_14" data-name="Path 14" d="M14,13.5H12" transform="translate(-2 -3.5)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
        </svg>
    
        Resources
    </a>
    
    <a title="View Account" [routerLink]="['/profile']" routerLinkActive="active">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
            <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-5 -3.5)">
                <path id="Path_8" data-name="Path 8" d="M22,28.5v-2a4,4,0,0,0-4-4H10a4,4,0,0,0-4,4v2" transform="translate(0 -6)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_9" data-name="Path 9" d="M20,8.5a4,4,0,1,1-4-4,4,4,0,0,1,4,4Z" transform="translate(-2 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
        </svg>
    
        Account &amp; My Offerings
    </a>

    <a title="Help" target="_blank" href="https://insights.theaiminstitute.com/aim-institute-request-support">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.632" height="22.632" viewBox="0 0 22.632 22.632" style="stroke: none">
            <g id="Help_Icon" data-name="Help Icon" transform="translate(-4.5 -4.5)">
                <path id="Path_304" data-name="Path 304" d="M15.816,4.5a11.316,11.316,0,1,1-8,3.314A11.242,11.242,0,0,1,15.816,4.5Zm0,20.382A9.066,9.066,0,1,0,6.75,15.816,9.076,9.076,0,0,0,15.816,24.882Z" fill="#00376f"/>
                <path id="Path_305" data-name="Path 305" d="M17.016,20.084a.984.984,0,0,1-.984-.984,3.724,3.724,0,0,1,1.862-3.23c1.187-.8,1.67-1.211,1.67-2.124,0-.234,0-.945-1.145-1.49a2.9,2.9,0,0,0-1.1-.162,2.371,2.371,0,0,0-1.5.461,1.925,1.925,0,0,0-.769,1.191c0-.016,0-.024,0-.024l-1.966-.094a3.779,3.779,0,0,1,1.5-2.606,4.311,4.311,0,0,1,2.712-.9,4.678,4.678,0,0,1,1.974.353,3.573,3.573,0,0,1,2.268,3.268c0,2.054-1.409,3-2.542,3.759A1.771,1.771,0,0,0,18,19.1.984.984,0,0,1,17.016,20.084Z" transform="translate(-1.489 -0.968)" fill="#00376f"/>
                <path id="Path_306" data-name="Path 306" d="M18.488,24.221a1.158,1.158,0,1,1-1.158-1.158A1.158,1.158,0,0,1,18.488,24.221Z" transform="translate(-1.862 -3.078)" fill="#00376f"/>
            </g>
        </svg>

        Help
    </a>

    <a title="Logout" (click)="logout()" routerLinkActive="active">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
            <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-5 -3.5)">
                <path id="Path_8" data-name="Path 8" d="M22,28.5v-2a4,4,0,0,0-4-4H10a4,4,0,0,0-4,4v2" transform="translate(0 -6)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_9" data-name="Path 9" d="M20,8.5a4,4,0,1,1-4-4,4,4,0,0,1,4,4Z" transform="translate(-2 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
        </svg>

        Logout
    </a>
    
    
</div>

<div class="links footer">
    <a routerLink="/terms">Terms</a>
    <a routerLink="/privacy">Privacy</a>
    <a routerLink="/cookie-policy">Cookie Policy</a>
    <a routerLink="/faq">FAQs</a>
    <a routerLink="/contact-us">Contact Us</a>    
</div>
    

<app-footer-smart/>