import {PdfGenerator} from "../pdf-generator";
import {DiscussionTopicsSection, ILegendItem} from "./discussion-topics-section";
import {DiscussionTopics} from "../../../model";



export class ProcessStepsSection extends DiscussionTopicsSection {
    private fasterLegendItem = {
        color: '#f35f7c',
        icon: 'faster',
        label: 'Faster?',
        items: ['Streamlined, Less time,', 'Less complex']
    }
    private betterLegendItem = {
        color: '#b29fd7',
        icon: 'better',
        label: 'Better?',
        items: ['Higher output, More uniform,', 'Greater value']
    }
    private cheaperLegendItem = {
        color: '#a2dc9d',
        icon: 'cheaper',
        label: 'Cheaper?',
        items: ['More efficient, Less resources,', 'Less waste']
    }
    private _labelDrawn = false;
    private _labelHeight = 21;
    
    constructor(pdf: PdfGenerator, topics: DiscussionTopics) {
        super(pdf, topics.processSteps.filter(x => x.selected), topics.role, 'PROCESS STEPS', 'process-steps', 16.779, 16.142);
    }

    override drawTopLegend(y: number): number {
        return Math.max(
            this._drawLegendItem(44, y, this.fasterLegendItem, true),
            this._drawLegendItem(224, y, this.betterLegendItem, true),
            this._drawLegendItem(404, y, this.cheaperLegendItem, true)
        );
    }
    
    override drawSideLegend(y: number, startPage: number, endPage: number) {
        if (startPage != endPage) {
            this.pdf.doc.setPage(startPage);
        }
        
        y = this._drawLegendItem(405, y, this.fasterLegendItem);
        y = this._drawLegendItem(405, y, this.betterLegendItem);
        y = this._drawLegendItem(405, y, this.cheaperLegendItem);

        const currentPage = this.pdf.getPageNumber();
        if (currentPage < endPage) {
            this.pdf.doc.setPage(endPage);
            return 0; // no need to offset for the legend height
        }
        return y;
    }

    private _drawLegendItem(x: number, y: number, item: ILegendItem, topLegend = false) {
        
        const cellWidth = 162;
        const h = 112;
        const pdf = this.pdf;
        const radius = 10;
        const centeredX = x + cellWidth / 2;
        
        if (!topLegend && y + h + (this._labelDrawn ? 0 : this._labelHeight) > pdf.pageHeight) {
            pdf.doc.setPage(pdf.doc.getCurrentPageInfo().pageNumber + 1);
            y = 60.577;
            this._labelDrawn = false;
        }

        
        if (!this._labelDrawn) {
            pdf.text('Would you like any step to be...', centeredX, y, 'Raleway-BoldItalic', 10, 0, {
                align: 'center'
            });
            y += this._labelHeight;
            this._labelDrawn = true;
        } else if (topLegend) {
            y += this._labelHeight;
        }

        // const h = 87.620 + descHeight - pdf.doc.getLineHeight();
        pdf.doc.setFillColor(item.color);
        pdf.doc.roundedRect(x, y, 162.000, h, radius, radius, 'F');
        

        const width = 42.952;
        pdf.doc.addImage(`assets/pdf/process-steps-${item.icon}.png`, 'png', centeredX - width / 2, y + 12.666, width, 41.077);

        pdf.text(item.label, centeredX, y + 55.682, 'Raleway-ExtraBold', 14, 0, {
            baseline: 'top',
            align: 'center'
        });
        
        let _y = y + 76.937;
        item.items.forEach(d => _y = pdf.text(d, centeredX, _y, 'Raleway-MediumItalic', 9, 300,{
            
            align: 'center'
        }));
        

        return y + h + 5  + (topLegend ? this._labelHeight - 11 : 0);
    }
}