import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {ClaireBannerComponent} from "../claire-banner/claire-banner.component";
import {Offering} from "../../../../model/offering";
import {FormsModule} from "@angular/forms";
import {InfoPanelComponent} from "../../../../components/info-panel/info-panel.component";
import {OfferingCategory, OfferingType} from "../../../../model";
import {
    SelectOfferingFormComponent
} from "../../../report-builder/screens/select-offering/components/select-offering-form/select-offering-form.component";
import {
    PureAddOfferingComponent,
    RemoveJobArgs
} from "../../../profile/components/pure-add-offering/pure-add-offering.component";

@Component({
    selector: 'app-select-offering',
    standalone: true,
    imports: [
        ClaireBannerComponent,
        FormsModule,
        InfoPanelComponent,
        PureAddOfferingComponent,
        SelectOfferingFormComponent
    ],
    templateUrl: './select-offering.component.html',
    styleUrl: './select-offering.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectOfferingComponent {
    @Input() offerings: Offering[] = [];
    
    @Output() onSelect = new EventEmitter<Offering>();
    @Output() onAdd = new EventEmitter<Offering>();
    @Output() onRemoveJob = new EventEmitter<RemoveJobArgs>();
    @Output() onSelectOfferingType = new EventEmitter<OfferingType>();
    @Output() onSelectReport = new EventEmitter();
    
    @HostBinding('class.sp-form') f = true;
    selectedIndex = -1;
    addNew = false;
    
    type: OfferingType | undefined;
    category: OfferingCategory | undefined;
    @Input() categories: OfferingCategory[] = [];

    saveOffering(offering: Offering) {
        this.onAdd.emit(offering);
        this.cancelDialog();
    }
    
    cancelDialog() {
        this.addNew = false;
        this.type = undefined;
        this.category = undefined;
    }
}
