<app-main-header/>

<app-page-header [title]="'Terms'" [text]="'SaaS Subscription Terms of Service for SalesPrepTM software'"/>

<div class="sp-wrapped content">    
<!--    <small>Revised May 21, 2024</small>-->

    <h1 class="first">Overview</h1>
    <p>SalesPrep software-as-a-service (the “Service”) is provided as part of a broader training program for understanding B2B customer needs and increasing sales to B2B customers. This training includes self-paced e-learning, an AI practice guide, downloadable resources, and in some cases may also include a virtual or in-person workshop (for corporate clients). For more, please visit <a href="http://www.salesprep.com">www.salesprep.com</a> and <a href="http://www.everydayvoctraining.com">www.everydayvoctraining.com</a>.</p>

    <p>SalesPrep software is used by sales professionals and others to prepare for meetings with existing and prospective customers. The Service delivers AI-based reports, each including information about the target company, key market trends, common problems, and typical process steps (for the application in question).</p>

    <p>The sales professional enters very little information into the Service... just a) the name of the company they plan to meet with, b) their commercial product, and c) the typical customer application for their product. In other words, the data entered is generally non-confidential and the same as that entered in a typical Google search. Any information learned during the customer meeting is <em>not</em> captured by the Service. Rather, most sales professionals record such information in their CRM (customer relationship management) software, e.g. Salesforce.com.</p>

    <p>In some cases, the AIM Institute reaches an agreement with a client company (the “Client”)—not individuals—and makes its training and software Service available to each employee (the “User”) designated by the Client. In other cases, an individual (“User”) can subscribe directly to SalesPrep directly at <a href="http://www.salesprep.com">www.salesprep.com</a>.</p>

    <h1>Subscriptions</h1>
    <p>The software Service is only available with a paid subscription. Subscription to this Service also includes access to self-paced e-learning modules, an AI Guide (to practice asking good questions with customers), and downloadable PDF resources. The service is available on a month-to-month basis or annual basis.</p>

    <p>When a corporate Client engages The AIM Institute in an Everyday VOC workshop (see <a href="http://www.everydayvoctraining.com">www.everydayvoctraining.com</a>), the learners are typically granted a one-year subscription to the Service at no extra cost. At the Client’s discretion, these subscriptions may be renewed after the first year at the existing published subscription price.</p>

    <p>The AIM Institute will provide notice to the Client at least 30 days prior to the renewal date, so the Client can determine which User subscriptions it wishes to renew. At the Client’s discretion, The AIM Institute can pro-rate User subscriptions and bundle renewals for less frequent invoicing.</p>

    <h1>Information Security</h1>
    <p>The AIM Institute takes very seriously the trust Clients place in it to protect their confidential information. Since most of AIM’s work is with Fortune 500-level companies, it maintains a very high standard in protecting Client data, which is encrypted in-transit and at-rest in the Microsoft Azure cloud environment. To better understand the safeguards, the following documents are available for download:</p>
    <ul>
        <li><a href="https://www.blueprintingcenter.com/public_downloads/ad38da01-5714-4c1f-9511-92a6ff13bb4d">AIM Information Security Policy [PDF]</a></li>
        <li><a href="https://www.blueprintingcenter.com/public_downloads/c8388b4c-f71d-41b7-a7c3-c57955ac67b1">AIM Security Overview [PDF]</a></li>
        <li><a routerLink="/privacy">AIM Privacy Policy</a></li>
    </ul>
    <p>An independent auditor conducts an annual SOC2 audit of AIM’s security measures. You can request a copy of the most recent auditor report by emailing AIM at <a href="mailto:info@theaiminstitute.com">info{{ '@' }}theaiminstitute.com</a>.</p>

    <h1>Service Levels</h1>
    <blockquote class="alternative"><b>Availability:</b> The AIM Institute agrees that the Service will be available at least 99.7% of the time, measured on a 24x7 basis, exclusive of scheduled maintenance time. Scheduled maintenance time will not exceed two hours per month.</blockquote>
    <blockquote class="alternative"><b>Notifications:</b> The AIM Institute will provide at least three business days’ advance notice of scheduled maintenance time, including the anticipated start and stop times of the outage.</blockquote>
    <blockquote class="alternative"><b>Support and Error Resolution:</b> The AIM Institute accepts support requests through the Contact Support form and via email to xxx. These accounts are monitored between 9am and 5pm Eastern time. AIM defines the following severity levels and response actions:
        <ul>
            <li><strong>SEVERITY 1</strong> – CRITICAL BUSINESS IMPACT, defined as the Service is entirely unavailable to all Client Users. AIM will begin working on the error immediately upon notification and will engage appropriate staff around the clock until the error is resolved. AIM will provide Client with regular updates on resolution status. Note that timely resolution may require active participation by Customer personnel, and that lack of availability of Customer personnel may extend time to resolution.</li>
            <li><strong>SEVERITY 2</strong> – MAJOR BUSINESS IMPACT, defined as the Service is unavailable to a significant number of Client Users, or functionality is seriously degraded for all or most Client Users. AIM will begin working on the error within one hour of notification and will engage appropriate staff during normal business hours on a sustained basis until the issue is resolved. AIM will provide Client with regular updates on resolution status. Note that timely resolution may require active participation by Customer personnel, and that lack of availability of Customer personnel may extend time to resolution.</li>
            <li><strong>SEVERITY 3</strong> – SOME BUSINESS IMPACT, defined as moderate loss of functionality where a convenient workaround exists. AIM will begin working on the error upon notification and will engage appropriate staff during normal business hours until the error is resolved. AIM will provide Client with regular updates on resolution status. Note that timely resolution may require active participation by Customer personnel, and that lack of availability of Customer personnel may extend time to resolution.</li>
            <li><strong>SEVERITY 4</strong> – MINIMAL BUSINESS IMPACT, defined as minor loss of functionality, feature requests, and “how-to” questions. AIM will respond to the request within two business days.</li>
        </ul>
    </blockquote>

    <h1>Intellectual Property</h1>
    <p>The Service and its original content (excluding any content provided by Users), features and functionality are the exclusive property of The AIM Institute. It is acknowledged that any content provided by Users is the exclusive property of the Client, and is treated with utmost confidentiality by The AIM Institute.</p>
    <p>The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. The AIM Institute’s trademarks and trade dress may not be used in connection with any product or service without the prior written consent of The AIM Institute.</p>

    <h1>Use of AI Services</h1>
    <p>The Service includes integration with artificial intelligence services powered by OpenAI's ChatGPT ("AI Services"). The AI Services are provided to improve the quality and relevance of company news and meeting preparation insights. You may use the AI Services solely for lawful purposes and in accordance with these Terms of Service. The use of AI Services may involve the processing of your data. By using the Service, you consent to such processing. We are committed to protecting your privacy and handling your data in compliance with our <a routerLink="/privacy">Privacy Policy</a> and applicable data protection laws. No personally identifiable information is shared with the AI Services.</p>

    <h1>Disclaimer</h1>
    <p>The Service is provided to the Client "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. The AIM Institute disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice.</p>
    <p>The entire liability of The AIM Institute shall be limited to the amount actually paid by the Client through the Service. The AIM Institute shall not be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms of Service).</p>

</div>

<app-footer-smart/>