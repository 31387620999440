<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="179.036" height="176.59" viewBox="0 0 179.036 176.59">
    <defs>
        <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#5cc0ff"/>
            <stop offset="1" stop-color="#006db2"/>
        </linearGradient>
        <filter id="Polygon_82" x="0" y="0" width="179.036" height="176.59" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
        </filter>
        <linearGradient id="linear-gradient-2" x1="0.5" y1="-0.742" x2="0.5" y2="5.658" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#009cdc"/>
            <stop offset="0.24" stop-color="#0082c9"/>
            <stop offset="1" stop-color="#002f63"/>
        </linearGradient>
        <linearGradient id="linear-gradient-3" y1="-1.169" y2="5.216" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-4" y1="-2.437" y2="3.902" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-5" y1="-1.594" y2="4.776" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-6" y1="0.364" y2="0.999" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-7" y1="-2.016" y2="4.338" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-8" y1="0.164" y2="6.237" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-9" y1="0.621" y2="6.693" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-10" y1="1.078" y2="7.15" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-11" y1="-0.292" y2="5.78" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-12" y1="-0.749" y2="5.323" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-13" y1="-0.245" y2="0.989" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-14" y1="1.184" y2="3.278" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-15" y1="2.028" y2="5.613" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-16" y1="0.359" y2="0.994" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-17" y1="1.597" y2="4.421" xlink:href="#linear-gradient-2"/>
        <linearGradient id="linear-gradient-18" y1="1.317" y2="3.646" xlink:href="#linear-gradient-2"/>
    </defs>
    <g id="Certification_Badge" data-name="Certification Badge" transform="translate(698.679 -3119.919)">
        <path id="Path_349" data-name="Path 349" d="M0,0H54.621V21.583H0L10.325,11.063Z" transform="translate(-692 3238.4)" fill="#dd7114"/>
        <path id="Path_345" data-name="Path 345" d="M54.621,0H0V21.583H54.621L44.3,11.063Z" transform="translate(-582.621 3238.4)" fill="#dd7114"/>
        <g transform="matrix(1, 0, 0, 1, -698.68, 3119.92)" filter="url(#Polygon_82)">
            <path id="Polygon_82-2" data-name="Polygon 82" d="M89.3,1.77a10,10,0,0,1,9.486,1.673l4.576,3.678a10,10,0,0,0,6.054,2.2l5.869.124a10,10,0,0,1,8.342,4.816l3.042,5.021a10,10,0,0,0,4.935,4.141l5.473,2.124a10,10,0,0,1,6.192,7.379l1.141,5.759a10,10,0,0,0,3.221,5.58l4.417,3.867a10,10,0,0,1,3.295,9.052l-.9,5.8a10,10,0,0,0,1.119,6.345l2.827,5.145a10,10,0,0,1,0,9.633l-2.827,5.145a10,10,0,0,0-1.119,6.345l.9,5.8a10,10,0,0,1-3.295,9.052l-4.417,3.867a10,10,0,0,0-3.221,5.58l-1.141,5.759a10,10,0,0,1-6.192,7.379l-5.473,2.124a10,10,0,0,0-4.935,4.141l-3.042,5.021a10,10,0,0,1-8.342,4.816l-5.869.124a10,10,0,0,0-6.054,2.2l-4.576,3.678a10,10,0,0,1-9.486,1.673l-5.558-1.891a10,10,0,0,0-6.443,0l-5.558,1.891a10,10,0,0,1-9.486-1.673l-4.576-3.678a10,10,0,0,0-6.054-2.2l-5.869-.124a10,10,0,0,1-8.342-4.816L34.37,139.3a10,10,0,0,0-4.935-4.141l-5.473-2.124a10,10,0,0,1-6.192-7.379L16.628,119.9a10,10,0,0,0-3.221-5.58L8.99,110.454A10,10,0,0,1,5.7,101.4l.9-5.8a10,10,0,0,0-1.119-6.345L2.647,84.111a10,10,0,0,1,0-9.633l2.827-5.145a10,10,0,0,0,1.119-6.345l-.9-5.8A10,10,0,0,1,8.99,48.136l4.417-3.867a10,10,0,0,0,3.221-5.58l1.141-5.759a10,10,0,0,1,6.192-7.379l5.473-2.124a10,10,0,0,0,4.935-4.141l3.042-5.021a10,10,0,0,1,8.342-4.816l5.869-.124a10,10,0,0,0,6.054-2.2l4.576-3.678A10,10,0,0,1,71.739,1.77L77.3,3.662a10,10,0,0,0,6.443,0Z" transform="translate(9 6)" fill="url(#linear-gradient)"/>
        </g>
        <ellipse id="Ellipse_291" data-name="Ellipse 291" cx="69.251" cy="68.722" rx="69.251" ry="68.722" transform="translate(-678.076 3136.491)" fill="#fff"/>
        <text id="Practitioner" transform="translate(-609.528 3240.675)" fill="#2371ad" font-size="15" font-family="MuseoSlab-700, Museo Slab" font-weight="700"><tspan x="-45" y="0">Practitioner</tspan></text>
        <path id="Path_346" data-name="Path 346" d="M674.184,277.21l2.6,5.26,5.8.843-4.2,4.094.991,5.781-5.192-2.729-5.191,2.729.991-5.781-4.2-4.094,5.8-.843Z" transform="translate(-1282.973 2932.515)" fill="#ff851b"/>
        <rect id="Rectangle_767" data-name="Rectangle 767" width="128.574" height="21.145" transform="translate(-673.434 3246.447)" fill="#ff851b"/>
        <text id="Certified" transform="translate(-610.453 3260.129)" fill="#fff" font-size="12" font-family="MuseoSans-900, Museo Sans" font-weight="900" letter-spacing="0.17em"><tspan x="-39" y="0">CERTIFIED</tspan></text>
        <line id="Line_135" data-name="Line 135" x2="98.418" transform="translate(-658.349 3204.63)" fill="none" stroke="#faa21a" stroke-width="1"/>
        <line id="Line_136" data-name="Line 136" x2="98.418" transform="translate(-658.349 3176.561)" fill="none" stroke="#faa21a" stroke-width="1"/>
        <g id="Group_886" data-name="Group 886" transform="translate(-625.472 3144.944)">
            <g id="Group_654" data-name="Group 654" transform="translate(0 0)">
                <g id="Group_650" data-name="Group 650" transform="translate(0.389 20.031)">
                    <path id="Path_261" data-name="Path 261" d="M6.772,200.3h.673v-4.215H6.772V195.5H8.891v.589H8.21V200.3h.681v.6H6.772Z" transform="translate(-6.772 -195.497)" fill="#fb8e03"/>
                    <path id="Path_262" data-name="Path 262" d="M33.958,212.756h.6V210.2a.15.15,0,0,0-.168-.168H33.92v-.574h.872c.337,0,.49.145.49.428v.2a1.1,1.1,0,0,1-.023.222h.015a1.628,1.628,0,0,1,1.507-.941c.887,0,1.285.5,1.285,1.484v1.9h.612v.574H37.324v-2.31c0-.543-.107-.972-.727-.972a1.383,1.383,0,0,0-1.293,1.484v1.224h.612v.574H33.958Z" transform="translate(-31.105 -207.929)" fill="#fb8e03"/>
                    <path id="Path_263" data-name="Path 263" d="M84.53,212.083h.65v.252c0,.329.459.482.887.482s.78-.176.78-.5c0-.39-.428-.513-.964-.7-.574-.214-1.209-.474-1.209-1.178,0-.765.757-1.071,1.431-1.071.543,0,1.339.214,1.339.78v.467h-.65v-.253c0-.237-.306-.4-.681-.4s-.688.153-.688.467c0,.367.428.52.9.681.6.2,1.277.451,1.277,1.17,0,.734-.673,1.14-1.553,1.14-.7,0-1.515-.314-1.515-.926Z" transform="translate(-76.467 -207.929)" fill="#fb8e03"/>
                    <path id="Path_264" data-name="Path 264" d="M119.042,201.634h-.65v-.574h.665V200h.727v1.063h.926v.574h-.926v1.813c0,.788.535.887.826.887.107,0,.176-.008.176-.008v.62a2.083,2.083,0,0,1-.268.015,1.332,1.332,0,0,1-1.476-1.446Z" transform="translate(-106.818 -199.53)" fill="#fb8e03"/>
                    <path id="Path_265" data-name="Path 265" d="M146.832,200.324h.6V197.6h-.643v-.574h1.385v3.3h.612v.574h-1.958Zm.589-4.827h.78v.757h-.78Z" transform="translate(-132.275 -195.497)" fill="#fb8e03"/>
                    <path id="Path_266" data-name="Path 266" d="M170.02,201.634h-.65v-.574h.666V200h.727v1.063h.926v.574h-.926v1.813c0,.788.535.887.826.887.107,0,.176-.008.176-.008v.62a2.086,2.086,0,0,1-.268.015,1.332,1.332,0,0,1-1.476-1.446Z" transform="translate(-152.51 -199.53)" fill="#fb8e03"/>
                    <path id="Path_267" data-name="Path 267" d="M196.725,210.826h-.65v-.574h1.392v2.326c0,.535.107.964.727.964a1.385,1.385,0,0,0,1.255-1.507v-1.209h-.65v-.574h1.392v3.136a.153.153,0,0,0,.168.168h.405v.566h-.8c-.337,0-.49-.153-.49-.428v-.168a1.684,1.684,0,0,1,.015-.229h-.015a1.583,1.583,0,0,1-1.476.918c-.834,0-1.27-.459-1.27-1.484Z" transform="translate(-176.446 -208.722)" fill="#fb8e03"/>
                    <path id="Path_268" data-name="Path 268" d="M245.269,201.634h-.65v-.574h.665V200h.727v1.063h.926v.574h-.926v1.813c0,.788.536.887.826.887.107,0,.176-.008.176-.008v.62a2.085,2.085,0,0,1-.268.015,1.332,1.332,0,0,1-1.477-1.446Z" transform="translate(-219.956 -199.53)" fill="#fb8e03"/>
                    <path id="Path_269" data-name="Path 269" d="M274.809,209.367a1.643,1.643,0,0,1,1.675,1.813c0,.1-.023.283-.023.283h-2.83a1.3,1.3,0,0,0,1.339,1.316,1.884,1.884,0,0,0,1.155-.436l.306.543a2.372,2.372,0,0,1-1.507.536,1.971,1.971,0,0,1-2.05-2.027A1.9,1.9,0,0,1,274.809,209.367Zm.91,1.622a.944.944,0,0,0-.926-1.033,1.137,1.137,0,0,0-1.132,1.033Z" transform="translate(-245.28 -207.929)" fill="#fb8e03"/>
                </g>
                <g id="Group_652" data-name="Group 652" transform="translate(0 0)">
                    <g id="Group_651" data-name="Group 651">
                        <path id="Path_270" data-name="Path 270" d="M3.389,3.722h.2V2.5h-.37v.245H3.023V2.321H4.375v.428H4.181V2.5h-.37V3.722h.2V3.9h-.62Z" transform="translate(-3.023 -2.321)" fill="#fb8e03"/>
                        <path id="Path_271" data-name="Path 271" d="M17.276,3.729h.178V2.488h-.19V2.321h.4v.585A.627.627,0,0,1,17.662,3h0a.467.467,0,0,1,.422-.259c.265,0,.388.145.388.433v.555h.176V3.9H18.26V3.222c0-.158-.033-.283-.214-.283a.394.394,0,0,0-.377.428v.361h.178V3.9h-.571Z" transform="translate(-15.788 -2.321)" fill="#fb8e03"/>
                        <path id="Path_272" data-name="Path 272" d="M32.63,6.366a.479.479,0,0,1,.489.529c0,.029-.007.083-.007.083h-.825a.38.38,0,0,0,.391.384.549.549,0,0,0,.337-.127l.089.158a.692.692,0,0,1-.44.156.575.575,0,0,1-.6-.591A.553.553,0,0,1,32.63,6.366Zm.266.473a.275.275,0,0,0-.27-.3.332.332,0,0,0-.33.3Z" transform="translate(-29.054 -5.947)" fill="#fb8e03"/>
                    </g>
                </g>
                <g id="Group_653" data-name="Group 653" transform="translate(30.477 19.315)">
                    <path id="Path_273" data-name="Path 273" d="M297.8,188.6a.853.853,0,1,1-.86.849A.849.849,0,0,1,297.8,188.6Zm0,1.581a.728.728,0,1,0-.7-.732A.7.7,0,0,0,297.8,190.178Zm-.333-1.227h.386c.237,0,.349.094.349.285a.257.257,0,0,1-.262.272l.285.44h-.167l-.271-.429h-.164v.429h-.155Zm.155.443h.162c.137,0,.262-.007.262-.164,0-.132-.114-.153-.219-.153h-.205Z" transform="translate(-296.938 -188.597)" fill="#009cdc"/>
                </g>
            </g>
            <g id="Group_659" data-name="Group 659" transform="translate(0.266 0.427)">
                <g id="Group_658" data-name="Group 658" transform="translate(0 0)">
                    <g id="Group_655" data-name="Group 655">
                        <path id="Path_274" data-name="Path 274" d="M73.014,84.047,70.05,85.479l.093.394,2.968-1.433Z" transform="translate(-63.366 -76)" fill="url(#linear-gradient-2)"/>
                        <path id="Path_275" data-name="Path 275" d="M74.824,91.613l-2.973,1.436.093.394L74.922,92Z" transform="translate(-64.98 -82.781)" fill="url(#linear-gradient-3)"/>
                        <path id="Path_276" data-name="Path 276" d="M80.254,114.307l-3,1.449.093.394,3-1.451Z" transform="translate(-69.823 -103.123)" fill="url(#linear-gradient-4)"/>
                        <path id="Path_277" data-name="Path 277" d="M76.634,99.177l-2.982,1.44.093.394,2.987-1.442Z" transform="translate(-66.594 -89.562)" fill="url(#linear-gradient-5)"/>
                        <path id="Path_278" data-name="Path 278" d="M16.218,18.412l-6.4,3.078,1.885-7.974.476,2.011L15.141,14.1,13.229,6.443H10.186l-4.595,18.4H9.027l.683-2.892H13.7l.685,2.892h3.437Z" transform="translate(-5.591 -6.443)" fill="url(#linear-gradient-6)"/>
                        <path id="Path_279" data-name="Path 279" d="M78.444,106.743l-2.991,1.444.093.394,3-1.447Z" transform="translate(-68.209 -96.342)" fill="url(#linear-gradient-7)"/>
                    </g>
                    <g id="Group_656" data-name="Group 656" transform="translate(13.668 0.004)">
                        <path id="Path_280" data-name="Path 280" d="M140.481,67.614v-.439l-3.075,1.485v.44Z" transform="translate(-137.406 -60.881)" fill="url(#linear-gradient-8)"/>
                        <path id="Path_281" data-name="Path 281" d="M140.481,59.137V58.7l-3.075,1.485v.44Z" transform="translate(-137.406 -53.283)" fill="url(#linear-gradient-9)"/>
                        <path id="Path_282" data-name="Path 282" d="M137.406,51.7v.44l3.075-1.485v-.44Z" transform="translate(-137.406 -45.685)" fill="url(#linear-gradient-10)"/>
                        <path id="Path_283" data-name="Path 283" d="M140.481,76.091v-.439l-3.075,1.485v.439Z" transform="translate(-137.406 -68.479)" fill="url(#linear-gradient-11)"/>
                        <path id="Path_284" data-name="Path 284" d="M140.481,84.568v-.439l-3.075,1.485v.44Z" transform="translate(-137.406 -76.077)" fill="url(#linear-gradient-12)"/>
                        <path id="Path_285" data-name="Path 285" d="M137.406,94.09v7.983h3.075V92.605Z" transform="translate(-137.406 -83.675)" fill="url(#linear-gradient-13)"/>
                        <path id="Path_286" data-name="Path 286" d="M140.481,10.58v-4.1h-3.075v5.58Z" transform="translate(-137.406 -6.485)" fill="url(#linear-gradient-14)"/>
                    </g>
                    <g id="Group_657" data-name="Group 657" transform="translate(18.472 0.004)">
                        <path id="Path_287" data-name="Path 287" d="M186.982,8.175l3.5-1.69h-.91L186.8,7.825l-.185-.35,2.051-.991h-.91l-1.327.641-.185-.35.6-.291h-3.109V9.744Z" transform="translate(-183.733 -6.485)" fill="url(#linear-gradient-15)"/>
                        <path id="Path_288" data-name="Path 288" d="M194.4,6.485l-.224.416-10.439,5.041v.44l10.12-4.887-.319.594-9.8,4.732v.44l9.481-4.578-.319.594L183.733,13.7v.44l8.842-4.27-.319.594-8.523,4.116v10.3h3.075V13.708l3.4,6.406,3.413-6.352V24.883H196.7V6.485Zm-4.056,7.541-.206.1-.185-.35.711-.343Zm.639-1.187-1.216.587-.185-.35,1.72-.831Zm.639-1.187-2.225,1.074-.185-.35,2.73-1.318Z" transform="translate(-183.733 -6.485)" fill="url(#linear-gradient-16)"/>
                        <path id="Path_289" data-name="Path 289" d="M183.733,10.184v.439l8.57-4.138h-.91Z" transform="translate(-183.733 -6.485)" fill="url(#linear-gradient-17)"/>
                        <path id="Path_290" data-name="Path 290" d="M183.733,11.063V11.5l10.39-5.017h-.91Z" transform="translate(-183.733 -6.485)" fill="url(#linear-gradient-18)"/>
                    </g>
                </g>
            </g>
        </g>
        <g id="SalesPrep_Logo" data-name="SalesPrep Logo" transform="translate(-654.931 3179.027)">
            <g id="Group_1" data-name="Group 1" transform="translate(-31.718)">
                <text id="TM" transform="translate(119.718 5.868)" fill="#2371ad" font-size="3" font-family="Raleway-Medium, Raleway" font-weight="500"><tspan x="0" y="0">TM</tspan></text>
                <text id="SalesPrep" transform="translate(31.718 18)" fill="#2371ad" font-size="19" font-family="Raleway-Medium, Raleway" font-weight="500"><tspan x="0" y="0">Sales</tspan><tspan y="0" font-family="Raleway-MediumItalic, Raleway" font-style="italic">Prep</tspan></text>
            </g>
        </g>
    </g>
</svg>