import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {
    PureSubscriptionExpiredComponent
} from "./components/pure-subscription-expired/pure-subscription-expired.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {RouterLink} from "@angular/router";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";

@Component({
    selector: 'app-subscription-expired',
    standalone: true,
    imports: [
        PureSubscriptionExpiredComponent,
        MainTitleComponent,
        MainPanelComponent,
        SpinnerComponent,
        RouterLink,
        MainHeaderComponent,
        FooterCompactComponent,
        FooterSmartComponent
    ],
    templateUrl: './subscription-expired.component.html',
    styleUrl: './subscription-expired.component.scss'
})
export class SubscriptionExpiredComponent implements OnInit {
    userProfile = this.authService.userProfile;
    
    constructor(private authService: AuthService) {
        
    }

    ngOnInit(): void {
        const p = this.authService.userProfile();
        if (!p) {
            throw Error('User profile not set');
        }
    }

}
