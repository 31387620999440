<button (click)="onNext.emit()" [disabled]="loading" qa="next">
    @if (loading) {
        <span class="material-symbols-outlined sp-spin" qa="next-loading">progress_activity</span> Loading...        
    } @else {
        
        @if (nextSteps) {
            Role-Play Again
        } @else {
            Next @if (completed) { Steps }
        }
    }
</button>
