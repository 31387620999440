import {computed, Injectable, signal} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StateService {

    updateAvailable = signal(false);
    cacheFailure = signal(false);
    activeRequests = signal<number>(0);
    busy = signal(false);
    
    constructor() {
    }
}
