<div class="levels">
    <button class="level-0" [class.selected]="coachingLevel == TutorCoachingLevels.Beginner" (click)="onSelect.emit(TutorCoachingLevels.Beginner)" qa="nov">Novice</button>
    <button class="level-1" [class.selected]="coachingLevel == TutorCoachingLevels.Intermediate" (click)="onSelect.emit(TutorCoachingLevels.Intermediate)" qa="int">Intermediate</button>
    <button class="level-2" [class.selected]="coachingLevel == TutorCoachingLevels.Expert" (click)="onSelect.emit(TutorCoachingLevels.Expert)" qa="exp">Expert</button>
</div>

<div class="notes" qa="notes">
    @switch (coachingLevel) {
        @case (TutorCoachingLevels.Beginner) {
            <span class="novice" qa="desc">
                You are an observer. AI will play both your part @if (userInitials) { ({{ userInitials }}) } and the customer (C). Claire will be the coach and will offer advice throughout the interview.
            </span>
        }
        @case (TutorCoachingLevels.Intermediate) {
            <span class="intermediate" qa="desc">
                You @if (userInitials) { ({{ userInitials }}) } are the interviewer. AI (C) is your customer. Claire will be your coach and offer advice throughout your interview.
            </span>
        }
        @case (TutorCoachingLevels.Expert) {
            <span class="expert" qa="desc">
                You @if (userInitials) { ({{ userInitials }}) } are the interviewer. AI (C) is your customer. Claire will be your coach and offer advice at the end of the interview.
            </span>
        }
    }
</div>