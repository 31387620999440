import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UserProfile} from "../../../../model";
import {DatePipe} from "@angular/common";
import {InitialsPipe} from "../../../../pipes/initials.pipe";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-pure-subscription-expired',
    standalone: true,
    imports: [
        DatePipe,
        InitialsPipe,
        RouterLink
    ],
    templateUrl: './pure-subscription-expired.component.html',
    styleUrl: './pure-subscription-expired.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureSubscriptionExpiredComponent {
    @Input() userProfile!: UserProfile;

}
