import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UserProfile} from "../../../../model";
import {DatePipe} from "@angular/common";
import {InitialsPipe} from "../../../../pipes/initials.pipe";
import {RouterLink} from "@angular/router";
import {CardComponent} from "../../../splash/components/card/card.component";
import {NoteComponent} from "../../../../components/note/note.component";

@Component({
    selector: 'app-profile-info',
    standalone: true,
    imports: [
        DatePipe,
        InitialsPipe,
        RouterLink,
        CardComponent,
        NoteComponent
    ],
    templateUrl: './profile-info.component.html',
    styleUrl: './profile-info.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileInfoComponent {
    @Input() userProfile!: UserProfile;
    @Input() offeringsCount = 0;
    @Output() onLogout = new EventEmitter();
}
