import {Component, signal} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {MessageService} from "../../services/message.service";
import {Router} from "@angular/router";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {FooterComponent} from "../../components/footer/footer.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {PureForgotPasswordComponent} from "./components/pure-forgot-password/pure-forgot-password.component";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";

@Component({
    selector: 'app-forgot-password',
    standalone: true,
    imports: [
        MainPanelComponent,
        MainHeaderComponent,
        FooterComponent,
        SpinnerComponent,
        PureForgotPasswordComponent,
        FooterCompactComponent
    ],
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
    sent = signal(false);
    errorMessage = signal('');

    constructor(public authService: AuthService, private messageService: MessageService, private router: Router) {
    }

    send(email: string) {
        this.authService.sendPasswordRecovery(email).subscribe({
            next: () => {
                this.sent.set(true);                
            },
            error: error => this.errorMessage.set('Internal error. Please contact support or try again later')
        })
        
    }
}
