import {Component, Input, signal} from '@angular/core';
import {ProfileSetupComponent} from "./components/profile-setup/profile-setup.component";
import {AuthService} from "../../services/auth.service";
import {UserProfile} from "../../model/userProfile";
import {SignupService} from "../../services/signup.service";
import {Router, RouterLink} from "@angular/router";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {PureSignupComponent} from "../signup/components/pure-signup/pure-signup.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";

@Component({
    selector: 'app-profile-edit',
    standalone: true,
    imports: [
        ProfileSetupComponent,
        MainHeaderComponent,
        MainPanelComponent,
        PureSignupComponent,
        SpinnerComponent,
        MainTitleComponent,
        FooterCompactComponent,
        RouterLink
    ],
    templateUrl: './profile-edit.component.html',
    styleUrl: './profile-edit.component.scss'
})
export class ProfileEditComponent {
    @Input() mode = '';
    
    errorMessage = signal('');
    profileSetupMode = () => this.mode == 'setup';
    userProfile = this.authService.userProfile;
    loadingProfile = this.authService.loadingProfile;
    
    constructor(private authService: AuthService, private signupService: SignupService, private router: Router) {
    }

    update(profile: UserProfile) {
        this.signupService.updateProfile(profile).subscribe({
            next: () => {
                this.authService.reloadProfile();
                if (this.profileSetupMode()) {
                    this.router.navigate(['/profile/setup-offerings']);
                } else {
                    this.router.navigate(['/profile']);
                }
            },
            error: () => {
                this.errorMessage.set('Unable to update profile');
            }
        })
    }
}
