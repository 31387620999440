import {Component, Input, signal} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {PureLoginComponent} from "../login/components/pure-login/pure-login.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {PureResetPasswordComponent} from "./components/pure-reset-password/pure-reset-password.component";

@Component({
    selector: 'app-reset-password',
    standalone: true,
    imports: [
        FooterCompactComponent,
        MainHeaderComponent,
        MainPanelComponent,
        PureLoginComponent,
        SpinnerComponent,
        PureResetPasswordComponent
    ],
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {

    @Input() email = '';
    @Input() code = '';

    errorMessage = signal('');

    constructor(private router: Router, private authService: AuthService) {
    }
    
    resetPassword(password: string) {
        this.errorMessage.set('');
        
        this.authService.resetPassword(this.email, this.code, password).subscribe({
            next: () => this.router.navigate(['/']),
            error: err => {
                console.error(err);
                this.errorMessage.set(err.error || 'Unable to change password.')
            }
        });
    }

}
