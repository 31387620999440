import {Pipe, PipeTransform} from '@angular/core';
import {Offering} from "../model/offering";
import {OfferingType} from "../model/offeringType";

@Pipe({
    name: 'offeringAction',
    standalone: true
})
export class OfferingActionPipe implements PipeTransform {

    transform(type: OfferingType | undefined, short: boolean = false, ...args: unknown[]): unknown {
        if (!type) return '';
        
        if (type == OfferingType.MaterialOrIngredientOrComponent) {
            return short ? 'to produce' : 'used to produce';
        }
        
        return short ? 'to' : 'used to';
    }
}
