import {Component, Input} from '@angular/core';
import { Company } from '../../../../model';
import {UrlPipe} from "../../../../pipes/url.pipe";

@Component({
    selector: 'app-company-links',
    standalone: true,
    imports: [
        UrlPipe
    ],
    templateUrl: './company-links.component.html',
    styleUrl: './company-links.component.scss'
})
export class CompanyLinksComponent {
    @Input() company!: Company;

}
