import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {TwelveWayStep} from "../twelve-way-slider/twelve-way-slider.component";
import {CheckmarkComponent} from "../checkmark/checkmark.component";
import {TwelveWaysServiceService} from "../../services/twelve-ways-service.service";

export interface TwelveWayGrid {
    title: string;
    description: string;
    note: string;
    report: number[];
    ai: number[];
}
@Component({
    selector: 'app-twelve-way-comparition-grid',
    standalone: true,
    imports: [
        CheckmarkComponent
    ],
    templateUrl: './twelve-way-comparition-grid.component.html',
    styleUrl: './twelve-way-comparition-grid.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwelveWayComparitionGridComponent {
    
    @Input() pricingMode = false;
    @HostBinding('class.sp-typography') t = true;
    
    reportTitle = this.service.reportTitle;
    rolePlayTitle = this.service.rolePlayTitle;    
    steps = this.service.steps;
    
    constructor(private service: TwelveWaysServiceService) {
    }
}
