<form #signupForm="ngForm" (ngSubmit)="submit(signupForm)">
    
        <div class="left">
            @if (setupMode) {
                <h1>Congratulations!</h1>
                <div>Your SalesPrep account is confirmed. Now, let’s set up your profile.</div>
            } @else {
                <h1>Profile Information</h1>
            }
        </div>

        <div class="sp-form right">
            @if (!setupMode) {
                <label for="firstName">First Name</label>
                <input appAutofocus type="text" id="firstName" [(ngModel)]="data.firstName" required name="firstName">

                <label for="lastName">Last Name</label>
                <input appAutofocus type="text" id="lastName" [(ngModel)]="data.lastName" required name="lastName">
            }

            <label for="companyName">Company</label>
            <input appAutofocus type="text" id="companyName" [(ngModel)]="data.company" required name="companyName">

            <label for="linkedInProfile">LinkedIn Profile (optional)</label>
            <input type="text" id="linkedInProfile" [(ngModel)]="data.linkedIn" name="linkedInProfile">

            <div class="password-hints">
                SalesPrep won’t use your LinkedIn profile now, but may eventually use it to find common interests between you and the people you intend to meet with in your meetings.
            </div>


            @if (errorMessage) {
                <app-error-message [text]="errorMessage"></app-error-message>
            }

            <button class="primary" type="submit" [disabled]="signupForm.invalid">
                @if (setupMode) {
                    Next
                } @else {
                    Update
                }
            </button>
        </div>
        

    

</form>