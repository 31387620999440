import {Component, OnDestroy} from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {RouterOutlet} from "@angular/router";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {ReportBuilderService} from "../../services/report-builder.service";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";

@Component({
    selector: 'app-report-builder-practice',
    standalone: true,
    imports: [
        MainHeaderComponent,
        RouterOutlet,
        PageHeaderComponent,
        FooterSmartComponent
    ],
    templateUrl: './report-builder-practice.component.html',
    styleUrl: './report-builder-practice.component.scss'
})
export class ReportBuilderPracticeComponent implements OnDestroy {
    
    constructor(private reportBuilderService: ReportBuilderService) {
    }

    ngOnDestroy() {
        console.log('[ReportBuilderComponent] ngOnDestroy');
        this.reportBuilderService.reset();
    }
}
