import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ReportSectionRating, Stakeholder} from "../../../../model";
import {RateSectionFooterComponent} from "../../../../components/rate-section-footer/rate-section-footer.component";
import {RateSectionComponent} from "../../../../components/rate-section/rate-section.component";
import {ReportGenerationIndicatorComponent} from "../report-generation-indicator/report-generation-indicator.component";

@Component({
    selector: 'app-stakeholders-list',
    standalone: true,
    imports: [
        RateSectionFooterComponent,
        RateSectionComponent,
        ReportGenerationIndicatorComponent
    ],
    templateUrl: './stakeholders-list.component.html',
    styleUrl: './stakeholders-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StakeholdersListComponent {
    @Input() stakeholders!: Stakeholder[];
    @Input() rating!: ReportSectionRating;
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();
    @Output() onGenerateReport = new EventEmitter<Stakeholder>();
}
