import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'url',
    standalone: true
})
export class UrlPipe implements PipeTransform {

    transform(url: string | null | undefined, ...args: unknown[]): string {
        if (!url) {
            return '';
        }
        url = url.replace('http://', '');
        url = url.replace('https://', '');
        if (url.startsWith('www.')) {
            url = url.substring(4);
        }
        return url;
    }

}
