@if (!inlineMode) {
    <app-top-progress-bar [step]="5" />
    <app-side-progress-bar [step]="5" [company]="company" [offering]="product" [job]="job" />
}

<div [class.inline]="inlineMode" class="left-column">

    @if (!inlineMode) {
        <app-main-panel class="mobile-header mobile-layout">
            <app-report-builder-header [company]="company" [offering]="product" [job]="job"/>
        </app-main-panel>
    }

    <app-main-panel class="sp-form secondary">
        @if (questions.length) {
            <h1 class="title">
                @if (inlineMode) {
                    Exploratory Questions:
                } @else {
                    5b. Select Exploratory Questions:
                    <em>
                        Choose 3-5 questions to include in your report. (These are <span class="i">possible</span> questions to ask. You know your customer best!)
                    </em>
                }
            </h1>
    
            <div class="questions sp-form-alternative">
                @for (q of questions; track q; let i = $index) {
                    @if (q.edit) {
                        <div class="flex">
                            <textarea appAutofocus rows="3" [(ngModel)]="q.text" qa="text"></textarea>
                            <a class="sp-link save" (click)="save(q)" qa="done">DONE</a>
                        </div>
                    } @else {
                        <div class="sp-checkbox question" [attr.qa]="'q-' + (i + 1)">
                            <input id="q{{ i }}" [checked]="q.selected" (change)="toggle(q)" type="checkbox" qa="inp">
                            <label for="q{{ i }}">
                                <span qa="lbl">{{ q.text }}</span>&nbsp;
                                <a class="sp-link save" (click)="edit($event, q)" qa="ed">EDIT</a>
                            </label>
                        </div>
                    }
                }
                
                <a class="add-new" (click)="add()">
                    <span class="material-symbols-outlined">add_box</span>
                    <span class="text" qa="add">Add question</span>
                </a>

                <div class="actions">                    
                    @if (practiceMode) {
                        <button class="primary" type="submit" (click)="onCreateReport.emit()" qa="create-report">
                            Create report
                        </button>
                    } @else {
                        <button (click)="onBack.emit()" class="back desktop-layout" qa="back">
                            Back
                        </button>
                        <button class="primary" type="submit" (click)="next()" qa="next">
                            {{ inlineMode ? 'Save' : 'Next' }}
                        </button>                    
                    }
                </div>
            </div>
        } @else {
            <h1 class="title">
                @if (!practiceMode) { 5. } Create Exploratory Questions
            </h1>
    
            @for (option of options; track option; let i = $index) {
                    
                <div class="item">
                    <div class="sp-selectable" [class.selected]="i == selectedIndex">
                        <input type="radio" id="option-{{i}}" [value]="i" [(ngModel)]="selectedIndex" qa="option">
                        <label for="option-{{i}}" qa="lbl">{{ option }}</label>
                    </div>
                </div>
            }
            
            @if (selectedIndex == 0) {
                <div class="benefits">
                    <h1 class="title">@if (!practiceMode) {5a. }Optional: Draw attention to my offering’s benefits in some questions. “My offering can help the customer...” <em>(begin with a verb)</em></h1>
                    
                    @for (tip of potentialBenefitsTips; track tip; let i = $index) {
                        <div class="benefit" [attr.qa]="'tip-' + (i + 1)">
                            <h1 class="title" qa="tit">{{ i + 1 }}.</h1> 
                            <input type="text" [(ngModel)]="potentialBenefits[i]" placeholder="{{ tip }}" qa="ben">
                        </div>
                    }
                </div>
            }
    
            <div class="actions">
                @if (practiceMode) {
                    @if (selectedIndex == 0) {
                        <button class="primary" type="submit" (click)="generate()" qa="generate-questions">
                            Generate Questions
                        </button>
                    } @else {
                        <button class="primary" type="submit" (click)="onCreateReport.emit()" qa="create-report">
                            Create report
                        </button>
                    }
                    
                } @else {
                    <button [routerLink]="['/report-builder', 'topics']" class="back desktop-layout" qa="back">
                        Back
                    </button>
                
                    <button class="primary" type="submit" (click)="generate()" [disabled]="selectedIndex == -1" qa="next">
                        Next
                    </button>
                }
            </div>
        }        
    </app-main-panel>
</div>