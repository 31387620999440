import {PdfGenerator} from "./pdf-generator";
import {PdfGeneratorBase} from "./pdf-generator-base";

export class TextSection {
    lineHeight = 1.2;
    _lines!: string[];
    _lineHeight = 0;
    
    constructor(private text: string, private maxWidth: number, private font: string, private size: number) {
    }
    
    getHeight(pdf: PdfGeneratorBase) {
        this._lines = pdf.splitTextToSize(this.text, this.maxWidth, this.font, this.size);
        this._lineHeight = pdf.doc.getLineHeight();
        return this._lines.length * this._lineHeight * this.lineHeight;
    }
    
    draw(pdf: PdfGeneratorBase, x: number, y: number) {
        if (!this._lines) {
            this.getHeight(pdf);
        }

        let doc = pdf.doc;
        doc.setFont(this.font);
        doc.setFontSize(this.size);

        this._lines.forEach(line => {
            pdf.text(line, x, y);
            y += this._lineHeight * this.lineHeight;
        });
        
        return y;
    }
}