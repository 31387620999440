import {Injectable} from '@angular/core';
import {MessageService} from "./message.service";
import {StateService} from "./state.service";
import {SwUpdate} from "@angular/service-worker";
import {interval} from "rxjs";

const CHECK_FOR_UPDATE_INVERVAL = 60 * 1000;

@Injectable({
    providedIn: 'root'
})
export class UpdateManagerService {

    constructor(private toastr: MessageService, private stateService: StateService, private updates: SwUpdate) {
    }

    init() {
        if (this.updates.isEnabled) {
            console.log('[UpdateManagerService] enabled');
            this.updates.versionUpdates.subscribe((e) => {
                if (e.type == 'VERSION_READY') {
                    this.stateService.updateAvailable.set(true);
                    console.log('[UpdateManagerService] current version is', e.currentVersion);
                    console.log('[UpdateManagerService] latest version is', e.latestVersion);
                } else if (e.type == 'VERSION_INSTALLATION_FAILED') {
                    this.toastr.error('Installation Failed: ' + e.error);
                }
            });
            interval(CHECK_FOR_UPDATE_INVERVAL).subscribe(() => this.checkForUpdate());
        } else {
            console.error('[UpdateManagerService] not enabled');
            this.stateService.cacheFailure.set(true);
        }
    }

    checkForUpdate() {
        return this.updates.checkForUpdate();
    }

    activateUpdate() {
        console.log('[UpdateManagerService] activateUpdate');
        this.updates.activateUpdate().then(() => document.location.reload());
    }
}
