import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'app-top-progress-bar',
    standalone: true,
    imports: [],
    templateUrl: './top-progress-bar.component.html',
    styleUrl: './top-progress-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopProgressBarComponent {
    @Input() step = 1;
    
    @HostBinding('class.half-step') @Input() halfStep = false;

}
