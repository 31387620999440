<form #loginForm="ngForm" (ngSubmit)="login(loginForm)">

    <div class="sp-form">

        <h1>Sign in to continue</h1>

        <label for="email">Email</label>
        <input autocomplete="login" appAutofocus type="email" id="email" [(ngModel)]="user.email" required
               name="email">

        <label for="password">Password</label>
        <div class="password-input">
            <input autocomplete="current-password" [type]="showPassword ? 'text' : 'password'" id="password" [(ngModel)]="user.password"
                   required name="password">    
            
            <a (click)="togglePasswordVisibility()">
                <i class="material-icons">@if(showPassword) { visibility_off } @else { visibility }</i>
            </a>
        </div>
        

        @if (errorMessage) {
            <app-error-message [text]="errorMessage"></app-error-message>                
        }

        <button class="primary" type="submit" [disabled]="loginForm.invalid">Sign in</button>
        <!--<div class="separator">
            <div class="line"></div>
            <div class="text">Or continue with:</div>
            <div class="line"></div>
        </div>-->
        
        <!--<button class="google" type="button" (click)="onSignInWithGoogle()">
            <div class="icon" [class.sp-spin]="signInWithGoogleActive"></div>
            <div class="text">Google</div>
        </button>-->
        
        <div class="links">
            <a class="sp-link" (click)="onForgotPassword.emit()">Forgot password?</a>
            <a class="sp-link" (click)="onCreateAccount.emit()">Create Account</a>
        </div>

        <!--                <div id="g_id_onload"-->
        <!--                     data-client_id="550144733543-k1teupas01tkkce851dg9fstbvouacjb.apps.googleusercontent.com"-->
        <!--                     data-context="signin"-->
        <!--                     data-ux_mode="popup"-->
        <!--                     data-login_uri="https://qa.sp.aim.vayner.systems/api/signin-google"-->
        <!--                     data-itp_support="true">-->
        <!--                </div>-->

        <!--                <div class="g_id_signin"-->
        <!--                     data-type="standard"-->
        <!--                     data-shape="rectangular"-->
        <!--                     data-theme="outline"-->
        <!--                     data-text="signin_with"-->
        <!--                     data-size="large"-->
        <!--                     data-logo_alignment="left">-->
        <!--                </div>-->

    </div>

</form>