import {Injectable, signal} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {
    BehaviorSubject,
    catchError, exhaustMap,
    filter, forkJoin,
    map, of,
    retry,
    Subject,
    switchMap, takeUntil,
    tap,
    throwError
} from "rxjs";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {Company, SalesPrepReport} from '../model';
import {
    SetMeetingDateArgs
} from "../screens/report-builder/screens/set-meeting-date/components/pure-set-meeting-date/pure-set-meeting-date.component";
import {RatingService} from "./rating.service";

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    selectedReportId = signal<string | undefined>(undefined);

    reportData = signal<SalesPrepReport | undefined>(undefined);
    reportsLoading = signal(true);
    reportDataLoading = signal(false);
    reportIsMissing = signal(false);

    companyData = signal<Company | undefined>(undefined);
    companyDataLoading = signal(false);

    private loadReports$ = new BehaviorSubject<void>(undefined);

    constructor(private http: HttpClient, private ratingsService: RatingService) {
    }

    reports$ = this.loadReports$.pipe(
        tap(() => console.log('[ReportService] Loading reports...')),
        exhaustMap(() => this.http.get<SalesPrepReport[]>('/api/reports/v1.8')),
        map(data => {
            // data.sort((a, b) => {
            //     if (a.mee)
            //     return a.lastUpdated < b.lastUpdated ? 1 : -1;
            // });
            data.forEach(x => this._initData(x));
            return data;
        }),
        retry(1),
        // shareReplay(1),
        // map(x => x.sort()),
        tap(() => this.reportsLoading.set(false)),
        tap(list => this.reports.set(list)),
    )

    _reports = toSignal<SalesPrepReport[], SalesPrepReport[]>(this.reports$, {initialValue: []});
    reports = signal<SalesPrepReport[]>([]);

    _cancelCompanyLoading$ = new Subject<void>();

    private reportData$ = toObservable(this.selectedReportId).pipe(
        tap(() => this._cancelCompanyLoading$.next()),
        filter(id => !!id),

        tap(() => this.reportDataLoading.set(true)),
        tap(() => this.companyDataLoading.set(true)),
        tap(() => this.reportIsMissing.set(false)),
        
        // 1. let's first display what's already available 
        tap(id => {
            const r = this.reports().find(r => r.id == id);
            if (r) {                
                this.reportData.set(r);
                this.companyData.set(r.company);
            } else {
                this.reportData.set(undefined);
                this.companyData.set(undefined);
            }
        }),
        
        // 2. let's load report from the server to display all the information
        switchMap(id => this.http.get<SalesPrepReport>('/api/reports/' + id).pipe(
            retry(1),
            tap(data => this.reportData.set(data)),
            tap(() => this.reportDataLoading.set(false)),
            tap(() => {
                const r = this.reports().find(r => r.id == id);
                if (r) r.lastViewed = new Date();
            }),
            tap(r => this.ratingsService.reportId.set(r.id)),
            
            // 3. let's load company information if needed
            switchMap(report => {
                const company = report.company;
                this.companyData.set(company);
                if (company.indexed) {
                    console.log('[ReportService] indexed company', company)
                    return of(company);
                }
                console.log('[ReportService] waiting for company to be indexed', company)
                return this.http.get<Company>(`/api/companies/${company.crunchbasePermalink}?waitForLatest=true`).pipe(
                    // have to manually cancel operation 
                    takeUntil(this._cancelCompanyLoading$),
                    retry(1),
                );
            }),
            tap(c => this.companyData.set(c)),
            tap(() => this.companyDataLoading.set(false)),
        
            catchError(e => {
                console.error(e);
                this.reportIsMissing.set(true);
                this.reportDataLoading.set(false);
                return of(undefined);
            })
        )),
                
    )

    companyData$ = toObservable(this.reportData).pipe(
        filter(r => !!r),
        
        
        // shareReplay(1)
    )

    _reportDataReadonly = toSignal(this.reportData$);

    reloadList(x: SalesPrepReport) {
        x.lastUpdated = new Date(x.lastUpdated);
        ReportService.setMeetingDateTime(x);
        this.reports.update(list => [x, ...list]);
    }

    regenerate() {
        const data = this.reportData();
        if (!data) {
            return;
        }

        const id = data.id;

        this.reportDataLoading.set(true);

        const companyUpdate = this.http.put<Company>(`/api/companies/index`, {
            id: data.company.crunchbasePermalink,
            forceRefresh: true
        }).pipe(
            retry(1),
            catchError(e => {
                console.error('Unable to regenerate report');
                return throwError(e);
            })
        );
        const reportUpdate = this.http.put('/api/reports/regenerate', {
            id
        }).pipe(
            retry(1),
            catchError(e => {
                console.error('Unable to regenerate report');
                return throwError(e);
            })
        );
        forkJoin([companyUpdate, reportUpdate]).subscribe(
            s => {
                this.selectedReportId.set('');
                this.selectedReportId.set(id);
                this.reportDataLoading.set(false);
            });
    }

    remove(report: SalesPrepReport) {
        console.log('[ReportService] remove', report.id);
        return this.http.delete('/api/reports/' + report.id).pipe(
            retry(1),
            tap(() => this.reports.update(list => list.filter(x => x.id != report.id))),
        );
    }

    updateReport() {
        const report = this.reportData();
        if (!report) throw Error('Report is not defined');

        ReportService.setMeetingDateTime(report);
        const { id, meetingDate, meetingTime, newsArticles, discussionTopics, otherTopics, exploratoryQuestions, stakeholders } = report;
        return this.http.put<SalesPrepReport>('/api/reports/v1.8/' + report.id, {
            id, meetingDate, meetingTime: this._fixTime(meetingTime), newsArticles, discussionTopics, otherTopics, exploratoryQuestions, stakeholders
        }).pipe(
            retry(1),
            tap(report => {
                this._initData(report);
                this.reportData.set(report);
                this.reports.update(list => list.map(x => x.id == report.id ? report : x));
            }),
        );
    }

    private _fixTime(time: string | undefined) {
        if (!time) return time;
        return time.startsWith('24:') ? '00' + time.substring(2) : time;
    }

    public static setMeetingDateTime(x: SalesPrepReport) {
        x.meetingDateTime = undefined;

        if (x.meetingDate) {
            const args = x.meetingDate.split('-');
            if (args.length == 3) {
                x.meetingDateTime = new Date(+args[0], +args[1] - 1, +args[2]);
            }

            if (x.meetingDateTime && x.meetingTime) {
                const time = x.meetingTime.split(':');
                if (time.length == 2) {
                    x.meetingDateTime.setHours(+time[0], +time[1]);
                }
            }
        }
    }

    private _initData(x: SalesPrepReport) {
        x.lastUpdated = new Date(x.lastUpdated);
        x.lastViewed = new Date(x.lastViewed);
        ReportService.setMeetingDateTime(x);
    }
}
