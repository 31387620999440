<h1 class="warn">
    @if (title) {
        {{title}} loading failed.
    } @else {
        Loading failed.
    }
</h1>
<!--<div>
    Do you want to <a (click)="onRetry.emit()" class="sp-link">retry</a>?
</div>-->
<button (click)="onRetry.emit()" class="primary">Retry</button>
