import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-aim-company',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './aim-company.component.html',
    styleUrl: './aim-company.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AimCompanyComponent {
    @HostBinding('class.sp-typography') t = true;
}
