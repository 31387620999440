import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule, NgForm} from "@angular/forms";
import {AutosizeDirective} from "../../../../directives/autosize.directive";
import {AutofocusDirective} from "../../../../directives/autofocus.directive";

@Component({
    selector: 'app-message-form',
    standalone: true,
    imports: [
        FormsModule,
        AutosizeDirective,
        AutofocusDirective
    ],
    templateUrl: './message-form.component.html',
    styleUrl: './message-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageFormComponent {
    @Input() message = '';

    @Output() send = new EventEmitter<string>();

    submit(f: NgForm) {
        if (!f.valid) return;

        this.send.emit(this.message);
        this.message = '';
    }

    keydown(e: KeyboardEvent, f: NgForm) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            this.submit(f);
        }
    }
}
