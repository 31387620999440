<div class="header">THE SALESPREP SYSTEM</div>

<div class="video">
    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
            <div class="wistia_embed wistia_async_x957dv57pl seo=true videoFoam=true"
                 style="height:100%;position:relative;width:100%">
                <div class="wistia_swatch"
                     style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                    <img src="https://fast.wistia.com/embed/medias/x957dv57pl/swatch"
                         style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true"
                         onload="this.parentNode.style.opacity=1;"/></div>
            </div>
        </div>
    </div>
</div>


<h1 style="margin-bottom: 0; margin-top: 50px;">
    Enter your customer’s world in a way that <em>impresses</em> them
</h1>

<div class="content column">
    
    <div class="row">
        
        <blockquote>
            PREPARE FOR AMAZING CUSTOMER VISITS 
            <p>Create an AI customer insights report in less than 60 seconds.</p>
            <p><a href="/#reports">Learn more</a></p>
        </blockquote>
        <blockquote>
            MASTER VOICE-OF-CUSTOMER PROBING VIA E-LEARNING
            <p>Engage your customer's interest and trust with cutting-edge techniques.</p>
            <p><a href="/#elearning">Learn more</a></p>
        </blockquote>
        <blockquote>
            CLOSE DEALS FASTER
            <p>Experience less friction after practicing your probing questions and receiving guidance from Claire, your AI Guide.</p>
            <p><a href="/#aiguide">Learn more</a></p>
        </blockquote>
    </div>

    <div class="footer large">          
        Expensive? Nope. Just $19.95 per month for everything (billed annually).
        
        <div class="note">Enterprise and other options available. <a class="sp-link" (click)="showPricing.emit()">See pricing below</a></div>
    </div>
</div>
