import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {OfferingTypeComponent} from "../offering-type/offering-type.component";
import {OfferingType} from "../../../../model/offeringType";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-select-offering-type',
    standalone: true,
    imports: [
        OfferingTypeComponent,
        RouterLink
    ],
    templateUrl: './select-offering-type.component.html',
    styleUrl: './select-offering-type.component.scss'
})
export class SelectOfferingTypeComponent {
    OfferingType = OfferingType;
    
    @Output() onSelect = new EventEmitter<OfferingType>();
}
