import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {MainPanelComponent} from "../../../../../../components/main-panel/main-panel.component";
import {
    ReportBuilderHeaderComponent
} from "../../../../components/report-builder-header/report-builder-header.component";
import {Company, OfferingCategory} from "../../../../../../model";
import {Offering} from "../../../../../../model/offering";
import {TopProgressBarComponent} from "../../../../components/top-progress-bar/top-progress-bar.component";
import {InfoPanelComponent} from "../../../../../../components/info-panel/info-panel.component";
import {OfferingType} from "../../../../../../model/offeringType";
import {SideProgressBarComponent} from "../../../../components/side-progress-bar/side-progress-bar.component";
import {FormsModule} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {SelectOfferingFormComponent} from "../select-offering-form/select-offering-form.component";
import {
    PureAddOfferingComponent, RemoveJobArgs
} from "../../../../../profile/components/pure-add-offering/pure-add-offering.component";

@Component({
    selector: 'app-pure-select-offering',
    standalone: true,
    imports: [
        MainPanelComponent,
        ReportBuilderHeaderComponent,
        TopProgressBarComponent,
        InfoPanelComponent,
        PureAddOfferingComponent,
        SideProgressBarComponent,
        FormsModule,
        RouterLink,
        SelectOfferingFormComponent
    ],
    templateUrl: './pure-select-offering.component.html',
    styleUrl: './pure-select-offering.component.scss'
})
export class PureSelectOfferingComponent {
    @Input() company!: Company;
    @Input() offerings: Offering[] = [];
    
    @Output() onSelectOfferingType = new EventEmitter<OfferingType>();
    @Output() onSelect = new EventEmitter<Offering>();
    @Output() onAdd = new EventEmitter<Offering>();

    @HostBinding('class.sp-screen') s = true;
    
    @Input() addNew = false;
    @Input() type: OfferingType | undefined;
    @Input() category: OfferingCategory | undefined;
    @Input() categories: OfferingCategory[] = [];
    
    @Output() onRemoveJob = new EventEmitter<RemoveJobArgs>();
    
    constructor(private router: Router) {
    }
    

    saveOffering(offering: Offering) {
        console.log('[PureSelectOfferingComponent] saveOffering', offering);
        
        this.onAdd.emit(offering);
        this.cancelDialog();        
    }

    cancelDialog() {
        this.addNew = false;
        this.type = undefined;
        this.category = undefined;
    }


    back() {
        this.router.navigate(['/report-builder', 'new']);
    }
}
