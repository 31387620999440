@if (companyNotFound) {
    <div class="note">
        We couldn't find any companies matching your search.
        Please double-check your spelling or try adjusting your search criteria.
    </div>
}

<div class="tips">
    <strong>Search Tips:</strong>
    <ul>
        <li>
            You can specify a country or industry. For example: <em>Aim Institute Dan Adams</em> or <em>Aurys France</em>.
        </li>
        <li>
            If you are confident the spelling is correct but the company is still not listed, it may be because our AI helper does not have information on it yet.
        </li>
        <li>
            In that case, feel free to <a (click)="onNext.emit()">proceed and build a report</a> for the company.<br> 
            Some company-related sections may remain empty in this scenario.
        </li>
    </ul>
</div>
