import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ELearningModule, ELearningModuleStatus} from "../../../../services/elearning.service";
import {ModuleComponent} from "../module/module.component";

@Component({
    selector: 'app-modules-list',
    standalone: true,
    imports: [
        ModuleComponent
    ],
    templateUrl: './modules-list.component.html',
    styleUrl: './modules-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModulesListComponent {
    @Input() modules: ELearningModule[] = [];
    @Input() modulesStatuses: ELearningModuleStatus[] = [];

    completed(number: number) {
        const s = this.modulesStatuses.find(x => x.id == number);
        if (s) {
            return s.status == 2;
        }
        return false;
    }

    inProgress(number: number) {
        const s = this.modulesStatuses.find(x => x.id == number);
        if (s) {
            return s.status == 1;
        }
        return false;
    }
}
