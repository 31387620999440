<app-main-header class="sp-desktop"/>

<app-main-title class="mobile-layout" [text]="'Role-Play'" [routerLink]="['/']"></app-main-title>

<app-page-header class="desktop-layout"
                 [title]="'Let’s role-play, using <em>your</em> own offering and customer application'"
                 [text]="authenticated() ? '' : 'One free practice session is available for you to try before subscribing.'"
                 >
    <div style="width: 61px"></div>
</app-page-header>

<app-pure-try-ai-guide class="sp-wrapped"
                       [authenticated]="authenticated()"
                       [userInitials]="userInitials()"
                       [offering]="offering()"
                       [job]="job()"
                       (onSubmit)="onSubmit($event)" />

<app-footer-smart />
