export enum OfferingType {
    Unknown = 0,
    MaterialOrIngredientOrComponent = 1,
    EquipmentOrToolOrConsumable = 2,
    ServiceOrSoftware = 3,
}

// You may also define a type for the description if needed
type OfferingTypeDescription = {
    [key in keyof typeof OfferingType]: string;
};

// Define descriptions
export const OfferingTypeDescription: OfferingTypeDescription = {
    Unknown: "Unknown",
    MaterialOrIngredientOrComponent: "Material, ingredient, or component used in my customers' products",
    EquipmentOrToolOrConsumable: "Equipment, tool, or consumable used in my customers' operations",
    ServiceOrSoftware: "Service or software used in my customers' operation or process"
};

// Usage example
// const category: ProductCategoryGroupEnum = ProductCategoryGroupEnum.MaterialOrIngredientOrComponent;
// console.log(category); // Output: 1
// console.log(productCategoryGroupDescriptions[category]); // Output: Material, ingredient, or component used in my customers' products