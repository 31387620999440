import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {UserProfile} from "../../../../model/userProfile";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {ClickOutsideDirective} from "../../../../directives/click-outside.directive";

@Component({
    selector: 'app-pure-main-header',
    standalone: true,
    imports: [
        RouterLink,
        ClickOutsideDirective,
        RouterLinkActive,
    ],
    templateUrl: './pure-main-header.component.html',
    styleUrl: './pure-main-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureMainHeaderComponent {
    @Output() onLogout = new EventEmitter();
    @HostBinding('class.authenticated') @Input() authenticated = false;
    @Input() userProfile: UserProfile | undefined;
    @HostBinding('class.compact') @Input() compact = false;
    @HostBinding('class.no-menu') @Input() noMenu = false;
    
    open = false;


    hide() {
        this.open = false;
    }
    
    toggle(e: MouseEvent | undefined) {
        this.open = !this.open;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }
}
