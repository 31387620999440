<app-main-panel>
    <form #loginForm="ngForm" (ngSubmit)="resetPassword(loginForm)">
    
        <div class="sp-form">
    
            <h1>Create new password</h1>

            <ul class="password-hints">
                <li style="white-space: nowrap">8 characters minimum</li>
                <li>a number</li>
                <li>a special character<br>!{{'@'}}#$%^&*()_+<></li>
            </ul>
            
            <label for="password">Password</label>
            <div class="password-input">
                <input autocomplete="current-password" [type]="showPassword ? 'text' : 'password'" id="password" [(ngModel)]="password"
                       required name="password">
    
                <a (click)="togglePasswordVisibility()">
                    <i class="material-icons">@if(showPassword) { visibility_off } @else { visibility }</i>
                </a>
            </div>

    
            <label for="password">Password Confirmation</label>
            <div class="password-input">
                <input autocomplete="current-password" [type]="showPassword2 ? 'text' : 'password'" id="password2" [(ngModel)]="password2"
                       required name="password2">
    
                <a (click)="togglePasswordVisibility2()">
                    <i class="material-icons">@if(showPassword) { visibility_off } @else { visibility }</i>
                </a>
            </div>
    
    
            @if (errorMessage) {
                <app-error-message [text]="errorMessage"></app-error-message>
            }
    
            <button class="primary" type="submit" [disabled]="loginForm.invalid">Reset Password</button>
            
        </div>
    </form>
</app-main-panel>