import {PdfGenerator} from "../pdf-generator";
import {DiscussionTopic} from "../../../model";
import {OtherTopicSection} from "./other-topic-section";
import {IReportSection} from "../report-section";
import {DiscussionTopicSection} from "./discussion-topic-section";
import {IReportPart} from "../ireport-part";

export interface ILegendItem {
    color: string;
    label: string;
    items: string[];

    icon?: string;
    height?: number;
}

export class DiscussionTopicsSection implements IReportPart {
    protected _legendOnTheSide = true;
    protected topics: DiscussionTopic[]
    
    constructor(protected pdf: PdfGenerator, items: DiscussionTopic[],
                role: string,
                protected title: string, 
                protected icon: string, 
                protected w: number, 
                protected h: number, 
                private singleColumn = false) {
        if (!!role) {
            this.title = `${this.title} (${role})`;
        }
        this.topics = items.filter(x => x.selected);
    }

    draw(y: number): number {
        if (!this.topics.length) {
            return 0;
        }
        
        
        this._legendOnTheSide = this.topics.length >= 6;
        
        if (y + 150 > this.pdf.pageHeight) {
            y = this.pdf.addPage();
        }

        const pageNumber = this.pdf.getPageNumber();
        this.drawTitle(y);        
        y += 27.143;
        if (!this._legendOnTheSide) {
            y = this.drawTopLegend(y);
        }
        const offsetY = this.drawItems(y);

        if (this._legendOnTheSide) {
            const newPageNumber = this.pdf.getPageNumber();
            const legendOffsetY = this.drawSideLegend(y + 16.26, pageNumber, newPageNumber);
            return Math.max(offsetY, legendOffsetY);
        }
        return offsetY;
    }
    
    protected drawSideLegend(y: number, pageNumber: number, newPageNumber: number) {
        return y;
    }
    
    protected drawTopLegend(y: number) {
        return y;
    }
    
    drawTitle(y: number) {
        this.pdf.partTitle(y, this.title, this.icon, this.w, this.h);
    }
    
    drawItems(offsetY: number) {
        const pdf = this.pdf;
        
        const items: IReportSection[] = 
            this.singleColumn 
            ? this.topics.map((x, i) => new OtherTopicSection(pdf, x, i + 1))
            : this.topics.map((x, i) => new DiscussionTopicSection(pdf, x, i + 1));
               
        while (true)
        {           
            if (this.singleColumn) {
                offsetY = pdf.drawColumn(items, pdf.styles.paddingX, offsetY);
            } else {
                offsetY = Math.max(
                    pdf.drawColumn(items, pdf.styles.paddingX, offsetY, items.length / 2),
                    pdf.drawColumn(items, 225.621, offsetY)
                );
            }
            
            if (items.length == 0) {
                break;
            } else {
                pdf.addPage();
                offsetY = pdf.partSubTitle(this.title +' CONTINUED...');
            }            
        }
        
        return offsetY;
    }
}



