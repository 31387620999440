<app-main-header/>

<app-page-header [title]="'Contact Us'"/>

<div class="flex content">
    <div class="left company-info">
        <div class="name">AIM Institute</div> 
        2206 20th Street<br>
        Cuyahoga Falls, OH 44223<br> 
        P. +1 330-715-8709<br>
        F. +1 216-373-2026
    </div>
    
    <div class="right">
        <h1>How can we help?</h1>
        
        <p class="details">
            Please contact us with any questions or comments you might have. We do our best to respond quickly to all inquiries. We can review your specific situation and goals…and provide additional information beyond what’s publicly available. 
            <br><br>
            <!-- If you need support for one of our software products, please follow this link to the BlueHelp® Knowledge Center. 
            <br><br>
            Otherwise, simply fill out the fields below: -->
        </p>

        <div id="wrapper" #wrapper class="sp-form">
            <app-spinner/>
        </div>
    </div>
    
</div>

<app-footer-smart />