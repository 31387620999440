import {Component} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-mobile-menu',
    standalone: true,
    imports: [
        RouterLinkActive,
        RouterLink,
        MainHeaderComponent,
        PageHeaderComponent,
        FooterSmartComponent
    ],
    templateUrl: './mobile-menu.component.html',
    styleUrl: './mobile-menu.component.scss'
})
export class MobileMenuComponent {
  
    constructor(private authService: AuthService) {
    }

    logout() {
        this.authService.logout();
    }
}
