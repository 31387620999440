<app-company-logo [company]="company" />
<div class="flex-column description mobile-layout">
    <div class="date" qa="meet-date">
        {{reportData?.meetingDate | date}}
        {{reportData?.meetingTime | time}}
        @if (reportData?.meetingDate | today) {
            <app-badge qa="badge">TODAY</app-badge>
        }
        &nbsp;
    </div>
    <h1 class="company-name" qa="rep-company">{{company?.name || "Loading..."}}</h1>
    <div class="subject" qa="div-subj">
        @if (reportData) {
            <div>
                <app-report-subject
                        [offeringName]="reportData.offeringName"
                        [offeringType]="reportData.offeringType"
                        [job]="reportData.job"
                        qa="rep-subj"
                />.
                @if (roles.length) {
                    <br><div class="roles" qa="roles">
                        Customer Role@if(roles.length > 1){s}: 
                        <b>{{roles.join(', ')}}</b>.
                    </div>
                }
            </div>
            
            <em qa="gen"> Generated {{reportData.lastUpdated | date:'MMM d, y h:mm a'}}</em>
        }
    </div>
</div>

<div class="flex description desktop-layout">
    <div class="flex-column">
        <h1 class="company-name" qa="rep-company">{{company?.name || "Loading..."}}</h1>
        <div class="subject" qa="div-subj">
            @if (reportData) {
                <div>
                    <app-report-subject
                            [offeringName]="reportData.offeringName"
                            [offeringType]="reportData.offeringType"
                            [job]="reportData.job"
                            qa="rep-subj"
                    />.
                    @if (roles.length) {
                        <br><div class="roles" qa="roles">
                            Customer Role@if(roles.length > 1){s}:
                            <b>{{roles.join(', ')}}</b>.
                        </div>
                    }
                </div>
            }
        </div>
    </div>
    <div class="right flex-column">
        <div class="date" qa="meet-date">
            {{reportData?.meetingDate | date}}
            {{reportData?.meetingTime | time}}
            @if (reportData?.meetingDate | today) {
                <app-badge qa="badge">TODAY</app-badge>
            }
            &nbsp;
        </div>
        <div class="generated">
            @if (reportData) {
                <em> Generated {{reportData.lastUpdated | date:'MMM d, y h:mm a'}}</em>
            }
        </div>
    </div>
    
</div>

<div class="actions">

    <div class="desktop-layout">
        <a class="menu-icon scroll-top" (click)="onScrollToTop.emit()" title="Click to scroll back to top">
            <span class="material-symbols-outlined" qa="scr-up">keyboard_arrow_up</span>
        </a>
    </div>
    <div class="menu-wrapper desktop-layout" appClickOutside (clickOutside)="showExportMenu = false" title="Click to see export options">        
        <a class="menu-icon" (click)="showExportMenu = true">
            @if (exporting) {
                <span class="material-symbols-outlined sp-spin" qa="sync">sync</span>
            } @else {

                <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="23" viewBox="0 0 20.5 23">
                    <g id="Download_PDF" data-name="Download PDF" transform="translate(-797 -89)" qa="exp">
                        <path id="Subtraction_71" data-name="Subtraction 71" d="M-4258.728-2142H-4265a2,2,0,0,1-2-2v-17a2,2,0,0,1,2-2h14a2,2,0,0,1,2,2v12.343h-1.5V-2161a.5.5,0,0,0-.5-.5h-14a.5.5,0,0,0-.5.5v17a.5.5,0,0,0,.5.5h4.68l1.59,1.5Z" transform="translate(5064 2252)" fill="#fff"/>
                        <path id="Polygon_80" data-name="Polygon 80" d="M5,0l5,5H0Z" transform="translate(815 112) rotate(180)" fill="#fff"/>
                        <rect id="Rectangle_756" data-name="Rectangle 756" width="3" height="6" transform="translate(808.5 104)" fill="#fff"/>
                        <rect id="Rectangle_755" data-name="Rectangle 755" width="17" height="8" rx="1" transform="translate(800.5 93)" fill="#fff"/>
                        <text id="PDF" transform="translate(809 99.5)" fill="#379fe0" font-size="7" font-family="Raleway-Black, Raleway" font-weight="800"><tspan x="-6" y="0" qa="exp-pdf">PDF</tspan></text>
                    </g>
                </svg>
            }
        </a>

        @if (showExportMenu) {
            <div class="menu" title="Export menu">
                <a (click)="showExportMenu = false;onExportToPdf.emit()" qa="exp-sp" title="Download SalesPrep Report">
                    <span>SalesPrep Report (Internal Use)</span>
                </a>

                <a (click)="showExportMenu = false; printAll()" qa="exp-all-hand" title="Download All Customer Handouts">
                    <span>All Customer Handouts</span>
                </a>

                <a (click)="showExportMenu = false; printTrends()" qa="exp-tr" title="Download Industry Trends Handout">
                    <span>Industry Trends Handout</span>
                </a>

                <a (click)="showExportMenu = false; printProblems()" qa="exp-pr" title="Download Common Problem Handout">
                    <span>Common Problem Handout</span>
                </a>

                <a (click)="showExportMenu = false; printSteps()" qa="exp-st" title="Download Process Steps Handout">
                    <span>Process Steps Handout</span>
                </a>
            </div>
        }
    </div>
@if (authenticated) {
    <div class="menu-wrapper desktop-layout" appClickOutside (clickOutside)="showEditMenu = false" title="Click to see edit options">
        <a class="menu-icon" (click)="showEditMenu = true">
            <span class="material-symbols-outlined" qa="ed-rep">edit_note</span>
        </a>

        @if (showEditMenu) {
            <div class="menu">
                @if (reportData?.exploratoryQuestions?.length) {
                    <a (click)="showEditMenu = false;onEditExploratoryQuestions.emit()" qa="edit-questions" title="Edit Exploratory Questions">
                        <span>Edit Exploratory Questions</span>
                    </a>
                }
                
                <a (click)="showEditMenu = false;onEditDiscussionTopics.emit()" qa="edit-topics" title="Edit Discussion Topics">
                    <span>Edit Discussion Topics</span>
                </a>

                <a (click)="showEditMenu = false;onEditMeetingDate.emit()" qa="edit-date" title="Edit Meeting Date">
                    <span>Edit Meeting Date</span>
                </a>
            </div>
        }
    </div>
}    
    
    <div class="menu-wrapper mobile-layout" appClickOutside (clickOutside)="showMenu = false">
        <a class="menu-icon" (click)="showMenu = true">
            <span class="material-symbols-outlined"> more_vert </span>
        </a>
    
        @if (showMenu) {
            <div class="menu">
                <a (click)="showMenu = false;onExportToPdf.emit()" qa="print" title="Download SalesPrep Report">
                    <span>SalesPrep Report (Internal Use)</span>
                </a>

                <a (click)="showMenu = false; printAll()" qa="print" title="Download All Customer Handouts">
                    <span>All Customer Handouts</span>
                </a>

                <a (click)="showMenu = false; printTrends()" qa="print" title="Download Industry Trends Handout">
                    <span>Industry Trends Handout</span>
                </a>

                <a (click)="showMenu = false; printProblems()" qa="print" title="Download Common Problem Handout">
                    <span>Common Problem Handout</span>
                </a>

                <a (click)="showMenu = false; printSteps()" qa="print" title="Download Process Steps Handout">
                    <span>Process Steps Handout</span>
                </a>

                @if (authenticated) {
                    <hr>
    
                    <a (click)="showMenu = false;onEditExploratoryQuestions.emit()" qa="edit-questions" title="Edit Exploratory Questions">
                        <span>Edit Exploratory Questions</span>
                    </a>
                    
                    <a (click)="showMenu = false;onEditDiscussionTopics.emit()" qa="edit-topics" title="Edit Discussion Topics">
                        <span>Edit Discussion Topics</span>
                    </a>
                    
                    <a (click)="showMenu = false;onEditMeetingDate.emit()" qa="edit-date" title="Edit Meeting Date">
                        <span>Edit Meeting Date</span>            
                    </a>
                }
            </div>
        }
    </div>
</div>