import { Component } from '@angular/core';
import {FooterComponent} from "../../components/footer/footer.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {ResourcesListComponent} from "../resources/components/resources-list/resources-list.component";
import {PureFaqComponent} from "./components/pure-faq/pure-faq.component";
import {RouterLink} from "@angular/router";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";

@Component({
  selector: 'app-faq',
  standalone: true,
    imports: [
        FooterComponent,
        MainHeaderComponent,
        MainTitleComponent,
        PageHeaderComponent,
        ResourcesListComponent,
        PureFaqComponent,
        RouterLink,
        FooterSmartComponent
    ],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {

}
