<app-top-progress-bar [step]="2" />
<app-side-progress-bar [step]="2" [company]="company" />

<app-main-panel class="sp-form left-column">
    <app-report-builder-header class="mobile-layout" [company]="company"/>
    
    <app-select-offering-form 
            [title]="company.name"
            [offerings]="offerings"
            (onSelect)="onSelect.emit($event)"
            (onAddNew)="addNew = true"
            (onBack)="back()"
        />
    
</app-main-panel>

@if (addNew) {

    <app-info-panel [title]="'Add Offering'" (onClose)="cancelDialog()">
        <app-pure-add-offering
                class="sp-form-alternative"

                (onSelectOfferingType)="type = $event; onSelectOfferingType.emit($event)"
                (onCategorySelected)="category = $event"                
                (onSave)="saveOffering($event)"
                (onRemoveJob)="onRemoveJob.emit($event)"
                
                [inline]="true"
                [type]="type"
                [selectedCategory]="category"                
                [categories]="categories"
        />
    </app-info-panel>
}