import {AfterViewInit, Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {PureConfirmEmailComponent} from "./components/pure-confirm-email/pure-confirm-email.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {catchError, throwError} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: 'app-confirm-email',
    standalone: true,
    imports: [
        PureConfirmEmailComponent,
        MainHeaderComponent,
        MainPanelComponent,
        MainTitleComponent,
        FooterCompactComponent
    ],
    templateUrl: './confirm-email.component.html',
    styleUrl: './confirm-email.component.scss'
})
export class ConfirmEmailComponent implements AfterViewInit {
    
    @Input() userId = '';
    @Input() code = '';
    
    errorMessage = '';

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private authService: AuthService) {
    }

    ngAfterViewInit() {
        const isServer = !isPlatformBrowser(this.platformId);
        if (!isServer) {
            this.confirm();
        }
    }

    confirm() {
        this.errorMessage = '';
        this.authService.confirmEmail(this.userId, this.code)
            .pipe(
                catchError(e => this._handleError(e))
            )
            .subscribe(() => {
                this.router.navigate(['/dashboard']);
            });
    }

    resendVerificationEmail() {
        this.router.navigate(['/forgot-password']);
    }

    private _handleError(error: HttpErrorResponse) {
        console.warn(error);
        this.errorMessage = error?.error || 'Email Verification Failed.';
        return throwError(() => this.errorMessage);
    }
}
