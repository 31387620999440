import {PdfGenerator} from "../pdf-generator";
import {ProductSection} from "./product-section";
import {IReportPart} from "../ireport-part";

export class ProductsSection implements IReportPart  {
    constructor(private pdf: PdfGenerator) {
        
    }


    draw(y: number) {
        if (!this.pdf.report.company.products.length) {
            return 0;
        }
        
        if (y + 150 > this.pdf.pageHeight) {
            y = this.pdf.addPage();
        }
        this.drawTitle(y);
        y += 27.143;
        return this.drawItems(y);
    }

    drawTitle(offsetY: number) {
        this.pdf.partTitle(offsetY, 'PRODUCTS', 'products', 18, 17.999);        
    }

    drawItems(offsetY: number) {
        const pdf = this.pdf;
        const industries = pdf.report.company.products;
        const industryMargin = 4;
        
        industries.forEach(i => {
            if (offsetY + 150 > pdf.pageHeight) {
                offsetY = pdf.addPage();
            }

            this.pdf.text(i.name, this.pdf.styles.paddingX, offsetY, 'Raleway-Bold', 12);
            
            offsetY += this.pdf.doc.getLineHeight() + industryMargin;

            const items = i.products.map(x => new ProductSection(pdf, x));
            console.log(i.name, i.products, i.products.length / 3);

            while (true) {
                offsetY = Math.max(
                    pdf.drawColumn(items, pdf.styles.paddingX, offsetY, items.length / 3),
                    pdf.drawColumn(items, 225, offsetY, items.length / 3),
                    pdf.drawColumn(items, 404.438, offsetY)
                );

                if (items.length == 0) {
                    break;
                } else {
                    pdf.addPage();
                    offsetY = pdf.partSubTitle('PRODUCTS CONTINUED...');
                }
            }
        });

        return offsetY;
    }
}