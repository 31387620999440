import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input, OnChanges,
    Output,
    signal,
    SimpleChanges
} from '@angular/core';
import {
    ReportPracticeData
} from "../../report-practice-start/pure-report-practice-start/pure-report-practice-start.component";
import {
    DiscussionTopicsListComponent
} from "../../../../report-builder/screens/select-discussion-topics/components/discussion-topics-list/discussion-topics-list.component";
import {
    RetryComponent
} from "../../../../report-builder/screens/select-discussion-topics/components/retry/retry.component";
import {SpinnerComponent} from "../../../../../components/spinner/spinner.component";
import {
    Company,
    DiscussionTopic,
    ExploratoryQuestion,
    NewsArticle,
    Offering,
    ReportSectionRating, Stakeholder
} from "../../../../../model";
import {IEditableDiscussionTopics} from "../../../../../services/report-builder.service";
import {
    NewsDiscussionTopic
} from "../../../../report-builder/screens/select-discussion-topics/components/pure-select-discussion-topics/pure-select-discussion-topics.component";
import {DatePipe} from "@angular/common";
import {RouterLink} from "@angular/router";
import {
    PureExploratoryQuestionsComponent
} from "../../../../report-builder/screens/exploratory-questions/components/pure-exploratory-questions/pure-exploratory-questions.component";

@Component({
    selector: 'app-pure-select-topics',
    standalone: true,
    imports: [
        DiscussionTopicsListComponent,
        RetryComponent,
        SpinnerComponent,
        RouterLink,
        PureExploratoryQuestionsComponent
    ],
    providers: [
        DatePipe
    ],
    templateUrl: './pure-select-topics.component.html',
    styleUrl: './pure-select-topics.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureSelectTopicsComponent implements OnChanges {

    @Input() sessionExpired = false;
    @Input() companyNewsLoading = false;
    @Input() companyNewsLoadingFailed = false;

    @Input() company: Company | undefined;
    @Input() product!: Offering;
    @Input() job = '';

    @Input() discussionTopics: IEditableDiscussionTopics[] = [];
    @Input() otherTopics: DiscussionTopic[] = [];
    @Input() newsArticles: NewsArticle[] = [];

    @Input() companyNewsRating!: ReportSectionRating;
    @Input() exploratoryQuestionsRating!: ReportSectionRating;
    @Input() stakeholdersRating!: ReportSectionRating;
    @Input() marketTrendsRating!: ReportSectionRating;
    @Input() commonProblemsRating!: ReportSectionRating;
    @Input() processStepsRating!: ReportSectionRating;
    @Input() otherTopicsRating!: ReportSectionRating;
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();

    @Input() selectedCustomDiscussionTopic: DiscussionTopic | undefined;
    @Input() customTopic = '';

    @Input() stakeholders!: Stakeholder[];
    @Input() stakeholdersLoading!: boolean;
    @Input() stakeholdersLoadingFailed!: boolean;
    
    @Input() exploratoryQuestions!: ExploratoryQuestion[];
    @Input() exploratoryQuestionsLoading!: boolean;
    @Input() exploratoryQuestionsLoadingFailed!: boolean;    

    @Output() onCancelEditMode = new EventEmitter();
    @Output() onNext = new EventEmitter();
    @Output() onAddDiscussionTopic = new EventEmitter();
    @Output() onSaveCustomTopic = new EventEmitter<DiscussionTopic>();

    @Output() onCompanyDataRetry = new EventEmitter();
    @Output() onStakeholdersRetry = new EventEmitter();
    @Output() onMarketTrendsRetry = new EventEmitter();
    @Output() onCommonProblemsRetry = new EventEmitter();
    @Output() onProcessStepsRetry = new EventEmitter();

    @HostBinding('class.sp-screen') s = true;

    title = signal('');

    selectedTopic = signal<DiscussionTopic | undefined>(undefined);
    newsTopics: DiscussionTopic[] = [];
    addOtherTopic = false;
    @Output() onGenerateExploratoryQuestions = new EventEmitter<string[]>();
    
    

    constructor(private datePipe: DatePipe) {
    }
    
    ngOnChanges(changes: SimpleChanges) {

        if (changes['selectedCustomDiscussionTopic']) {
            this.addOtherTopic = !this.selectedCustomDiscussionTopic?.title;
        }
        if (changes['newsArticles']) {
            if (this.newsArticles) {
                this.newsTopics = this.newsArticles.map(x => {
                    return new NewsDiscussionTopic(x, this.datePipe);
                });
            } else {
                this.newsTopics = [];
            }
        }
    }
    
    onSelectNewsArticle(topic: DiscussionTopic) {
        this.title.set('Company News');

        const a = this.newsArticles.find(x => x.url == (<any>topic).id);
        if (a) {
            this.selectedTopic.set({
                selected: true,
                title: topic.title,
                description: a.summary
            })
        }
    }
}
