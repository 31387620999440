import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-date-picker',
    standalone: true,
    imports: [],
    templateUrl: './date-picker.component.html',
    styleUrl: './date-picker.component.scss'
})
export class DatePickerComponent implements OnInit, OnChanges {
    currentMonth!: number;
    currentYear!: number;
    
    weeks: Day[][] = [];
    now = new Date();
    today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());

    @Input() date: Date | undefined
    @Output() dateChange = new EventEmitter<Date>();

    constructor() { }

    ngOnInit(): void {
        const currentDate = this.date ? this.date : new Date();
        this.currentMonth = currentDate.getMonth();
        this.currentYear = currentDate.getFullYear();
        
        this._init();
    }
    
    ngOnChanges() {
        this._init();
    }

    private _init() {
        this.generateCalendar();        
    }

    nextMonth(): void {
        if (this.currentMonth === 11) {
            this.currentMonth = 0;
            this.currentYear++;
        } else {
            this.currentMonth++;
        }
        this.generateCalendar();
    }

    previousMonth(): void {
        if (this.currentMonth === 0) {
            this.currentMonth = 11;
            this.currentYear--;
        } else {
            this.currentMonth--;
        }
        this.generateCalendar();
    }

    selectDay(day: Day): void {
        console.log("[DatePickerComponent] Selected day:", day.date);
        this.weeks.forEach(w => w.forEach(d => d.selected = false));
        day.selected = true;
        this.date = day.date;
        this.dateChange.emit(this.date);
        
        if (this.currentMonth != this.date.getMonth()) {
            this.currentMonth = this.date.getMonth();
            this.generateCalendar();
        }
    }

    getCurrentMonthName(): string {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return monthNames[this.currentMonth];
    }

    getWeeks(): Day[][] {
        const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
        const firstDayOfWeek = firstDayOfMonth.getDay(); // 0 for Sunday, 1 for Monday, etc.
        const weeks: Day[][] = [[]];
        let currentWeek = 0;

        // Fill in preceding days from previous month if necessary
        if (firstDayOfWeek !== 0) {
            const lastDayOfPreviousMonth = new Date(this.currentYear, this.currentMonth, 0).getDate();
            const daysToAdd = firstDayOfWeek;

            for (let i = 0; i < daysToAdd; i++) {
                const date = new Date(this.currentYear, this.currentMonth - 1, lastDayOfPreviousMonth - daysToAdd + i + 1);
                weeks[currentWeek].push(this._getDate(date));
            }
        }

        // Fill in days of current month
        const daysInMonth = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();

        for (let i = 1; i <= daysInMonth; i++) {
            const date = new Date(this.currentYear, this.currentMonth, i);
            if (weeks[currentWeek].length === 7) {
                currentWeek++;
                weeks[currentWeek] = [];
            }
            weeks[currentWeek].push(this._getDate(date));
        }

        // Fill in remaining days from next month if necessary
        const lastDayOfWeek = new Date(this.currentYear, this.currentMonth, daysInMonth).getDay();
        if (lastDayOfWeek !== 6) {
            const daysToAdd = 6 - lastDayOfWeek;

            for (let i = 0; i < daysToAdd; i++) {
                const date = new Date(this.currentYear, this.currentMonth + 1, i + 1);
                weeks[currentWeek].push(this._getDate(date));
            }
        }

        return weeks;
    }
    
    private _getDate(date: Date): Day {
        return { 
            date,
            past: (+date - +this.today) < 0 || date.getMonth() != this.currentMonth,
            selected: this._sameDate(this.date, date) 
        }
    }


    private generateCalendar() {
        console.log('[DatePickerComponent] generateCalendar');
        this.weeks = this.getWeeks();
    }

    private _sameDate(date1: Date | undefined, date2: Date) {
        if (!date1) return false;

        // Compare year, month, and date of both dates
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }
}

interface Day {
    date: Date;
    past: boolean;
    selected: boolean;
}
