import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'app-checkmark',
    standalone: true,
    imports: [],
    templateUrl: './checkmark.component.html',
    styleUrl: './checkmark.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckmarkComponent {
    @HostBinding('class.ai') @Input() ai = false;

}
