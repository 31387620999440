import {SalesPrepReport, UserProfile} from "../../model";
import {MainHeaderSection} from "./layout/main-header-section";
import {CompanyOverviewSection} from "./company-overview/company-overview-section";
import {MarketTrendsSection} from "./discussion-topics/market-trends-section";
import {CommonProblemsSection} from "./discussion-topics/common-problems-section";
import {ProcessStepsSection} from "./discussion-topics/process-steps-section";
import {OtherTopicsSection} from "./discussion-topics/other-topics-section";
import {CompanyNewsSection} from "./company-news/company-news-section";
import {HeaderSection} from "./layout/header-section";
import {FooterSection} from "./layout/footer-section";
import {CompanyInformationSection} from "./company-information/company-information-section";
import {LeadershipSection} from "./leadership/leadership-section";
import {IReportSection} from "./report-section";
import {ProductsSection} from "./products/products-section";
import {CompetitorsSection} from "./competitors/competitors-section";
import {StrategySection} from "./strategy/strategy-section";
import {IReportPart} from "./ireport-part"; 
import {PdfGeneratorBase} from "./pdf-generator-base";
import {BriefDisclaimerSection} from "../brief-reports/brief-disclaimer-section";

export class PdfGenerator extends PdfGeneratorBase {

    static fonts = [
        'Raleway-Regular',
        'Raleway-Medium',
        'Raleway-Bold',
        'Raleway-ExtraBold',
        
        'Raleway-MediumItalic',
        'Raleway-BoldItalic',
        'Raleway-ExtraBoldItalic'
    ]
    
    
    pageHeight = 745;
    
    
    constructor(public report: SalesPrepReport, public userProfile: UserProfile | undefined) {
        super();        
    }

    async generate(filename: string) {
        const o = new CompanyOverviewSection(this);
        await o.loadLogo();
        
        const topics: IReportPart[] = this.report.discussionTopics.map(topics => {
            return [
                new MarketTrendsSection(this, topics),
                new CommonProblemsSection(this, topics),
                new ProcessStepsSection(this, topics),
            ]
        }).reduce((acc, curr) => acc.concat(curr), []);
        
        const sections: IReportPart[] = [
            o,
            new CompanyNewsSection(this),
            
            ...topics,
            
            new OtherTopicsSection(this),
            new CompanyInformationSection(this),
            new LeadershipSection(this),
            new ProductsSection(this),
            new CompetitorsSection(this),
            new StrategySection(this),
            new BriefDisclaimerSection(this)
        ];
        
        let y = 0;
        sections.forEach(s => y = this._drawPart(s, y));
        
        // y = new CompanyOverviewSection(this).draw(y);
        // y = new CompanyNewsSection(this).draw(y);        
        // y = new MarketTrendsSection(this, this.report.marketTrends).draw(y);
        // y = new CommonProblemsSection(this, this.report.commonProblems).draw(y);
        // y = new ProcessStepsSection(this, this.report.processSteps).draw(y);
        // y = new OtherTopicsSection(this, this.report.otherTopics).draw(y);
        // y = new CompanyInformationSection(this).draw(y);
        // y = new LeadershipSection(this).draw(y);
        // y = new ProductsSection(this).draw(y);
        // y = new CompetitorsSection(this).draw(y);
        // y = new StrategySection(this).draw(y);

        new MainHeaderSection().draw(this);
        new HeaderSection().draw(this);
        new FooterSection().draw(this);
        
        this.doc.save(filename);

        // this.doc.output('dataurlnewwindow');
    }

    

    hr(y: number) {
        const d =  this.doc;
        d.setFillColor("#d3d2d2");
        d.setLineWidth(2);
        const margin = 14.642;
        d.line(45.853, y + margin + this.styles.offset, 45.853 + 522.000, y + margin + this.styles.offset, 'F');
        return y + margin;
    }

    
    drawColumn(items: IReportSection[], x: number, y: number, max: number = 0) {
        let i = 0;
        while (items.length > 0) {
            if (max && i >= max) {
                break;
            }
            if (y + items[0].getHeight() > this.pageHeight) {
                break;
            }
            const item = items.shift();
            if (item) {
                i++;
                y = item.draw(x, y);
            }
        }
        return y;
    }

    private _drawPart(part: IReportPart, _y: number) {
        const margin = 20;
        const y = part.draw(_y);
        if (y > 0) {
            if (y + 40 < this.pageHeight && !(part instanceof StrategySection)) {
                return this.hr(y) + margin;
            }
            return y;
        }
        
        return _y;
    }

    partTitle(y: number, text: string, icon: string = '', w: number = 0, h: number = 0) {
        const x = this.styles.paddingX;
        if (icon) {
            const iconY = h > 10.8 ? (h - 10.8) / 2 : 0
            this.doc.addImage(`assets/pdf/${icon}-icon.png`, 'png', x, y - iconY, w, h);
        }

        this.text(text, icon ? x + 28 : x, y, 'Raleway-ExtraBold', 12);
    }

    partSubTitle(text: string) {
        const s = this.styles;
        this.text(text, s.paddingX, s.paddingY, 'Raleway-ExtraBoldItalic', 10);
        return 70;
    }

    getPageNumber() {
        return this.doc.getCurrentPageInfo().pageNumber;
    }
}