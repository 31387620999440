import {MyProblemBriefReportGenerator} from "./problem-brief-report-generator";
import {IReportPart} from "../pdf-export/ireport-part";
import {BriefReportItem, BriefReportItemList, BriefReportItemListItem} from "../../model";
import {BriefCheckboxSection} from "./brief-checkbox-section";
import {BriefCheckboxGroupSection} from "./brief-checkbox-group-section";


export class ProcessBriefCheckboxesSection implements IReportPart {
    
    private marginBottom = 10;
    
    constructor(private pdf: MyProblemBriefReportGenerator, private title: string, private items: BriefReportItemList[]) {
    }
        
    draw(y: number) {
        const pdf = this.pdf;
        const items = this.items;
        if (!items.length) {
            return 0;
        }

        y = pdf.subHeading(y, this.title);        

        items.forEach(a => {
            const s = (a.subtopics.length == 1) 
                ? new BriefCheckboxSection(pdf, {
                    title: a.title,
                    description: !!a.description ? a.description + '. '  + a.subtopics[0].title : a.subtopics[0].title,
                })
                : new BriefCheckboxGroupSection(pdf, {
                    title: a.title,
                    description: a.description,                                        
                    items: a.subtopics.map(x => this._format(x))
                })
            
            if (y + s.getHeight() > pdf.pageHeight) {
                pdf.doc.addPage();
                y = pdf.styles.paddingY;
            }
            y = s.draw(y) + this.marginBottom;
        });

        return y;
    }

    private _format(x: BriefReportItemListItem) {
        return !x.description ? x.title : `${x.title}: ${x.description}`;
    }
}