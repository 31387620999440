import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SalesPrepReport, TutorCoachingLevels} from "../../../../model";
import {ReportSubjectComponent} from "../../../../components/report-subject/report-subject.component";
import {TutorAvatarComponent} from "../../../../components/tutor-avatar/tutor-avatar.component";
import {OfferingType} from "../../../../model/offeringType";
import {ClaireBannerComponent} from "../claire-banner/claire-banner.component";
import {MessageFormComponent} from "../message-form/message-form.component";
import {OfferingActionPipe} from "../../../../pipes/offering-action.pipe";
import {SelectCoachingLevelComponent} from "../select-coaching-level/select-coaching-level.component";

@Component({
    selector: 'app-start-chat',
    standalone: true,
    imports: [
        ReportSubjectComponent,
        TutorAvatarComponent,
        ClaireBannerComponent,
        MessageFormComponent,
        OfferingActionPipe,
        SelectCoachingLevelComponent,
    ],
    templateUrl: './start-chat.component.html',
    styleUrl: './start-chat.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartChatComponent {
    
    @Input() userInitials = '';
    @Input() showStep = true;
    @Input() busy = false;
    @Input() coachingLevel = TutorCoachingLevels.Intermediate;

    @Input() offeringName = '';
    @Input() offeringType = OfferingType.Unknown;
    @Input() job = '';

    @Output() onStart = new EventEmitter<string>();
    @Output() coachingLevelChange = new EventEmitter<TutorCoachingLevels>();

    TutorCoachingLevels = TutorCoachingLevels;

    select(level: TutorCoachingLevels) {
        if (this.coachingLevel != level) {
            this.coachingLevel = level;
            this.coachingLevelChange.emit(this.coachingLevel);
        }
        
    }
}
