<div class="background">&nbsp;</div>

<div class="image">
    <div class="clarify">
        <span class="title">Clarify</span>
        <p><em>Are you saying</em> you want to...</p>
        <p class="minimize">"minimize fading over time"?</p>
    </div>
    <div class="why">
        <span class="title">Why</span>
        <p>How does this <em>impact</em> you?</p>
        <p><em>Who</em> does this impact?</p>
    </div>
    <div class="what">
        <span class="title">What</span>
        <p>Can you <em>describe</em> this problem?</p>
        <p><em>When</em> does it happen? <em>Where? How often?</em></p>
    </div>
</div>