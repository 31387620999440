<div class="header flex">
    <div class="flex-grow-1 flex">
        <h1 qa="h1-title">{{ title }}</h1>
        <app-rate-section [rating]="rating" (ratingChange)="onRatingChange.emit($event)" qa="rate-sec"/>
    </div>
    <button (click)="expandAll()" class="sp-flat add"><i class="material-icons" qa="exp">add</i> ALL</button>
    <button (click)="collapseAll()" class="sp-flat"><i class="material-icons" qa="col">remove</i> ALL</button>
</div>
<div class="topics">
    @for (topic of selectedTopics; track topic.title; let i = $index) {
        <app-discussion-topic-item [flat]="flat" [topic]="topic" [icon]="icon" [attr.qa]="'item-' + (i + 1)"/>
    } @empty {
        <div qa="empty">No items to display.</div>
    }
</div>

<app-rate-section-footer [rating]="rating" (ratingChange)="onRatingChange.emit($event)" qa="rate-fot"/>