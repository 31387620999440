import {Injectable, signal} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {catchError, finalize, map, Observable, retry, shareReplay, tap, throwError} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {OfferingType, OfferingTypeResponse, OfferingTypesResponse} from "../model";
import {Offering} from "../model/offering";
import {AuthService} from "./auth.service";
import {MessageService} from "./message.service";

@Injectable({
    providedIn: 'root'
})
export class ProductsService {

    busy = signal(false);
    
    constructor(private messageService: MessageService, private authService: AuthService, private http: HttpClient) {
    }

    offeringTypes$ = this.http.get<OfferingTypesResponse>('/api/offerings/types').pipe(
        map(data => data.types),
        retry(1),
        shareReplay(1)
    )

    offeringTypes = toSignal<OfferingTypeResponse[], OfferingTypeResponse[]>(this.offeringTypes$, {
        initialValue: []
    });

    
    addOffering(offering: Offering): Observable<Offering> {
        console.log('[ProductsService] addOffering', offering);
        
        return this.http.put<AddOfferingResponse>('/api/offerings', {offering}).pipe(
            retry(1),
            catchError(e => {
                this.messageService.error('Unable to add offering');
                return throwError(e);
            }),
            map(data => data.offering),
            tap(data => {
                offering.id = data.id;
                const p = this.authService.userProfile;
                const profile = p();
                if (profile) {
                    p.set({
                        ...profile,
                        offerings: [...profile.offerings, data]
                    })
                }
            }),
            tap(() => this.authService.reloadProfile()),
            tap(() => this.messageService.success('Done')),
        );
    }

    removeOffering(offering: Offering) {
        return this.http.delete('/api/offerings/' + offering.id).pipe(
            retry(1),
            tap(data => {
                const p = this.authService.userProfile;
                const profile = p();
                if (profile) {
                    p.set({
                        ...profile,
                        offerings: profile.offerings.filter(x => x.id != offering.id)
                    })
                }
            }),
            tap(() => this.authService.reloadProfile()),
            catchError(e => {
                this.messageService.error('Unable to remove offering');
                return throwError(e);
            })
        );
    }
}

interface AddOfferingResponse {
    offering: Offering
}