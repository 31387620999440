import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {DiscussionTopic, DiscussionTopics, ReportSectionRating, Stakeholder} from "../../../../model";
import {DiscussionTopicItemComponent} from "../discussion-topic-item/discussion-topic-item.component";
import {RateSectionComponent} from "../../../../components/rate-section/rate-section.component";
import {RateSectionFooterComponent} from "../../../../components/rate-section-footer/rate-section-footer.component";

@Component({
    selector: 'app-discussion-topics-list-by-role',
    standalone: true,
    imports: [
        DiscussionTopicItemComponent,
        RateSectionComponent,
        RateSectionFooterComponent
    ],
    templateUrl: './discussion-topics-list-by-role.component.html',
    styleUrl: './discussion-topics-list-by-role.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscussionTopicsListByRoleComponent {
    @Input() rating!: ReportSectionRating;
    @Output() onBriefReport = new EventEmitter<DiscussionTopic>();
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();
    @Output() onJobDescription = new EventEmitter<Stakeholder>();

    @Input() title = '';
    @Input() topics!: DiscussionTopics[] | undefined;
    @Input() icon = 1;
    
    @ViewChildren(DiscussionTopicItemComponent) items!: QueryList<DiscussionTopicItemComponent>;

    @HostBinding('class.flat') @Input() flat = false;

    // selectedTopics!: DiscussionTopic[] | undefined;

    ngOnChanges() {
        // this.selectedTopics = this.topics?.filter(x => x.selected) ?? [];
    }

    getSelectedTopics(list: DiscussionTopics) {
        return this._getList(list).filter(x => x.selected);
    }
    

    expandAll() {
        console.log('[DiscussionTopicsListComponent] expandAll');
        this._set(true);
    }

    collapseAll() {
        console.log('[DiscussionTopicsListComponent] collapseAll');
        this._set(false);
    }

    _set(expanded: boolean) {
        this.items.forEach(item => item.expanded = expanded);
    }


    private _getList(list: DiscussionTopics) {
        switch (this.icon) {
            case 1:                
                return list.marketTrends;
            case 2:
                return list.commonProblems;
            case 3:
                return list.processSteps;
        }        
        
        throw Error('Unsupported type ' + this.icon);
    }

    getTitle(role: string) {
        return `${this.title} for ${role} role`;
    }

    jobDescriptionEmit(role: string) {
        this.onJobDescription.emit(<Stakeholder> {
            title: role,
            description: ''
        })
        
    }
}
