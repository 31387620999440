import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {SalesPrepReport} from "../../../../model";
import {DatePipe} from "@angular/common";
import {TimePipe} from "../../../../pipes/time.pipe";
import {ReportSubjectComponent} from "../../../../components/report-subject/report-subject.component";
import {BadgeComponent} from "../../../../components/badge/badge.component";
import {TodayPipe} from "../../../../pipes/today.pipe";
import {CompanyLogoComponent} from "../../../../components/company-logo/company-logo.component";
import {ClickOutsideDirective} from "../../../../directives/click-outside.directive";

@Component({
    selector: 'app-home-report-tile',
    standalone: true,
    imports: [
        DatePipe,
        TimePipe,
        TodayPipe,
        ReportSubjectComponent,
        BadgeComponent,
        CompanyLogoComponent,
        ClickOutsideDirective
    ],
    providers: [
        DatePipe,
        TimePipe,
        TodayPipe
    ],
    templateUrl: './home-report-tile.component.html',
    styleUrl: './home-report-tile.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeReportTileComponent implements OnChanges {
    @Input() report!: SalesPrepReport;
    @HostBinding('class.today') today = false;

    showMenu = false;
    
    @Output() onRemove = new EventEmitter<SalesPrepReport>();

    constructor(private todayPipe: TodayPipe) {
    }
    
    remove(e: MouseEvent) {
        e.stopPropagation();
        e.preventDefault();
        this.onRemove.emit(this.report)
    }

    ngOnChanges(changes: SimpleChanges) {
        const d = this.report.meetingDate;
        this.today = this.todayPipe.transform(d);
    }

    displayMenu(e: MouseEvent) {
        this.showMenu = true;
        e.preventDefault();
        e.stopPropagation();
    }
}
