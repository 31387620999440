import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MainPanelComponent} from "../../../../components/main-panel/main-panel.component";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-pure-confirm-email',
    standalone: true,
    imports: [
        MainPanelComponent,
        SpinnerComponent,
        RouterLink
    ],
    templateUrl: './pure-confirm-email.component.html',
    styleUrl: './pure-confirm-email.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureConfirmEmailComponent {
    @Input() error = '';
    
    @Output() onResendVerificationEmail = new EventEmitter();
}
