import {TextSection} from "../text-section";
import {PdfGenerator} from "../pdf-generator";
import {NewsArticle, Stakeholder} from "../../../model";

export class StakeholderSection {
    private maxWidth = 340.910;

    private titleSection: TextSection;
    private summarySection: TextSection;

    constructor(private pdf: PdfGenerator, private a: Stakeholder, index: number) {
        const title = index + '. ' + a.title.replace(/<\/?[^>]+(>|$)/g, "");
        const summary = a.description.replace(/<\/?[^>]+(>|$)/g, "");

        this.titleSection = pdf.createSection(title, this.maxWidth, 'Raleway-Bold', 12)
        this.summarySection = pdf.createSection(summary, this.maxWidth, 'Raleway-Regular', 9)
    }

    draw(offsetY: number) {
        const pdf = this.pdf;
        const a = this.a;

        offsetY = this.titleSection.draw(pdf, pdf.styles.paddingX, offsetY);
        // offsetY += 18.283;
        return this.summarySection.draw(pdf, pdf.styles.paddingX, offsetY);
    }

    getHeight() {
        return this.pdf.getLineHeight('Raleway-MediumItalic', 9) * 3
            + this.titleSection.getHeight(this.pdf)
            + this.summarySection.getHeight(this.pdf);
    }
}