import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {DiscussionTopic } from "../../../../model";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";
import {ReportGenerationIndicatorComponent} from "../report-generation-indicator/report-generation-indicator.component";

@Component({
    selector: 'app-discussion-topic-item',
    standalone: true,
    imports: [
        SpinnerComponent,
        ReportGenerationIndicatorComponent
    ],
    templateUrl: './discussion-topic-item.component.html',
    styleUrl: './discussion-topic-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscussionTopicItemComponent {
    @Input() expanded = true;
    @Input() topic!: DiscussionTopic;
    @Input() icon = 1;

    @HostBinding('class.flat') @Input() flat = false;
    
    @Output() onBriefReport = new EventEmitter<DiscussionTopic>();
}
