<app-offering-type-icon [type]="type"/>

<div class="desc">
    <div class="title">
        @if (text) {
            <span [innerHTML]="text | formatOfferingCategoryTitle:type" qa="title"></span>
        } @else {
            @switch (type) {
                @case (OfferingType.MaterialOrIngredientOrComponent) {
                    A part of my customers’ products
                }
                @case (OfferingType.EquipmentOrToolOrConsumable) {
                    Used in my customers’ process
                }
                @case (OfferingType.ServiceOrSoftware) {
                    A service or software
                }
                @default {
                    NA: "{{type}}"
                }
            }
        }
    </div>
    <div class="text">
        @if (text) {
            <span [innerHTML]="text | formatOfferingCategoryDesc" qa="text"></span>
        } @else {
            @switch (type) {
                @case (OfferingType.MaterialOrIngredientOrComponent) {
                    Material, ingredient, or component within my customers’ products
                }
                @case (OfferingType.EquipmentOrToolOrConsumable) {
                    Equipment, tool, or consumable used in my customers’ operations
                }
                @case (OfferingType.ServiceOrSoftware) {
                    Service or software used by my customers
                }
                @default {
                    NA: "{{type}}"
                }
            }
        }
    </div>
</div>