import {AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {FooterComponent} from "../../components/footer/footer.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: 'app-contact-us',
    standalone: true,
    imports: [
        MainHeaderComponent,
        MainPanelComponent,
        FooterComponent,
        PageHeaderComponent,
        SpinnerComponent,
        FooterSmartComponent,
    ],
    templateUrl: './contact-us.component.html',
    styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent implements OnInit, AfterViewInit {

    @ViewChild('wrapper', {read: ElementRef}) wrapper!: ElementRef;

    constructor(private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object) {
        const isServer = !isPlatformBrowser(this.platformId);
        if (!isServer) {
            // for now we need to always redirect to the external website
            window.location.href = 'https://theaiminstitute.com/contact-us-2/';
        }
    }

    ngOnInit() {
        
    }

    ngAfterViewInit() {
        if (this.wrapper) {

            const script = this.renderer.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://js.hsforms.net/forms/embed/v2.js';
            
            script.onload = () => {
                (<any>window).hbspt.forms.create({
                    region: "na1",
                    portalId: "2688878",
                    formId: "1e6028ba-dacb-4494-aec8-25a02cffc455",
                    target: "#wrapper"
                });
            };
            script.onerror = () => {
                console.error('Error loading script.');
            };
            this.renderer.appendChild(this.wrapper.nativeElement, script);
        }
    }
}
