import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SalesPrepReport} from "../../../model";
import {DatePipe} from "@angular/common";
import {RolesPipe} from "../../../pipes/roles.pipe";
import {TimePipe} from "../../../pipes/time.pipe";
import {ReportSubjectComponent} from "../../../components/report-subject/report-subject.component";
import {RouterLink} from "@angular/router";
import {DatePickerComponent} from "../../../components/date-picker/date-picker.component";
import {InfoPanelComponent} from "../../../components/info-panel/info-panel.component";
import {IReportFilter, ReportsFilterComponent} from "../reports-filter/reports-filter.component";
import {RoundedButtonComponent} from "../rounded-button/rounded-button.component";
import {ReportsFilterService} from "../services/reports-filter.service";
import {IReportSort, ReportsSortComponent} from "../reports-sort/reports-sort.component";
import {IDateFilterValue} from "../date-filter/date-filter.component";


const LOCAL_STORAGE_FILTERS_KEY = 'reports-filters';
const LOCAL_STORAGE_SORT_KEY = 'reports-sort';

@Component({
    selector: 'app-reports-list',
    standalone: true,
    imports: [
        DatePipe,
        RolesPipe,
        TimePipe,
        ReportSubjectComponent,
        RouterLink,
        InfoPanelComponent,
        ReportsFilterComponent,
        RoundedButtonComponent,
        ReportsSortComponent
    ],
    templateUrl: './reports-list.component.html',
    styleUrl: './reports-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsListComponent implements OnChanges {
    @Input() reports: SalesPrepReport[] = [];
    @Output() onRemove = new EventEmitter<SalesPrepReport>();

    reportsFilter: IReportFilter = ReportsFilterComponent.getEmptyFilters();
    
    _sortedList: SalesPrepReport[] = [];
    sortIndex = 2;
    sortDirection = 1;

    showFilter = false;
    showSort = false;

    constructor(private reportsFilterService: ReportsFilterService) {
        const sort = localStorage.getItem(LOCAL_STORAGE_SORT_KEY);
        if (sort) {
            const s = JSON.parse(sort);
            this.sortIndex = s.sortIndex;
            this.sortDirection = s.sortDirection;
        }
        const filter = localStorage.getItem(LOCAL_STORAGE_FILTERS_KEY);
        if (filter) {
            const f = JSON.parse(filter);
            this.reportsFilter = f;
            this._fixDate(f.lastModifiedFilter, f.lastViewedFilter, f.meetingDateFilter);
        }
    }
    
    get filtered() {
        return this._sortedList.length != this.reports.length;
    }
    
    get sortTitle() {
        return 'SORT BY ' + this._getSortTitle();
    }
    
    ngOnChanges() {
        this._sortedList = [...this.reports];
        
        this._filter();
        this._sort();        
    }

    resetFilter() {
        this.filter(ReportsFilterComponent.getEmptyFilters());        
    }
    
    filter(f: IReportFilter) {
        this.reportsFilter = f;
        this._filter();
        this._sort();
    }
    
    _filter() {
        this._sortedList = this.reportsFilterService.filter(this.reports, this.reportsFilter)
        
        localStorage.setItem(LOCAL_STORAGE_FILTERS_KEY, JSON.stringify(this.reportsFilter));
    }
    
    private _getSortTitle() {
        switch (this.sortIndex) {
            case 1:
                return 'COMPANY';
            case 2:
                return 'MEETING DATE';
            case 3:
                return 'MEETING WITH';
            case 4:
                return 'OFFERING';
            case 5:
                return 'LAST VIEWED';
            case 6:
                return 'LAST MODIFIED';
            default:
                throw Error('Unknown sortIndex');
        }
    }
    private _getSortMethod() {
        switch (this.sortIndex) {
            case 1:
                return this._sortByCompanyName;
            case 2:
                return this._sortByMeetingDateTime;
            case 3:
                return this._sortByRole;
            case 4:
                return this._sortByOffering;
            case 5:
                return this._sortByLastViewed;
            case 6:
                return this._sortByLastModified;
            default:
                throw Error('Unknown sortIndex');
        }
        
    }
    private _sort() {
        
        const sortMethod = this._getSortMethod();
        this._sortedList.sort((a, b) => this.sortDirection * sortMethod(a, b));
        // switch (this.sortIndex) {
        //     case 1:
        //         break;
        //     case 2:
        //         this._sortedList.sort((a, b) => this.sortDirection * this._sortByMeetingDateTime(a, b));
        //         break;                
        // }
    }

    toggleSort(number: number) {
        if (this.sortIndex == number) {
            this.sortDirection = this.sortDirection * -1;
        } else {
            this.sortDirection = 1;
            this.sortIndex = number;
        }

        localStorage.setItem(LOCAL_STORAGE_SORT_KEY, JSON.stringify({
            sortIndex: this.sortIndex,
            sortDirection: this.sortDirection
        }));
        this._sort();
    }

    private _sortByCompanyName(x: SalesPrepReport, y: SalesPrepReport) {
        const a = x.company.name;
        const b = y.company.name;

        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
    }
    
    private _sortByMeetingDateTime(x: SalesPrepReport, y: SalesPrepReport) {
        const a = x.meetingDateTime;
        const b = y.meetingDateTime;
        if (!a) {
            return !b ? 0 : -1;
        }
        if (!b) {
            return 1;
        }
        return a.getTime() - b.getTime();
    }

    
    private _sortByRole(x: SalesPrepReport, y: SalesPrepReport) {
        const a = x.roles[0];
        const b = y.roles[0];

        if (!a) {
            return !b ? 0 : -1;
        }
        if (!b) {
            return 1;
        }

        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
    }
    private _sortByOffering(x: SalesPrepReport, y: SalesPrepReport) {
        const a = x.offeringName;
        const b = y.offeringName;

        if (!a) {
            return !b ? 0 : -1;
        }
        if (!b) {
            return 0;
        }

        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
    }

    private _sortByLastViewed(x: SalesPrepReport, y: SalesPrepReport) {
        const a = x.lastViewed;
        const b = y.lastViewed;

        return a.getTime() - b.getTime();
    }

    private _sortByLastModified(x: SalesPrepReport, y: SalesPrepReport) {
        const a = x.lastUpdated;
        const b = y.lastUpdated;

        return a.getTime() - b.getTime();
    }

    sort(sort: IReportSort) {
        this.sortIndex = sort.sortIndex;
        this.sortDirection = sort.sortDirection;
        this._sort();
        
    }

    private _fixDate(...args: IDateFilterValue[]) {
        args.forEach(x => {
            if (x.date1) x.date1 = new Date(x.date1);
            if (x.date2) x.date2 = new Date(x.date2);
        });
    }

    remove(e: MouseEvent, r: SalesPrepReport) {
        e.stopPropagation();
        e.preventDefault();
        this.onRemove.emit(r);
    }
}
