import {PdfGenerator} from "./pdf-generator";
import {TextSection} from "./text-section";

export class ListSection {
    _lines: TextSection[];
    marginLeft = 10; 
    bulletOffset = 10;
    
    constructor(items: string[], private maxWidth: number, font: string,  size: number) {
        this._lines = items.map(x => new TextSection(x, maxWidth - this.bulletOffset - this.marginLeft, font, size));
    }
    
    getHeight(pdf: PdfGenerator) {        
        return this._lines.reduce((a, b) => a + b.getHeight(pdf), 0);        
    }
    
    draw(pdf: PdfGenerator, x: number, y: number) {
        // const _y = y;
        
        x += this.marginLeft;
        
        this._lines.forEach(listItem => {
            pdf.doc.setFont("Symbol");
            pdf.doc.setFontSize(10);
            pdf.doc.text('·', x, y - 1.5, { baseline: 'top' });
            
            y = listItem.draw(pdf, x + this.bulletOffset, y);
        });

        // pdf.doc.setDrawColor('#00F');
        // pdf.doc.rect(x, _y, this.maxWidth, y - _y);
        
        return y;
    }
}