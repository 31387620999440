import {AfterContentInit, booleanAttribute, Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[appAutofocus]',
    standalone: true
})
export class AutofocusDirective implements AfterContentInit {

    @Input() autofocus: any = undefined;

    constructor(private el: ElementRef) {}

    public ngAfterContentInit() {
        if (this.autofocus === false) {
            console.log('[AutofocusDirective] ', this.el.nativeElement.name, this.autofocus);
            return;
        }

        setTimeout(() => {
            if (document.activeElement != this.el.nativeElement) {
                console.log('[AutofocusDirective] ', this.el.nativeElement.name, this.autofocus);
                this.el.nativeElement.focus();    
            }            
        }, 100);
    }
}
