import {Component, signal} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    standalone: true,
    imports: [],
    templateUrl: './progress-bar.component.html',
    styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {
}
