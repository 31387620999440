<form #form="ngForm" (ngSubmit)="onSubmit.emit(data)">
    <p>
        To get started, all you need to do is enter your <b class="offering">Offering</b> (what you’re selling) and the customer’s
        <b class="job-to-be-done">Application</b> (job-to-be-done with what you’re selling). For example…
    </p>
    
    <div class="examples flex">
        <div>
            <h2 class="offering">My Offering</h2>
            <p>Thickener</p>
            <p>Corrugated boxes</p>
            <p>Hydraulic cylinders</p>
            <p>Bottling Equipment</p>
        </div>
    
        <div class="separator">
            <h2>&nbsp;</h2>
            <p>(Used to) =></p>
            <p>(Used to) =></p>
            <p>(Used to) =></p>
            <p>(Used to) =></p>
        </div>
        <div>
            <h2 class="job-to-be-done">Application</h2>
            <p>Produce paint</p>
            <p>Ship merchandise</p>
            <p>Manufacture backhoes</p>
            <p>Produce soft drinks</p>
        </div>
    </div>
    
    @if (authenticated) {
        <h1 class="ready-to-give-a-try">Ready to give it a try?</h1>
        <p>
           After you click the button, enter 1) your offering, and 2) your application. Then submit your first question to start the interview!
        </p>
        <button type="button" class="primary" [routerLink]="['/ai-guide/start']" qa="play">
            Let's role-play!
        </button>
        
    } @else {
        <p class="offering"><b>My offering is described as (1-3 words):</b></p>
        <input name="offering" type="text" [(ngModel)]="data.offering" placeholder="Enter your offering" required qa="offering">
        <p class="job-to-be-done"><b>The customer will use it to (start with a verb):</b></p>
        <input name="job" type="text" [(ngModel)]="data.jobToBeDone" placeholder="Enter the customer application" required qa="job">
        
        <h1 class="ready-to-give-a-try">Ready to give it a try?</h1>
        <p>
            The first interview question is already entered for you below. So just click the Let’s role-play button to kick off the interview!
            <br><br>
        </p>
        
        <p class="question"><b>My first interview question:</b></p>
        <textarea name="question" type="text" [(ngModel)]="data.question" required qa="first-int"></textarea>
        
        <button type="submit" class="primary" [disabled]="form.invalid" qa="play">
            Let's role-play!
        </button>
    }
</form>