import {Component, Input, signal} from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {PureLoginComponent} from "../login/components/pure-login/pure-login.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {AuthService} from "../../services/auth.service";
import {MessageService} from "../../services/message.service";
import {Router, RouterLink} from "@angular/router";
import {PureSignupComponent, SignUpData} from "./components/pure-signup/pure-signup.component";
import {SignupService} from "../../services/signup.service";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {SignUpMode} from '../../model';
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";

@Component({
    selector: 'app-signup',
    standalone: true,
    imports: [
        MainHeaderComponent,
        MainPanelComponent,
        PureLoginComponent,
        SpinnerComponent,
        PureSignupComponent,
        MainTitleComponent,
        RouterLink,
        FooterCompactComponent
    ],
    templateUrl: './signup.component.html',
    styleUrl: './signup.component.scss'
})
export class SignupComponent {

    @Input() mode = SignUpMode.Trial;

    // SignUpMode = SignUpMode.Monthly;

    errorMessages = signal<string[]>([]);
    loadingProfile = this.authService.loadingProfile;

    constructor(public authService: AuthService,
                private signupService: SignupService,
                public messageService: MessageService, private router: Router) {
    }

    signup(user: SignUpData) {
        console.log('[SignupComponent] signup', user, this.mode);

        user.mode = this.mode;
        
        this.signupService.signup(user).subscribe({
            next: () => {
                this.signupService.signedUpEmail.set(user.email);
                this.router.navigate(['/signup/confirmation']);
            },
            error: err => {
                const errors = err.error?.errors;
                if (!errors) {
                    this.errorMessages.set(['Signup failed'])
                } else {
                    const list: string[] = [];

                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            const errorArray = errors[key];
                            list.push(errorArray);
                        }
                    }
                    this.errorMessages.set(list);
                }
            }
        });
    }

    login() {
        this.router.navigate(['/login']);
    }

    signInWithGoogle() {
        this.authService.signInWithGoogle();

    }

    
}
