import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-retry',
    standalone: true,
    imports: [],
    templateUrl: './retry.component.html',
    styleUrl: './retry.component.scss'
})
export class RetryComponent {

    @Input() title = '';
    @Output() onRetry = new EventEmitter();

}
