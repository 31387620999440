<div class="sp-form">
    <form #searchForm="ngForm" (ngSubmit)="onNewReport(searchForm)">
        <input #searchInput appAutofocus [autofocus]="autofocus" autocomplete="off" placeholder="Enter target company name or URL" name="company" [(ngModel)]="query" 
               (focus)="_focus()"
               (ngModelChange)="queryChange.emit(query)" required qa="search">
    
        <button class="next mobile-layout" type="submit" title="Click to search for company..." qa="submit">
            <svg xmlns="http://www.w3.org/2000/svg" width="21.243" height="14.581" viewBox="0 0 21.243 14.581">
                <path id="Icon_ionic-ios-arrow-round-back" data-name="Icon ionic-ios-arrow-round-back" d="M20.792,11.51a.919.919,0,0,0-.007,1.294l4.268,4.282H8.789a.914.914,0,0,0,0,1.828H25.053L20.777,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.027,1.027,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.792,11.51Z" transform="translate(-7.382 -10.752)" stroke-width="1"/>
            </svg>
        </button>

        <button class="search" (click)="searchInput.focus()" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Icon_open-magnifying-glass" data-name="Icon open-magnifying-glass" d="M10.406-.015a10.406,10.406,0,1,0,0,20.812,10.285,10.285,0,0,0,4.935-1.219,2.973,2.973,0,0,0,.386.386L18.7,22.937a3.033,3.033,0,1,0,4.281-4.281l-2.973-2.973a2.973,2.973,0,0,0-.476-.386,10.266,10.266,0,0,0,1.308-4.935A10.417,10.417,0,0,0,10.435-.045Zm0,2.973a7.4,7.4,0,0,1,7.433,7.433A7.455,7.455,0,0,1,15.876,15.5l-.089.089a2.973,2.973,0,0,0-.386.386,7.44,7.44,0,0,1-5.024,1.873,7.433,7.433,0,1,1,0-14.865Z" transform="translate(0 0.045)" fill="#00376f"/>
            </svg>
        </button>
    </form>
</div>
