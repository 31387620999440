import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
    selector: 'app-rounded-button',
    standalone: true,
    imports: [],
    templateUrl: './rounded-button.component.html',
    styleUrl: './rounded-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundedButtonComponent {
    
    @Input() icon = '';
    @Input() text = '';

    @HostBinding('class.primary')
    @Input() primary = false;
    
    @Input() cancellable = false;
    @Input() cancellableTitle = '';
    
    
    @Output() onCancel = new EventEmitter();

    cancel(e: MouseEvent) {
        e.stopPropagation();
        e.preventDefault();
        this.onCancel.emit()
    }
}
