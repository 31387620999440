import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReportSectionRating} from "../../model";

@Component({
    selector: 'app-rate-section-footer',
    standalone: true,
    imports: [],
    templateUrl: './rate-section-footer.component.html',
    styleUrl: './rate-section-footer.component.scss'
})
export class RateSectionFooterComponent {
    rateAgain = false;
    
    @Input() rating!: ReportSectionRating;
    
    @Output() ratingChange = new EventEmitter<ReportSectionRating>();

    set(rating: boolean) {
        this.rateAgain = false;
        this.rating.rating = rating;
        this.ratingChange.emit(this.rating);
    }
}
