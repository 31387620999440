import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {OfferingType} from "../../../../model/offeringType";
import {OfferingTypeComponent} from "../offering-type/offering-type.component";
import {OfferingCategory} from "../../../../model";
import {FormatOfferingCategoryPipe} from "../../../../pipes/format-offering-category.pipe";

@Component({
    selector: 'app-select-offering-category',
    standalone: true,
    imports: [
        OfferingTypeComponent,
        FormatOfferingCategoryPipe
    ],
    templateUrl: './select-offering-category.component.html',
    styleUrl: './select-offering-category.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectOfferingCategoryComponent implements OnChanges {
    @Input() type!: OfferingType;
    @Input() categories: OfferingCategory[] = [];
    
    group1: OfferingCategory[] = [];
    group2: OfferingCategory[] = [];
    
    @Output() categorySelected = new EventEmitter<OfferingCategory>();

    OfferingType = OfferingType;
    
    ngOnChanges(changes: SimpleChanges) {
        if (this.type == OfferingType.ServiceOrSoftware) {
            this.group1 = this.categories.filter(c => c.name.endsWith('services'));
            this.group2 = this.categories.filter(c => c.name.endsWith('software'));
        } else {
            this.group1 = this.categories;
        }
    }
}
