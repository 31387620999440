import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OfferingsListComponent} from "../offerings-list/offerings-list.component";
import {RouterLink} from "@angular/router";
import { Offering } from '../../../../model/offering';

@Component({
    selector: 'app-pure-my-offerings',
    standalone: true,
    imports: [
        OfferingsListComponent,
        RouterLink
    ],
    templateUrl: './pure-my-offerings.component.html',
    styleUrl: './pure-my-offerings.component.scss'
})
export class PureMyOfferingsComponent {
    @Input() offerings: Offering[] | undefined;
    @Input() signUpMode = false;
    
    @Output() onAdd = new EventEmitter();
    @Output() onEdit = new EventEmitter<Offering>();
    @Output() onDelete = new EventEmitter<Offering>();
}
