import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {inject} from "@angular/core";
import {catchError, map, of, tap} from "rxjs";

export const authenticatedGuard: CanActivateFn = (route, state) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    
    if (authService.authenticated()) {
        const p = authService.userProfile();
        if (p && !p.subscriptionExpired) {
            return true;
        }
    }
    
    return authService.userProfile$.pipe(
        map(x => {
            if (!x) {
                router.navigate(['/login']);
                return false;
            }

            if (x.subscriptionExpired) {
                if (!x.company) {
                    // new subscriber, let's route to stripe for account confirmation
                    window.location.href = '/api/user/stripe-subscribe';
                } else {
                    router.navigate(['/subscription-expired']);
                }
                return false;                
            }
            
            return true;
        })
    );
};
