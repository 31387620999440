<app-pure-exploratory-questions
        (onSkip)="skip()"
        (onGenerate)="generate($event)"
        (onBack)="exploratoryQuestions.set([])"
        (onNext)="next($event)"
        (onAdd)="add()"

        [company]="company()!"
        [product]="product()!"
        [job]="job()!"

        [exploratoryQuestionsLoading]="exploratoryQuestionsLoading()"
        [exploratoryQuestionsLoadingFailed]="exploratoryQuestionsLoadingFailed()"
        [questions]="exploratoryQuestions()"
    />