<div class="steps" qa="steps-div">
    <div class="step step1" qa="st1-d"></div>
    <div class="step step2"></div>
    <div class="step step3"></div>
    <div class="step step4"></div>
    <div class="step step5"></div>
    <div class="step step6"></div>
</div>
<div class="progress step{{step}}">
    <div class="bar"></div>
</div>
<div class="progress-step step{{step}}">
    <div class="step"></div>
</div>