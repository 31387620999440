@if (pricingMode) {
    <h3>USE SALESPREP TO…</h3>
} @else {
    <h3>COMPARISON GRID</h3>
}

<div class="grid">
    
    <div class="rows">
        
<!--        <div class="header">-->
<!--            -->
<!--        </div>-->
        
        <div class="row header desktop">
            <div class="step">
                @if (pricingMode) {
                    <h1>12 ways to use SalesPrep</h1>
                    <div style="font: normal normal 16px/20px Raleway;margin-top: 24px;max-width: 380px">SalesPrep can do a lot more than just increase sales. Use it to…</div>
                } @else {
                    <h1>SalesPrep can do a lot more than just increase sales</h1>
                    <a class="sp-button primary sp-desktop" target="_blank" href="/api/resources/download?type=TwelveWays">Download Comparison Grid PDF</a>
                }
            </div>
            <div class="title">SALESPREP REPORT</div>
            <div class="title ai">AI ROLE-PLAY</div>
            <div class="report">
                @for (title of reportTitle; track title) {
                    <div>{{ title }}</div>
                }
            </div>
            <div class="role-play">
                @for (title of rolePlayTitle; track title) {
                    <div>{{ title }}</div>
                }
            </div>
        </div>
        <div class="row header tablet">
            <h1>SalesPrep can do a lot more than just increase sales</h1>
        </div>
        <div class="row tablet">
            <a class="sp-button primary sp-desktop" target="_blank" href="/api/resources/download?type=TwelveWays">Download Comparison Grid PDF</a>
            
            <div>
                <app-checkmark/>
                SALESPREP REPORT
            </div>
            <div class="ai">
                <app-checkmark [ai]="true"/>
                AI ROLE-PLAY
            </div>

        </div>

        @for (step of steps; track step.title; let i = $index; let odd = $odd) {

            <div class="row" [class.odd]="odd" [attr.qa]="'row-' + (i + 1)">
                <div class="step" qa="step">
                    <div class="num" qa="num">{{ i + 1}}</div>
                    <div class="flex-column">
                        <div class="title" qa="tit">{{ step.title }}</div>
                        <div class="description" [innerHTML]="step.description" qa="desc"></div>
                    </div>
                </div>
                
                <div class="report">
                    @for (x of step.report; track x; let i = $index) {
                        <div [class.checked]="x" [attr.qa]="'rep-' + (i + 1)">
                            @if (x) { <app-checkmark /> }
                            <span class="tablet">{{ reportTitle[i] }}</span>
                        </div>
                    }
                </div>
                
                <div class="role-play">
                    @for (x of step.ai; track x; let i = $index) {
                        <div [class.checked]="x" [attr.qa]="'ai-' + (i + 1)">
                            @if (x) { <app-checkmark [ai]="true" /> }
                            <span class="tablet">{{ rolePlayTitle[i] }}</span>
                        </div>
                    }
                </div>
            </div>            
        }
    </div>
    
    

</div>