<div class="left">
    <h1>Check your email...</h1>
    
    <div>
        To finish creating your subscription, please click the “Verify Email Address” link in the message 
        you will receive shortly.

        <app-note>
            Look for an email from <b>SalesPrep</b>
            &lt;<span class="email">postmaster{{'@'}}salesprep.com</span>&gt;
            within the next couple of minutes with the subject: 
            <b>Action Required: Please Confirm Your Account at SalesPrep.com</b>
        </app-note>
        
        <div class="bold">
            Didn’t get an email from SalesPrep?<br> 
            Check your spam/junk folder. 
        </div>
        
        <div><br>
            If you still can’t find it, first whitelist <u><span class="email">postmaster{{'@'}}salesprep.com</span></u>
            then click the link below to request a new verification email:<br>
            <a class="sp-link" (click)="onResendConfirmationEmail.emit()"><u>Resend Confirmation Email</u></a>.
        </div>
        
    </div>
</div>