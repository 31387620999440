<app-select-job-form 
        [aiGuideMode]="true"
        [step]="'2'"
        [offering]="offering"
        [company]="company"
        (onSelect)="onSelect.emit($event)"
        (onBack)="back()"
        (onAddNew)="addNew = true; job = ''"
    />

@if (addNew) {
    <app-info-panel [title]="'Add job-to-be-done'" (onClose)="addNew = false">
        <form (ngSubmit)="onAddJob.emit(job);addNew = false" class="sp-form sp-form-alternative">
            <label for="job">Customers use this offering {{ offering.type | offeringAction:true }} {{ offering.type | offeringActionTip}}</label>
            <input appAutofocus type="text" id="job" name="job" [(ngModel)]="job">
            <button class="primary">Add Job-to-be-done</button>
        </form>
    </app-info-panel>
}