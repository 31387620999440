import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-novice-actions',
    standalone: true,
    imports: [],
    templateUrl: './novice-actions.component.html',
    styleUrl: './novice-actions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoviceActionsComponent {
    @Input() loading = false;
    @Input() completed = false;
    @Input() nextSteps = false;
    @Output() onNext = new EventEmitter();
}
