import {Component, OnInit} from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {RouterLink} from "@angular/router";
import {ElearningService} from "../../services/elearning.service";
import {ModulesListComponent} from "./components/modules-list/modules-list.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";

@Component({
    selector: 'app-e-learning',
    standalone: true,
    imports: [
        MainHeaderComponent,
        MainPanelComponent,
        MainTitleComponent,
        RouterLink,
        ModulesListComponent,
        PageHeaderComponent,
        FooterCompactComponent
    ],
    templateUrl: './e-learning.component.html',
    styleUrl: './e-learning.component.scss'
})
export class ELearningComponent implements OnInit {

    modules = ElearningService.modules;
    modulesStatuses = this.eLearningService.modulesStatuses;
    
    constructor(private eLearningService: ElearningService) {
    }
    
    ngOnInit() {
        this.eLearningService.reloadELearningData();
    }

}
