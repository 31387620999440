@for (message of _messages; track message.text; let i = $index) {
    <div class="role-{{message.role}}" (click)="onClick($event, message.text)" qa="roles" [attr.qa]="'role-' + (i + 1)">
        @if (message.role == 'customer') {
            <app-user-avatar class="customer" [initials]="'C'"/>
        } @else if (message.role == 'user') {
            <app-user-avatar class="user" [initials]="userInitials"/>
        } @else if (message.role == 'coach') {
            <app-tutor-avatar class="coach" />
        }
        
        <span [innerHTML]="message.text" qa="txt"></span>
                
        <!--@if (message.role == 'coach' && message.questionType) {
            <p qa="p-quest"><br>
                Question Type: {{message.questionType}}<br>
                Question Quality: {{message.questionQuality}}
            </p>            
        }-->
    </div>
}

@if (loading || messages.length != _messages.length) {
    <div>
        <span class="material-symbols-outlined sp-spin" qa="pr-chat">progress_activity</span>
    </div>    
} 
