import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Offering} from "../../../../../../model";
import {StepButtonsComponent} from "../../../../components/step-buttons/step-buttons.component";

@Component({
    selector: 'app-select-offering-form',
    standalone: true,
    imports: [
        FormsModule,
        StepButtonsComponent
    ],
    templateUrl: './select-offering-form.component.html',
    styleUrl: './select-offering-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectOfferingFormComponent {
    @Input() step = '2';
    @Input() title = '';
    @Input() offerings: Offering[] = [];
    
    @Input() backVisible = true;

    @Input() aiGuideMode = false;
        
    @Output() onSelect = new EventEmitter<Offering>();
    @Output() onAddNew = new EventEmitter();
    @Output() onBack = new EventEmitter();
    @Output() onSelectReport = new EventEmitter();
    
    selectedIndex = -1;
}
