<div class="header" qa="rec-e">
    RECOMMENDED E-LEARNING
</div>

@for (module of modules; track module.number; let index = $index) {

    <div class="module" [attr.qa]="'mod-' + (index + 1)">
        <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="16.956" viewBox="0 0 21.75 16.956">
            <path id="Icon_awesome-graduation-cap" data-name="Icon awesome-graduation-cap" d="M19.448,8.031,10.731,4.638a2,2,0,0,0-1.462,0L.552,8.031a1.014,1.014,0,0,0,0,1.8l1.52.591a3.638,3.638,0,0,0-.559,1.856A1.328,1.328,0,0,0,1,13.367a1.355,1.355,0,0,0,.433,1.015l-.8,4.547c-.069.4.168.771.488.771H2.877c.32,0,.558-.375.488-.771l-.8-4.547A1.355,1.355,0,0,0,3,13.367,1.335,1.335,0,0,0,2.511,12.3a2.021,2.021,0,0,1,.647-1.453l6.112,2.379a2,2,0,0,0,1.462,0l8.717-3.392a1.014,1.014,0,0,0,0-1.8Zm-8.423,6.408a2.8,2.8,0,0,1-2.05,0L4.443,12.675,4,17.167c0,1.4,2.686,2.533,6,2.533s6-1.134,6-2.533l-.443-4.491Z" transform="translate(0.875 -3.619)" fill="none" stroke="#037eff" stroke-width="1.75"/>
        </svg>
    
        <div class="content" qa="mod-cont">
            @if (authenticated) {
                <a href="/api/user/elearning-session/{{module.number}}" class="title sp-link" target="_blank">
                    <span class="module-name">Module {{ module.number }}:</span> {{ module.title }}
                </a>
            } @else {
                <span class="title">
                    <span class="module-name">Module {{ module.number }}:</span> {{ module.title }}
                </span>
            }
            <div class="description">
                {{ module.description }}
            </div>
        </div>
    </div>
}