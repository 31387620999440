import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'time',
    standalone: true
})
export class TimePipe implements PipeTransform {
    transform(time: string | undefined, ...a: unknown[]): string {
        if (!time) return '';

        const args = time.split(':');
        const h = +args[0];
        const m = args[1];

        if (h > 12) {
            return `${h - 12}:${m.padStart(2, '0')} PM`;
        } else {
            return `${h}:${m.padStart(2, '0')} AM`;
        }
    }
}
