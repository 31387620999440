import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-note',
    standalone: true,
    imports: [],
    templateUrl: './note.component.html',
    styleUrl: './note.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteComponent {

}
