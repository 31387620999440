import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'app-pure-faq',
    standalone: true,
    imports: [],
    templateUrl: './pure-faq.component.html',
    styleUrl: './pure-faq.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureFaqComponent {
    @HostBinding('class.splash-mode') @Input() splashMode = false;
    
    visibility: boolean[] = [];
    @HostBinding('class.sp-typography') t = true;

    toggle(number: number) {
        this.visibility[number] = !this.visibility[number]; 
    }

    visible(number: number) {
        return this.visibility[number];
    }
}
