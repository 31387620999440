import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {OfferingActionPipe} from "../../../../pipes/offering-action.pipe";
import {Offering} from "../../../../model/offering";
import {RouterLink} from "@angular/router";
import {OfferingTypeIconComponent} from "../../../../components/offering-type-icon/offering-type-icon.component";

@Component({
    selector: 'app-offerings-list',
    standalone: true,
    imports: [
        OfferingActionPipe,
        RouterLink,
        OfferingTypeIconComponent
    ],
    templateUrl: './offerings-list.component.html',
    styleUrl: './offerings-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferingsListComponent {
    @Input() offerings: Offering[] = [];
    
    @Output() onEdit = new EventEmitter<Offering>();
    @Output() onDelete = new EventEmitter<Offering>();
}
