import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {ELearningModule} from "../../../../services/elearning.service";

@Component({
    selector: 'app-module',
    standalone: true,
    imports: [],
    templateUrl: './module.component.html',
    styleUrl: './module.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleComponent {
    @Input() module!: ELearningModule;

    @HostBinding('class.thumb-only') @Input() thumbOnly = false;
    
    @HostBinding('class.completed') @Input() completed = false;
    @HostBinding('class.inProgress') @Input() inProgress = false;
}
