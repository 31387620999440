import {IReportPart} from "../pdf-export/ireport-part";
import {MyProblemBriefReportGenerator} from "./problem-brief-report-generator";
import {BriefReportItem} from "../../model";
import {BriefCitationSection} from "./brief-citation-section";
import {jsPDF} from "jspdf";

export class BriefCitationsSection implements IReportPart {
    
    private paddingTop = 18.2;
    private paddingBottom = 24;
    constructor(private pdf: MyProblemBriefReportGenerator, private citations: BriefReportItem[]) {
    }

    draw(y: number) {
        const pdf = this.pdf;
        let items = this.citations;
        if (!items.length) {
            return 0;
        }

        const sections = items.map((c, i) => new BriefCitationSection(pdf, c, i  + 1));
        
        if (y + this.paddingTop  + this.paddingBottom + pdf.subHeadingHeight() + sections[0].getHeight() > pdf.pageHeight) {
            y = pdf.addPage();
        }

        let height = 
            this.paddingTop +
            pdf.subHeadingHeight() + 
            sections.map(s => s.getHeight()).reduce((a, b) => a + b) +
            this.paddingBottom; 

        this._drawBackground(pdf.doc, y, Math.min(height, pdf.pageHeight - y));

        pdf.doc.setTextColor(this.pdf.styles.textColor);        
        y = pdf.subHeading(y + this.paddingTop, 'Citations');

        let secondPageSections: BriefCitationSection[] = [];
        pdf.doc.setTextColor(this.pdf.styles.linkColor);
        
        for (let i = 0; i < sections.length; i++){
            const s = sections[i];
            
            if (y + 1 /* a smaller padding bottom  */ + s.getHeight()  > pdf.pageHeight) {
                secondPageSections = [...sections].slice(i);
                break;                
            }
            
            y = s.draw(y);
        }

        if (secondPageSections.length > 0) {
            y = pdf.addPage() + this.paddingBottom;
            
            height =
                this.paddingBottom +
                secondPageSections.map(s => s.getHeight()).reduce((a, b) => a + b) +
                this.paddingBottom;
            
            this._drawBackground(pdf.doc, pdf.styles.paddingY, height);

            secondPageSections.forEach(s => y = s.draw(y));
        }
        
        return y;
    }
    
    private _drawBackground(doc: jsPDF, top: number, height: number) {
        doc.setFillColor('def4fc');
        doc.rect(45.000, top, 522, height, 'F');
    }
}