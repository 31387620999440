import {PdfGeneratorBase} from "../../pdf-export";

export class TextSection {
    hasMore = false;
    
    lineHeight = 1.2;
    _lines!: string[];
    _lineHeight = 0;
    
    _drawnIndex = 0;
    constructor(private pdf: PdfGeneratorBase, private text: string, private maxWidth: number, private font: string, private size: number) {
        let doc = pdf.doc;
        doc.setFont(this.font);
        doc.setFontSize(this.size);
        this._lineHeight = doc.getLineHeight();
        this._lines = doc.splitTextToSize(this.text, this.maxWidth);
    }
    
    draw(x: number, y: number, maxHeight: number) {
        let doc = this.pdf.doc;
        
        for (let i = this._drawnIndex; i < this._lines.length; i++) {
            
            if (y > maxHeight) {
                this._drawnIndex = i;
                this.hasMore = true;
                break;
            }

            doc.text(this._lines[i], x, y, { baseline: 'top' });
            y += this._lineHeight * this.lineHeight;
            
        }
        
        return y;
    }
}