<form #companyForm="ngForm" (ngSubmit)="submit(!companyForm.invalid)">

    <div class="sp-form sp-form-alternative">

        <h1>Adding Company</h1>

        <label for="name">Company Name</label>
        <input appAutofocus type="text" id="name" [(ngModel)]="company.name" required name="name" (ngModelChange)="companyChange.emit(company)">

        <label for="name">Website</label>
        <input type="text" id="website" [(ngModel)]="company.website" name="website" (ngModelChange)="companyChange.emit(company)">

        <button type="submit" style="visibility: collapse"></button>
    </div>
</form>