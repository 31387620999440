import {Pipe, PipeTransform} from '@angular/core';
import {OfferingType} from "../model";

@Pipe({
    name: 'offeringActionTip',
    standalone: true
})
export class OfferingActionTipPipe implements PipeTransform {

    transform(type: OfferingType | undefined, ...args: unknown[]): unknown {
        if (!type) return '';
        
        if (type == OfferingType.MaterialOrIngredientOrComponent) {
            return '(use nouns)';
        }

        return '(start with verbs)';
    }

}
