import {PdfGenerator} from "../pdf-generator";

export class NotesSection {

    draw(pdf: PdfGenerator, x: number, y: number, maxY: number) {
        pdf.text('NOTES', x, y, 'Raleway-ExtraBold', 9);

        y += 33.828;

        while (y < maxY) {
            pdf.line(x, y, 162, "#d3d2d2", .5);
            y += 19.136;
        }
    }
}