<app-main-header class="sp-desktop"/>

<app-main-title class="mobile-layout" (onAction)="showHome()" [customAction]="true">
    Role Play with Claire —
    @if (job) {
        @switch (tutorLevel) {
            @case (TutorCoachingLevels.Beginner) {
                <span class="novice">Novice</span>
            }
            @case (TutorCoachingLevels.Intermediate) {
                <span class="intermediate">Intermediate</span>
            }
            @case (TutorCoachingLevels.Expert) {
                <span class="expert">Expert</span>
            }
        }
    } @else {
        your AI Guide
    }
</app-main-title>


<app-page-header class="desktop-layout" 
                 [title]="'Role-play with Claire — your AI Guide'" 
                 [text]="subject">
    <div class="claire">
        <div class="claire-wrapper">
            <app-tutor-avatar />
    
            @if (!job) {
                <div>
                    Hello! I’m Claire and I’ll be your AI Guide in this practice session. I’ll offer just the amount of guidance that you’d like. 
                    
                    <br><br>If you want a little refresher on how the role-playing works, 
                    <a class="sp-link" routerLink="/ai-guide/try">click here</a>.
                </div>
            }
        </div>
    </div>
</app-page-header>

<div class="container">
    <div class="wrapper">
        @if (job) {
            @if (!tutorService.started()) {
                <app-main-panel class="sp-form select-level-header mobile-layout" >
                    <div class="claire-mobile-header">
                        <app-tutor-avatar />
    
                        @if (offering) {
                            <div>                            
                                For this session, you'll practice offering <span class="extra-bold">{{offering.name}}</span> @if (job) { {{offering.type | offeringAction}} {{job}} }.
                                @if (tutorService.roles().length > 1) {
                                    <br>Customer Roles: 
                                    @for (role of tutorService.roles(); track role; let last = $last) {
                                        {{ role }}@if (!last) {,}
                                    }
                                }
                            </div>
                        }
                    </div>
                </app-main-panel>
            }
            
            <app-pure-tutor-chat
                (onStart)="sendToTutor($event)"
                (onSend)="sendToTutor($event)"
    
                (onRolePlayAgain)="rolePlayAgain()"
                
                [inlineMode]="true"
                [authenticated]="authenticated()"
                [userInitials]="userInitials()"
                [(coachingLevel)]="tutorLevel"
        
                [offeringName]="offering!.name"
                [offeringType]="offering!.type"
                [job]="job"
    
                [what]="tutorService.numWhat()"
                [why]="tutorService.numWhy()"
                [clarify]="tutorService.numClarify()"
                [problem]="tutorService.numProblem()"
                
                [started]="tutorService.started()"
                [completed]="tutorService.completed()"
                [starting]="tutorService.starting()"
                [loading]="tutorService.loading()"
                [messages]="tutorService.messages()"
                
                [advicesLoading]="tutorService.advicesLoading()"
                [advices]="tutorService.advices()"
                [strengths]="tutorService.strengths()"
            />
        } @else {
            <app-main-panel class="sp-form">
                <div class="claire-mobile-header mobile-layout">
                    <app-tutor-avatar />

                    @if (!job) {
                        <div>
                            Hello! I’m Claire and I’ll be your AI Guide in this practice session. I’ll offer just the amount of guidance that you’d like.

                            <br><br>If you want a little refresher on how the role-playing works,
                            <a class="sp-link" routerLink="/try-ai-guide">click here</a>.
                        </div>
                    }
                </div>
                
                @if (reportMode) {
                    @if (reportsLoading()) {
                        <app-spinner/>
                    } @else {
                        <app-select-report 
                                (onSelectOffering)="reportMode = false"
                                (onSelectReport)="selectReport($event)"
                                [reports]="reports()" />
                    }
                } @else {
                    @if (offering) {                
                            <app-select-job 
                                (onSelect)="setJob($event)" 
                                (onSkip)="setJob({job: '_', roles: []})"
                                (onAddJob)="addJob($event)"
                                [offering]="offering"
                            />
                    } @else {
                        @if (userProfile()) {
                            <app-select-offering 
                                    (onSelectReport)="reportMode = true"
                                    (onSelectOfferingType)="setCategories($event)"
                                    (onSelect)="setOffering($event)"
                                    (onAdd)="addOffering($event)"
                                    [categories]="categories()"
                                    [offerings]="offerings()" />
                        }
                    }
                }
            </app-main-panel>
        }
    </div>
</div>

<!--<app-footer-compact/>-->