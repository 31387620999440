import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'roles',
    standalone: true
})
export class RolesPipe implements PipeTransform {

    transform(roles: string[] | undefined): unknown {
        if (!roles) {
            return '';
        }
        
        return roles.join(', ');
    }
}
