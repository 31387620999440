<h3>E-LEARNING</h3>

<h1>Develop new skills with E-learning</h1>

<div class="content">

    <div class="left">
        <p>
            Quickly accessing raw information is just half the battle. It’s what you <em>do</em> with it in your customer meeting that
            really counts.
        </p>

        <blockquote class="first">
            What questions will you ask? How will you ask them? How can you probe “below the surface”?
        </blockquote>

        <blockquote class="second">
            What fresh ideas will you challenge your customer with? When and how will you do this?
        </blockquote>


        <p>
            This is where our e-learning helps.
            For 20 years, the AIM Institute has refined B2B-optimized methods in tens of thousands of B2B customer visits.
            Our <b>What-Why-Clarify</b> questions, <b>SALT</b> probing, <b>PEAR</b> listening, and <b>AMUSE</b> observation
            are the most advanced B2B methods today.
            Now you can learn them through self-paced
            @if (authenticated) {
                <a [routerLink]="['/e-learning']" class="sp-link">e-learning</a> 
            } @else {
                e-learning
            }

            (included with SalesPrep subscription).
        </p>

    </div>

    <div class="right">
        <h2 class="center">
            Learn better interview techniques with<br>
            self-paced e-Learning.
            Watch the

            <span class="wistia_embed wistia_async_0isgqw9qww popover=true popoverContent=link videoFoam=false" style="display:inline;position:relative"><a href="#">6-1/2 minute preview</a></span>.
        </h2>

        <div class="video" title="Preview video of SalesPrep e-learning modules">
            <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><span class="wistia_embed wistia_async_0isgqw9qww popover=true videoFoam=true" style="display:inline-block;height:100%;position:relative;width:100%">&nbsp;</span></div></div>
        </div>
    </div>

</div>
    