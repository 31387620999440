@for (offering of offerings; track offering.id; let i = $index) {
    <div class="offering sp-selectable" (click)="onEdit.emit(offering)" [attr.qa]="'offer-' + (i + 1)">
        <app-offering-type-icon [type]="offering.type" qa="off-type-app"/>
        <div qa="off-type">
            <div class="title"> <span class="name" qa="off-name">{{offering.name}}</span> <span qa="off-act"> {{offering.type | offeringAction}}</span> </div>
            <ul>
                @for (job of offering.jobs; track job; let i = $index) {
                    <li class="job" [attr.qa]="'job-' + (i + 1)">{{job}}</li>
                }
            </ul>
        </div>
    </div>
}