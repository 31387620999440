import {Component} from '@angular/core';
import {
    PureExploratoryQuestionsComponent
} from "./components/pure-exploratory-questions/pure-exploratory-questions.component";
import {Router} from "@angular/router";
import {ExploratoryQuestion} from "../../../../model";
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {
    PureSetMeetingDateComponent
} from "../set-meeting-date/components/pure-set-meeting-date/pure-set-meeting-date.component";

@Component({
    selector: 'app-exploratory-questions',
    standalone: true,
    imports: [
        PureExploratoryQuestionsComponent,
        PureSetMeetingDateComponent
    ],
    templateUrl: './exploratory-questions.component.html',
    styleUrl: './exploratory-questions.component.scss'
})
export class ExploratoryQuestionsComponent {

    exploratoryQuestionsLoading = this.report.exploratoryQuestionsLoading;
    exploratoryQuestionsLoadingFailed = this.report.exploratoryQuestionsLoadingFailed;
    exploratoryQuestions = this.report.exploratoryQuestions;

    company = this.report.company;
    product = this.report.offering;
    job = this.report.job;

    constructor(private report: ReportBuilderService, private router: Router) {
        if (!report.job()) {
            const o = report.offering();
            if (o?.jobs?.length) {
                this.router.navigate(['/report-builder', 'job']);
            } else {
                report.job.set('_');
            }
        }
    }

    skip() {
        this.router.navigate(['/report-builder', 'date']);
    }

    generate(benefits: string[]) {
        console.log('[ExploratoryQuestionsComponent] Generate', benefits);
        this.report.generateExploratoryQuestions(benefits).subscribe(() => console.log('[ExploratoryQuestionsComponent] generated successfully.'));
    }

    next(questions: ExploratoryQuestion[]) {
        console.log('[ExploratoryQuestionsComponent] next', questions);
        this.skip();
    }

    add() {
        this.exploratoryQuestions.update(x => [...x, {
            edit: true,
            selected: true,
            text: ''
        }]);
    }
}
