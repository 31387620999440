import {Component, computed, OnDestroy, signal} from '@angular/core';
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {DiscussionTopicsListComponent} from "./components/discussion-topics-list/discussion-topics-list.component";
import {ReportBuilderHeaderComponent} from "../../components/report-builder-header/report-builder-header.component";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";
import {MainPanelComponent} from "../../../../components/main-panel/main-panel.component";
import {
    PureSelectDiscussionTopicsComponent
} from "./components/pure-select-discussion-topics/pure-select-discussion-topics.component";
import {DiscussionTopic, ReportSectionRating, ReportSectionType} from "../../../../model";
import {RatingService} from "../../../../services/rating.service";
import {InfoPanelComponent} from "../../../../components/info-panel/info-panel.component";
import {RateSectionDialogComponent} from "../../../../components/rate-section-dialog/rate-section-dialog.component";
import {Subject, takeUntil} from "rxjs";

@Component({
    selector: 'app-select-discussion-topics',
    standalone: true,
    imports: [
        DiscussionTopicsListComponent,
        PureSelectDiscussionTopicsComponent,
        InfoPanelComponent,
        RateSectionDialogComponent
    ],
    templateUrl: './select-discussion-topics.component.html',
    styleUrl: './select-discussion-topics.component.scss'
})
export class SelectDiscussionTopicsComponent implements OnDestroy {
    company = this.reportBuilderService.company;
    companyDataLoading = this.reportBuilderService.companyDataLoading;
    companyDataLoadingFailed = this.reportBuilderService.companyDataLoadingFailed;
    
    companyNewsLoading = this.reportBuilderService.companyNewsLoading;
    companyNewsLoadingFailed = this.reportBuilderService.companyNewsLoadingFailed;

    stakeholdersEnabled = this.reportBuilderService.stakeholdersEnabled;
    stakeholdersLoading = this.reportBuilderService.stakeholdersLoading;
    stakeholdersLoadingFailed = this.reportBuilderService.stakeholdersLoadingFailed;
    stakeholders = this.reportBuilderService.stakeholders;
    
    product = this.reportBuilderService.offering;
    job = this.reportBuilderService.job;
    reportData = this.reportBuilderService.reportData;

    discussionTopics = this.reportBuilderService.discussionTopics;
    otherTopics = this.reportBuilderService.otherTopics;

    marketTrendsLoading = this.reportBuilderService.marketTrendsLoading;
    commonProblemsLoading = this.reportBuilderService.commonProblemsLoading;
    processStepsLoading = this.reportBuilderService.processStepsLoading;

    marketTrendsLoadingFailed = this.reportBuilderService.marketTrendsLoadingFailed;
    commonProblemsLoadingFailed = this.reportBuilderService.commonProblemsLoadingFailed;
    processStepsLoadingFailed = this.reportBuilderService.processStepsLoadingFailed;

    companyNewsRating!: ReportSectionRating;
    stakeholdersRating!: ReportSectionRating;
    marketTrendsRating!: ReportSectionRating;
    commonProblemsRating!: ReportSectionRating;
    processStepsRating!: ReportSectionRating;
    otherTopicsRating!: ReportSectionRating;
    
    selectedCustomDiscussionTopic: DiscussionTopic | undefined;
    badReportSectionRating: ReportSectionRating | undefined;
    
    loading = computed(() => {
        if (this.newsMode()) {
            return this.companyNewsLoading();
        } else {
            return this.marketTrendsLoading()
                || this.commonProblemsLoading()
                || this.processStepsLoading();
        }
    });

    generatingReport = this.reportBuilderService.generatingReport;
    companyData = this.reportBuilderService.companyData;
    newsArticles = this.reportBuilderService.newsArticles;

    reportLoaded = computed(() => {
        const reportData = this.reportData();
        return !!reportData
            && this.company()?.crunchbasePermalink == reportData?.company?.crunchbasePermalink;
    });

    reportGenerated = computed(() => {
        const reportData = this.reportData();
        return !!reportData
            && this.company()?.crunchbasePermalink == reportData!.company?.crunchbasePermalink
            && !reportData!.discussionTopics.filter(x => 
                !x.marketTrends.length
                || !x.commonProblems.length
                || !x.processSteps.length
            ).length
    })
    
    
    newsMode = signal(false);
    private _destroy$ = new Subject<void>();
    constructor(private reportBuilderService: ReportBuilderService,
                private ratingService: RatingService,
                route: ActivatedRoute,
                private router: Router) {
        
        route.url.pipe(
            takeUntil(this._destroy$)
        ).subscribe(url => {
            this.newsMode.set(url[0].path == 'news');
        });
        
        const o = reportBuilderService.offering();
        if (!o) {
            // offering not selected
            this.router.navigate(['/report-builder', 'new']);
        } else if (!reportBuilderService.job()) {            
            if (o.jobs?.length) {
                this.router.navigate(['/report-builder', 'job']);
            } else {
                reportBuilderService.job.set('_');
            }
        }

        this.companyNewsRating = this._rating(ReportSectionType.CompanyNews);
        this.stakeholdersRating = this._rating(ReportSectionType.Stakeholders);
        this.marketTrendsRating = this._rating(ReportSectionType.MarketTrends);
        this.commonProblemsRating = this._rating(ReportSectionType.CommonProblems);
        this.processStepsRating = this._rating(ReportSectionType.ProcessSteps);
        this.otherTopicsRating = this._rating(ReportSectionType.OtherTopics);
        
        this.ratingService.reset();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private _rating(section: ReportSectionType): ReportSectionRating {
        return {
            section,
            rating: undefined,
            description: '',            
        }
    }

    next() {
        this.router.navigate(['/report-builder', this.newsMode() ? 'questions' : 'news']);
    }

    companyDataRetry() {
        this._retry();
    }

    stakeholdersRetry() {
        this._retry();
    }
    
    marketTrendsRetry() {
        this._retry();
    }

    commonProblemsRetry() {
        this._retry();
    }

    processStepsRetry() {
        this._retry();
    }
    
    private _retry() {
        const job = this.job();
        this.job.set('');
        this.job.set(job);
    }

    saveCustomTopic(topic: DiscussionTopic) {
        const r = this.reportBuilderService;
        if (!r) throw Error('Report is not defined');
        
        const list = r.otherTopics();
        if (list.indexOf(topic) == -1) {
            r.otherTopics.update(x => [...x, topic]);
        }
    }

    addDiscussionTopic() {
        this.selectedCustomDiscussionTopic = {
            selected: true,
            title: '',
            description: ''            
        };
    }

    rate(rating: ReportSectionRating, closeDialog = false) {
        console.log('[SelectDiscussionTopicsComponent] rate', rating);
        const companyId = this.company()?.id;
        if (!companyId) return;

        if (rating.rating === false) {
            this.badReportSectionRating = rating;
        }
        
        this.ratingService.rateSection({
            companyId: +companyId,
            offeringId: this.product()!.id,
            job: this.job() ?? '',
            ...rating
        }).subscribe(() => {
            console.log('[SelectDiscussionTopicsComponent] rate success');
            if (closeDialog) {
                this.badReportSectionRating = undefined;
            }
        })
    }
}