@if (company() && product() && job()) {
    <app-pure-select-discussion-topics
            (onNext)="next()"
            [loading]="loading()"
            [newsMode]="newsMode()"
            [company]="company()!"
            [newsArticles]="newsArticles()"

            [companyNewsLoading]="companyNewsLoading()"
            [companyNewsLoadingFailed]="companyNewsLoadingFailed()"

            [stakeholdersEnabled]="stakeholdersEnabled()"
            [stakeholdersLoading]="stakeholdersLoading()"
            [stakeholdersLoadingFailed]="stakeholdersLoadingFailed()"
            [stakeholders]="stakeholders()"

            [product]="product()!"
            [job]="job()!"

            [discussionTopics]="discussionTopics()"
            [otherTopics]="otherTopics()"

            (onCompanyDataRetry)="companyDataRetry()"
            (onStakeholdersRetry)="stakeholdersRetry()"
            (onMarketTrendsRetry)="marketTrendsRetry()"
            (onCommonProblemsRetry)="commonProblemsRetry()"
            (onProcessStepsRetry)="processStepsRetry()"

            [selectedCustomDiscussionTopic]="selectedCustomDiscussionTopic"
            (onAddDiscussionTopic)="addDiscussionTopic()"
            (onSaveCustomTopic)="saveCustomTopic($event)"
            
            [companyNewsRating]="companyNewsRating"
            [stakeholdersRating]="stakeholdersRating"
            [marketTrendsRating]="marketTrendsRating"
            [commonProblemsRating]="commonProblemsRating"
            [processStepsRating]="processStepsRating"
            [otherTopicsRating]="otherTopicsRating"
            
            (onRatingChange)="rate($event)"
        />
}

@if (badReportSectionRating) {
    <app-info-panel [title]="'Reason for your rating'" (onClose)="badReportSectionRating = undefined">
        <app-rate-section-dialog
                (onCancel)="badReportSectionRating = undefined"
                (onSubmit)="rate($event, true)"
                [rating]="badReportSectionRating!"
        />
    </app-info-panel>
}