<div class="title">CLAIRE'S ADVICE</div>

<div class="message" [attr.qa]="'advice-summary'">
    <app-tutor-avatar class="coach" />

    <div class="flex">        
        <span class="text">{{ advices.summary }}</span>    
    </div>
</div>

@for (advice of advices.advices; track advice.text; let i = $index) {
    <div class="message" [attr.qa]="'advice-' + (i + 1)">
        <app-tutor-avatar class="coach" />

        <div class="flex">
            <strong class="index">{{ i + 1 }}.</strong>
            <div class="flex-column">
                <strong>{{ advice.title }}</strong>
                <span class="text">{{ advice.text }}</span>
            </div>
        </div>
    </div>
}
