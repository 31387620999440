<app-main-panel>

    <div class="left">
        
        @if (signUpMode) {
            <app-signup-note/>
        } @else {
            <h1>Account</h1>
            
            <app-profile-info 
                    [offeringsCount]="offerings.length"
                    [userProfile]="userProfile" 
                    (onLogout)="onLogout.emit()"/>
        }
    </div>
    
    <div class="right">
        <app-pure-my-offerings
                (onAdd)="onAddOffering.emit()"
                (onEdit)="onEditOffering.emit($event)"
                (onDelete)="onDeleteOffering.emit($event)"
                [signUpMode]="false"
                [offerings]="offerings"
        />

        @if (signUpMode) {
            @if (offerings.length) {
                <a class="done" [routerLink]="['/dashboard']">Done</a>
            } @else {
                <a class="sp-link skip" [routerLink]="['/dashboard']">Skip</a>
            }
        }
    </div>
    
</app-main-panel>