import { Component } from '@angular/core';

@Component({
  selector: 'app-problems-header',
  standalone: true,
  imports: [],
  templateUrl: './problems-header.component.html',
  styleUrl: './problems-header.component.scss'
})
export class ProblemsHeaderComponent {

}
