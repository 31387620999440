import {Component} from '@angular/core';
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {ReportsListComponent} from "./reports-list/reports-list.component";
import {ReportService} from "../../services/report.service";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {SalesPrepReport} from "../../model";
import {MessageService} from "../../services/message.service";

@Component({
    selector: 'app-reports',
    standalone: true,
    imports: [
        FooterCompactComponent,
        MainHeaderComponent,
        PageHeaderComponent,
        ReportsListComponent,
        SpinnerComponent
    ],
    templateUrl: './reports.component.html',
    styleUrl: './reports.component.scss'
})
export class ReportsComponent {

    reportsLoading = this.reportService.reportsLoading;
    reports = this.reportService.reports;
    
    constructor(private reportService: ReportService, private messageService: MessageService) {
    }

    removeReport(report: SalesPrepReport) {
        this.messageService.confirm('You are about to remove report for ' + report.company.name + '. Are you sure?',
            () => this._doRemove(report));
    }

    private _doRemove(report: SalesPrepReport) {
        this.reportService.remove(report).subscribe({
            error: () => {
                this.messageService.error('Unable to remove report');
            },
            next: () => {
                this.messageService.success('Report has been removed');
            }
        });
    }
}
