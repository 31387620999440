import { Component } from '@angular/core';

@Component({
  selector: 'app-process-steps-header',
  standalone: true,
  imports: [],
  templateUrl: './process-steps-header.component.html',
  styleUrl: './process-steps-header.component.scss'
})
export class ProcessStepsHeaderComponent {

}
