import {Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {
    Company,
    DiscussionTopic,
    DiscussionTopics,
    ExploratoryQuestion,
    NewsArticle,
    SalesPrepReport,
    Stakeholder
} from "../model";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {
    catchError,
    filter,
    Observable,
    of, retry, retryWhen,
    Subscription,
    switchMap,
    tap, throwError, timeout, timer
} from "rxjs";
import {HttpClient, HttpDownloadProgressEvent, HttpEventType} from "@angular/common/http";
import {Offering} from "../model/offering";
import {OfferingActionPipe} from "../pipes/offering-action.pipe";

export interface IEditableDiscussionTopicsList {
    job: string;
    topics: IEditableDiscussionTopics[];
}
export interface IEditableDiscussionTopics {
    role: string;

    marketTrendsLoading: WritableSignal<boolean>;
    commonProblemsLoading: WritableSignal<boolean>;
    processStepsLoading: WritableSignal<boolean>;

    marketTrendsLoadingFailed: WritableSignal<boolean>;
    commonProblemsLoadingFailed: WritableSignal<boolean>;
    processStepsLoadingFailed: WritableSignal<boolean>;

    marketTrends: WritableSignal<DiscussionTopic[]>;
    commonProblems: WritableSignal<DiscussionTopic[]>;
    processSteps: WritableSignal<DiscussionTopic[]>;

    _marketTrends?: Subscription;
    _commonProblems?: Subscription;
    _processSteps?: Subscription;
}

enum SalesPrepReportSection {
    MarketTrends = 1,
    CommonProblems = 2,
    ProcessSteps = 3
}

@Injectable({
    providedIn: 'root'
})
export class ReportBuilderService {

    generatingReport = signal(false);
    companyDataLoading = signal(false);
    companyDataLoadingFailed = signal(false);
    companyNewsLoading = signal(false);
    companyNewsLoadingFailed = signal(false);
    exploratoryQuestionsLoading = signal(false);
    exploratoryQuestionsLoadingFailed = signal(false);
    stakeholdersLoading = signal(false);
    stakeholdersLoadingFailed = signal(false);

    company = signal<Company | undefined>(undefined);
    offering = signal<Offering | undefined>(undefined);
    roles = signal<string[]>([]);
    job = signal<string | undefined>(undefined);
    reportData = signal<SalesPrepReport | undefined>(undefined);

    exploratoryQuestions = signal<ExploratoryQuestion[]>([]);

    stakeholdersEnabled = signal(true);
    stakeholders = signal<Stakeholder[]>([]);
    newsArticles = signal<NewsArticle[]>([]);
    discussionTopics = signal<IEditableDiscussionTopics[]>([]);
    otherTopics = signal<DiscussionTopic[]>([]);

    marketTrendsLoading = signal(false);
    commonProblemsLoading = signal(false);
    processStepsLoading = signal(false);

    marketTrendsLoadingFailed = signal(false);
    commonProblemsLoadingFailed = signal(false);
    processStepsLoadingFailed = signal(false);

    constructor(private http: HttpClient) {
        this._loadCompany();
        this._loadOffering();

        toObservable(this.company)
            .subscribe(s => this._saveCompany());
        toObservable(this.offering)
            .subscribe(s => this._safeOffering());
        // toObservable(this.job)
        //     .subscribe(s => this.saveJob());
    }

    reset() {
        this.company.set(undefined);
        this.offering.set(undefined);
        this.job.set(undefined);
        this.otherTopics.set([]);
        this.roles.set([]);
        this.exploratoryQuestions.set([]);
    }

    
    companyData$ = toObservable(this.company).pipe(
        filter(c => !!c),
        tap(() => this.companyDataLoading.set(true)),
        tap(() => this.companyDataLoadingFailed.set(false)),
        switchMap(c => this.createCompanyObservable(c!)),
        tap(company => {
            this.companyDataLoading.set(false);
            if (!company) {
                this.companyDataLoadingFailed.set(true);
            }
        }),
        // shareReplay(1)
    );

    newsData$ = toObservable(this.job).pipe(
        tap(() => this.newsArticles.set([])),
        filter(job => !!job),
        switchMap(job => this.loadNewsObservable(job))
    );

    stakeholders$ = toObservable(this.job).pipe(
        tap(() => this.stakeholders.set([])),
        filter(job => !!job),
        switchMap(job => this.stakeholdersObservable(job))
    );

    createCompanyObservable(c: Company): Observable<Company | undefined> {
        return this.http.post<Company>(`/api/companies/index`, {
            name: c.name,
            website: c.website,
            isCompanyFound: c.isCompanyFound,
            description: c.description,
            facebookUrl: c.facebookUrl,
            linkedInUrl: c.linkedInUrl
        }).pipe(
            retry(3),
            catchError(e => {
                return of(undefined);
            })
        )
    }

    loadNewsObservable(job: string | undefined) {

        if ((<any> window).qaSkipNews) {
            return of([]);
        }
        
        
        this.companyNewsLoading.set(true);
        this.companyNewsLoadingFailed.set(false);

        let buffer = '';
        
        return this.http.put('/api/reports/load-news', {
            companyName: this.company()?.name,
            job: this._getJob(job),
        }, {
            observe: "events",
            responseType: <any>"text",
            reportProgress: true
        }).pipe(
            tap((event) => {
                if ((<any>window).qaLoadingFailure) {
                    if ((<any>window).qaLoadingFailure--) {
                        throw Error('Not implemented');
                    }
                }
                if (event.type == HttpEventType.DownloadProgress) {
                    this._readNewsArticle(this.newsArticles, (<HttpDownloadProgressEvent>event).partialText);
                } else if (event.type == HttpEventType.Response) {
                    this._readNewsArticle(this.newsArticles, (<any>event).body);
                    this.companyNewsLoading.set(false)
                }
            }),
            catchError(e => {
                this.companyNewsLoading.set(false);
                this.companyNewsLoadingFailed.set(true);
                return of([]);
            })
        );
    }

    discussionTopics$ = toObservable(this.job).pipe(
        tap(() => console.log('[ReportBuilderService] job changed', this.job(), this.roles())),
        tap(() => this.discussionTopics().forEach(x => {
            x._marketTrends?.unsubscribe();
            x._commonProblems?.unsubscribe();
            x._processSteps?.unsubscribe();
        })),
        filter(job => !!job),
        switchMap((job) => {
            // Fetch data from three endpoints
            const list: IEditableDiscussionTopicsList = {
                job: this.job() || '',
                topics: []
            }

            const roles = this.roles();
            if (!roles.length) {
                roles.push('');
            }

            roles.forEach(role => {

                const result: IEditableDiscussionTopics = {
                    role,

                    marketTrendsLoading: signal<boolean>(false),
                    commonProblemsLoading: signal<boolean>(false),
                    processStepsLoading: signal<boolean>(false),

                    marketTrendsLoadingFailed: signal<boolean>(false),
                    commonProblemsLoadingFailed: signal<boolean>(false),
                    processStepsLoadingFailed: signal<boolean>(false),

                    marketTrends: signal<DiscussionTopic[]>([]),
                    commonProblems: signal<DiscussionTopic[]>([]),
                    processSteps: signal<DiscussionTopic[]>([]),
                }

                const marketTrends$ = this._discussiontTopicsObservable(list.job, role, SalesPrepReportSection.MarketTrends, result.marketTrends, result.marketTrendsLoading, result.marketTrendsLoadingFailed);
                const commonProblems$ = this._discussiontTopicsObservable(list.job, role, SalesPrepReportSection.CommonProblems, result.commonProblems, result.commonProblemsLoading, result.commonProblemsLoadingFailed);
                const processSteps$ = this._discussiontTopicsObservable(list.job, role, SalesPrepReportSection.ProcessSteps, result.processSteps, result.processStepsLoading, result.processStepsLoadingFailed);

                result._marketTrends = marketTrends$.subscribe(x => {});
                result._commonProblems = commonProblems$.subscribe(x => {});
                result._processSteps = processSteps$.subscribe(x => {});

                list.topics.push(result);
            });

            console.log('[ReportBuilderService] result', list);
            this.discussionTopics.set(list.topics);
            return of(list);
        })
    )


    private _discussiontTopicsObservable(job: string, role: string, section: SalesPrepReportSection,
                                         list: WritableSignal<DiscussionTopic[]>,
                                         loading: WritableSignal<boolean>,
                                         loadingFailed: WritableSignal<boolean>
    ) {
        console.log('[ReportBuilderService] job changed', this.job());
        loading.set(true);
        loadingFailed.set(false);
        list.set([]);

        return this.http.put('/api/reports/generate', {
            section,
            job: this._getJob(job),
            role: role,
            offeringId: this.offering()?.id,
            offeringType: this.offering()?.type,
            offeringName: this.offering()?.name,
        }, {
            observe: "events",
            responseType: <any>"text",
            reportProgress: true
        }).pipe(
            tap((event) => {
                if ((<any>window).qaLoadingFailure) {
                    if ((<any>window).qaLoadingFailure--) {
                        throw Error('Not implemented');
                    }
                }
                if (event.type == HttpEventType.DownloadProgress) {
                    this._readTopics(list, (<HttpDownloadProgressEvent>event).partialText);
                } else if (event.type == HttpEventType.Response) {
                    this._readTopics(list, (<any>event).body);
                    loading.set(false)
                }
            }),
            catchError(e => {
                loading.set(false);
                loadingFailed.set(true);
                return of([]);
            })
        );
    }

    private _readTopics(list: WritableSignal<DiscussionTopic[]>, text: string | undefined) {
        if (!text) return;

        const lines = text.substring(0, text.lastIndexOf('\n')).split('\n');        
        const existingCount = list().length;
        for (let i = existingCount; i < lines.length; i++) {
            const line = lines[i];
            if (!line) continue;

            const [title, description] = line.split('\t');
            list.update(x => [...x, {
                selected: true,
                title,
                description
            }]);
        }
    }

    private _readNewsArticle(list: WritableSignal<NewsArticle[]>, text: string | undefined) {
        if (!text) return;

        const lines = text.substring(0, text.lastIndexOf('\n')).split('\n');
        const existingCount = list().length;
        for (let i = existingCount; i < lines.length; i++) {
            const line = lines[i];
            if (!line) continue;

            const [url, title, date, source, summary] = line.split('\t');
            list.update(x => [...x, <NewsArticle>{
                url,
                summary,
                source,
                title,
                date: new Date(date),
                selected: true,
            }]);
        }
    }

    private _readQuestion(list: WritableSignal<ExploratoryQuestion[]>, text: string | undefined) {
        if (!text) return;

        const lines = text.substring(0, text.lastIndexOf('\n')).split('\n');
        const existingCount = list().length;
        for (let i = existingCount; i < lines.length; i++) {
            let text = lines[i];
            if (!text) continue;
            
            if (text.startsWith('- ')) {
                text = text.substring(2);
            }
            
            text = text.replace(/^\d+\.\s*/, '');            

            list.update(x => [...x, <ExploratoryQuestion>{
                text,
                selected: false
            }]);
        }
    }

    private _readStakeholders(list: WritableSignal<Stakeholder[]>, text: string | undefined) {
        if (!text) return;

        const lines = text.substring(0, text.lastIndexOf('\n')).split('\n');
        const existingCount = list().length;
        for (let i = existingCount; i < lines.length; i++) {
            const line = lines[i];
            if (!line) continue;

            const [title, description] = line.split('\t');
            list.update(x => [...x, <Stakeholder> {
                title,
                description,
                selected: true
            }]);
        }
    }

    private _discussionTopics = toSignal(this.discussionTopics$);
    private _stakeholders = toSignal(this.stakeholders$);
    
    companyData = toSignal<Company | undefined>(this.companyData$, {
        initialValue: undefined
    });

    newsData = toSignal(this.newsData$, { initialValue: undefined });
    

    private _loadCompany = () => this.load<Company | undefined>('company', this.company);
    private _saveCompany = () => this.save('company', this.company());

    private _loadOffering = () => this.load<Offering | undefined>('offering', this.offering);
    private _safeOffering = () => this.save('offering', this.offering());

    // private loadJob = () => this.load<string | undefined>('job', this.job);
    // private saveJob = () => this.save('job', this.job());

    private load<T>(key: string, obj: WritableSignal<T>) {
        const json = sessionStorage.getItem(key);
        if (json) {
            const x = <T>JSON.parse(json);
            obj.set(x);
        }
    }

    private save(key: string, obj: any) {
        console.log('[ReportBuilderService] save', key, obj);
        if (obj) {
            const json = JSON.stringify(obj);
            sessionStorage.setItem(key, json);
        } else {
            sessionStorage.setItem(key, '');
        }
    }

    generateExploratoryQuestions(benefits: string[]) {
        this.exploratoryQuestionsLoading.set(true);
        this.exploratoryQuestionsLoadingFailed.set(false);

        const c = this.company();
        if (!c) throw Error('Company not loaded');

        const o = this.offering();
        if (!o) throw Error('Offering not loaded');
        
        const job = !!this.job() ? new OfferingActionPipe().transform(o.type, true) + ' ' + this.job() : '';
        
        return this.http.put('/api/reports/exploratory-questions', {
            benefits,

            companyName: c.name,
            companyWebsite: c.website,
            offering: o?.name,
            job: job,
            roles: this.roles().filter(x => !!x),
            companyNews: this.newsArticles().map(x => {
                return {
                    title: x.title,
                    description: x.summary,
                }
            }),
            marketTrends: this.discussionTopics().map(x => x.marketTrends()).flat(),
            commonProblems: this.discussionTopics().map(x => x.marketTrends()).flat(),
            processSteps: this.discussionTopics().map(x => x.marketTrends()).flat(),
        }, {
            observe: "events",
            responseType: <any>"text",
            reportProgress: true
        }).pipe(
            tap((event) => {
                if ((<any>window).qaLoadingFailure) {
                    if ((<any>window).qaLoadingFailure--) {
                        throw Error('Not implemented');
                    }
                }
                if (event.type == HttpEventType.DownloadProgress) {
                    this._readQuestion(this.exploratoryQuestions, (<HttpDownloadProgressEvent>event).partialText);
                } else if (event.type == HttpEventType.Response) {
                    this._readQuestion(this.exploratoryQuestions, (<any>event).body);
                    this.exploratoryQuestionsLoading.set(false);
                }
            }),
            catchError(e => {
                this.exploratoryQuestionsLoading.set(false);
                this.exploratoryQuestionsLoadingFailed.set(true);
                return of([]);
            })
        );
    }

    stakeholdersObservable(j: string | undefined) {
        if (!this.stakeholdersEnabled()) {
            return of([]);
        }
        
        let retryNum = 0;
        let retryCount = 10;
        this.stakeholdersLoading.set(true);
        this.stakeholdersLoadingFailed.set(false);

        const o = this.offering();
        if (!o) throw Error('Offering not loaded');
        
        const job = !!j ? new OfferingActionPipe().transform(o.type, true) + ' ' + j : '';
        
        return this.http.put('/api/reports/stakeholders', {
            offering: this.offering()?.name,
            job: job,
        }, {
            observe: "events",
            responseType: <any>"text",
            reportProgress: true
        }).pipe(
            timeout(5000),
            retry({
                count: retryCount,
                delay: (error, count) => {
                    console.warn(`[ReportBuilderService] Retrying stakeholders ${count} due to error:`, error);
                    retryNum = count;
                    return timer(2000); // Wait 2 seconds before retrying
                }
            }),
            
            tap((event) => {
                if ((<any>window).qaLoadingFailure) {
                    if ((<any>window).qaLoadingFailure--) {
                        throw Error('Not implemented');
                    }
                }
                if (event.type == HttpEventType.DownloadProgress) {
                    this._readStakeholders(this.stakeholders, (<HttpDownloadProgressEvent>event).partialText);
                } else if (event.type == HttpEventType.Response) {
                    this._readStakeholders(this.stakeholders, (<any>event).body);
                    this.stakeholdersLoading.set(false);
                }
            }),
            catchError(error => {
                console.error('[ReportBuilderService] Final error after retries:', error);
                if (retryNum < retryCount) {
                    return throwError(() => error); // Re-throw the error after retries or other handling
                } else {
                    this.stakeholdersLoading.set(false);
                    this.stakeholdersLoadingFailed.set(true);
                    return of([]);
                }
            })
            // catchError(e => {
            //     this.stakeholdersLoading.set(false);
            //     this.stakeholdersLoadingFailed.set(true);
            //     return of([]);
            // })
        );
    }

    saveReport(date: string, time: string) {
        console.log('[ReportBuilderService] save report', date, time);

        const offering = this.offering();
        if (!offering) {
            throw Error('Offering is not defined');
        }

        const topics = this.discussionTopics();
        return this.http.post<SalesPrepReport>('/api/reports', {
            companyId: this.companyData()?.crunchbasePermalink,
            meetingDate: date,
            meetingTime: this._fixTime(time),
            offeringId: offering.id,
            offeringType: offering.type,
            offeringName: offering.name,
            job: this._getJob(this.job()),
            roles: this.roles(),
            exploratoryQuestions: this.exploratoryQuestions(),
            stakeholders: this.stakeholders(),
            newsArticles: this.newsArticles(),
            discussionTopics: topics.map(x => {
                return <DiscussionTopics>{
                    role: x.role,
                    marketTrends: x.marketTrends(),
                    commonProblems: x.commonProblems(),
                    processSteps: x.processSteps(),
                }
            }),
            otherTopics: this.otherTopics(),
        }).pipe(
            retry(1),
        );
    }

    private _fixTime(time: string | undefined) {
        if (!time) return time;
        return time.startsWith('24:') ? '00' + time.substring(2) : time;
    }

    private _getJob(job: string | undefined) {
        return job == '_' ? '' : job;
    }

    
}
