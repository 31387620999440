<app-main-header [sticky]="false" />

<div class="sp-wrapped">
    <app-main-panel>
        
        <app-pricing [inline]="false"
                (onStartTrial)="signUp()"
                (onContactUs)="contactUs()"
                (onSelectWorkshop)="signUp(SignUpMode.EvocWorkshop)"/>

        <div class="note">
            <a (click)="scrollToGrid()">Scroll</a> to see all the ways you and your team can use SalePrep
        </div>
    </app-main-panel>
</div>



<div #grid class="comparison">
    <div class="sp-wrapped">
        <app-twelve-way-comparition-grid [pricingMode]="true"/>
    </div>
</div>

<!--
<div class="quote">
    <span>“Great training presentation and great job with the software. I had no idea that even existed, but I’m very impressed. We’ll be using it a lot.”</span> 
    <em>— Torentson</em>
</div>
-->

<app-footer-smart [noMargin]="true" />