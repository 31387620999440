import {Component, Input} from '@angular/core';
import {Offering} from "../../../../model/offering";
import {Company} from "../../../../model";
import {OfferingActionPipe} from "../../../../pipes/offering-action.pipe";
import {LowerCasePipe} from "@angular/common";
import {ReportSubjectComponent} from "../../../../components/report-subject/report-subject.component";
import {CompanyLogoComponent} from "../../../../components/company-logo/company-logo.component";

@Component({
    selector: 'app-report-builder-header',
    standalone: true,
    imports: [
        OfferingActionPipe,
        LowerCasePipe,
        ReportSubjectComponent,
        CompanyLogoComponent
    ],
    templateUrl: './report-builder-header.component.html',
    styleUrl: './report-builder-header.component.scss'
})
export class ReportBuilderHeaderComponent {
    @Input() company!: Company | undefined;
    @Input() offering!: Offering | undefined;
    @Input() job!: string | undefined;
}
