<div class="header">
    <h1>Account</h1>

    <div class="profile-info">
        <div class="avatar">
            <div>{{ userProfile | initials }}</div>
        </div>
        <div>
            <div [routerLink]="['/profile/edit']" class="name">{{ userProfile.firstName || 'John' }} {{ userProfile.lastName || 'Doe' }}</div>
            <div class="email">{{ userProfile.email || 'john.doe@example.com' }}</div>
        </div>
    </div>
</div>

<div class="info">
    <div class="container expired">
        
        @if (userProfile.stripeSubscriptionId) {
            <h2>Subscription Expired</h2>

            <div>
                Your subscription has expired. Please make a payment to renew it, or contact support if you have any questions.
            </div>

            <a class="sp-form renew" href="/api/user/stripe-session">
                <button class="primary">Renew Subscription</button>
            </a>
        } @else {
            <h2>Subscription not Confirmed</h2>

            <div>
                Please confirm your subscription and payment options.
            </div>

            <a class="sp-form renew" href="/api/user/stripe-subscribe">
                <button class="primary">Confirm Subscription</button>
            </a>
        }
    </div>
</div>