<div class="title" qa="next-steps-div">NEXT STEPS...</div>

<p>
    Take advantage of the <a [routerLink]="['/e-learning']" class="sp-link">included e-learning</a> to learn more about
    when and how to successfully probe your customers. The more and better you probe, the more sales you’ll achieve!

    <br><br>
    
    When ready to test these skills out in a completely safe environment, try
    <a (click)="onRolePlaying.emit()" class="sp-link">role-playing</a> the
    sale of your product with Claire as either intermediate or expert.
</p>