<h3>AI CUSTOMER INSIGHTS REPORT</h3>

<h1>
    <span class="h2 sub-title">Three decades of research make it clear: </span>
    Top sales professionals ask great <em>questions</em> and <em>challenge</em> customers with fresh ideas.
</h1>

<div class="content">

    <div class="left">
        <p>
            But how do you prepare these questions and ideas given your hectic schedule?
            You unleash the power of AI.
        </p>

        <blockquote class="desktop-layout">
            Just input your target customer, your product, and their job to be done (application) with it.
            In about 60 seconds, you'll have a report on your phone, computer or PDF print out filled with
            insights to enter your customer's world.
        </blockquote>
        
        <blockquote class="mobile-layout">
            Just input your target customer, your product, and what they use it for.
            In 60 seconds, you'll have a report filled with
            insights to enter your customer's world.
        </blockquote>

        <p style="margin-bottom: 24px;">
            Perfect for any B2B sales professional.
            But also great for marketing, product management, technical support, and business development.
            Anyone keen to impress customers with their professionalism… and interest in them!
        </p>

        <div class="sub-title mobile-layout">
            <h2 class="center">
                Each SalesPrep report includes<br>
                5 categories of AI insights to<br>
                prepare you for every sales meeting.
            </h2>
        </div>

        <app-card
                [title]="'Company News'"
                [desc]="'M&A activity, management changes, earnings reports. (Shown in PDF)'">
            <div class="img company-news" title="Company News section of SalesPrep report"></div>            
        </app-card>

        <app-card
                [title]="'Market Trends'"
                [desc]="'Key trends for this type of customer application.'">
            <div class="img market-trends" title="Market Trends section of SalesPrep report"></div>
        </app-card>

        <a title="Sample SalesPrep report" class="sp-button desktop-layout" target="_blank" href="https://www.blueprintingcenter.com/public_downloads/7251c6e0-c933-4050-ab00-e4d4e6042789">
            Download a sample report
        </a>
        
        <!--
        <button [routerLink]="[authenticated ? '/dashboard' : '/signup']" class="primary desktop-layout" qa="start-4">
            Start 30-day trial<br>
            <span>(No credit card needed)</span>
        </button>
        -->
    </div>

    <div class="right">
        <div class="sub-title desktop-layout">
            <h2 class="center">
                Each SalesPrep report includes<br>
                5 categories of AI insights to<br>
                prepare you for every sales meeting.
            </h2>
        </div>
        
    
        <app-card
                [title]="'Common Problems'"
                [desc]="'Frequent problems with this customer application.'">
            <div class="img common-problems" title="Common Problems section of SalesPrep report"></div>
        </app-card>
    
        <app-card
                [title]="'Process Steps'"
                [desc]="'Major steps for using your product in this application.'">
            <div class="img process-steps" title="Process Steps section of SalesPrep report"></div>
        </app-card>
    
        <app-card
                [title]="'Company Overview'"
                [desc]="'Leadership, size, strategy, products, competitors.'">
            <div class="img company-overview" title="Company Overview section of SalesPrep report"></div>
        </app-card>
    </div>

</div>

<a title="Sample SalesPrep report" class="sp-button mobile-layout" target="_blank" href="https://www.blueprintingcenter.com/public_downloads/7251c6e0-c933-4050-ab00-e4d4e6042789">
    Download a sample report
</a>

<!--
<button [routerLink]="[authenticated ? '/dashboard' : '/signup']" class="primary mobile-layout" qa="start-3">
    Start 30-day trial<br>
    <span>(No credit card needed)</span>
</button>
-->
