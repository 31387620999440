import {Component} from '@angular/core';

@Component({
    selector: 'app-badge',
    standalone: true,
    imports: [],
    templateUrl: './badge.component.html',
    styleUrl: './badge.component.scss'
})
export class BadgeComponent {

}
