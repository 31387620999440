import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {RouterLink} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DropdownComponent} from "../../../../reports/dropdown/dropdown.component";
import {AutofocusDirective} from "../../../../../directives/autofocus.directive";
import {
    CompanyListItemComponent
} from "../../../../report-builder/screens/select-company/components/company-list-item/company-list-item.component";
import {SpinnerComponent} from "../../../../../components/spinner/spinner.component";
import {Company} from "../../../../../model";
import {ClickOutsideDirective} from "../../../../../directives/click-outside.directive";

@Component({
    selector: 'app-pure-report-practice-start',
    standalone: true,
    imports: [
        RouterLink,
        FormsModule,
        ReactiveFormsModule,
        AutofocusDirective,
        CompanyListItemComponent,
        SpinnerComponent,
        ClickOutsideDirective,
    ],
    templateUrl: './pure-report-practice-start.component.html',
    styleUrl: './pure-report-practice-start.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureReportPracticeStartComponent {
    @Input() companies: Company[] = [];
    @Input() company: Company | undefined;
    @Input() searchResultsLoading = false;
    @Input() showDropDown = false;
    @Input() errorMessage = '';

    @Output() companyQueryChange = new EventEmitter<string>();
    @Output() onSelectCompany = new EventEmitter<Company>();
    @Output() onSubmit = new EventEmitter<ReportPracticeData>();

    data: ReportPracticeData = {
        offering: '',
        jobToBeDone: '',
        accessCode: ''
    };

    @HostBinding('class.sp-typography') t = true;
    
    companyQuery = '';

    select(company: Company) {
        this.companyQuery = company.name; 
        this.onSelectCompany.emit(company);
    }
}

export interface ReportPracticeData {
    offering: string;
    jobToBeDone: string;
    accessCode: string;
}