import {TextSection} from "../text-section";
import {PdfGenerator} from "../pdf-generator";
import {DiscussionTopic} from "../../../model";
import {IReportSection} from "../report-section";

export class OtherTopicSection implements IReportSection {
    _height = 0;
    private _titleSection: TextSection;
    titleMarginBottom = 5;

    constructor(private pdf: PdfGenerator, topic: DiscussionTopic, index: number) {
        this._titleSection = pdf.createSection(`${index}. ${topic.title}`, 522, 'Raleway-Bold', 10)
    }

    getHeight() {
        if (this._height) {
            return this._height;
        }
        this._height = this._titleSection.getHeight(this.pdf) + this.titleMarginBottom
        return this._height;
    }

    draw(x: number, y: number) {
        return this._titleSection.draw(this.pdf, x, y) + this.titleMarginBottom
    }
}