import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {DiscussionTopic} from "../../../../../../model";
import {RemoveHtmlPipe} from "../../../../../../pipes/remove-html.pipe";

@Component({
    selector: 'app-discussion-topic',
    standalone: true,
    imports: [
        RemoveHtmlPipe
    ],
    templateUrl: './discussion-topic.component.html',
    styleUrl: './discussion-topic.component.scss',
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscussionTopicComponent implements AfterViewInit {
    @Input() index = '1';
    @Input() topic!: DiscussionTopic;
    
    @ViewChild('typing', { static: false }) typingDiv!: ElementRef;
    
    @Output() onClick = new EventEmitter();
    @Output() onChange = new EventEmitter();
    @HostBinding('class.sp-checkbox') checkbox = true;
    
    @HostBinding('class.inline')
    @Input() inline = false;

    @HostBinding('class.animated') @Input() animated = true;
    
    ngAfterViewInit() {
        if (this.animated) {
            const style = document.createElement('style');
            style.innerHTML = this.topic.title == '_'
                ? `.typing${this.index}::after {
                animation: caret 1s infinite !important; 
            }`
                : `.typing${this.index}::after {
                animation: typing 3s steps(${this.topic.title.length}) forwards, caret 1s infinite !important; 
            }`;
            (<HTMLDivElement>this.typingDiv.nativeElement).appendChild(style);
        }
    }

    click(e: MouseEvent) {
        e.preventDefault();
        this.onClick.emit();
    }

    toggle(topic: DiscussionTopic) {
        topic.selected = !topic.selected;
        this.onChange.emit();
    }
}
