<div class="dropdown" appClickOutside (clickOutside)="collapse()">
    <span class="text">{{ selectedValuesLabel }}</span>
    <span class="material-symbols-outlined icon">keyboard_arrow_down</span>

    @if (expanded) {
        <div class="menu">
            @for (item of items; track item.value; let i = $index) {
                <a (click)="toggle($event, item)" [class.selected]="selected(item)" class="sp-checkbox" [attr.qa]="'it-' + (i + 1)">
                    
                    @if (selected(item)) {
                        <input type="checkbox" [checked]="true" value="{{item.value}}" (click)="toggle($event, item)" qa="chk1">
                    } @else {
                        <input type="checkbox" [checked]="false" value="{{item.value}}" (click)="toggle($event, item)" qa="chk2">
                    }
                    
                    <label qa="val">
                        {{ item.text || item.value }}
                    </label>
                </a>
            }
        </div>
    }
</div>