import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChatMessagesComponent} from "../chat-messages/chat-messages.component";
import {IAdvicesGroup} from "../../../../services/tutor.service";
import {Roles, TutorChatMessage} from "../../../../model";
import {TutorAvatarComponent} from "../../../../components/tutor-avatar/tutor-avatar.component";
import {UserAvatarComponent} from "../../../../components/user-avatar/user-avatar.component";

@Component({
    selector: 'app-advices',
    standalone: true,
    imports: [
        ChatMessagesComponent,
        TutorAvatarComponent,
        UserAvatarComponent
    ],
    templateUrl: './advices.component.html',
    styleUrl: './advices.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvicesComponent implements OnChanges {
    @Input() advices!: IAdvicesGroup;

    messages: TutorChatMessage[] = []; 

    ngOnChanges(changes: SimpleChanges): void {
        this.messages = this.advices.advices.map((a, i) => {
            return {
                role: Roles.Coach,
                text: `<strong>${i + 1}. ${a.title}</strong><br> ${a.text}`
            }
        })
    }

}
