import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {CardComponent} from "../card/card.component";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-ai-insights',
    standalone: true,
    imports: [
        CardComponent,
        RouterLink
    ],
    templateUrl: './ai-insights.component.html',
    styleUrl: './ai-insights.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiInsightsComponent {
    @HostBinding('class.sp-typography') t = true;    
    @Input() authenticated = false;
}
