import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {FormsModule, NgForm} from "@angular/forms";
import {AutofocusDirective} from "../../../../directives/autofocus.directive";

@Component({
    selector: 'app-new-report',
    standalone: true,
    imports: [
        FormsModule,
        AutofocusDirective
    ],
    templateUrl: './new-report.component.html',
    styleUrl: './new-report.component.scss'
})
export class NewReportComponent {
    @Input() query = '';
    @Input() autofocus = true;
    @Input() widgetMode = false;
    
    @HostBinding('class.compact') @Input() compact = false;

    @Output() queryChange = new EventEmitter<string>();
    @Output() onSearch = new EventEmitter<string>();

    onNewReport(form: NgForm) {
        if (form.invalid) return;
        
        console.log('[NewReportComponent] onNewReport ' + this.query);
        this.onSearch.emit(this.query);        
    }

    _focus() {
        if (this.widgetMode) {
            this.onSearch.emit('');
        }
    }
}
