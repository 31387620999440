import {TextSection} from "../text-section";
import {PdfGenerator} from "../pdf-generator";
import {NewsArticle} from "../../../model";

export class CompanyNewsArticleSection {
    private maxWidth = 340.910;

    private titleSection: TextSection;
    private summarySection: TextSection;

    constructor(private pdf: PdfGenerator, private a: NewsArticle) {
        const title = a.title.replace(/<\/?[^>]+(>|$)/g, "");
        const summary = a.summary.replace(/<\/?[^>]+(>|$)/g, "");

        this.titleSection = pdf.createSection(title, this.maxWidth, 'Raleway-Bold', 12)
        this.summarySection = pdf.createSection(summary, this.maxWidth, 'Raleway-Regular', 9)
    }

    draw(offsetY: number) {
        const pdf = this.pdf;
        const doc = pdf.doc;
        const a = this.a;

        const w = pdf.text((pdf.datePipe.transform(a.date) ?? '') + ' by ', pdf.styles.paddingX, offsetY, 'Raleway-MediumItalic', 9);

        doc.setTextColor(pdf.styles.linkColor);
        // pdf.text(a.source, pdf.styles.paddingX + w, offsetY, 'Raleway-MediumItalic', 9);
        doc.textWithLink(a.source, pdf.styles.paddingX + w, offsetY, { baseline: 'top', url: a.url});
        
        doc.setTextColor(pdf.styles.textColor);

        offsetY += 2 * doc.getLineHeight();
        offsetY = this.titleSection.draw(pdf, pdf.styles.paddingX, offsetY);
        // offsetY += 18.283;
        return this.summarySection.draw(pdf, pdf.styles.paddingX, offsetY);
    }

    getHeight() {
        return this.pdf.getLineHeight('Raleway-MediumItalic', 9) * 3
            + this.titleSection.getHeight(this.pdf)
            + this.summarySection.getHeight(this.pdf);
    }
}