import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OfferingCategory} from "../../../../model";
import {OfferingTypeComponent} from "../offering-type/offering-type.component";
import {Offering} from "../../../../model/offering";
import {FormsModule, NgForm} from "@angular/forms";
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {OfferingType} from "../../../../model/offeringType";
import {RemoveJobArgs} from "../pure-add-offering/pure-add-offering.component";

@Component({
    selector: 'app-edit-offering',
    standalone: true,
    imports: [
        OfferingTypeComponent,
        FormsModule,
        AutofocusDirective
    ],
    templateUrl: './edit-offering.component.html',
    styleUrl: './edit-offering.component.scss'
})
export class EditOfferingComponent implements OnInit {
    @Output() onSave = new EventEmitter<Offering>();
    @Output() onRemove = new EventEmitter<Offering>();
    @Output() onRemoveJob = new EventEmitter<RemoveJobArgs>();
    @Input() category!: OfferingCategory;
    
    @Input() offering: Offering | undefined;
    
    OfferingType = OfferingType;
    
    ngOnInit() {
        console.log('[EditOfferingComponent] init', this.category);
        
        if (!this.offering) {
            const type = this.category.type;
            this.offering = {
                id: -1,
                type: type,
                categoryId: this.category.id,
                name: '',
                jobs: type == OfferingType.ServiceOrSoftware ? [] : ['']
            }
        }
    }

    onKeyUp(e: KeyboardEvent, key: string) {
        if (key == "Enter" && this.offering) {
            e.preventDefault();
            this.offering.jobs.push('');
        }        
    }

    save(f: NgForm) {
        const o = this.offering;
        if (!f.valid || !o) return;
        
        console.log('[EditOfferingComponent] ', o);
        o.name = o.name.trim();
        o.jobs = o.jobs.map(x => x.trim());
        this.onSave.emit(o);
    }


    removeJob(offering: Offering, jobIndex: number) {
        this.onRemoveJob.emit( {
            offering,
            jobIndex            
        })
    }
}
