<div class="header">
    @if (company) {
        <app-report-builder-header [company]="company" [offering]="offering" [job]="job"></app-report-builder-header>
    } @else {
        <h1 qa="nr-h1">New report</h1>
    }
</div>

<div class="steps">
    <a [routerLink]="['/report-builder', 'new']" [class.active]="step == 1" [class.done]="!!company" qa="s1-a">
        <div class="flex-column">
            <h2 qa="s1-h2">Select Target Company</h2>
            <div class="notes">
                <strong qa="s1-n">Select the company you’re preparing for.</strong>
                (If you can’t find it, enter the company URL instead.)
            </div>
        </div>        
    </a>
    <a [routerLink]="['/report-builder', 'offering']" [class.active]="step == 2" [class.done]="!!offering" qa="s2-a">
        <h2 qa="s2-h2">Select Offering</h2>
    </a>
    <a [routerLink]="['/report-builder', 'job']" [class.active]="step == 3" [class.done]="!!job" qa="s3-a">
        <h2 qa="s3-h2">Select Customer’s Job-to-be-done</h2>
    </a>
    <a [routerLink]="['/report-builder', 'topics']" [class.active]="step == 4" [class.done]="step > 4" qa="s4-a">
        <h2 qa="s4-h2">Select Discussion Topics</h2>
    </a>
    <a [routerLink]="['/report-builder', 'questions']" [class.active]="step == 5" [class.done]="step > 5" qa="s5-a">
        <h2 qa="s5-h2">Create Exploratory Questions</h2>
    </a>
    <a [class.active]="step == 6" qa="s6-a">
        <h2 qa="s6-h2">Select Date and Time</h2>
    </a>
</div>