import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-next-steps',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './next-steps.component.html',
    styleUrl: './next-steps.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextStepsComponent {
    @Output() onRolePlaying = new EventEmitter(); 
}
