import {PdfGenerator} from "../pdf-generator";
import {CompetitorSection } from "./competitor-section";

export class CompetitorsSection {
    constructor(private pdf: PdfGenerator) {
        
    }


    draw(y: number) {
        if (!this.pdf.report.company.competitors.length) {
            return 0;
        }
        
        if (y + 150 > this.pdf.pageHeight) {
            y = this.pdf.addPage();
        }
        this.drawTitle(y);
        y += 27.143;
        return this.drawItems(y);
    }

    drawTitle(offsetY: number) {
        this.pdf.text('COMPETITORS', this.pdf.styles.paddingX, offsetY, 'Raleway-ExtraBold', 12);
    }

    drawItems(offsetY: number) {
        const pdf = this.pdf;
        
        const items = pdf.report.company.competitors.map(x => new CompetitorSection(pdf, x));

        while (true)
        {
            offsetY = Math.max(
                pdf.drawColumn(items, pdf.styles.paddingX, offsetY, items.length / 2),
                pdf.drawColumn(items, 314.038, offsetY)
            );

            if (items.length == 0) {
                break;
            } else {
                pdf.addPage();
                offsetY = pdf.partSubTitle('COMPETITORS CONTINUED...');
            }
        }

        return offsetY;
    }
}