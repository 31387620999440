<h3>RESOURCES</h3>

<h1>Resources you can download</h1>

<div class="content" qa="res">

    <div class="left">

        <p>
            Your SalesPrep e-learning modules introduce you to new methods for listening, probing, and observation during your customer meetings and tours. Your SalesPrep subscription lets you download and print these Resource PDFs anytime, for quick reference before and during customer meetings.
        </p>

        <h2 class="center mobile-layout" style="margin: 21px 0 0 0">
            Download several additional aids for your meetings.
        </h2>        

        <app-resources-list title="SalesPrep resources you can download"/>
        
        <button class="primary mobile-layout" [routerLink]="[authenticated ? '/resources' : '/signup']" qa="start-2">
            Start 30-day trial<br>
            <span>(No credit card needed)</span>
        </button>
        
    </div>

    <div class="right desktop-layout">
        <h2 class="center">
            Download several<br>additional aids for your<br> meetings.
        </h2>

        <button class="primary" [routerLink]="[authenticated ? '/resources' : '/signup']" qa="start-1">
            Start 30-day trial<br>
            <span>(No credit card needed)</span>
        </button>

    </div>

</div>
    