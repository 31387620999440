import {Component, HostBinding, HostListener, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {PureMainHeaderComponent} from "./components/pure-main-header/pure-main-header.component";

@Component({
    selector: 'app-main-header',
    standalone: true,
    imports: [
        PureMainHeaderComponent
    ],
    templateUrl: './main-header.component.html',
    styleUrl: './main-header.component.scss'
})
export class MainHeaderComponent {
    authenticated = this.authService.authenticated;
    userProfile = this.authService.userProfile;

    @HostBinding('class.scrolled') isScrolled: boolean = false;
    @HostBinding('class.scrolled-far') isScrolledFar: boolean = false;

    @HostBinding('class.sticky') @Input() sticky = true;
    @HostBinding('class.compact') @Input() compact = false;
    
    @Input() noMenu = false;

    constructor(public authService : AuthService) {
    }
    
    @HostListener('window:scroll', []) onWindowScroll = () => {
        this.isScrolled = window.scrollY > 0;
        if (window.scrollY > 100) {
            this.isScrolledFar = true;
        } else if (this.isScrolledFar && window.scrollY == 0) {
            this.isScrolledFar = false;
        }
    }
    
}
