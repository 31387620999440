import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding, HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {DatePipe} from "@angular/common";
import {TimePipe} from "../../../../pipes/time.pipe";
import {Company, SalesPrepReport} from "../../../../model";
import {TodayPipe} from "../../../../pipes/today.pipe";
import {BadgeComponent} from "../../../../components/badge/badge.component";
import {ReportSubjectComponent} from "../../../../components/report-subject/report-subject.component";
import {CompanyLogoComponent} from "../../../../components/company-logo/company-logo.component";
import {ClickOutsideDirective} from "../../../../directives/click-outside.directive";
import {CustomerHandoutArgs} from "../../../../services/customer-handouts/customer-handouts-generator";


@Component({
    selector: 'app-report-header',
    standalone: true,
    imports: [
        DatePipe,
        TimePipe,
        TodayPipe,
        BadgeComponent,
        ReportSubjectComponent,
        CompanyLogoComponent,
        ClickOutsideDirective,
    ],
    templateUrl: './report-header.component.html',
    styleUrl: './report-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportHeaderComponent implements OnChanges {
    @Input() authenticated = true;
    @Input() exporting = false;
    @Input() reportData!: SalesPrepReport | undefined;
    @Input() company!: Company | undefined;

    @Output() onScrollToTop = new EventEmitter();
    @Output() onExportToPdf = new EventEmitter();
    @Output() onExportCustomerHandouts = new EventEmitter<CustomerHandoutArgs>();
    @Output() onEditExploratoryQuestions = new EventEmitter();
    @Output() onEditDiscussionTopics = new EventEmitter();
    @Output() onEditMeetingDate = new EventEmitter();

    @HostBinding('class.not-scrolled') notScrolled = true;
    
    showExportMenu = false;
    showEditMenu = false;
    showMenu = false;
    roles: string[] = [];

    ngOnChanges() {
        this.roles = this.reportData?.roles.filter(x => !!x) || [];
    }

    @HostListener('window:scroll', []) onWindowScroll = () => {
        this.notScrolled = window.scrollY <= 70;
    }

    export() {
        if (this.authenticated) {
            this.onExportToPdf.emit();
        }
    }

    printAll() {
        this.onExportCustomerHandouts.emit({
            trends: true,
            problems: true,
            steps: true
        });
    }

    printTrends() {
        this.onExportCustomerHandouts.emit({trends: true});
    }

    printProblems() {
        this.onExportCustomerHandouts.emit({problems: true})
    }

    printSteps() {
        this.onExportCustomerHandouts.emit({steps: true});
    }
}
