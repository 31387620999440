import {Component, OnInit, signal} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {PureSplashComponent} from "./components/pure-splash/pure-splash.component";
import {SignupService} from "../../services/signup.service";
import {AuthService} from "../../services/auth.service";
import {FooterComponent} from "../../components/footer/footer.component";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {PureFaqComponent} from "../faq/components/pure-faq/pure-faq.component";

@Component({
    selector: 'app-splash',
    standalone: true,
    imports: [
        MainHeaderComponent,
        MainPanelComponent,
        PureSplashComponent,
        FooterComponent,
        FooterCompactComponent,
        FooterSmartComponent,
        PureFaqComponent
    ],
    templateUrl: './splash.component.html',
    styleUrl: './splash.component.scss'
})
export class SplashComponent {
    
    trialDays = signal(30);
    authenticated = this.authService.authenticated;
    
    constructor(private authService: AuthService) {
    }    
}
