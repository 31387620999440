import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {ErrorMessageComponent} from "../../../../components/error-message/error-message.component";
import {FormsModule, NgForm} from "@angular/forms";
import {UserProfile} from "../../../../model/userProfile";

@Component({
    selector: 'app-profile-setup',
    standalone: true,
    imports: [
        AutofocusDirective,
        ErrorMessageComponent,
        FormsModule
    ],
    templateUrl: './profile-setup.component.html',
    styleUrl: './profile-setup.component.scss'
})
export class ProfileSetupComponent {
    @Input() errorMessage = '';
    data!: UserProfile;
    
    @Input() set profileInfo(data: UserProfile) {
        this.data = {...data};
    };
    
    @Input() setupMode = false;
    
    @Output() onUpdate = new EventEmitter<UserProfile>();

    submit(f: NgForm) {
        if (f.valid) {
            this.onUpdate.emit(this.data);
        }
    }
}
