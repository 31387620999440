import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";
import {map} from "rxjs";

export const subscriptionExpiredGuard: CanActivateFn = (route, state) => {
    const authService = inject(AuthService);
    const router = inject(Router);

    if (authService.authenticated()) {
        const p = authService.userProfile();
        if (p && p.subscriptionExpired) {
            return true;
        }
    }

    return authService.userProfile$.pipe(
        map(x => {
            if (x) {
                router.navigate(['/dashboard']);
                return false;
            }
            return true;
        })
    );
};
