<app-main-header/>

<app-page-header [title]="'Page not found'"/>

<div class="sp-wrapped">
    <h1>The page you’re looking for cannot be found.</h1>

    <p>
        We're sorry, but the page you're looking for doesn't seem to exist. It might have been moved, renamed, or deleted.
                
    </p>
    
    <p>
        Please check the URL for any typos or <a class="sp-link" routerLink="/">return to the homepage</a>.
    </p>
    
    <p>
        <br>
        If you believe this is an error, please <a class="sp-link" routerLink="/contact-us">contact our support team</a> for further assistance.
    </p>

    <p>
        <br>
        Thank you for your understanding.
    </p>
    
</div>

<app-footer/>