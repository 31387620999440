<div class="title">
    <input id="dt-{{index}}" [attr.qa]="'dt-' + index" [disabled]="topic.title == '_'" type="checkbox" [checked]="topic.selected" (change)="toggle(topic)">
    <div class="label">
        <label for="dt-{{index}}" [attr.qa]="'dt-lbl-' + index" class="text-overflow typing typing{{index}}" #typing (click)="click($event)" [innerHTML]="topic.title" title="{{ topic.title | removeHtml }}"></label>
        @if (topic.url) {
            <a class="text-overflow" target="_blank" href="{{ topic.url }}" title="{{ topic.url }}" qa="url">{{ topic.urlText }}</a>
        }
    </div>
</div>

<div class="description" [innerHTML]="topic.description" qa="descr"></div>
