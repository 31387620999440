<div class="header">
    <h1 class="sp-breadcrumbs">
        <!--            <a [routerLink]="['/profile']">Account</a> <i class="material-icons">arrow_right_alt</i> -->
        My Offerings
        @if (offerings?.length) {
            <div class="number" qa="count">{{ offerings?.length }}</div>
        }
    </h1>
    <a class="add" (click)="onAdd.emit()">
        <i class="material-icons" qa="add-1">add</i>
        <span class="text">Add</span>
    </a>
</div>

@if (!offerings?.length) {
    <div class="no-offerings center">
        <div class="message center">
            You don't have any offerings ready to use in SalesPrep reports. <a class="sp-link bold" (click)="onAdd.emit()" qa="add-2">Add one now.</a>
        </div>

        <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="91.746" height="70.009" viewBox="0 0 91.746 70.009">
            <g id="Arrow_for_Offerings" data-name="Arrow for Offerings" transform="translate(-1172.426 -150.745)">
                <path id="Arrow_for_Adding_Offerings" data-name="Arrow for Adding Offerings" d="M9477.942,274.254c19.991,0,66.139-7.507,76.7-56.853" transform="translate(-8305.517 -55)" fill="none" stroke="#ff851b" stroke-width="3"/>
                <path id="Path_303" data-name="Path 303" d="M-7.071-7.071,13,0-1.278-1.278,0,13Z" transform="matrix(0.588, 0.809, -0.809, 0.588, 1248.531, 164.052)" fill="#ff851b" stroke="#ff851b" stroke-width="3"/>
            </g>
        </svg>

    </div>
} @else {
    <app-offerings-list 
            (onDelete)="onDelete.emit($event)"
            (onEdit)="onEdit.emit($event)"
            [offerings]="offerings || []"/>
}
