import {Component} from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {AuthService} from "../../services/auth.service";
import {SignupService} from "../../services/signup.service";
import {MessageService} from "../../services/message.service";
import {ConfirmEmailComponent} from "./components/confirm-email/confirm-email.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-signup-confirm-email',
    standalone: true,
    imports: [
        MainHeaderComponent,
        MainPanelComponent,
        FooterCompactComponent,
        ConfirmEmailComponent,
        MainTitleComponent,
        RouterLink
    ],
    templateUrl: './signup-confirm-email.component.html',
    styleUrl: './signup-confirm-email.component.scss'
})
export class SignupConfirmEmailComponent {
    
    constructor(private authService: AuthService, 
                private messageService: MessageService,
                private signupService: SignupService) {
    }
    resendConfirmationEmail() {
        const email = this.signupService.signedUpEmail();
        this.authService.resendConfirmationEmail(email).subscribe({
            next: () => this.messageService.info('Confirmation email sent'),
            error: () => this.messageService.error('Unable to send confirmation email')
        });
    }
}
