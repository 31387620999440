import {
    ChangeDetectionStrategy,
    Component, ElementRef,
    EventEmitter,
    HostBinding, Input, OnInit,
    Output, Renderer2,
    ViewChild
} from '@angular/core';
import {JsscriptService} from "../../../../services/jsscript.service";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-e-learning',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './e-learning.component.html',
    styleUrl: './e-learning.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ELearningComponent implements OnInit {
    @HostBinding('class.sp-typography') t = true;
    @Input() authenticated = false;
    
    constructor(private jsScriptService: JsscriptService, private renderer: Renderer2, private el: ElementRef) {}
    
    ngOnInit() {
        this.jsScriptService.loadScript(this.renderer, this.el, 'https://fast.wistia.com/embed/medias/0isgqw9qww.jsonp');
        this.jsScriptService.loadScript(this.renderer, this.el, 'https://fast.wistia.com/assets/external/E-v1.js');        
    }
}
