import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DropdownComponent, IDropdownItem} from "../dropdown/dropdown.component";
import {DatePickerComponent} from "../../../components/date-picker/date-picker.component";
import {InfoPanelComponent} from "../../../components/info-panel/info-panel.component";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-date-filter',
    standalone: true,
    imports: [
        DropdownComponent,
        DatePickerComponent,
        InfoPanelComponent,
        DatePipe
    ],
    templateUrl: './date-filter.component.html',
    styleUrl: './date-filter.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFilterComponent implements OnChanges {    
    @Input() value: IDateFilterValue = {
        filterType: DateFilterType.NotSet
    }
    
    @Input() position: 'bottom' | 'top' = 'bottom';
    
    @Output() valueChange = new EventEmitter<IDateFilterValue>();
    
    get filterType() {
        return this.value.filterType;
    }

    showDatePicker1 = false;
    showDatePicker2 = false;

    selectedFilterType: IDropdownItem | undefined;
    
    filterTypes: IDropdownItem[] = [
        {value: DateFilterType.Is, text: 'Is'},
        {value: DateFilterType.Between, text: 'Between'},
        {value: DateFilterType.OnOrAfter, text: 'On or After'},
        {value: DateFilterType.OnOrBefore, text: 'On or Before'},
    ];

    DateFilterType = DateFilterType;
    
    ngOnChanges() {        
        this.selectedFilterType = this.filterTypes.find(x => x.value == this.value.filterType);
    }
}

export enum DateFilterType {
    NotSet,
    Is,
    Between,
    OnOrAfter,
    OnOrBefore,
}

export interface IDateFilterValue {
    filterType: DateFilterType;
    date1?: Date;
    date2?: Date;
}