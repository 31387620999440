import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {DropdownComponent, IDropdownItem} from "../dropdown/dropdown.component";

@Component({
    selector: 'app-reports-sort',
    standalone: true,
    imports: [
        DropdownComponent
    ],
    templateUrl: './reports-sort.component.html',
    styleUrl: './reports-sort.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsSortComponent implements OnChanges {
    
    @HostBinding('class.sp-form') f = true;
    @Input() sortIndex = 2;
    @Input() sortDirection = 1;
    
    @Output() sortChange = new EventEmitter<IReportSort>();

    sortDirectionSelected!: IDropdownItem;
    sortItemsSelected!: IDropdownItem;

    sortItems: IDropdownItem[] = [
        { value: 1, text: 'Company' },
        { value: 2, text: 'Meeting Date' },
        { value: 3, text: 'Meeting With' },
        { value: 4, text: 'Offering' },
        { value: 5, text: 'Last Viewed' },
        { value: 6, text: 'Last Modified' },
    ];
    sortDirectionItems: IDropdownItem[] = [
        { value: 1, text: 'Ascending' },
        { value: -1, text: 'Descending' },        
    ]
    
    ngOnChanges(changes: SimpleChanges) {
        this.sortDirectionSelected = this.sortDirectionItems.find(x => x.value == this.sortDirection)!;
        this.sortItemsSelected = this.sortItems.find(x => x.value == this.sortIndex)!;
    }

    update() {
        this.sortChange.emit({
            sortIndex: this.sortIndex,
            sortDirection: this.sortDirection           
        });
    }
}

export interface IReportSort {
    sortIndex: number;
    sortDirection: number;
}