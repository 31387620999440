import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-time-picker',
    standalone: true,
    imports: [],
    templateUrl: './time-picker.component.html',
    styleUrl: './time-picker.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePickerComponent implements OnInit, OnChanges {
    @Input() time = '';
    @Input() hoursActive = true;
    
    @Output() timeChange = new EventEmitter<string>();
    @Output() onTimSelected = new EventEmitter<string>();
     
    hour = '00';
    minute = '00';
    mode = 'AM';

    hourHandTransform = 'rotate(-90deg)';
    minuteHandTransform = 'rotate(-90deg)';

    hourLabels: string[] = Array.from({length: 12}, (_, i) => (i + 1).toLocaleString());
    minuteLabels: string[] = Array.from({length: 12}, (_, i) => ((i + 1) * 5).toLocaleString());

    ngOnChanges() {
        console.log('[TimePickerComponent] ngOnChanges', this.time);
        this._init();
    }
    
    ngOnInit() {
        console.log('[TimePickerComponent] ngOnInit', this.time);
        this._init();        
    }
        
    private _init() {
        const t = this.time || ((new Date().getHours() + 1) + ':00');
        
        const args = t.split(':');
        if (args.length > 1) {
            let hour = +args[0];
            const h = hour > 12 ? hour - 12 : hour;
            this._setHour(h);            
            this._setMinute(+args[1])
            this.mode = hour > 12 ? 'PM' : 'AM';            
            this._update(false);
        }        
    }
    
    private _setHour(h: number) {        
        this.hour = h > 9 ? h + '' : '0' + h;
    }
    
    private _setMinute(m: number) {
        m = m % 60;
        this.minute = m > 9 ? m + '': '0' + m;
    }

    setMode(mode: string) {
        if (this.mode == mode) return;
        this.mode = mode;
        this._update();
                
    }
    
    private _update(emitUpdate = true) {
        const hour = +this.hour;
        const h = this.mode == 'AM' ? +this.hour : (+this.hour + 12);
        const m  = this.minute;

        const time = h + ':' + m;
        if (this.time != time) {
            this.time = time;
            if (emitUpdate) {
                this.timeChange.emit(this.time);
            }
        }

        let d = (hour / 12.0) * 360 - 90;        
        this.hourHandTransform = `rotate(${d}deg)`;

        const mdeg = +this.minute * 6 - 90;
        this.minuteHandTransform = `rotate(${mdeg}deg)`;
    }

    getLabelPosition(index: number): any {
        const radius = 42; // Adjust as needed
        const angle = (index + 1) * 30 - 90; // 30 degrees per hour
        const x = radius * Math.cos(angle * Math.PI / 180);
        const y = radius * Math.sin(angle * Math.PI / 180);
        return { left: `${x}%`, top: `${y}%` };
    }

    setHour(h: number) {
        this._setHour(h);        
        this._update();
        
        this.hoursActive = false;
    }
    
    setMinute(m: number) {
        this._setMinute(m)
        this._update();
        
        this.onTimSelected.emit();
    }
}
