@if (activeRequests() > 0 || busy()) {
    <app-progress-bar qa="pr-bar"></app-progress-bar>
}

@if (stateService.updateAvailable()) {
    <app-update-available (onActivateUpdate)="updateManagerService.activateUpdate()"/>
}

@if (trialExpirationNoticeService.noticeVisible()) {    
    <app-trial-expiration-notice class="desktop-layout" (onDismiss)="trialExpirationNoticeService.dismiss()" 
                                 [nextPayment]="trialExpirationNoticeService.nextPayment()" />
    
}

@if (messageService.confirmMessage()) {
    <app-confirmation-dialog 
            (onConfirm)="messageService.onConfirm()"
            (onCancel)="messageService.onCancel()"
            [message]="messageService.confirmMessage()" 
            [title]="messageService.confirmTitle()" />
}

<div class="content" [class.authenticated]="authenticated()">
    <router-outlet />
</div>

@if (authenticated()) {
    <app-mobile-nav class="sp-not-desktop"></app-mobile-nav>
}