<app-top-section [authenticated]="authenticated"/>

<div class="wrapper"><app-sales-prep-system [authenticated]="authenticated" (showPricing)="scrollToPricing()" qa="app-auth" ngSkipHydration/></div>

<div class="wrapper"><app-ai-insights id="reports" [authenticated]="authenticated" qa="app-rep" /></div>
<div class="wrapper"><app-e-learning id="elearning" [authenticated]="authenticated" qa="app-e" ngSkipHydration/></div>
<div class="wrapper"><app-ai-guide id="aiguide" [authenticated]="authenticated" qa="app-ai"/></div>
<div class="wrapper"><app-resources id="resources" [authenticated]="authenticated" qa="app-res"/></div>

<div class="wrapper twelve-ways twelve-ways-{{ twelveWayStep }}">
    <app-twelve-way-slider [inline]="true" [(step)]="twelveWayStep"/>
</div>
<div #pricing class="wrapper pricing">
    <app-pricing 
            (onStartTrial)="signUp()" 
            (onContactUs)="contactUs()"
            (onSelectWorkshop)="signUp(SignUpMode.EvocWorkshop)"/>
</div>


<div class="wrapper aim-company">
    <app-aim-company/>
</div>


<div class="wrapper faq">
    <app-pure-faq [splashMode]="true"/>
</div>
