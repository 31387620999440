import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TwelveWaySliderComponent} from "./components/twelve-way-slider/twelve-way-slider.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {PricingComponent} from "../splash/components/pricing/pricing.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {
    TwelveWayComparitionGridComponent
} from "./components/twelve-way-comparition-grid/twelve-way-comparition-grid.component";
import {ActivatedRoute} from "@angular/router";
import {TwelveWaysServiceService} from "./services/twelve-ways-service.service";

@Component({
    selector: 'app-twelve-way-slider-screen',
    standalone: true,
    imports: [
        TwelveWaySliderComponent,
        FooterSmartComponent,
        MainHeaderComponent,
        MainPanelComponent,
        PricingComponent,
        PageHeaderComponent,
        TwelveWayComparitionGridComponent
    ],
    templateUrl: './twelve-way-slider-screen.component.html',
    styleUrl: './twelve-way-slider-screen.component.scss'
})
export class TwelveWaySliderScreenComponent implements OnInit {
    
    @ViewChild('grid', { static: true }) grid: ElementRef | undefined;
    
    currentStep = 1;

    constructor(private service: TwelveWaysServiceService, private route: ActivatedRoute) {
        route.params.subscribe(p => {
            this.currentStep = p['step'] || 1
        })
        
    }

    ngOnInit() {
        
    }
    scrollToGrid(): void {
        this.grid?.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
}
