import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AutofocusDirective} from "../../../../../../directives/autofocus.directive";
import {FormsModule} from "@angular/forms";
import { Company } from '../../../../../../model';

@Component({
    selector: 'app-add-company',
    standalone: true,
    imports: [
        AutofocusDirective,
        FormsModule
    ],
    templateUrl: './add-company.component.html',
    styleUrl: './add-company.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddCompanyComponent {
    @Input() company!: Company;
    @Output() companyChange = new EventEmitter<Company>();
    
    @Output() onAdd = new EventEmitter();

    submit(isValid: boolean) {
        console.log('[AddCompanyComponent] submit', isValid);
        
        if (isValid) {
            this.onAdd.emit(this.company);
        }
    }
}
