@if (company.website) {
    <div class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.003" viewBox="0 0 20 18.003" class="www">
            <g id="www_icon" data-name="www icon" transform="translate(-665 -5052)">
                <path id="Icon_awesome-globe" data-name="Icon awesome-globe" d="M12.214,6.08C11.688,2.838,10.446.563,9,.563S6.316,2.838,5.789,6.08Zm5.089,0A9.021,9.021,0,0,0,11.568.94a12.251,12.251,0,0,1,1.815,5.14ZM6.432.94A9.015,9.015,0,0,0,.7,6.08h3.92A12.2,12.2,0,0,1,6.432.94ZM5.789,13.049C6.316,16.29,7.557,18.566,9,18.566s2.686-2.276,3.212-5.517Zm5.782,5.14a9.029,9.029,0,0,0,5.735-5.14h-3.92a12.251,12.251,0,0,1-1.815,5.14ZM.7,13.049a9.021,9.021,0,0,0,5.735,5.14,12.251,12.251,0,0,1-1.815-5.14H.7Z" transform="translate(666 5051.438)"/>
                <text id="WWW" transform="translate(675 5063.5)" font-size="7" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="-9" y="0">WWW</tspan></text>
            </g>
        </svg>
        <a href="{{ company.website }}" class="sp-link desktop-layout" qa="wb">{{ company.website }}</a>
        <a href="{{ company.website }}" class="sp-link mobile-layout" qa="wb-lbl">Official Website</a>
    </div>
}
@if (company.linkedInUrl) {
    <div class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="linkedin_icon" data-name="linkedin icon" d="M4.029,18H.3V5.983H4.029ZM2.161,4.344A2.171,2.171,0,1,1,4.322,2.162,2.179,2.179,0,0,1,2.161,4.344ZM18,18H14.272V12.15c0-1.394-.028-3.182-1.94-3.182-1.94,0-2.238,1.515-2.238,3.082V18H6.367V5.983H9.946V7.622H10a3.921,3.921,0,0,1,3.531-1.941C17.306,5.682,18,8.169,18,11.4V18Z" transform="translate(0 0)"/>
        </svg>
        <a href="{{ company.linkedInUrl }}" class="sp-link desktop-layout" qa="li">{{ company.linkedInUrl | url }}</a>
        <a href="{{ company.linkedInUrl }}" class="sp-link mobile-layout" qa="li-lbl">LinkedIn</a>
    </div>
}
<!--@if (company.crunchbasePermalink) {
    <div class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="crunchbase_icon" data-name="crunchbase icon" d="M16.2,0H1.8A1.808,1.808,0,0,0,0,1.8V16.2A1.807,1.807,0,0,0,1.8,18H16.2A1.807,1.807,0,0,0,18,16.2V1.8A1.807,1.807,0,0,0,16.2,0ZM5.284,10.849a1.583,1.583,0,0,0,2.1-.784H8.625a2.768,2.768,0,1,1,0-1.312H7.38a1.583,1.583,0,1,0-2.1,2.1Zm8.509.634a2.663,2.663,0,0,1-.8.472,2.76,2.76,0,0,1-2.543-.285v.285H9.323V4.028h1.125V7.11a2.8,2.8,0,0,1,1.35-.473H12a2.753,2.753,0,0,1,1.793,4.845Zm-.167-2.075A1.578,1.578,0,1,1,12.047,7.83a1.577,1.577,0,0,1,1.578,1.578Z" />
        </svg>
        <a href="https://www.crunchbase.com/organization/{{ company.crunchbasePermalink }}" class="sp-link desktop-layout" qa="cr">crunchbase.com/organization/{{ company.crunchbasePermalink }}</a>
        <a href="https://www.crunchbase.com/organization/{{ company.crunchbasePermalink }}" class="sp-link mobile-layout" qa="cr-lbl">Crunchbase</a>
    </div>
}-->
@if (company.facebookUrl) {
    <div class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.891" viewBox="0 0 18 17.891">
            <path id="facebook_icon" data-name="facebook icon" d="M18.562,9.563A9,9,0,1,0,8.156,18.454V12.164H5.87v-2.6H8.156V7.58a3.175,3.175,0,0,1,3.4-3.5,13.851,13.851,0,0,1,2.015.176V6.468H12.435a1.3,1.3,0,0,0-1.466,1.406V9.563h2.5l-.4,2.6h-2.1v6.289A9,9,0,0,0,18.562,9.563Z" transform="translate(-0.563 -0.563)"/>
        </svg>
        <a href="{{ company.facebookUrl }}" class="sp-link desktop-layout" qa="fb">{{ company.facebookUrl | url }}</a>
        <a href="{{ company.facebookUrl }}" class="sp-link mobile-layout" qa="fb-lbl">Facebook</a>
    </div>
}


<!--<div class="flex">-->
<!--    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16.36" viewBox="0 0 18 16.36">-->
<!--        <path id="twitter-x_icon" data-name="twitter-x icon" d="M.264,16.36,6.658,8.824,0,.037H5.65L9.642,5.306,14.145,0h2.774L10.951,7.034,18,16.336H12.35L7.967,10.551,3.038,16.36Zm12.868-1.6h1.7L4.868,1.612h-1.7Z"/>-->
<!--    </svg>-->
<!--</div>-->