import {Component, Input} from '@angular/core';
import { Company } from '../../model';

@Component({
    selector: 'app-company-logo',
    standalone: true,
    imports: [],
    templateUrl: './company-logo.component.html',
    styleUrl: './company-logo.component.scss'
})
export class CompanyLogoComponent {
    @Input() company: Company | undefined;
}
