import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {DatePipe} from "@angular/common";
import {ErrorMessageComponent} from "../error-message/error-message.component";

@Component({
    selector: 'app-trial-expiration-notice',
    standalone: true,
    imports: [
        DatePipe,
        ErrorMessageComponent
    ],
    templateUrl: './trial-expiration-notice.component.html',
    styleUrl: './trial-expiration-notice.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrialExpirationNoticeComponent {
    @Input() nextPayment!: Date;
    
    @Output() onDismiss = new EventEmitter();
}
