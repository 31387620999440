<app-main-header></app-main-header>

<app-pure-home
        (onSearch)="buildReport($event)"
        (onRemove)="removeReport($event)"

        [reports]="reports()"
        [reportsLoading]="reportsLoading()"
        [modules]="modules"
        [eLearningData]="eLearningData()"
    />

<app-footer-compact/>