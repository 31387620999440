import {computed, Injectable, signal} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {debounceTime, distinctUntilChanged, filter, finalize, map, of, retry, shareReplay, switchMap, tap} from "rxjs";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {Company} from "../model/company";
import {CompaniesResponse} from "../model/companiesResponse";

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {
    
    searchQuery = signal('');
    searchQueryChanged = signal(false);
    searchResultsLoading = signal(false);

    constructor(private http: HttpClient) {
    }

    search$ = toObservable(this.searchQuery).pipe(
        tap(() => this.searchQueryChanged.set(true)),
        debounceTime(600),
        distinctUntilChanged(),
        map(query => query.trim()),
        switchMap(query => {
            return query.length > 1 ? this.searchData(query) : of(<Company[]>[]);
        })        
    );

    search = toSignal<Company[], Company[]>(this.search$, {
        initialValue: []
    });
    

    private searchData(query: string) {
        console.log('[CompaniesService] Looking for ' + query);
        this.searchResultsLoading.set(true);
        return this.http.get<CompaniesResponse>('/api/companies/search?query=' + encodeURIComponent(query))
            .pipe(
                map(data => data.companies),
                retry(1),
                shareReplay(1),
                finalize(() => {
                    this.searchQueryChanged.set(false);
                    this.searchResultsLoading.set(false);
                })
            );
    }

    searchForCompany(query: string) {
        this.searchQuery.set(query);
    }

    reset() {
        this.searchForCompany('');
    }
}
