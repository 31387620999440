import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Company, SalesPrepReport} from "../../../model";
import {DropdownComponent, IDropdownItem} from "../dropdown/dropdown.component";
import {DateFilterComponent, DateFilterType, IDateFilterValue} from "../date-filter/date-filter.component";
import {app} from "../../../../../server";
import {MultiselectComponent} from "../multiselect/multiselect.component";
import {ReportsFilterService} from "../services/reports-filter.service";

@Component({
    selector: 'app-reports-filter',
    standalone: true,
    imports: [
        DropdownComponent,
        DateFilterComponent,
        MultiselectComponent
    ],
    templateUrl: './reports-filter.component.html',
    styleUrl: './reports-filter.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsFilterComponent implements OnChanges {
    @Input() reports: SalesPrepReport[] = [];
    
    @Input() filter: IReportFilter = ReportsFilterComponent.getEmptyFilters();
    
    companiesItems: IDropdownItem[] = [];
    rolesItems: IDropdownItem[] = [];
    offeringsItems: IDropdownItem[] = [];

    @Output() filterChange = new EventEmitter<IReportFilter>();

    
    constructor(private reportsFilterService: ReportsFilterService) {
    }
    
    
    public static getEmptyFilters(): IReportFilter {
        return {
            selectedCompany: [],
            meetingDateFilter: { filterType: DateFilterType.NotSet },
            roles: [],
            offerings: [],
            lastViewedFilter: { filterType: DateFilterType.NotSet },
            lastModifiedFilter: { filterType: DateFilterType.NotSet },
        }
    } 
    
    
    ngOnChanges() {
        this._init();
    }

    updateFilter() {
    }
    
    private _init() {
        this.companiesItems = [];
        this.rolesItems = [];
        this.offeringsItems = [];
        
        this.reports.forEach(r => {

            const company = r.company;
            if (!this.companiesItems.find(y => y.value.id == company.id)) {
                this.companiesItems.push({
                    value: company,
                    text: company.name
                });
            }
            
            r.roles.forEach(role => {
                const _role = this._capitalize(role);
                if (!this.rolesItems.find(x => x.value == _role)) {
                    this.rolesItems.push({
                        value: _role
                    })
                }
            });
            
            const o = this._capitalize(r.offeringName);
            if (!this.offeringsItems.find(x => x.value == o)) {
                this.offeringsItems.push({
                    value: o
                })
            }
        });

        this.companiesItems.sort((x, y) => {
            const a = x.value.name;
            const b = y.value.name;

            if(a < b) { return -1; }
            if(a > b) { return 1; }
            return 0;
        });
        
        this.rolesItems.sort(this._sortByValue);
        this.offeringsItems.sort(this._sortByValue);
    }
    
    private _capitalize(str: string) {
        if (str.length < 1) {
            return str;
        }
        
        return str[0].toUpperCase() + str.substring(1);        
    }
    
    private _sortByValue(x: IDropdownItem, y: IDropdownItem) {
        const a = x.value;
        const b = y.value;

        if(a < b) { return -1; }
        if(a > b) { return 1; }
        
        return 0;        
    }

    clearFilters() {
        this.filter = ReportsFilterComponent.getEmptyFilters()
        this.applyFilters();
    }

    applyFilters() {
        this.filterChange.emit(this.filter);
    }
}

export interface IReportFilter {
    selectedCompany: IDropdownItem[];
    meetingDateFilter: IDateFilterValue;
    roles: IDropdownItem[];
    offerings: IDropdownItem[];
    lastViewedFilter: IDateFilterValue;
    lastModifiedFilter: IDateFilterValue;
}