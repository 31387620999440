<app-main-header class="sp-desktop" [noMenu]="true"/>

<app-page-header
        [title]="'SalesPrep practice exercise: Create a SalePrep report'"
        [text]="''">
    <div style="width: 61px;"></div>
</app-page-header>


<div class="sp-wrapped">
    <router-outlet></router-outlet>
</div>

<app-footer-smart/>