import {Component, EventEmitter, HostBinding, Output} from '@angular/core';

@Component({
    selector: 'app-update-available',
    standalone: true,
    imports: [],
    templateUrl: './update-available.component.html',
    styleUrl: './update-available.component.scss'
})
export class UpdateAvailableComponent {
    @Output() onActivateUpdate = new EventEmitter();
    
    @HostBinding('class.sp-form') form = true; 
}
