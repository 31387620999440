import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appClickOutside]',
    standalone: true
})
export class ClickOutsideDirective {

    @Output() clickOutside = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(target: Node) {
        const el = <HTMLElement> this.elementRef.nativeElement;
        const clickedInside = !el.contains(target);
        if (clickedInside) {
            this.clickOutside.emit();
        }
    }

}
