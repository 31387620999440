<app-offering-type [type]="type" [headerMode]="true"></app-offering-type>

<div class="title">2. My offering is best categorized as:</div>

@if (type == OfferingType.ServiceOrSoftware) {
    <div class="label">Services</div>
}

<div class="categories">
    @for (cat of group1; track cat.name; let i = $index) {
        <div (click)="categorySelected.emit(cat)" class="category sp-selectable" [innerHTML]="cat.name | formatOfferingCategory" [attr.qa]="'cat-' + (i + 1)"></div>
    }
</div>

@if (type == OfferingType.ServiceOrSoftware) {
    <div class="label software">Software</div>

    <div class="categories">
        @for (cat of group2; track cat.name) {
            <div (click)="categorySelected.emit(cat)" class="category sp-selectable" [innerHTML]="cat.name | formatOfferingCategory"></div>
        }
    </div>
}