import {MyProblemBriefReportGenerator} from "./problem-brief-report-generator";
import {IReportPart} from "../pdf-export/ireport-part";
import {BriefReportItem} from "../../model";
import {BriefCheckboxSection} from "./brief-checkbox-section";


export class BriefCheckboxesSection implements IReportPart {
    
    constructor(private pdf: MyProblemBriefReportGenerator, private title: string, private items: BriefReportItem[]) {
    }
    
    draw(y: number) {
        const pdf = this.pdf;
        const items = this.items;
        if (!items.length) {
            return 0;
        }

        y = pdf.subHeading(y, this.title);        

        items.forEach(a => {            
            const s = new BriefCheckboxSection(pdf, a);
            if (y + s.getHeight() > pdf.pageHeight) {
                y = pdf.addPage();                
            }
            y = s.draw(y) + 10;
        });

        return y;
    }
}