<div class="flex">
    <h1 qa="h1-news">Company News</h1>
    <app-rate-section [(rating)]="rating" (ratingChange)="onRatingChange.emit($event)"></app-rate-section>
</div>

@for (article of news; track article.url; let i = $index) {
    <app-news-section-item 
            [article]="article"
            [attr.qa]="'news-' + (i + 1)" />
} @empty {
    <div class="article" qa="no-items">Sorry, we couldn't find any related news at this time.</div>
}

<app-rate-section-footer [(rating)]="rating" (ratingChange)="onRatingChange.emit($event)"/>
