import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatOfferingCategory',
    standalone: true
})
export class FormatOfferingCategoryPipe implements PipeTransform {

    transform(value: string | undefined, ...args: unknown[]): string {
        if (!value) return 'NA';
        const i = value.indexOf('(');
        if (i > 0 && value[value.length - 1] == ')') {
            return `<strong>${value.substring(0, i - 1)}</strong> ${value.substring(i)}`
        }
        return `<strong>${value}</strong>`;
    }

}
