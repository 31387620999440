import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ErrorMessageComponent} from "../../../../components/error-message/error-message.component";
import {FormsModule, NgForm} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {NoteComponent} from "../../../../components/note/note.component";
import {MainPanelComponent} from "../../../../components/main-panel/main-panel.component";

@Component({
    selector: 'app-pure-forgot-password',
    standalone: true,
    imports: [
        ErrorMessageComponent,
        FormsModule,
        RouterLink,
        NoteComponent,
        MainPanelComponent
    ],
    templateUrl: './pure-forgot-password.component.html',
    styleUrl: './pure-forgot-password.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureForgotPasswordComponent {
    @Input() errorMessage = '';
    @Input() email = '';
    @Input() sent = false;
    
    @Output() onRecovery = new EventEmitter<string>();

    recovery(form: NgForm) {
        if (form.valid) {
            this.onRecovery.emit(this.email);
        }
        
    }
}
