import {TextSection} from "../text-section";
import {PdfGenerator} from "../pdf-generator";
import {CompanyCompetitor, DiscussionTopic, Person} from "../../../model";
import {IReportSection} from "../report-section";
import {CompanyStrategy} from "../../../model/companyStrategy";

export class StrategyItemSection implements IReportSection {

    maxWidth = 152.937;
    sectionMarginBottom = 21;
    titleMarginBottom = 5;

    private _height = 0;
    private _titleSection: TextSection;
    private _descSection: TextSection;

    constructor(private pdf: PdfGenerator, strategy: CompanyStrategy) {
        this._titleSection = pdf.createSection(strategy.name, this.maxWidth, 'Raleway-Bold', 10)
        this._descSection = pdf.createSection(strategy.description, this.maxWidth, 'Raleway-Regular', 9)
    }

    getHeight() {
        if (this._height) {
            return this._height;
        }
        this._height = this._titleSection.getHeight(this.pdf) + this.titleMarginBottom
            + this._descSection.getHeight(this.pdf)
            + this.sectionMarginBottom;
        return this._height;
    }

    draw(x: number, y: number) {
        y = this._titleSection.draw(this.pdf, x, y) + this.titleMarginBottom;
        return this._descSection.draw(this.pdf, x, y) + this.sectionMarginBottom;
    }

}