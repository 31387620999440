import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'app-main-panel',
    standalone: true,
    imports: [],
    templateUrl: './main-panel.component.html',
    styleUrl: './main-panel.component.scss'
})
export class MainPanelComponent {
    
    @HostBinding('class.secondary') @Input() secondary = false;
    // @HostBinding('style.padding') 
    @Input() padding: string | number = '1.875em';

}
