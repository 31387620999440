import {ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-user-avatar',
    standalone: true,
    imports: [],
    templateUrl: './user-avatar.component.html',
    styleUrl: './user-avatar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnChanges {
    @Input() initials = '';
    
    @HostBinding('class.customer') customer = false;
    
    ngOnChanges(changes: SimpleChanges) {
        this.customer = this.initials == 'C';
    }
}
