<app-dropdown class="picker-0" [position]="position" [(selectedItem)]="selectedFilterType" [items]="filterTypes" (selectedItemChange)="value.filterType = $event?.value || DateFilterType.NotSet" qa="pd-0"/>

<div class="pickers">
    <div class="picker-1 date" (click)="showDatePicker1 = true" [class.hidden]="filterType == DateFilterType.NotSet" qa="pd-1">
        {{ value.date1 | date }} <span class="material-symbols-outlined">calendar_today</span>
    </div>
    
    @if (filterType == DateFilterType.Between) {
        <span class="separator" [class.hidden]="filterType != DateFilterType.Between">-</span>
        
        <div class="picker-2 date" (click)="showDatePicker2 = true" [class.hidden]="filterType != DateFilterType.Between" qa="pd-2">
            {{ value.date2 | date }} <span class="material-symbols-outlined" qa="tod">calendar_today</span>
        </div>
    }
</div>

@if (showDatePicker1) {
    <app-info-panel [title]="'Select Date'" (onClose)="showDatePicker1 = false">
        <app-date-picker [(date)]="value.date1" (dateChange)="showDatePicker1 = false"/>
    </app-info-panel>
}

@if (showDatePicker2) {
    <app-info-panel [title]="'Select Date'" (onClose)="showDatePicker2 = false">
        <app-date-picker [(date)]="value.date2" (dateChange)="showDatePicker2 = false"/>
    </app-info-panel>
}

