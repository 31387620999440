import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-pricing',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './pricing.component.html',
    styleUrl: './pricing.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingComponent {

    @HostBinding('class.inline') @Input() inline = true;    
    @Input() annualPlans = true;
    
    @Output() onStartTrial = new EventEmitter();
    @Output() onSelectWorkshop = new EventEmitter();
    
    // @Output() onStartMonthlyPlan = new EventEmitter();
    // @Output() onStartAnnualPlan = new EventEmitter();
    
    @Output() onContactUs = new EventEmitter();
    // @Output() onEverydayVOCWorkshop = new EventEmitter();
    
    @HostBinding('class.sp-typography') t = true;
}
