import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-confirmation-dialog',
    standalone: true,
    imports: [],
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {
    @Output() onCancel = new EventEmitter();
    @Output() onConfirm = new EventEmitter();
    @Input() title: string = 'Confirmation';
    @Input() message: string = 'Are you sure?';
}
