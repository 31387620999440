<div class="company-logo">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="95.103" height="104.629" viewBox="0 0 95.103 104.629">
        <defs>
            <linearGradient id="cp-linear-gradient" x1="-0.014" y1="0.006" x2="0.99" y2="-0.009" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#d1d1d1"/>
                <stop offset="1" stop-color="#d1d1d1" stop-opacity="0"/>
            </linearGradient>
        </defs>
        <g id="Group_334" data-name="Group 334" transform="translate(2760 -2482.713)">
            <path id="Rectangle_397" data-name="Rectangle 397" d="M6,0H55.481a0,0,0,0,1,0,0V88.726a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V6A6,6,0,0,1,6,0Z" transform="matrix(0.839, 0.545, -0.545, 0.839, -2711.427, 2482.713)" opacity="0.5" fill="url(#cp-linear-gradient)" style="mix-blend-mode: multiply;isolation: isolate"/>
            <path id="Rectangle_396" data-name="Rectangle 396" d="M0,0H50a4,4,0,0,1,4,4V68a4,4,0,0,1-4,4H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(-2760 2485)" fill="#3b536c"/>
            <path id="Path_61" data-name="Path 61" d="M0,0H2V3.249l-2,2Z" transform="translate(-2755 2557)" fill="#ff851b"/>
            <path id="Path_62" data-name="Path 62" d="M2,0H0V3.249l2,2Z" transform="translate(-2753 2557)" fill="#db6600"/>
            <rect id="Rectangle_398" data-name="Rectangle 398" width="2" height="72" transform="translate(-2758 2485)"/>
            <rect id="Rectangle_399" data-name="Rectangle 399" width="34" height="28" rx="2" transform="translate(-2748 2496)" fill="#fff"/>
            <rect id="Rectangle_400" data-name="Rectangle 400" width="22" height="2" rx="1" transform="translate(-2742 2534)" fill="#fff"/>
            <rect id="Rectangle_401" data-name="Rectangle 401" width="22" height="2" rx="1" transform="translate(-2742 2538)" fill="#fff"/>
            <rect id="Rectangle_402" data-name="Rectangle 402" width="22" height="2" rx="1" transform="translate(-2742 2542)" fill="#fff"/>
        </g>
    </svg>
    @if (company && company.logoUrl) {
        <div class="logo" [style.background-image]="'url(' + company.logoUrl + ')'"></div>
    }
</div>