<div class="header" qa="sel-report">
    <strong>
        1. Select <a class="sp-link blue" (click)="onSelectOffering.emit()">an offering</a> or a report
    </strong> for this practice session
</div>

<div class="wrapper">
    <div class="report-mobile mobile-layout">
        @for (r of sortedReports; track r.id; let index = $index) {
            <a class="sp-selectable flex" (click)="onSelectReport.emit(r)">
                @if (r.company && r.company.logoUrl) {
                    <div class="logo" [style.background-image]="'url(' + r.company.logoUrl + ')'"></div>
                }
                
                <div class="flex-column">                    
                    <div class="name">
                        <div class="date">
                            @if (r.meetingDate) {
                                <span qa="meet-date">{{ r.meetingDate | date }}</span>&nbsp;
                                <span qa="meet-time">{{ r.meetingTime | time }}</span>
                            } @else {
                                <span class="na" qa="meet-date">Not Set</span>
                            }
                        </div>
                        <span class="company">
                            {{ r.company.name }}
                        </span>
                    </div>
                    <div class="offering">
                        <app-report-subject
                                [offeringName]="r.offeringName"
                                [offeringType]="r.offeringType"
                                [job]="r.job" />
                    </div>
                </div>
            </a>
        }
    </div>
    <div class="reports desktop-layout">
        @for (r of sortedReports; track r.id; let index = $index) {
            <div class="sp-selectable report" (click)="selectedIndex = index" [class.selected]="selectedIndex == index">
                <div class="icon">
                    <input type="radio" id="offering-{{index}}" [value]="index" [(ngModel)]="selectedIndex" qa="inpt-off">
                    @if (r.company && r.company.logoUrl) {
                        <div class="logo" [style.background-image]="'url(' + r.company.logoUrl + ')'"></div>
                    }
                </div>
                <div class="name">
                    <div class="date">
                        @if (r.meetingDate) {
                            <span qa="meet-date">{{ r.meetingDate | date }}</span>&nbsp;
                            <span qa="meet-time">{{ r.meetingTime | time }}</span>
                        } @else {
                            <span class="na" qa="meet-date">Not Set</span>
                        }
                    </div>
                    <span class="company">
                        {{ r.company.name }}
                    </span>
                </div>
                <div class="roles">
                    <div class="with">With</div>
                    <div>
                        @if (!r.roles || !r.roles.length) {
                            <span class="na" qa="meet-with">Not Set</span>
                        } @else {
                            <span class="titles" qa="meet-with">{{ r.roles | roles }}</span>
                        }
                    </div>
                </div>
                <div class="offering">
                    <app-report-subject
                            [offeringName]="r.offeringName"
                            [offeringType]="r.offeringType"
                            [job]="r.job" />
                </div>
            </div>
        }
    </div>
    
    <button [routerLink]="['/report-builder', 'new']" type="button" class="add alternative">
        <i class="material-icons">add</i>
        <span class="text" qa="add-off">Add @if (sortedReports.length) { another } report</span>
    </button>
    
</div>


<div class="desktop-layout sp-form">
    <button (click)="onSelectReport.emit(sortedReports[selectedIndex])" [disabled]="selectedIndex == -1" class="primary">Next</button>
</div>