import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    HostBinding, Inject,
    Input,
    OnChanges, OnDestroy,
    OnInit, Output, PLATFORM_ID,
    SimpleChanges
} from '@angular/core';
import {Router} from "@angular/router";
import {TwelveWaysServiceService} from "../../services/twelve-ways-service.service";
import {CheckmarkComponent} from "../checkmark/checkmark.component";
import {animate, style, transition, trigger} from "@angular/animations";
import {Subscription, timer} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

export interface TwelveWayStep {
    indent?: number;
    title: string;
    description: string;
}

@Component({
    selector: 'app-twelve-way-slider',
    standalone: true,
    imports: [
        CheckmarkComponent
    ],
    templateUrl: './twelve-way-slider.component.html',
    styleUrl: './twelve-way-slider.component.scss',
    animations: [
        trigger('fadeOutIn', [
            transition('* => *', [
                style({ opacity: 1 }),
                animate(0, style({ opacity: 0 })),
                animate('0.4s', style({ opacity: 1 })),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwelveWaySliderComponent implements OnInit, OnChanges, OnDestroy {
    @Input() step = 1;    
    @Output() stepChange = new EventEmitter<number>();
    @HostBinding('class.inline') @Input() inline = false;
    @HostBinding('class.sp-typography') t = true;

    reportTitle = this.service.reportTitle;
    rolePlayTitle = this.service.rolePlayTitle;
    stepsDetails = this.service.steps;
    
    private _timerSub: Subscription | undefined;
    private _timerDisabled = false;

    private observer!: IntersectionObserver; // Intersection observer to detect visibility

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private cdRef: ChangeDetectorRef,
                private elementRef: ElementRef,
                private router: Router, private service: TwelveWaysServiceService) {
    }

    ngOnInit() {
        const isServer = !isPlatformBrowser(this.platformId);
        if (isServer || !this.inline) {
            return;
        }
        
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // When the component is visible, start the timer and animation
                    this._startTimer();
                }
            });
        });

        // Observe the component's root element
        this.observer.observe(this.elementRef.nativeElement);
        
        this.ngOnChanges();
    }
    
    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
        
        this._stopTimer()
    }

    ngOnChanges() {
        this.steps.forEach((step, i) => {
            step.indent = Math.abs((this.step - 1) - i);
        });
    }
    
    private _startTimer() {
        if (this._timerSub) {
            return;
        }
        
        if (this._timerDisabled) {
            console.log('[TwelveWaySliderComponent] timer disabled');
            return;            
        }
        
        console.log('[TwelveWaySliderComponent] startTimer');        
        
        this._timerSub = timer(5000, 5000).subscribe(() => {
            this._next();
            this.cdRef.detectChanges();
        });
    }
    
    private _stopTimer() {
        console.log('[TwelveWaySliderComponent] _stopTimer');
        
        this._timerDisabled = true;
        this._timerSub?.unsubscribe();
        this._timerSub = undefined;
    }
    
    prev() {
        this._stopTimer();
        this.step = this.step == 1 ? this.stepsDetails.length : +this.step - 1;
        this.stepChange.emit(this.step);
        this.ngOnChanges();
    }
    
    next() {
        this._stopTimer();
        this._next();
    }
    
    private _next() {
        console.log('[TwelveWaySliderComponent] _next');        
        this.step = this.step == this.stepsDetails.length ? 1 : (+this.step + 1);
        this.stepChange.emit(this.step);
        this.ngOnChanges();
    }
    
    select(index: number) {
        this._stopTimer();
        
        if (this.inline) {
            this.step = index;
            this.stepChange.emit(index);
            this.ngOnChanges();
        } else {
            this.router.navigate(['/12-ways', index])
        }
    }
    
    steps: TwelveWayStep[] = [
        {
            title: 'Start conversations',
            description: `
<div style="margin-bottom: 20px">Use Company News & Market Trends for ice-breakers:</div><br>
<blockquote>
    “How does this new acquisition impact you?”
</blockquote>
<em>-or-</em>
<blockquote>
    “What are your thoughts on this trend?”
</blockquote>
`,
        }, {
            title: 'Deepen conversations',
            description: 'Create a full customer report… and role-play with Claire.<br><br>Now you can discuss any topic… and challenge the customer with fresh insights.',
        }, {
            title: 'Stay current',
            description: 'Check Company News for late-breaking news before recurring visits.<br><br>Refresh your memory in the customer’s lobby by reviewing their full SalesPrep report.',
        }, {
            title: 'Scout new accounts',
            description: 'Use the Company Overview to quickly learn the company’s size, leadership, products, org structure, competitors, and published strategy.',
        }, {
            title: 'Explore new applications',
            description: 'Run a report to learn about Market Trends, Common Problems, and Process Steps.<br><br>Learn terminology & role-play with Claire.',
        }, {
            title: 'Engage each contact',
            description: 'Run a report that specifies the job function you’ll meet with.<br><br>Role-play with <i>this</i> job function to explore the problems important to them.',
        }, {
            title: 'Research problems',
            description: 'If you think the account has a problem you could solve, run a 2-page Problem Brief in <60 seconds.<br><br>Role-play <i>this</i> problem with Claire.',
        }, {
            title: 'Find prospects',
            description: 'Check the “Competitors” section of the Company Overview.<br><br>You’ll find prospect companies with a business model like this account’s.',
        }, {
            title: 'Make CRM entries',
            description: 'Record faster, better notes by copy-pasting from report sections.<br><br>Or paste an entire Problem Brief and simply delete the unneeded portions.',
        }, {
            title: 'Leapfrog purchasing',
            description: 'Hand the purchasing manager one of the 3 Customer Handouts.<br><br>Ask which Trends/Problems/<br class="desktop">Steps are critical. Then ask who you should talk to about them.',
        }, {
            title: 'Build skills',
            description: 'Skills need refreshing to stay sharp. Routinely conduct Intermediate- and Expert-level role-plays with Claire, AI Guide.',            
        }, {
            title: 'Investigate ideas',
            description: 'Got a new product idea? Run a Problem Brief to ensure you’re not “creating a cure for no known disease.”'        
        }        
    ];
}
