import {computed, Injectable, signal} from '@angular/core';
import {AuthService} from "./auth.service";

const TRIAL_EXPIRATION_TRESHOLD_DAYS = 7;

@Injectable({
    providedIn: 'root'
})
export class TrialExpirationNoticeService {

    trialDismissed = signal<Date | undefined>(undefined);

    noticeVisible = computed(() => {
        const p = this.authService.userProfile();
        const dismissed = this.trialDismissed();
        const nextPayment = this.nextPayment();
        
        return this.authService.authenticated() && p && p!.plan == 'Trial'
            && !p.paymentMethodAttached
            && (+new Date(nextPayment) - +new Date() < TRIAL_EXPIRATION_TRESHOLD_DAYS * 24 * 60 * 60 * 1000)
            && (
                !dismissed || (24 * 60 * 60 * 1000 + +dismissed! < +new Date())
            )
    });

    nextPayment = computed(() => this.authService.userProfile()?.nextPayment || new Date());

    constructor(private authService: AuthService) {
    }

    init() {
        const dismissed = localStorage.getItem('trialNoticeDismissed');
        if (dismissed) {
            const date = new Date(dismissed);
            this.trialDismissed.set(date);
        }
    }

    dismiss() {
        localStorage.setItem('trialNoticeDismissed', new Date().toISOString());
        this.trialDismissed.set(new Date());
        console.log(+this.trialDismissed()!, (+new Date() + (24 * 60 * 60 * 1000)));
        console.log(+this.trialDismissed()! < (+new Date() + (24 * 60 * 60 * 1000)));
    }
}
