import {Component, HostBinding} from '@angular/core';
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {FooterComponent} from "../../components/footer/footer.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-terms',
    standalone: true,
    imports: [
        MainPanelComponent,
        MainHeaderComponent,
        PageHeaderComponent,
        FooterComponent,
        FooterSmartComponent,
        RouterLink
    ],
    templateUrl: './terms.component.html',
    styleUrl: './terms.component.scss'
})
export class TermsComponent {
    @HostBinding('class.sp-typography') t = true;
}
