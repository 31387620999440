import {MyProblemBriefReportGenerator} from "./problem-brief-report-generator";
import {IReportPart} from "../pdf-export/ireport-part";


export class BriefSummarySection implements IReportPart {
    
    constructor(private pdf: MyProblemBriefReportGenerator, private title: string, private summary: string) {
    }
    
    draw(y: number) {
        const pdf = this.pdf;
        y = pdf.subHeading(y, this.title);
        y = pdf.text(this.summary, 58.457, y, 'Raleway-Medium', 9, pdf.styles.pageInnerWidth, {});
        return y + 28.964;
    }
}