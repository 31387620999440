import {PdfGenerator} from "../pdf-generator";
import {StrategyItemSection} from "./strategy-item-section";
import {IReportPart} from "../ireport-part";

export class StrategySection implements IReportPart {
    constructor(private pdf: PdfGenerator) {
    }

    draw(y: number) {
        if (!this.pdf.report.company.strategy.length) {
            return 0;
        }
        
        if (y + 150 > this.pdf.pageHeight) {
            y = this.pdf.addPage();
        }
        this.drawTitle(y);
        y += 27.143;
        return this.drawItems(y);
    }

    drawTitle(offsetY: number) {
        this.pdf.partTitle(offsetY, 'STRATEGY', 'strategy', 18, 22.5);
    }

    drawItems(offsetY: number) {
        const pdf = this.pdf;
        
        const items = pdf.report.company.strategy.map(x => new StrategyItemSection(pdf, x));

        while (true)
        {
            offsetY = Math.max(
                pdf.drawColumn(items, pdf.styles.paddingX, offsetY, items.length / 3),
                pdf.drawColumn(items, 225.498, offsetY, items.length / 3),
                pdf.drawColumn(items, 405.818, offsetY)
            );

            if (items.length == 0) {
                break;
            } else {
                pdf.addPage();
                offsetY = this.pdf.partSubTitle('STRATEGY CONTINUED...');
            }
        }

        return offsetY;
    }
}