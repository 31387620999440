import {IReportPart} from "../../pdf-export/ireport-part";
import {PdfGeneratorBase} from "../../pdf-export";

export class CustomerHandoutDisclaimerSection implements IReportPart {
    private marginTop = 20;
    private sectionHeight = 30;
    
    constructor(private pdf: PdfGeneratorBase) {
    }
    
    draw(y: number) {
        y = 772.304 - 40;
        
        const pdf = this.pdf;
        const styles = pdf.styles;
        const x = pdf.pageWidth / 2;
        pdf.doc.setTextColor('000000');
        pdf.text('THIS WORK PRODUCT HAS BEEN GENERATED BY AI AND SHOULD BE REVIEWED CAREFULLY.', x, y + this.marginTop, 'Raleway-ExtraBold', 8, 0, {
            align: 'center'
        });
        return y + this.sectionHeight + this.marginTop;
    }
}