<app-main-panel>
    <form #loginForm="ngForm" (ngSubmit)="recovery(loginForm)">
    
        <div class="sp-form">
            
            @if (sent) {
                <h1>Password Reset Instructions Sent to Your Email</h1>
                
                <p class="note">
                    You will receive an email with instructions on how to reset your password in a few minutes.
                    If you don't receive the email in a minute or two, please check your spam folder.
                </p>
    
                <app-note>
                    Look for an email from <b>SalesPrep</b>
                    &lt;<span class="email">postmaster{{'@'}}salesprep.com</span>&gt;
                    within the next couple of minutes with the subject: <b>Reset password instructions</b>
                </app-note>
                
            } @else {
        
                <h1>Forgot your password?</h1>
                
                <p class="note">
                    Enter your email address to reset your password. You may need to check your spam folder or unblock
                    <span class="email">
                        postmaster{{'@'}}salesprep.com.
                    </span>
                </p>
        
                <label for="email">Email</label>
                <input autocomplete="login" appAutofocus type="email" id="email" [(ngModel)]="email" required
                       name="email">
        
                @if (errorMessage) {
                    <app-error-message [text]="errorMessage"></app-error-message>
                }
        
                <button class="primary" type="submit" [disabled]="loginForm.invalid">
                    Send <span class="desktop-layout">&nbsp;me reset password instructions</span>
                </button>
        
                <div class="links">
                    <a class="sp-link" [routerLink]="['/login']">Login</a>
                    <a class="sp-link" [routerLink]="['/signup']">Create Account</a>
                </div>
            }
    
        </div>
    
    </form>
</app-main-panel>