<div class="header" qa="sel2-off">
    <strong>
        {{ step }}. Select the job-to-be-done
    </strong> with {{ offering.name }}@if (company) { that you plan to discuss with {{ company.name }}}.
</div>


@for (x of offering.jobs; track x; let index = $index) {
<!--    <a class="sp-selectable mobile-layout" (click)="next(x)">{{ x }}</a>-->

    <div class="sp-selectable" [class.selected]="index == selectedIndex" [attr.qa]="'job-' + (index + 1)">
        <input type="radio" id="job-{{index}}" [value]="index" [(ngModel)]="selectedIndex" qa="inpt-off">
        <label for="job-{{index}}" qa="inpt-lbl">{{ x }}</label>
    </div>
} @empty {
}

<button (click)="onAddNew.emit()" type="button" class="alternative">
    <i class="material-icons">add</i>
    <span class="text" qa="add-job">Add @if (offering.jobs.length > 0) { another } job-to-be-done</span>
</button>

@if (!offering.jobs.length) {
    <hr>
    <a class="sp-link skip" (click)="onSkip.emit()">Skip</a>
}

<div class="header" qa="sel-jf">
    <strong>
        {{ step }}a. Optional:
        @if (aiGuideMode) {
            Enter the job function of AI customer,
        } @else {
            Enter the role of the person(s) you are preparing to meet with,
        }
    </strong>e.g. <em>design engineer</em> or <em>hospital administrator</em>. 
    This creates a more focused SalesPrep report reflecting their interests more than their entire company.    
</div>


@for (role of roles; track role; let index = $index) {
    <div class="role" [attr.qa]="'role-' + (index + 1)">
        <input appAutofocus [autofocus]="!role.name" type="text" [(ngModel)]="role.name" required 
               placeholder="The role of the person I'll meet with is..." qa="role-in">

        <a (click)="removeRole(index)" title="remove role">
            <span class="material-symbols-outlined" qa="role-x">close</span>
        </a>
    </div>
}

<button (click)="addRole()" type="button" class="add-role alternative">
    <i class="material-icons">add</i>
    <span class="text" qa="add-role">Add role</span>
</button>


@if (!aiGuideMode) {
    <div class="header sp-checkbox flex" qa="list-stakeholders">
        <input style="margin-right: 10px;" type="checkbox" [(ngModel)]="stakeholdersEnabled">
        <div qa="txt">
            <strong>
                {{ step }}b. Optional: List company stakeholders
            </strong>
            — those job functions who will help decide which offering to purchase
        </div>
    </div>
}

<app-step-buttons 
                  (onBack)="onBack.emit()" 
                  (onNext)="next(offering.jobs[selectedIndex])"
                  [nextEnabled]="selectedIndex !== -1" />
