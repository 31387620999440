import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {Company, Offering} from "../../../../../../model";
import {FormsModule} from "@angular/forms";
import {StepButtonsComponent} from "../../../../components/step-buttons/step-buttons.component";
import {AutofocusDirective} from "../../../../../../directives/autofocus.directive";

@Component({
    selector: 'app-select-job-form',
    standalone: true,
    imports: [
        FormsModule,
        StepButtonsComponent,
        AutofocusDirective
    ],
    templateUrl: './select-job-form.component.html',
    styleUrl: './select-job-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectJobFormComponent implements OnInit {
    
    @Input() step = '3';
    @Input() company: Company | undefined;
    @Input() offering!: Offering;
    @Input() roles: Role[] = [];

    @HostBinding('class.ai-guide-mode') @Input() aiGuideMode = false;
    
    @Output() onSelect = new EventEmitter<JobSelectedEvent>();
    @Output() onAddNew = new EventEmitter();
    @Output() onAddRole = new EventEmitter();
    @Output() onBack = new EventEmitter();
    @Output() onSkip = new EventEmitter();

    selectedIndex = -1;
    stakeholdersEnabled = true;

    ngOnInit() {
        const jobs = this.offering.jobs;
        if (jobs.length == 1) {
            this.selectedIndex = 0;            
        }
        if (this.aiGuideMode && !this.roles.length) {
            this.addRole();            
        }        
    }

    @Input() set job(job: string) {
        this.selectedIndex = this.roles.findIndex(x => x.name === job);
    }
    
    next(job: string) {
        const e = {
            job,
            stakeholdersEnabled: this.stakeholdersEnabled,
            roles: this.roles.filter(x => !!x).map(x => x.name.trim())
        };
        console.log('[SelectJobFormComponent] next', e);
        this.onSelect.emit(e);
    }

    addRole() {
        this.roles.push({name: ''});
    }

    removeRole(index: number) {
        this.roles = this.roles.filter((_, i) => i !== index);
    }
}

export interface Role {
    name: string;
}
export interface JobSelectedEvent {
    job: string;
    stakeholdersEnabled?: boolean;
    roles: string[];    
}