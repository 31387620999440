import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import {catchError, finalize, of, tap, throwError} from "rxjs";
import {inject} from "@angular/core";
import {StateService} from "../services/state.service";
import {Router} from "@angular/router";
import {MessageService} from "../services/message.service";

export const authInterceptorInterceptor: HttpInterceptorFn = (req, next) => {
    const activeRequests = 
        !!((<any>req.body)?.hide) ? undefined : inject(StateService).activeRequests;
    
    activeRequests?.update(x => x + 1);
    const messageService = inject(MessageService);

    const clonedRequest = req.clone({
        headers: req.headers
            .set('ngsw-bypass', 'true')
            .set('X-REQUEST-ID', Math.random().toString(36).slice(2, 11))
    });
    
    return next(clonedRequest).pipe(
        finalize(() => {
            activeRequests?.update(x => x - 1);
        }),
        catchError(e => {
            console.warn('[AuthInterceptor] catchError', e);
            if (e instanceof HttpErrorResponse) {
                if ((e.status == 401 || e.status == 302)) {
                    if (
                        location.pathname != '/'
                        && !location.href.includes("/login") 
                        && !location.href.includes("/pricing") 
                        && !location.href.includes("/12-ways") 
                        && !location.href.includes("/signup")                        
                        && !location.href.includes("/try-ai-guide")
                        && !location.href.includes("/practice")
                        && !location.href.includes("/ai-guide")
                        && !location.href.includes("/contact-us")
                        && !location.href.includes("/faq")
                        && !location.href.includes("/report/")
                        && !location.href.includes("/terms")
                        && !location.href.includes("/privacy")
                        && !location.href.includes("/cookie-policy")                        
                        && !location.href.includes("/auth/reset-password")                        
                        && !location.href.includes("/auth/confirmation")                        
                    ) {
                        location.href = '/login';
                        // router.navigate(['/login']);
                    }
                } else {
                    if (e.status == 500) {
                        const error = <InternalError> e.error;
                        const msg = error ? error.Title + '<br><br>Error ID: ' + error.Id : e.statusText || 'Internal Error';
                        messageService.error(msg);
                    }
                    console.error(e);
                }
                // if (e.status == 401 && !req.url.includes("login")) {
                //     // router.navigateByUrl("/login");
                //     window.location.href = '/login';
                // }
            }
            return throwError(e);
        })
    );
};


interface InternalError {
    Id: string;
    Title: string;
}