import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {ClaireBannerComponent} from "../claire-banner/claire-banner.component";
import {Company, Offering} from "../../../../model";
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {InfoPanelComponent} from "../../../../components/info-panel/info-panel.component";
import {
    JobSelectedEvent,
    SelectJobFormComponent
} from "../../../report-builder/screens/select-job-to-be-done/components/select-job-form/select-job-form.component";
import {of} from "rxjs";
import {OfferingActionPipe} from "../../../../pipes/offering-action.pipe";
import {OfferingActionTipPipe} from "../../../../pipes/offering-action-tip.pipe";

@Component({
    selector: 'app-select-job',
    standalone: true,
    imports: [
        FormsModule,
        ClaireBannerComponent,
        AutofocusDirective,
        InfoPanelComponent,
        SelectJobFormComponent,
        OfferingActionPipe,
        OfferingActionTipPipe
    ],
    templateUrl: './select-job.component.html',
    styleUrl: './select-job.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectJobComponent {
    @Input() offering!: Offering;
    @Input() company!: Company;
    
    @Output() onSelect = new EventEmitter<JobSelectedEvent>();
    @Output() onSkip = new EventEmitter();
    @Output() onAddJob = new EventEmitter<string>();

    @HostBinding('class.sp-form') f = true;
    
    selectedIndex = -1;
    addNew = false;
    job = '';

    back() {
        window.history.back();
    }
}
