<app-note >
    <div class="profile-info">
        <div class="avatar">
            <div>{{ userProfile | initials }}</div>
        </div>
        <div>
            <div class="name">
                <a [routerLink]="['/profile/edit']">
                    {{ userProfile.firstName || 'John' }} {{ userProfile.lastName || 'Doe' }}
                </a>
            </div>
            <div class="email">{{ userProfile.email || 'john.doe@example.com' }}</div>
        </div>
    </div>

    <hr>

    <div class="container" qa="pr-div">
        <h2 qa="tit">Profile</h2>
        <div class="row">
            <div class="label" qa="comp-lbl">
                My Company
            </div>
            <div class="value" [class.empty]="!userProfile.company">
                <a [routerLink]="['/profile/edit']" qa="comp">
                   {{userProfile.company || 'Not entered'}}
                </a>
            </div>
        </div>
        <div class="row">
            <div class="label" qa="off-lbl">
                My Offerings
            </div>
            <div class="value" qa="off">{{ offeringsCount }}</div>
        </div>
        <div class="row">
            <div class="label" qa="li-lbl">
                LinkedIn Profile
            </div>
            <div class="value" [class.empty]="!userProfile.linkedIn">
                @if (userProfile.linkedIn) {
                    <a [routerLink]="['/profile/edit']" qa="li">
                       {{userProfile.linkedIn}}
                    </a>
                } @else {
                    Not entered
                }
            </div>
        </div>
    </div>

    <hr>

    <div class="container" qa="sb-div">
        <h2 qa="tit">Subscription</h2>
        <div class="row"  >
            <div class="label" qa="pl-lbl">
                Plan
            </div>
            <div class="value">
                <a target="_blank" href="/api/user/stripe-session" qa="pl">
                    {{ userProfile.plan }}
                </a>
            </div>
        </div>
        <div class="row">
            <div class="label" qa="np-lbl">
                Next Payment
            </div>
            <div class="value" qa="np">
                {{ userProfile.nextPayment | date }}
            </div>
        </div>
    </div>

    <hr>

    <a title="Log out" class="logout" (click)="onLogout.emit()" qa="logout">Log out</a>

</app-note>