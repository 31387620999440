import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-try-ai-guide-form',
    standalone: true,
    imports: [
        FormsModule,
        AutofocusDirective,
        RouterLink
    ],
    templateUrl: './try-ai-guide-form.component.html',
    styleUrl: './try-ai-guide-form.component.scss'
})
export class TryAiGuideFormComponent implements OnInit {
    data: TryAiGuideFormData = {
        offering: '',
        jobToBeDone: '',
        question: 'Before I tell you about our products, can you tell me what challenges you’re facing today?'
    };
    
    @Input() authenticated = false;
    @Input() offering = '';
    @Input() job = '';

    @Output() onSubmit = new EventEmitter<TryAiGuideFormData>();
    @HostBinding('class.sp-form') f = true;    
    
    ngOnInit() {
        this.data.offering = this.offering;
        this.data.jobToBeDone = this.job;
    }
}


export interface TryAiGuideFormData {
    offering: string;
    jobToBeDone: string;
    // roles: string[];
    question: string;
} 