<div class="flex">
    <svg xmlns="http://www.w3.org/2000/svg" width="32.531" height="33.001" viewBox="0 0 32.531 33.001">
        <path id="Thumb_down" data-name="Thumb down" d="M15,22.5v6A4.5,4.5,0,0,0,19.5,33l6-13.5V3H8.58a3,3,0,0,0-3,2.55L3.51,19.05a3,3,0,0,0,3,3.45ZM25.5,3h4.005A3.465,3.465,0,0,1,33,6V16.5a3.465,3.465,0,0,1-3.495,3H25.5" transform="translate(-1.969 -1.499)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    </svg>

    <div class="legend">
        Please tell us what is wrong or what needs to be improved with the results in
        <strong>{{ desc }}</strong>
    </div>
</div>

<textarea appAutofocus [(ngModel)]="rating.description"></textarea>

<div class="actions flex">
    <button (click)="onCancel.emit()">Cancel</button>
    <button class="primary" (click)="onSubmit.emit(rating)">Submit Reason</button>
</div>