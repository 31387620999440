import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {ClaireBannerComponent} from "../../../ai-guide/components/claire-banner/claire-banner.component";
import {TutorAvatarComponent} from "../../../../components/tutor-avatar/tutor-avatar.component";
import {
    SelectCoachingLevelComponent
} from "../../../ai-guide/components/select-coaching-level/select-coaching-level.component";
import {TutorCoachingLevels} from "../../../../model";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-ai-guide',
    standalone: true,
    imports: [
        ClaireBannerComponent,
        TutorAvatarComponent,
        SelectCoachingLevelComponent,
        RouterLink,
    ],
    templateUrl: './ai-guide.component.html',
    styleUrl: './ai-guide.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiGuideComponent {
    @Input() authenticated = false;
    @HostBinding('class.sp-typography') t = true;

    coachingLevel = TutorCoachingLevels.Intermediate;
    
    step = 0;

    prev() {
        if (this.step > 0) {
            this.step--;
        }
    }
    
    next() {
        if (this.step < 2) {
            this.step++;
        }
    }
}
