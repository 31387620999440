import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {
    ReportSectionRating,
    ReportSectionType
} from "../../model";
import {AutofocusDirective} from "../../directives/autofocus.directive";

@Component({
    selector: 'app-rate-section-dialog',
    standalone: true,
    imports: [
        FormsModule,
        AutofocusDirective
    ],
    templateUrl: './rate-section-dialog.component.html',
    styleUrl: './rate-section-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateSectionDialogComponent implements OnChanges {
    @Input() rating!: ReportSectionRating;

    @Output() onCancel = new EventEmitter();
    @Output() onSubmit = new EventEmitter<ReportSectionRating>();
    
    @HostBinding('class.sp-form') f = true;
    @HostBinding('class.sp-form-alternative') a = true;

    desc = '';
    
    ngOnChanges() {
        this.desc = this._getDesc(this.rating.section);
    }

    private _getDesc(section: ReportSectionType) {
        switch (section) {
            case ReportSectionType.MarketTrends:
                return 'Market Trends';
            case ReportSectionType.CommonProblems:
                return 'Common Problems';
            case ReportSectionType.ProcessSteps:
                return 'Process Steps';
            case ReportSectionType.OtherTopics:
                return 'Other Topics';
            case ReportSectionType.ExploratoryQuestions:
                return 'Exploratory Questions';
            case ReportSectionType.CompanyNews:
                return 'Company News';
            case ReportSectionType.CompanyDescription:
                return 'Company Description';
            case ReportSectionType.CompanySize:
                return 'Company Size';
            case ReportSectionType.Leadership:
                return 'Leadership';
            case ReportSectionType.Products:
                return 'Products';
            case ReportSectionType.Competitors:
                return 'Competitors';
            case ReportSectionType.Strategy:
                return 'Strategy';
            default:
                return 'NA';
        }
    }
}
