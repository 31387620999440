import {Injectable, signal} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {catchError, exhaustMap, filter, of, retry, shareReplay, tap} from "rxjs";
import {ReportBuilderSectionRating, ReportSectionRating} from "../model";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";

@Injectable({
    providedIn: 'root'
})
export class RatingService {
    reportId = signal<string | undefined>(undefined);
    ratings = signal<ReportSectionRating[]>([])

    ratedBuilderSections: ReportSectionRating[] = [];
    
    constructor(private http: HttpClient) {
    }

    ratings$ = toObservable(this.reportId).pipe(
        filter(id => !!id),
        tap(id => console.log(`[RatingService] Loading ratings for ${id} ...`)),        
        exhaustMap(id => 
            this.http.get<ReportSectionRating[]>(`/api/ratings/${id}`)
                .pipe(retry(1))        
        ),
        // catchError(error => of([])),        
        tap(r => console.log(`[RatingService] loaded ratings`, r)),
        tap(r => this.ratings.set(r)),
    )

    _ratings = toSignal<ReportSectionRating[], ReportSectionRating[]>(this.ratings$, {initialValue: []});

    rateReportSection(rating: ReportSectionRating) {
        return this.http.put<ReportSectionRating>(`/api/ratings`, {
            ...rating,
            reportId: this.reportId()
        }).pipe(
            retry(1)
        )
    }

    rateSection(rating: ReportBuilderSectionRating) {
        console.log('[RatingService] rateSection', rating)
        // this.ratedBuilderSections.push(rating);
        
        return this.http.put<ReportSectionRating>(`/api/ratings`, {
            ...rating
        }).pipe(
            retry(1),
            tap(r => this.ratedBuilderSections.push(r))
        )
    }

    reset() {
        this.ratedBuilderSections = [];        
    }

    saveBuilderRatings(reportId: string) {
        const ids = this.ratedBuilderSections.map(s => s.id);

        if (!ids.length) {
            return of(<ReportSectionRating> {})
        }
        return this.http.put<ReportSectionRating>(`/api/ratings/assign`, {
            reportId,
            ids
        }).pipe(
            retry(1),
            tap(r => this.reset())
        );
    }
}
