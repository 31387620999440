import {IReportPart} from "../pdf-export/ireport-part";
import {MyProblemBriefReportGenerator} from "./problem-brief-report-generator";
import {BriefReportItem} from "../../model";
import {TextSection} from "../pdf-export/text-section";

export class BriefCitationSection implements IReportPart {
    maxWidth = 492;
    sectionMarginBottom = 5;
    leftOffset = 59.356;
    
    private _height = 0;
    private _titleSection: TextSection;
    private _descSection: TextSection; // use this one only to calculate section height    
    
    constructor(private pdf: MyProblemBriefReportGenerator, private c: BriefReportItem, private i: number) {
        this._titleSection = pdf.createSection(`[${c.title}] `, this.maxWidth, 'Raleway-Medium', 9);
        this._descSection = pdf.createSection(c.description, this.maxWidth, 'Raleway-Medium', 9);
    }

    getHeight() {
        if (this._height) {
            return this._height;
        }
        this._height = this._descSection.getHeight(this.pdf) + this.sectionMarginBottom;
        return this._height;
    }

    draw(y: number) {
        const x = this.leftOffset;
        let pdf = this.pdf;
        let doc = pdf.doc;
        doc.setTextColor(pdf.styles.textColor);
        
        this._titleSection.draw(pdf, x, y);
        const offsetX = pdf.doc.getTextWidth('[1] ');

        let url = this.c.description;
        doc.setTextColor(pdf.styles.linkColor);
        doc.textWithLink(url, x + offsetX, y, { baseline: 'top', maxWidth: this.maxWidth - offsetX, url: url});
        let linkHeight = this._descSection.getHeight(pdf);
        
        return y + linkHeight + this.sectionMarginBottom;
    }
}