import {Component, OnDestroy} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {CompaniesService} from "../../../../services/companies.service";
import {Company} from "../../../../model";
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {PureSelectCompanyComponent} from "./components/pure-select-company/pure-select-company.component";
import {MessageService} from "../../../../services/message.service";

@Component({
    selector: 'app-select-company',
    standalone: true,
    imports: [
        FormsModule,
        PureSelectCompanyComponent
    ],
    templateUrl: './select-company.component.html',
    styleUrl: './select-company.component.scss'
})
export class SelectCompanyComponent implements OnDestroy {

    companies = this.companiesService.search;
    searchResultsLoading = this.companiesService.searchResultsLoading;
    searchQueryChanged = this.companiesService.searchQueryChanged;
    searchQuery = this.companiesService.searchQuery;

    constructor(private route: ActivatedRoute,
                public companiesService: CompaniesService,
                public messageService: MessageService,
                private router: Router,
                private builder: ReportBuilderService) {
        const query = this.route.snapshot.params['query'];
        if (query) {
            this.searchQuery.set(query);
        } else {
            const c = builder.company();
            if (c) {
                this.searchQuery.set(c.name);
            }
        }
    }

    selectCompany(company: Company) {
        console.log('[SelectCompanyComponent] selectCompany', company);
        this._selectCompany(company, true);
    }

    companyNotFound(company: Company) {
        console.log('[SelectCompanyComponent] companyNotFound', company);
        this._selectCompany(company, false);
    }

    ngOnDestroy() {
        this.companiesService.reset();
    }

    private _selectCompany(company: Company, isCompanyFound: boolean) {
        company.isCompanyFound = isCompanyFound;
        this.builder.company.set(company);
        this.router.navigate(['/report-builder', 'offering'])
    }
}
