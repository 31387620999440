import {
    ChangeDetectionStrategy,
    Component,
    ElementRef, EventEmitter,
    HostBinding,
    Input, OnInit, Output,
    Renderer2
} from '@angular/core';
import {RouterLink} from "@angular/router";
import {JsscriptService} from "../../../../services/jsscript.service";

@Component({
    selector: 'app-sales-prep-system',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './sales-prep-system.component.html',
    styleUrl: './sales-prep-system.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesPrepSystemComponent implements OnInit {
    @Input() authenticated = false;
    @Input() trialDays = 30;
    @HostBinding('class.sp-typography') t = true;

    @Output() showPricing = new EventEmitter();
    
    constructor(private jsScriptService: JsscriptService, private renderer: Renderer2, private el: ElementRef) {}

    ngOnInit() {
        this.jsScriptService.loadScript(this.renderer, this.el, 'https://fast.wistia.com/embed/medias/x957dv57pl.jsonp');
        this.jsScriptService.loadScript(this.renderer, this.el, 'https://fast.wistia.com/assets/external/E-v1.js');
    }
}
