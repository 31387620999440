@if (company() && offering()) {
    <app-pure-select-job
            (onSelect)="selectJob($event)"
            (onSkip)="skipJobSection()"
            (onAdd)="addJob($event)"
            [company]="company()!"
            [offering]="offering()!"
            [job]="job()!"
            [rolesList]="roles()!"
    />
}