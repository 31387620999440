import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TutorCoachingLevels} from "../../../../model";

@Component({
    selector: 'app-select-coaching-level',
    standalone: true,
    imports: [],
    templateUrl: './select-coaching-level.component.html',
    styleUrl: './select-coaching-level.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectCoachingLevelComponent {
    @Input() userInitials = '';
    @Input() coachingLevel = TutorCoachingLevels.Beginner;
    
    @Output() onSelect = new EventEmitter<TutorCoachingLevels>();

    TutorCoachingLevels = TutorCoachingLevels;
}
