import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-certification-badge',
    standalone: true,
    imports: [],
    templateUrl: './certification-badge.component.html',
    styleUrl: './certification-badge.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CertificationBadgeComponent {

}
