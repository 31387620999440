import {PdfGenerator} from "../pdf-generator";

export class MainHeaderSection {

    draw(pdf: PdfGenerator) {
        const doc = pdf.doc;
        
        doc.setPage(1);

        const topBarHeight = 27.184;
        doc.setFillColor("#79C0EC");
        doc.rect(0, 0, pdf.pageWidth / 2, topBarHeight, 'F');
        doc.setFillColor(pdf.styles.linkColor);
        doc.rect(pdf.pageWidth / 2, 0, pdf.pageWidth / 2, topBarHeight, 'F');

        // Sales (blue)
        doc.setTextColor(33, 150, 243);
        pdf.text("Sales", 454.855, 43.291 - 5, 'Raleway-Medium', 14);

        // Prep (orange)
        doc.setTextColor(255, 87, 34);
        // const text1Width = doc.getTextWidth("Sales");        
        pdf.text("Prep", 490.226, 43.291 - 5, 'Raleway-MediumItalic', 14);

        doc.setTextColor(33, 150, 243);

        // TM (smaller size and raised)        
        pdf.text("TM", 517.773, 38.307, 'Raleway-Medium', 5);

        // REPORT (blue)
        pdf.text("REPORT", 529.172, 43.462 - 3, 'Raleway-Medium', 10);
    }
}