import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output
} from '@angular/core';
import {TutorAvatarComponent} from "../tutor-avatar/tutor-avatar.component";

@Component({
    selector: 'app-tutor-panel',
    standalone: true,
    imports: [
        TutorAvatarComponent
    ],
    templateUrl: './tutor-panel.component.html',
    styleUrl: './tutor-panel.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TutorPanelComponent {
    @Input() mode = TutorPanelStyle.Novice; 
    @Output() start = new EventEmitter();

    @HostBinding('class.flying') @Input() flying = false;
    @HostBinding('class.flown') @Input() flown = false;

    @HostBinding('class.novice') get first() {
        return this.mode == TutorPanelStyle.Novice;
    }

    @HostBinding('class.intermediate') get isIntermediate() {
        return this.mode == TutorPanelStyle.Intermediate;
    }

    @HostBinding('class.expert') get isExpert() {
        return this.mode == TutorPanelStyle.Expert;
    }
    
    @HostBinding('class.custom') get isCustom() {
        return this.mode == TutorPanelStyle.Custom;
    }
    
    flyaway() {
        this.flown = false;
    }
    
    flyin() {
        this.flown = this.flying && true;
    }

    TutorPanelStyle = TutorPanelStyle;
}


export enum TutorPanelStyle {
    Novice,
    Intermediate,
    Expert,
    Custom,
}