import {ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {MainHeaderComponent} from "../../../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../../../components/main-panel/main-panel.component";
import {AiInsightsComponent} from "../ai-insights/ai-insights.component";
import {ELearningComponent} from "../e-learning/e-learning.component";
import {ResourcesComponent} from "../resources/resources.component";
import {AimCompanyComponent} from "../aim-company/aim-company.component";
import {PricingComponent} from "../pricing/pricing.component";
import {AiGuideComponent} from "../ai-guide/ai-guide.component";
import {TopSectionComponent} from "../top-section/top-section.component";
import {SalesPrepSystemComponent} from "../sales-prep-system/sales-prep-system.component";
import { SignUpMode } from '../../../../model';
import {FooterComponent} from "../../../../components/footer/footer.component";
import {PureFaqComponent} from "../../../faq/components/pure-faq/pure-faq.component";
import {
    TwelveWaySliderComponent
} from "../../../twelve-way-slider-screen/components/twelve-way-slider/twelve-way-slider.component";

@Component({
    selector: 'app-pure-splash',
    standalone: true,
    imports: [
        RouterLink,
        MainHeaderComponent,
        MainPanelComponent,
        AiInsightsComponent,
        ELearningComponent,
        ResourcesComponent,
        FooterComponent,
        AimCompanyComponent,
        PricingComponent,
        AiGuideComponent,
        TopSectionComponent,
        SalesPrepSystemComponent,
        PureFaqComponent,
        TwelveWaySliderComponent
    ],
    templateUrl: './pure-splash.component.html',
    styleUrl: './pure-splash.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureSplashComponent {
    @Input() trialDays = 30;
    @Input() authenticated = false;

    @ViewChild('pricing', { static: true }) pricing: ElementRef | undefined;
    
    SignUpMode = SignUpMode;
    twelveWayStep = 1;
    
    constructor(private router: Router) {
    }


    signUp(mode: SignUpMode = SignUpMode.Trial) {
        console.log('[PureSplashComponent] startTrial');
        if (this.authenticated) {
            this.router.navigate(['/profile']);
        } else {
            this.router.navigate(['/signup', mode]);
        }
    }

    contactUs() {
        this.router.navigate(['/contact-us']);
    }
    
    scrollToPricing() {
        this.pricing?.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
}
