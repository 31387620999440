import {PdfGeneratorBase} from "../pdf-export";
import {DiscussionTopic, SalesPrepReport, UserProfile} from "../../model";
import {MainHeaderSection, MainHeaderSectionArgs} from "./sections/main-header-section";
import {CustomerHandoutDataSection} from "./sections/customer-handout-data-section";
import {FooterSection} from "./sections/footer-section";
import {IReportPart} from "../pdf-export/ireport-part";
import {CustomerHandoutDisclaimerSection} from "./sections/customer-handout-disclaimer-section";

interface ItemsToPrint {
    title: string;
    items: DiscussionTopic[];
}

export interface CustomerHandoutArgs {
    trends?: boolean;
    problems?: boolean;
    steps?: boolean;
}
export class CustomerHandoutsGenerator extends PdfGeneratorBase {

    static fonts = [
        'Raleway-Regular',
        'Raleway-Medium',
        'Raleway-Bold',
        'Raleway-SemiBold',
        'Raleway-ExtraBold',
        'Raleway-MediumItalic',
    ]
    
    pageHeight = 737.629;
    
    constructor(public report: SalesPrepReport, private profile: UserProfile | undefined) {
        super();
    }
    
    _print(args: MainHeaderSectionArgs, items: DiscussionTopic[]) {
        const sections = [
            new MainHeaderSection(this, args, this.report.company.name, this.profile),
            new CustomerHandoutDataSection(this, items.filter(x => x.selected), args.color),
            new CustomerHandoutDisclaimerSection(this)
        ];
        let y = 55.389;
        sections.forEach(s => y = this._drawPart(s, y));        
    }

    _printAll(args: MainHeaderSectionArgs, items: ItemsToPrint[]) {
        items.forEach((x, i) => {
            if (i > 0) {
                this.addPage();
            }
            this._print({
                ...args,
                title: !!x.title ? `${args.title} (${x.title})` : args.title 
            }, x.items)
        })
    }
    
    async generateAll(args: CustomerHandoutArgs) {
        const r = this.report;

        if (args.trends) {
            this._printAll({
                title: 'Industry Trends',
                color: '70c72d',
                icon: 2,
                iconWidth: 25,
                iconHeight: 15,
                iconX: 29.291,
                iconY: 61,
            }, r.discussionTopics.map(x => ({
                    title: x.role,
                    items: x.marketTrends
                })));
        }
        
        if (args.problems) {
            if (args.trends) {
                this.addPage();
            }
            
            this._printAll({
                title: 'Common Problems',
                color: 'f47415',
                icon: 1,
                iconWidth: 24.222,
                iconHeight: 24.222,
                iconX: 30.680,
                iconY: 55.389,
            }, r.discussionTopics.map(x => ({
                title: x.role,
                items: x.commonProblems
            })));            
        }       
        
        if (args.steps) {
            if (args.problems) {
                this.addPage();
            }
            this._printAll({
                title: 'Process Steps',
                color: '9845a8',
                icon: 3,
                iconWidth: 24.995,
                iconHeight: 24.995,
                iconX: 31.422,
                iconY: 57.192,
            }, r.discussionTopics.map(x => ({
                title: x.role,
                items: x.processSteps
            })));
        }
        
        new FooterSection().draw(this);        

        const companyName = r.company.name.replace(/\s+/g, ' ').replace(/[^a-z0-9 ]/gi, '');
        const date = this.datePipe.transform(new Date());
        const title = this._getTitle(args);
        const filename = `SalesPrep ${title} for ${companyName} - ${date}.pdf`;
        this.doc.save(filename);
    }

    private _drawPart(part: IReportPart, _y: number) {
        /*if (_y + 40 > this.pageHeight) {
            _y = this.addPage();
        }*/

        const y = part.draw(_y);

        if (y > 0) {
            // if (y + 40 < this.pageHeight) {
            //     debugger;
            //     return 0;
            // }
            return y;
        }
        return _y;
    }

    private _getTitle(args: CustomerHandoutArgs) {
        if (args.problems && args.trends && args.steps) {
            return 'Customer Handouts';
        }
        
        if (args.problems) {
            return 'Common Problems';
        }
        
        if (args.trends) {
            return 'Industry Trends';
        }
     
        if (args.steps) {
            return 'Process Steps';
        }
        
        return '';
    }
}