<div class="title" qa="int-title">INTERVIEW
    @switch (level) {
        @case (TutorCoachingLevels.Beginner) {
            <span class="novice">(NOVICE)</span>
        }
        @case (TutorCoachingLevels.Intermediate) {
            <span class="intermediate">(INTERMEDIATE)</span>
        }
        @case (TutorCoachingLevels.Expert) {
            <span class="expert">(EXPERT)</span>
        }
    }
</div>

<h1 class="subject">
    <app-report-subject
            [offeringName]="offeringName"
            [offeringType]="offeringType"
            [job]="job" />
</h1>

@switch (level) {
    @case (TutorCoachingLevels.Beginner) {
        <div class="note" qa="role-div">
            You are an observer. AI will play both your part ({{ userInitials }}) and the customer (C). Claire will be the coach and will offer advice throughout the interview.
            
            <br><br>While observing, think about what you might ask before clicking "Next" to advance the interview.
        </div>
    }
    @case (TutorCoachingLevels.Intermediate) {
    }
    @case (TutorCoachingLevels.Expert) {
    }
}

