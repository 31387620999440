import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter, HostBinding,
    HostListener,
    Input,
    Output
} from '@angular/core';
import {ClickOutsideDirective} from "../../../directives/click-outside.directive";

@Component({
    selector: 'app-dropdown',
    standalone: true,
    imports: [
        ClickOutsideDirective
    ],
    templateUrl: './dropdown.component.html',
    styleUrl: './dropdown.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent {
    @Input() selectedItem: IDropdownItem | undefined;
    @Input() items: IDropdownItem[] = [];
    
    @Input() position: 'bottom' | 'top' = 'bottom';
    @Input() expanded = false;
    
    @Output() selectedItemChange = new EventEmitter<IDropdownItem>();

    constructor(private cdRef: ChangeDetectorRef) {
    }
    @HostListener('click') expand() {
        console.log('[DropdownComponent] expand', this.expanded);
        this.expanded = !this.expanded;
        this.cdRef.detectChanges();
    }
    
    select(item: IDropdownItem | undefined) {
        this.expanded = false;
        this.selectedItem = item;
        this.selectedItemChange.emit(item);
    }

    collapse() {
        console.log('[DropdownComponent] collapse');
        this.expanded = false
    }
}

export interface IDropdownItem {
    value: any;
    text?: string;
}
