import {ElementRef, Injectable, Renderer2} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class JsscriptService {

    constructor() {
    }

    loadScript(renderer: Renderer2, el: ElementRef, src: string, async = true, id = '') {
        const script = renderer.createElement('script');
        script.setAttribute("type", "text/javascript");
        if (id) {
            script.id = id;
        }
        script.async = async;
        script.src = src;
        script.onload = () => {
            console.log('Script loaded successfully.');
        };
        script.onerror = () => {
            console.error('Error loading script.');
        };

        renderer.appendChild(el.nativeElement, script);
    }
}
