import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {ExploratoryQuestion, ReportSectionRating} from "../../../../model";
import {RateSectionComponent} from "../../../../components/rate-section/rate-section.component";
import {RateSectionFooterComponent} from "../../../../components/rate-section-footer/rate-section-footer.component";

@Component({
    selector: 'app-exploratory-questions',
    standalone: true,
    imports: [
        RateSectionComponent,
        RateSectionFooterComponent
    ],
    templateUrl: './exploratory-questions.component.html',
    styleUrl: './exploratory-questions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploratoryQuestionsComponent implements OnChanges {
    @Input() exploratoryQuestions: ExploratoryQuestion[] = [];
    filtered: ExploratoryQuestion[] = [];
    @Input() rating!: ReportSectionRating;
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();
    
    ngOnChanges() {
        this.filtered = this.exploratoryQuestions.filter(x => x.selected);
    }
}
