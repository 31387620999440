import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {AutofocusDirective} from "../../../../../../directives/autofocus.directive";
import {CompanyListItemComponent} from "../company-list-item/company-list-item.component";
import {FormsModule} from "@angular/forms";
import {MainPanelComponent} from "../../../../../../components/main-panel/main-panel.component";
import {SpinnerComponent} from "../../../../../../components/spinner/spinner.component";
import {Company} from "../../../../../../model";
import {NewReportComponent} from "../../../../../home/components/new-report/new-report.component";
import {TopProgressBarComponent} from "../../../../components/top-progress-bar/top-progress-bar.component";
import {SideProgressBarComponent} from "../../../../components/side-progress-bar/side-progress-bar.component";
import {RouterLink} from "@angular/router";
import {CompanyNotFoundComponent} from "../company-not-found/company-not-found.component";
import {AddCompanyComponent} from "../add-company/add-company.component";

@Component({
    selector: 'app-pure-select-company',
    standalone: true,
    imports: [
        CompanyListItemComponent,
        FormsModule,
        MainPanelComponent,
        SpinnerComponent,
        NewReportComponent,
        TopProgressBarComponent,
        SideProgressBarComponent,
        RouterLink,
        CompanyNotFoundComponent,
        AddCompanyComponent
    ],
    templateUrl: './pure-select-company.component.html',
    styleUrl: './pure-select-company.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureSelectCompanyComponent implements OnChanges {
    @Input() searchQuery = '';
    @Input() searchResultsLoading = false;
    @Input() searchQueryChanged = false;
    @Input() companies: Company[] = [];

    @Output() onSearch = new EventEmitter<string>();
    @Output() onSelectCompany = new EventEmitter<Company>();
    @Output() onCompanyNotFound = new EventEmitter<Company>();

    @HostBinding('class.sp-screen') s = true;

    selectedIndex = -1;

    companyNotFound = false;
    companyData?: Company;

    isCompanyDataValid() {        
        const d = this.companyData;
        return d && !!d.name;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.companyNotFound = this.companies.length == 0 && this.searchQuery.length > 0;
    }

    addCompany(searchQuery: string) {
        console.log('[PureSelectCompanyComponent] Add Company', searchQuery);
        const isUrl = searchQuery.startsWith('http');
        this.companyData = <any>{
            name: isUrl ? '' : searchQuery,
            website: isUrl ? searchQuery : ''
        }
    }
    
    search(query: string) {
        this.onSearch.emit(query);
    }
}
