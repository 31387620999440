import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {NoteComponent} from "../../../../components/note/note.component";

@Component({
    selector: 'app-confirm-email',
    standalone: true,
    imports: [
        NoteComponent
    ],
    templateUrl: './confirm-email.component.html',
    styleUrl: './confirm-email.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmEmailComponent {
    @Input() trial = true;
    @Output() onGoBack = new EventEmitter();
    @Output() onResendConfirmationEmail = new EventEmitter();
}
