import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import {HomeReportTileComponent} from "../home-report-tile/home-report-tile.component";
import {MainHeaderComponent} from "../../../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../../../components/main-panel/main-panel.component";
import {NewReportComponent} from "../new-report/new-report.component";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";
import {RouterLink} from "@angular/router";
import {SalesPrepReport, TutorCoachingLevels} from "../../../../model";
import {NoReportsComponent} from "../no-reports/no-reports.component";
import {
    ELearningData,
    ELearningModule,
    ELearningModuleStatus,
    ELearningModuleStatusEnum
} from "../../../../services/elearning.service";
import {ModuleComponent} from "../../../e-learning/components/module/module.component";
import {TutorAvatarComponent} from "../../../../components/tutor-avatar/tutor-avatar.component";
import {CountLabelComponent} from "../count-label/count-label.component";
import {BadgeComponent} from "../badge/badge.component";
import {DatePipe} from "@angular/common";
import {CertificationBadgeComponent} from "../certification-badge/certification-badge.component";
import {AiguideStatsComponent} from "../aiguide-stats/aiguide-stats.component";
import {ElearningStatsComponent} from "../elearning-stats/elearning-stats.component";
import {ReportsStatsComponent} from "../reports-stats/reports-stats.component";
import {TwelveWaysWidgetComponent} from "../twelve-ways-widget/twelve-ways-widget.component";

@Component({
    selector: 'app-pure-home',
    standalone: true,
    imports: [
        HomeReportTileComponent,
        MainHeaderComponent,
        MainPanelComponent,
        NewReportComponent,
        SpinnerComponent,
        RouterLink,
        NoReportsComponent,
        ModuleComponent,
        TutorAvatarComponent,
        CountLabelComponent,
        BadgeComponent,
        DatePipe,
        CertificationBadgeComponent,
        AiguideStatsComponent,
        ElearningStatsComponent,
        ReportsStatsComponent,
        TwelveWaysWidgetComponent
    ],
    templateUrl: './pure-home.component.html',
    styleUrl: './pure-home.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureHomeComponent implements OnChanges {
    @Output() onSearch = new EventEmitter<string>();
    @Output() onRemove = new EventEmitter<SalesPrepReport>();
    
    @Input() reports: SalesPrepReport[] = [];    
    @Input() reportsLoading = false;
    @Input() modules: ELearningModule[] = [];
    @Input() eLearningData: ELearningData | undefined;
    
    nextModule: ELearningModule | undefined;
    notScheduled: SalesPrepReport[] = [];
    future: SalesPrepReport[] = [];
    upcoming: SalesPrepReport[] = [];
    historical: SalesPrepReport[] = [];
    recent: SalesPrepReport[] = [];

    totalPracticeSessionsCompleted = 0;
    qualifyingPracticeSessionsCompleted = 0;
    novicePracticeSessionsCompleted = 0;
    intermediatePracticeSessionsCompleted = 0;
    advancedPracticeSessionsCompleted = 0;
    
    @HostBinding('class.sp-screen') s = true;
    @HostBinding('class.loading') get loading() {
        return !this.eLearningData; 
    }

    get certified() {
        return this.eLearningData?.certified;
    }
    
    get eLearningCertified() {
        return this.eLearningData?.eLearningCertified;
    }
    
    get aiGuideCertified() {
        return this.eLearningData?.aiGuideCertified;
    }
    
    get reportsCertified() {
        return this.eLearningData?.reportsCertified;
    }
    
    get moduleStatuses(): ELearningModuleStatus[] {
        return this.eLearningData?.modules || [];
    }
    
    
    ngOnChanges() {
        console.log('[PureHomeComponent] ngOnChanges');
        this._init();
    }
    
    private _initELearning() {
         
        const nextModuleId = this.eLearningData?.modules.find(x => x.status != ELearningModuleStatusEnum.Completed)
            ?.id || 0;
        if (nextModuleId) {
            this.nextModule = this.modules[nextModuleId - 1];
        }        
    }
    
    private _init() {        
        this._initELearning();
        
        const list = this.reports || [];
        
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        
        const scheduledForToday = list.filter(x => x.meetingDateTime && this._sameDate(x.meetingDateTime, today));
        const scheduledForLater = list.filter(x => x.meetingDateTime && x.meetingDateTime > today);
        const scheduledForTomorrow = list.filter(x => x.meetingDateTime && this._sameDate(x.meetingDateTime, tomorrow));
        
        this.upcoming = [
            ...scheduledForToday,
            ...scheduledForTomorrow,
        ];
        
        let recent = list.filter(x => !this.upcoming.find(y => y.id == x.id));
        recent.sort((a, b) => +b.lastUpdated - +a.lastUpdated);
                
        const notScheduled = list.filter(x => !x.meetingDateTime);
        const historical = list.filter(x => x.meetingDateTime && x.meetingDateTime < today);

        scheduledForToday.sort(this._sortByMeetingDateTime);
        notScheduled.sort(this._sortByLastUpdated);
        scheduledForLater.sort(this._sortByMeetingDateTime);
        historical.sort(this._sortByMeetingDateTime);

        this.recent = recent.slice(0, 4);
        this.notScheduled = notScheduled;
        
        
        
        
        
        this.future = [
            ...scheduledForToday,
            ...scheduledForLater,
            ...notScheduled,
        ];
        
        this.historical = historical;
        
        const sessions = this.eLearningData?.practiceSessionsCompleted || [];
        this.novicePracticeSessionsCompleted = sessions.filter(x => x.level == TutorCoachingLevels.Beginner).length;
        this.intermediatePracticeSessionsCompleted = sessions.filter(x => x.level == TutorCoachingLevels.Intermediate).length;
        this.advancedPracticeSessionsCompleted = sessions.filter(x => x.level == TutorCoachingLevels.Expert).length;
        this.qualifyingPracticeSessionsCompleted = sessions.filter(x => x.level > TutorCoachingLevels.Beginner).length;
        
        this.totalPracticeSessionsCompleted = sessions.length;
    }

    private _sortByMeetingDateTime(x: SalesPrepReport, y: SalesPrepReport) {
        return x.meetingDateTime!.getTime() - y.meetingDateTime!.getTime();
    }

    private _sortByLastUpdated(x: SalesPrepReport, y: SalesPrepReport) {
        return x.lastUpdated.getTime() - y.lastUpdated.getTime();
    }

    private _sameDate(date1: Date | undefined, date2: Date) {
        if (!date1) return false;

        // Compare year, month, and date of both dates
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }
}
