import {Component, HostBinding} from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {FooterComponent} from "../../components/footer/footer.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";

@Component({
    selector: 'app-privacy',
    standalone: true,
    imports: [
        MainHeaderComponent,
        MainPanelComponent,
        PageHeaderComponent,
        FooterComponent,
        FooterSmartComponent
    ],
    templateUrl: './privacy.component.html',
    styleUrl: './privacy.component.scss'
})
export class PrivacyComponent {
    @HostBinding('class.sp-typography') t = true;
}
