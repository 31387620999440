import {Injectable} from '@angular/core';
import {SalesPrepReport} from "../../../model";
import {IReportFilter} from "../reports-filter/reports-filter.component";
import {DateFilterType, IDateFilterValue} from "../date-filter/date-filter.component";

@Injectable({
    providedIn: 'root'
})
export class ReportsFilterService {

    filter(reports: SalesPrepReport[], f: IReportFilter) {
        return reports.filter(r => {
            if (f.selectedCompany.length) {
                if (!f.selectedCompany.find(x => x.text == r.company.name)) {
                    return false;
                }
            }

            if (f.meetingDateFilter.filterType != DateFilterType.NotSet) {
                if (!r.meetingDate) return false;
                if (!this._matchesFilter(new Date(r.meetingDate), f.meetingDateFilter)) {
                    return false;
                }

            }
            if (!this._matchesFilter(r.lastViewed, f.lastViewedFilter)) {
                return false;            
            }
            if (!this._matchesFilter(r.lastUpdated, f.lastModifiedFilter)) {
                return false;            
            }
            
            if (f.roles.length) {
                if (!r.roles.find(x => f.roles.find(_role => _role.value.toLowerCase() == x.toLowerCase()))) {
                    return false;
                }
            }
            
            if (f.offerings.length) {
                if (!f.offerings.find(o => o.value.toLowerCase() == r.offeringName.toLowerCase())) {
                    return false;
                }
            }
            
            return true;
        })

    }

    private _matchesFilter(date: Date, f: IDateFilterValue) {
        if (f.filterType == DateFilterType.NotSet) return true;

        if (!date.getFullYear) date = new Date(date);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const d = date.getTime();
        const a = f.date1?.getTime();
        const b = f.date2?.getTime();
        
        switch (f.filterType) {
            case DateFilterType.Is:
                return !a || d == a;
                
            case DateFilterType.Between:
                return !a || !b || (d >= a! && d <= b!);
                
            case DateFilterType.OnOrAfter:
                return !a || d >= a!;
                
            case DateFilterType.OnOrBefore:
                return !a || d <= a!;
                
            default:
                return true;
        }
    }
}
