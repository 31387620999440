import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {ResourcesListComponent} from "../../../resources/components/resources-list/resources-list.component";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-resources',
    standalone: true,
    imports: [
        ResourcesListComponent,
        RouterLink
    ],
    templateUrl: './resources.component.html',
    styleUrl: './resources.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesComponent {
    @HostBinding('class.sp-typography') t = true;

    @Input() authenticated = false;
}
