import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {toSignal} from "@angular/core/rxjs-interop";
import {filter, map} from "rxjs";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {ReportBuilderService} from "../../services/report-builder.service";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";

@Component({
    selector: 'app-report-builder',
    standalone: true,
    imports: [
        FormsModule,
        RouterOutlet,
        MainHeaderComponent,
        FooterCompactComponent
    ],
    templateUrl: './report-builder.component.html',
    styleUrl: './report-builder.component.scss'
})
export class ReportBuilderComponent implements OnDestroy {
    
    title = toSignal(this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map(route => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }),
        map(x => x.title ?? '')
    ));
    
    constructor(private router: Router, private route: ActivatedRoute, 
                private reportBuilderService: ReportBuilderService) {
    }
    
    ngOnDestroy() {
        console.log('[ReportBuilderComponent] ngOnDestroy');
        this.reportBuilderService.reset();
    }

    back() {
        window.history.back();
    }
}
