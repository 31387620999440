import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {FooterCompactComponent} from "../footer-compact/footer-compact.component";
import {FooterComponent} from "../footer/footer.component";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'app-footer-smart',
    standalone: true,
    imports: [
        FooterCompactComponent,
        FooterComponent
    ],
    templateUrl: './footer-smart.component.html',
    styleUrl: './footer-smart.component.scss',
})
export class FooterSmartComponent {
    @HostBinding('class.no-margin') @Input() noMargin = false;
    authenticated = this.authService.authenticated;

    constructor(private authService: AuthService) {
    }
}
