import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SalesPrepReport} from "../../../../model";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-reports-stats',
    standalone: true,
    imports: [],
    providers: [
        DatePipe,
    ],
    templateUrl: './reports-stats.component.html',
    styleUrl: './reports-stats.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsStatsComponent {
    @Input() reports: SalesPrepReport[] = [];

    constructor(private datePipe: DatePipe) {
    }
    
    getTitle(r: SalesPrepReport) {
        if (!r) return '';
        return `Generated on on ${this.datePipe.transform(r.lastUpdated)}`;
    }

}
