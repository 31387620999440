@if (!inlineMode) {
    <div class="flex header" [class.border]="messages.length">    
        <div class="title" style="flex: 1">ROLE-PLAY WITH CLAIRE</div>
        <a (click)="onClose.emit()" class="close"><i class="material-icons">close</i></a>
    </div>
}

<div class="container">
    
    @if (messages.length) {
        <div class="chat" #chat>
            <app-chat-header [userInitials]="userInitials" [level]="coachingLevel" [offeringName]="offeringName" [offeringType]="offeringType" [job]="job" />
            <app-chat-messages (messageAdded)="scrollToBottom()"
                               (onMessageSelected)="selectMessage($event)"
                               [completed]="completed"
                               [userInitials]="userInitials" [level]="coachingLevel" [messages]="messages" [loading]="loading || starting" />

            @if (nextSteps) {

                @if (coachingLevel == TutorCoachingLevels.Beginner) {
                    <app-next-steps (onRolePlaying)="onRolePlayAgain.emit()"/>
                } @else {
                    <app-interview-summary
                            [level]="coachingLevel" [offeringName]="offeringName" [offeringType]="offeringType" [job]="job"
                            [numWhat]="what"
                            [numWhy]="why"
                            [numClarify]="clarify"
                            [numProblem]="problem"
                            />
                    
                    @if (advicesLoading) {
                        <app-spinner />
                    }
                    
                    @if (advices) {
                        <app-advices [advices]="advices"/>
                    }
                    
                    @if (strengths) {
                        <app-strengths [strengths]="strengths"/>
                    }
                }
                                
                <app-recommended-elearning [authenticated]="authenticated" />
            } @else if (!loading) {            
                @if (completed) {
                    @if (coachingLevel == TutorCoachingLevels.Beginner) {
                        <p class="tip" qa="tip-ni">
                            This is the end of the Novice Interview.<br>
                            View Next Steps to continue.
                        </p>
                    }
                } @else if (coachingLevel != TutorCoachingLevels.Beginner) {
                    <p class="tip" qa="bye-tip">Enter your next question now or say “<a class="sp-link" (click)="onSend.emit('Goodbye')" >Goodbye</a>” to end the interview.</p>
                }
            }

            <!--@if (!completed) {
                <p class="tip small" qa="tips">What: <span qa="what">{{ what }}</span>, Why: <span qa="why">{{ why }}</span>, Clarify: <span qa="clar">{{ clarify }}</span></p>
            }-->
        </div>
    
        <div class="actions" qa="act-div">
            @if (nextSteps) {
                <app-novice-actions (onNext)="onRolePlayAgain.emit()" [loading]="loading" [completed]="true" [nextSteps]="true" />
            } @else {
                @if (completed) {
                    <app-novice-actions (onNext)="showNextSteps()" [loading]="loading" [completed]="true" />
                } @else if (coachingLevel == TutorCoachingLevels.Beginner) {
                    <app-novice-actions (onNext)="onSend.emit('**next**')" [loading]="loading" />
                } @else {
                    <app-message-form (send)="onSend.emit($event)" />
                }
            }
        </div>
    } @else {
        
        <app-start-chat
                [showStep]="inlineMode"
                (onStart)="onStart.emit($event)"
                [userInitials]="userInitials"
                [(coachingLevel)]="coachingLevel"
                [offeringName]="offeringName"
                [offeringType]="offeringType"
                [job]="job"
                [busy]="starting" qa="app-ai"/>
    }
    
</div>