<app-pure-select-topics 
    [sessionExpired]="sessionExpired()"
    [company]="company()!"
    [newsArticles]="newsArticles()"
    
    [companyNewsLoading]="companyNewsLoading()"
    [companyNewsLoadingFailed]="companyNewsLoadingFailed()"
    
    [product]="product()!"
    [job]="job()!"
    
    [discussionTopics]="discussionTopics()"
    [otherTopics]="otherTopics()"

    [stakeholders]="stakeholders()"
    [stakeholdersLoading]="stakeholdersLoading()"
    [stakeholdersLoadingFailed]="stakeholdersLoadingFailed()"
    
    [exploratoryQuestions]="exploratoryQuestions()"
    [exploratoryQuestionsLoading]="exploratoryQuestionsLoading()"
    [exploratoryQuestionsLoadingFailed]="exploratoryQuestionsLoadingFailed()"
    
    (onGenerateExploratoryQuestions)="generate($event)"
    (onCompanyDataRetry)="companyDataRetry()"
    (onMarketTrendsRetry)="marketTrendsRetry()"
    (onCommonProblemsRetry)="commonProblemsRetry()"
    (onProcessStepsRetry)="processStepsRetry()"
    
    [selectedCustomDiscussionTopic]="selectedCustomDiscussionTopic"
    (onAddDiscussionTopic)="addDiscussionTopic()"
    (onSaveCustomTopic)="saveCustomTopic($event)"
    
    [companyNewsRating]="companyNewsRating"
    [exploratoryQuestionsRating]="exploratoryQuestionsRating"
    [stakeholdersRating]="stakeholdersRating"
    [marketTrendsRating]="marketTrendsRating"
    [commonProblemsRating]="commonProblemsRating"
    [processStepsRating]="processStepsRating"
    [otherTopicsRating]="otherTopicsRating"
    
    (onRatingChange)="rate($event)"
    
    (onNext)="save()"
    />

@if (badReportSectionRating) {
    <app-info-panel [title]="'Reason for your rating'" (onClose)="badReportSectionRating = undefined">
        <app-rate-section-dialog
                (onCancel)="badReportSectionRating = undefined"
                (onSubmit)="rate($event, true)"
                [rating]="badReportSectionRating!"
        />
    </app-info-panel>
}