import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {TutorService} from "../services/tutor.service";

export const hadFirstChatGuard: CanActivateFn = (route, state) => {
    const tutorService = inject(TutorService);
    if (tutorService.hadRecentChat()) {
        return true;
    }
    
    const router = inject(Router);
    router.navigate(['/ai-guide', 'intro']);
    return false;
};
