import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, OnInit, Output, signal} from '@angular/core';
import {Router} from "@angular/router";
import {
    PureReportPracticeStartComponent,
    ReportPracticeData
} from "./pure-report-practice-start/pure-report-practice-start.component";
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {CompaniesService} from "../../../../services/companies.service";
import {Company} from "../../../../model";
import {AuthService} from "../../../../services/auth.service";
import {RecaptchaService} from "../../../../services/recaptcha.service";

@Component({
    selector: 'app-report-practice-start',
    standalone: true,
    imports: [
        PureReportPracticeStartComponent,
    ],
    templateUrl: './report-practice-start.component.html',
    styleUrl: './report-practice-start.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportPracticeStartComponent implements OnInit {

    errorMessage = signal('');
    selectedCompany: Company | undefined;
    companies = this.companiesService.search;
    company = this.reportBuilderService.company;
    searchResultsLoading = this.companiesService.searchResultsLoading;
    searchQuery = this.companiesService.searchQuery;
    
    constructor(private router: Router, 
                private recaptchaService: RecaptchaService,
                private authService: AuthService,
                private companiesService: CompaniesService, 
                private reportBuilderService: ReportBuilderService) {
        localStorage.clear();
    }
    
    ngOnInit() {
        this.showCaptcha();
    }

    async next(data: ReportPracticeData) {
        this.errorMessage.set('');

        const captcha = await this.recaptchaService.execute();        
        this.authService.validateCode(captcha, data.accessCode).subscribe({
            next: () => {
                this._buildReport(data);
            },
            error: e => {
                console.error(e); 
                const msg = e.error || 'Unable to validate Access code';
                this.errorMessage.set(msg);
            } 
        });
    }
    
    private _buildReport(data: ReportPracticeData) {
        const s = this.reportBuilderService;
        const c= this.selectedCompany;
        if (c) {
            c.isCompanyFound = true;
        }
        s.company.set(c);

        s.offering.set(<any> {
            name: data.offering,
            type: 3
        });

        if (data.jobToBeDone) {
            s.job.set(data.jobToBeDone);
        } else {
            s.job.set('_');
        }

        this.router.navigate(['/practice', 'topics']);
    }

    selectCompany(company: Company) {
        this.selectedCompany = company;
    }

    private showCaptcha() {
        this.recaptchaService.load();        
    }
}
