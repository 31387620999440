import {jsPDF} from "jspdf";
import {DatePipe} from "@angular/common";
import {TimePipe} from "../../pipes/time.pipe";
import {TextSection} from "./text-section";
import {ListSection} from "./list-section";

export class PdfGeneratorBase {
    doc: jsPDF;
    pageWidth: number;

    datePipe = new DatePipe('en-US');
    timePipe = new TimePipe();

    styles = {
        textColor: "#000",
        linkColor: "#176fc1",
        offset: 0,
        paddingX: 46.652,
        paddingY: 48.596,
        pageInnerWidth: 493.567
    }
    
    constructor() {
        const doc = new jsPDF({
            unit: 'pt',
            format: 'letter'
        });
        this.doc = doc;
        this.pageWidth = doc.internal.pageSize.getWidth();
    }

    addPage() {
        this.doc.addPage();
        return this.styles.paddingY;
    }
    
    line(x: number, y: number, width: number, color: string, lineWidth: number) {
        const d = this.doc;
        d.setLineWidth(lineWidth);
        d.setFillColor(color);
        d.line(x, y, x + width, y, 'F');
    }
    
    text(text: string, x: number, y: number, font: string = '', size: number = 0, maxWidth = 0, options = {}) {
        const doc = this.doc;
        if (font) {
            doc.setFont(font, 'normal');
        }
        if (size) {
            doc.setFontSize(size);
        }
        if (maxWidth) {
            const lines: string[] = doc.splitTextToSize(text, maxWidth);
            lines.forEach(line => {
                doc.text(line, x, y + this.styles.offset, {
                    baseline: 'top',
                    ...options
                });
                y += doc.getLineHeight() * 1.2;
            });
            return y;
        } else {
            doc.text(text, x, y + this.styles.offset, {
                baseline: 'top',
                ...options
            });

            const w = doc.getTextWidth(text);
            // doc.setDrawColor('#0F0');
            // doc.rect(x, y, w, doc.getLineHeight());
            return w;
        }
    }
    
    splitTextToSize(text: string, maxWidth: number, font: string, size: number) {
        const doc = this.doc;
        doc.setFont(font, 'normal');
        doc.setFontSize(size);
        return <string[]> doc.splitTextToSize(text, maxWidth);
    }
    
    createSection(text: string, maxWidth: number, font: string, size: number) {
        return new TextSection(text, maxWidth, font, size);
    }

    createListSection(text: string[], maxWidth: number, font: string, size: number) {
        return new ListSection(text, maxWidth, font, size);
    }

    getLineHeight(font: string, size: number) {
        const doc = this.doc;
        doc.setFont(font, 'normal');
        doc.setFontSize(size);
        return doc.getLineHeight();
    }


   

    forEachPage(action: (doc: jsPDF, index: number, numbersOfPages: number) => void) {
        const numbersOfPages = (<any>this.doc.internal).getNumberOfPages();
        for (let i = 1; i <= numbersOfPages; i++) {
            this.doc.setPage(i);

            action(this.doc, i, numbersOfPages);
        }
    }
}