<div class="flex">
    <h1 qa="h1-stakeholders">Company Stakeholders</h1>
    <app-rate-section [(rating)]="rating" (ratingChange)="onRatingChange.emit($event)"></app-rate-section>
</div>

@for (stakeholder of stakeholders; track stakeholder.title; let i = $index) {
    <div class="stakeholder" [attr.qa]="'stk-' + (i + 1)">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
            <g id="Leadership_Icon" data-name="Leadership Icon" transform="translate(-5 -3.5)">
                <path id="Path_70" data-name="Path 70" d="M22,28.5v-2a4,4,0,0,0-4-4H10a4,4,0,0,0-4,4v2" transform="translate(0 -6)" fill="none" stroke="#b5b5b5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_71" data-name="Path 71" d="M20,8.5a4,4,0,1,1-4-4,4,4,0,0,1,4,4Z" transform="translate(-2)" fill="none" stroke="#b5b5b5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
        </svg>
        
        <div>
            <div class="title">{{ stakeholder.title }}</div>
            <div class="desc">{{ stakeholder.description }}</div>
    
            @if (stakeholder.reportGenerating) {
                <app-report-generation-indicator qa="gen-jd"  [title]="'Generating a Job Description...'" />
            } @else {
                <a class="sp-link" (click)="stakeholder.reportGenerating = true; onGenerateReport.emit(stakeholder)" title="Download a 2-page in-depth report" qa="report">
                    JOB DESCRIPTION
                </a>
            }
        </div>
    </div>
}

<app-rate-section-footer [(rating)]="rating" (ratingChange)="onRatingChange.emit($event)"/>