import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {OfferingType, TutorCoachingLevels} from "../../../../model";
import {ReportSubjectComponent} from "../../../../components/report-subject/report-subject.component";

@Component({
    selector: 'app-interview-summary',
    standalone: true,
    imports: [
        ReportSubjectComponent
    ],
    templateUrl: './interview-summary.component.html',
    styleUrl: './interview-summary.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterviewSummaryComponent {
    @Input() level = TutorCoachingLevels.Beginner;

    @Input() offeringName = '';
    @Input() offeringType = OfferingType.Unknown;
    @Input() job = '';

    @Input() numWhat = 0;
    @Input() numWhy = 0;
    @Input() numClarify = 0;
    @Input() numProblem = 0;

    TutorCoachingLevels = TutorCoachingLevels;
}
