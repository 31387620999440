import { Component } from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {ModulesListComponent} from "../e-learning/components/modules-list/modules-list.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {RouterLink} from "@angular/router";
import {ResourcesListComponent} from "./components/resources-list/resources-list.component";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {FooterComponent} from "../../components/footer/footer.component";

@Component({
  selector: 'app-resources',
  standalone: true,
    imports: [
        MainHeaderComponent,
        MainTitleComponent,
        ModulesListComponent,
        PageHeaderComponent,
        RouterLink,
        ResourcesListComponent,
        FooterCompactComponent,
        FooterComponent
    ],
  templateUrl: './resources.component.html',
  styleUrl: './resources.component.scss'
})
export class ResourcesComponent {

}
