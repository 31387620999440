import { Component } from '@angular/core';

@Component({
  selector: 'app-trends-header',
  standalone: true,
  imports: [],
  templateUrl: './trends-header.component.html',
  styleUrl: './trends-header.component.scss'
})
export class TrendsHeaderComponent {

}
