import {Pipe, PipeTransform} from '@angular/core';
import {OfferingType} from "../model";

@Pipe({
    name: 'formatOfferingCategoryTitle',
    standalone: true
})
export class FormatOfferingCategoryTitlePipe implements PipeTransform {

    transform(value: string | undefined, type: OfferingType, ...args: unknown[]): string {
        if (!value) return 'NA';
        const i = value.indexOf('(');
        if (i > 0 && value[value.length - 1] == ')') {
            value = value.substring(0, i - 1);
        }
        return value + ' ' + this._get(type);
    }

    _get(type: OfferingType) {
        switch (type) {
            case OfferingType.MaterialOrIngredientOrComponent:
                return 'used within my customer\'s products';
            case OfferingType.EquipmentOrToolOrConsumable:
                return 'used in my customers\' operations';
            case OfferingType.ServiceOrSoftware:
                return 'used by my customers';
            default: 
                return '';
        }
    }
}
