import {Component, HostBinding} from '@angular/core';
import {FooterComponent} from "../../components/footer/footer.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-error-404',
    standalone: true,
    imports: [
        FooterComponent,
        MainHeaderComponent,
        PageHeaderComponent,
        SpinnerComponent,
        RouterLink
    ],
    templateUrl: './error-404.component.html',
    styleUrl: './error-404.component.scss'
})
export class Error404Component {
    
    @HostBinding('class.sp-typography') t = true;

}
