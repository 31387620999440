<div class="section overview" qa="div1">
    @if (company) {
        
        <div class="logo" [style.background-image]="'url(' + company.logoUrl + ')'" qa="logo">
        </div>
<!--            <img class="logo" alt="company logo" src="{{company!.logoUrl}}">-->
        <div class="links flex-column" qa="over1">
            <h1 class="name">
                <span qa="name">{{company!.name}}</span>
                @if (!company.isCompanyFound) {
                    *<br><em>* <span qa="ma">This company was manually added because it wasn't found in our records</span></em>
                }
            </h1> 
            
            <app-company-links [company]="company!" qa="ov-links"/>
        </div>
    }
</div>

@if (company?.description) {
    <div class="section" qa="div2">
        <div class="flex">
            <h1 qa="descr">Company Description</h1>
            <app-rate-section [rating]="companyDescriptionRating" (ratingChange)="onRatingChange.emit($event)" qa="rate"/>
        </div>
        <p qa="descr-p">{{ company?.description }}</p>
        
        <app-rate-section-footer [rating]="companyDescriptionRating" (ratingChange)="onRatingChange.emit($event)" qa="rate-foot"/>
    </div>
}

@if (!!company?.employeesCount || company?.companySize) {
    <div class="section" qa="div3">
        <div class="flex">
            <h1 qa="size">Size</h1>
            <app-rate-section [rating]="companySizeRating" (ratingChange)="onRatingChange.emit($event)"/>
        </div>
        @if (!!company?.employeesCount) {
            <div class="flex employeesCount">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.597" viewBox="0 0 24 15.597">
                    <g id="Size_Employees_Icon" data-name="Size Employees Icon" transform="translate(-2.215 -7.748)">
                        <path id="Path_67" data-name="Path 67"
                            d="M18.219,19.748c-.483-.214-1.272-.225-1.621-.39-.241-.118-.625-.246-.75-.433a7.08,7.08,0,0,1-.125-1.523,2.5,2.5,0,0,0,.427-.748,10.156,10.156,0,0,0,.207-1.47s.285.15.4-.556a5.674,5.674,0,0,0,.228-1.379c-.052-.449-.25-.342-.25-.342a4.83,4.83,0,0,0,.25-2A2.9,2.9,0,0,0,14.2,7.748a2.91,2.91,0,0,0-2.789,3.159,4.906,4.906,0,0,0,.246,2s-.2-.107-.25.342a5.283,5.283,0,0,0,.228,1.379c.112.711.4.556.4.556a10.255,10.255,0,0,0,.207,1.47,2.5,2.5,0,0,0,.427.748,7.08,7.08,0,0,1-.125,1.523c-.125.187-.509.315-.75.428-.345.166-1.134.187-1.621.4a3.757,3.757,0,0,0-1.974,3.592h12A3.752,3.752,0,0,0,18.219,19.748Z"
                            fill="#b5b5b5"/>
                        <path id="Path_68" data-name="Path 68"
                            d="M9.844,16.87a7.025,7.025,0,0,0,2.348-.416c-1.09-1.051-.5-2.277-.724-3.422s-1.245-1.825-3.284-1.825H8.156c-1.969,0-3.03.68-3.255,1.825s.4,2.5-.717,3.422a6.573,6.573,0,0,0,2.355.371h0s.07.644-.07.751a2.336,2.336,0,0,1-.844.246c-.619.085-1.273.2-1.821.322s-1.589.769-1.589,1.664H7.861a4.753,4.753,0,0,1,2.25-1.333,7.119,7.119,0,0,1,1.737-.233s.267-.268-.612-.371a8.526,8.526,0,0,1-1.35-.3C9.752,17.474,9.844,16.87,9.844,16.87Z"
                            fill="#b5b5b5"/>
                        <path id="Path_69" data-name="Path 69"
                            d="M26.156,16.87a7.025,7.025,0,0,1-2.348-.416c1.09-1.051.5-2.277.724-3.422s1.245-1.825,3.284-1.825h.028c1.969,0,3.03.68,3.255,1.825s-.4,2.5.717,3.422a6.573,6.573,0,0,1-2.355.371h0s-.07.644.07.751a2.336,2.336,0,0,0,.844.246c.619.085,1.273.2,1.821.322s1.589.769,1.589,1.664H28.139a4.753,4.753,0,0,0-2.25-1.333,7.119,7.119,0,0,0-1.737-.233s-.267-.268.612-.371a8.526,8.526,0,0,0,1.35-.3C26.248,17.474,26.156,16.87,26.156,16.87Z"
                            transform="translate(-7.57)" fill="#b5b5b5"/>
                    </g>
                </svg>

                <p class="bold" qa="empl">{{ company?.employeesCount | number }} employees</p>
            </div>
        } @else {
            <p>NA</p>
        }
        @if (company?.companySize) {
            <div class="flex companySize">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="21.218" viewBox="0 0 12 21.218">
                    <path id="Size_Dollars_Icon" data-name="Size Dollars Icon"
                        d="M15.94,13.812c-2.676-.7-3.536-1.415-3.536-2.534,0-1.285,1.191-2.181,3.183-2.181,2.1,0,2.876,1,2.947,2.475h2.605a4.7,4.7,0,0,0-3.784-4.491V4.5H13.818V7.046c-2.287.5-4.126,1.98-4.126,4.255,0,2.723,2.251,4.079,5.54,4.868,2.947.707,3.536,1.745,3.536,2.841,0,.813-.578,2.11-3.183,2.11-2.428,0-3.383-1.084-3.513-2.475H9.48c.141,2.582,2.075,4.031,4.338,4.515v2.558h3.536V23.184c2.3-.436,4.126-1.768,4.126-4.185C21.48,15.651,18.616,14.508,15.94,13.812Z"
                        transform="translate(-9.48 -4.5)" fill="#b5b5b5"/>
                </svg>
                <p class="bold"
                title="Source: craft.co" qa="annual">{{ company?.companySizeCurrency }}{{ company?.companySize | companySize }}</p>
            </div>
        } @else {
            <p>NA</p>
        }
        <app-rate-section-footer [rating]="companySizeRating" (ratingChange)="onRatingChange.emit($event)"/>
    </div>
}
<div>
    @if (company?.leadership?.length)
    {
        <div class="section" qa="lead">
            <div class="flex">
                <h1>Leadership</h1>
                <app-rate-section [rating]="leadershipRating" (ratingChange)="onRatingChange.emit($event)"/>
            </div>
                <div class="list-view">
                    @for (person of company?.leadership; track person.name; let i = $index) {
                        @if (person.name != "John Doe"){
                            <div class="list-item leadership" [attr.qa]="'mem-' + (i + 1)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                                    <g id="Leadership_Icon" data-name="Leadership Icon" transform="translate(-5 -3.5)">
                                        <path id="Path_70" data-name="Path 70" d="M22,28.5v-2a4,4,0,0,0-4-4H10a4,4,0,0,0-4,4v2" transform="translate(0 -6)" fill="none" stroke="#b5b5b5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_71" data-name="Path 71" d="M20,8.5a4,4,0,1,1-4-4,4,4,0,0,1,4,4Z" transform="translate(-2)" fill="none" stroke="#b5b5b5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
    
                                <div>
                                    <strong qa="per-name">{{ person.name }}</strong>
                                    <p qa="per-tit">{{ person.title }}</p>
                                </div>
                        </div>
                        }
                } @empty {
                    <p>NA</p>
                }
            </div>
            <app-rate-section-footer [rating]="leadershipRating" (ratingChange)="onRatingChange.emit($event)"/>        
        </div>
    }
    
    @if (company?.products?.length)
    {
        <div class="section" qa="prod">
            <div class="flex">
                <h1>Products</h1>
                <app-rate-section [rating]="productsRating" (ratingChange)="onRatingChange.emit($event)"/>
            </div>
    
            <div class="list-view">
                @for (industry of company?.products; track industry.name; let i = $index) {
                    <div class="list-item" [attr.qa]="'ind-' + (i + 1)">
                        <strong class="industry" qa="in-name">{{ industry.name }}</strong>
                        
                        <ul>
                            @for (p of industry.products; track p.name; let i = $index) {
                            <li>
                                <div class="flex" [attr.qa]="'prod-' + (i + 1)">
    
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path id="Product_Icon" data-name="Product Icon" d="M0,8.864V1.688A1.687,1.687,0,0,1,1.688,0H8.864a1.687,1.687,0,0,1,1.193.494l7.449,7.449a1.687,1.687,0,0,1,0,2.386L10.33,17.506a1.688,1.688,0,0,1-2.386,0L.494,10.057A1.687,1.687,0,0,1,0,8.864ZM3.938,2.25A1.688,1.688,0,1,0,5.625,3.938,1.687,1.687,0,0,0,3.938,2.25Z" fill="#b5b5b5"/>
                                    </svg>
                                    <div>
                                        <strong class="product" qa="pr-name">{{ p.name }}</strong>
                                            <ul class="trade-names" qa="tr-name-ul">
                                                @for (tradeName of p.tradeNames; track tradeName.name; let i = $index) {
                                                    <li>
                                                        <span [attr.qa]="'trade-' + (i + 1)">{{ tradeName.name }}</span> <span qa="tr-desc">{{ tradeName.description }}</span>
                                                    </li>
                                                }
                                            </ul>
                                    </div>
                                </div>
                            </li>
                            }
                        </ul>
                    </div>
                }
            </div>
            <app-rate-section-footer [rating]="productsRating" (ratingChange)="onRatingChange.emit($event)"/>
        </div>
    }

    @if (company?.competitors?.length)
    {
        <div class="section" qa="compt">
            <div class="flex">
                <h1>Competitors</h1>
                <app-rate-section [rating]="competitorsRating" (ratingChange)="onRatingChange.emit($event)"/>
            </div>
            <div class="list-view">
                @for (comp of company?.competitors; track comp.name; let i = $index) {
                    <div class="list-item flex" [attr.qa]="'comp-' + (i + 1)">
                        <strong class="product" qa="comp-name">{{ comp.name }}</strong>
                        <div>
                            @for (tradeName of comp.tradeNames; track tradeName; let last = $last; let i = $index) {
                                <span [attr.qa]="'tr-name-' + (i + 1)">{{ tradeName }}@if(!last){, }</span>
                            }
                        </div>
                    </div>
                }
            </div>
            <app-rate-section-footer [rating]="competitorsRating" (ratingChange)="onRatingChange.emit($event)"/>
        </div>
    }

    @if (company?.strategy?.length)
    {
        <div class="section" qa="str">
            <div class="flex">
                <h1>Strategy</h1>
                <app-rate-section [rating]="strategyRating" (ratingChange)="onRatingChange.emit($event)"/>
            </div>
    
            <div class="list-view">
                @for (x of company?.strategy; track x.name; let i = $index) {
                    <div class="list-item strategy" [attr.qa]="'str-' + (i + 1)">            
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22.5" viewBox="0 0 18 22.5">
                            <path id="Strategy_Icon" data-name="Strategy Icon" d="M17.109,13.522l-1.9.847a1.5,1.5,0,0,1-1.166.022l-.6-.24a1.5,1.5,0,0,1-.879-.961l-.432-1.437a1.125,1.125,0,0,0-.588-.734L10.5,10.5v2.359a2.25,2.25,0,0,0,1.244,2.013l2.682,1.343A3.75,3.75,0,0,1,16.5,19.569v.681H1.507v-9a9,9,0,0,1,9-9h6.935A.563.563,0,0,1,18,2.813a.792.792,0,0,1-.084.355L17.25,4.5l.422.422a1.125,1.125,0,0,1,.328.8V12.15a1.5,1.5,0,0,1-.891,1.372ZM15.562,6.75a.938.938,0,1,0,.937.938.937.937,0,0,0-.937-.937ZM.75,21.75h16.5a.75.75,0,0,1,.75.75V24a.75.75,0,0,1-.75.75H.75A.75.75,0,0,1,0,24V22.5A.75.75,0,0,1,.75,21.75Z" transform="translate(0 -2.25)" fill="#b5b5b5"/>
                        </svg>
                        <div class="flex-column">
                            <strong class="product" qa="str-na">{{ x.name }}</strong>
                            <p qa="str-desc">{{ x.description }}</p>
                        </div>
                    </div>
                }            
            </div>
            <app-rate-section-footer [rating]="strategyRating" (ratingChange)="onRatingChange.emit($event)"/>
        </div>
    }
</div>

