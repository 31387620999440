import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ELearningModule, ElearningService} from "../../../../services/elearning.service";

@Component({
    selector: 'app-recommended-elearning',
    standalone: true,
    imports: [],
    templateUrl: './recommended-elearning.component.html',
    styleUrl: './recommended-elearning.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendedElearningComponent {
    
    @Input() authenticated = false;
    
    @Input() modules: ELearningModule[] = [
        ElearningService.modules[2],
        ElearningService.modules[4],
        ElearningService.modules[5],
    ];
    
}
