import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-step-buttons',
    standalone: true,
    imports: [],
    templateUrl: './step-buttons.component.html',
    styleUrl: './step-buttons.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepButtonsComponent {
    @Output() onBack = new EventEmitter();
    @Output() onNext = new EventEmitter();
    
    @Input() backVisible = true;
    @Input() nextEnabled = false;

}
