import {IReportPart} from "../../pdf-export/ireport-part";
import {DiscussionTopic} from "../../../model";
import {CustomerHandoutsGenerator} from "../customer-handouts-generator";
import {TextSection} from "./text-section";

export class CustomerHandoutDataSection implements IReportPart {

    maxWidth = 141.742;
    pageHeight = 739.733;
    sectionMarginBottom = 9.414;
    titleMarginBottom = 3.75;

    columnsX = [63.620, 243.385, 423.987];
    column = 0;
    columnsY = 0;

    checkboxSize = 10.558;

    constructor(private pdf: CustomerHandoutsGenerator, private items: DiscussionTopic[], private color: string) {
    }

    draw(y: number): number {
        this.columnsY = y;

        const pdf = this.pdf;

        this.items.forEach((topic, i) => {
            const title = new TextSection(pdf, `${i + 1}. ${topic.title}`, this.maxWidth, 'Raleway-Bold', 10);
            if (y + title.lineHeight > this.pageHeight) {
                y = this.addSpace();
            }

            pdf.doc.setLineWidth(1.1);
            pdf.doc.rect(this.columnsX[this.column] - 19.663, y, this.checkboxSize, this.checkboxSize);
            
            y = this._draw(title, y);

            const desc = new TextSection(pdf, topic.description, this.maxWidth, 'Raleway-Medium', 9);
            y = this._draw(desc, y) + this.sectionMarginBottom;
        });

        pdf.doc.setTextColor(this.color);
        const note = new TextSection(pdf, 'Are we missing any others? Please add them here.', this.maxWidth, 'Raleway-MediumItalic', 10);
        y = this._draw(note, y + this.sectionMarginBottom);

        return y;
    }

    private _draw(text: TextSection, y: number) {
        y = text.draw(this.columnsX[this.column], y, this.pageHeight);
        if (text.hasMore) {
            y = this.addSpace();
            y = text.draw(this.columnsX[this.column], y, this.pageHeight)
        }
        return y;
    }

    addSpace() {
        if (this.column >= 2) {
            this.columnsY = this.pdf.addPage();
            this.column = 0;
        } else {
            this.column++;
        }

        return this.columnsY;
    }

}